import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'm-web-components';
import allActions from 'actions';

import SingleFilter from './SingleFilter';
import { FiltersButtonToggle } from '../Table.css';

const FiltersList = ({ type, filtersTable = [], category, getValues }) => {
  const dispatch = useDispatch();
  const nameTable = filtersTable[0]?.nameTable;

  const filtersValues = useSelector(state => state.filtersTable[nameTable]);

  const clearFilters = () => {
    //clear filterTable reducer
    dispatch(allActions.filtersTableActions.clearFiltersTable(nameTable));
    //clear pagination reducer
    getValues(type, 'clear', category);
  };

  const onSubmit = () => {
    getValues(type, { ...filtersValues, page: 1 }, category);
  };

  return (
    <>
      <h3>Filtry</h3>
      {filtersTable.map(el => (
        <SingleFilter key={el.nameFilter} {...el} />
      ))}

      <div
        style={{ display: 'flex', marginTop: '50px', justifyContent: 'center' }}
      >
        <FiltersButtonToggle type='button' onClick={clearFilters}>
          Wyczyść filtry
        </FiltersButtonToggle>
        <Button
          type='button'
          colorType='primary'
          onClick={onSubmit}
          padding='5px 10px'
          margin='0 0 0 20px'
          style={{ border: 'none' }}
        >
          Filtruj
        </Button>
      </div>
    </>
  );
};

export default FiltersList;
