import React from 'react';
import * as S from './UserHome.css';
import ZabrzeUM from 'assets/zabrze_UM.png';

const MainSection = () => {
  return (
    <S.MainSection>
      <S.ImgWrapper>
        <img src={ZabrzeUM} alt='Budynek Urzędu Miejskiego w Zabrzu' />
        <aside>
          “Połączenie sił to początek, pozostanie razem to postęp, wspólna praca
          to sukces”
          <p>Henry Ford</p>
        </aside>
      </S.ImgWrapper>
      <S.TextWrapper>
        <h1>Witamy na portalu rekrutacyjnym Urzędu Miejskiego w Zabrzu.</h1>
      </S.TextWrapper>
    </S.MainSection>
  );
};

export default MainSection;
