export const userTypes = [
  {
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    label: 'Kandydat',
    value: 'CANDIDATE',
  },
  {
    label: 'Rekruter',
    value: 'RECRUITER',
  },
];
