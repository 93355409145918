import React from 'react';
import SingleSection from './SingleSection';
import { Controller } from 'react-hook-form';
import { Button, ErrorAlert } from 'm-web-components';
import { ErrorMessage } from '@hookform/error-message';

import * as S from '../CreateAdvertisement.css';
import theme from 'styles/theme';

const FirstStep = ({
  sections,
  handleDrop,
  errors,
  defaultValues,
  control,
  addSection,
  deleteSection,
  onDragEnd,
  advId,
  deleteSubSection,
}) => {
  return (
    <S.WrapperFirstStep>
      <S.Label htmlFor='title'>Tytuł ogłoszenia</S.Label>
      <Controller
        errors={errors}
        defaultValue={defaultValues?.title}
        control={control}
        rules={{ required: 'To pole jest wymagane' }}
        name='title'
        render={({ onChange, value }) => (
          <S.Input
            id='title'
            onChange={onChange}
            value={value}
            placeholder='Wpisz tytuł'
            bigMargin
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name='title'
        render={({ message }) => (
          <ErrorAlert error={message} maxWidth='200px' type='simple' />
        )}
      />

      <S.Label>Szczegóły ogłoszenia</S.Label>
      <S.WrapperSections>
        {sections.map(({ accepts, elements, title, id }, index) => (
          <SingleSection
            key={id}
            id={id}
            accept={accepts}
            onDrop={item => handleDrop(index, item)}
            selectedDictionaries={elements}
            errors={errors}
            defaultValues={defaultValues?.sections?.[index]}
            control={control}
            index={index}
            deleteSection={deleteSection}
            title={title}
            deleteSubSection={deleteSubSection}
            onDragEnd={onDragEnd}
          />
        ))}
        <div>
          <Button
            type='button'
            colorType='light'
            style={{ border: '1px solid #2C81C7', color: theme.secondary }}
            padding='10px 16px'
            fontWeight='500'
            margin='30px auto 0'
            onClick={addSection}
          >
            + Dodaj sekcje
          </Button>
        </div>
      </S.WrapperSections>
    </S.WrapperFirstStep>
  );
};

export default FirstStep;
