import React, { useState } from 'react';
import styled from 'styled-components';
import useWindowSize from 'hooks/useWindowSize';

import MainWrapper from 'components/atoms/MainWrapper';
import Title from 'components/atoms/Title';
import {
  dictionaryType,
  buttons,
} from 'components/shared/AccountSettings/constansSettings';
import Settings from 'components/shared/AccountSettings/Settings';
import Privacy from 'components/shared/AccountSettings/Privacy/Privacy';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { MenuButton } from 'components/shared/AccountSettings/Settings.css';
import TableMenu from 'components/atoms/TableMenu';

const Wrapper = styled(MainWrapper)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`;

const StyledTitle = styled(Title)`
  width: 100%;
`;

const SettingsPage = () => {
  const [width] = useWindowSize();
  const [currentTab, setCurrentTab] = useState(dictionaryType.SETTINGS);

  const menuList = buttons.map((el, i) => (
    <MenuButton
      onClick={() => setCurrentTab(el.type)}
      active={el.type === currentTab}
      key={i}
    >
      <ChevronLeftIcon aria-hidden='false'>
        <title>{el.label}</title>
      </ChevronLeftIcon>{' '}
      {el.label}
    </MenuButton>
  ));

  const horizontalMenu = {
    currentType: currentTab,
    onClick: value => setCurrentTab(value),
    buttons: buttons,
  };

  const renderTab = () => {
    switch (currentTab) {
      default:
      case dictionaryType.SETTINGS:
        return <Settings menuList={menuList} />;
      case dictionaryType.PRIVACY:
        return <Privacy menuList={menuList} />;
    }
  };

  return (
    <Wrapper>
      <StyledTitle>Ustawienia</StyledTitle>
      {width < 768 && <TableMenu {...horizontalMenu} staticPos mobile />}
      {renderTab()}
    </Wrapper>
  );
};

export default SettingsPage;
