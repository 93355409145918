import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Button, Select } from 'm-web-components';

import * as S from '../CreateAdvertisement.css';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getListStyle } from '../dndFunctions';
import SingleSection from './SingleSection';
import { api } from 'API';
import theme from 'styles/theme';

const SecondStep = ({
  sections,
  handleDrop,
  errors,
  defaultValues,
  control,
  addSection,
  deleteSection,
  deleteSubSection,
  onDragEnd,
  formDetails,
  advId,
}) => {
  const [selectedForm, setSelectedForm] = useState(formDetails);
  const [formsList, setFormList] = useState([]);

  useEffect(() => {
    const getForms = async () => {
      const { data } = await api.getAllForms();
      setFormList(data);
    };

    getForms();
  }, []);

  const setTypeOfForm = value => {
    if (value.value) {
      setSelectedForm(value.value);
      control.setValue(`formDetails`, value);
      return;
    }

    setSelectedForm('create');
  };

  const resetForm = () => {
    setSelectedForm(null);
    control.setValue(`formDetails`, null);
  };

  return (
    <S.WrapperFirstStep>
      {selectedForm && (
        <Button
          type='button'
          colorType='light'
          margin='0'
          padding='5px 10px'
          fontWeight='600'
          style={{ border: 'none' }}
          onClick={resetForm}
        >
          Zresetuj formularz
        </Button>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '400px' }}>
          <Controller
            errors={errors}
            defaultValue={formDetails}
            control={control}
            name='formDetails'
            render={({ value }) => (
              <Select
                onChange={setTypeOfForm}
                value={value}
                placeholder='Wybierz formularz'
                bigMargin
                options={formsList}
                isDisabled={selectedForm === 'create'}
              />
            )}
          />
        </div>
        <span style={{ margin: '0 20px' }}>lub</span>
        <Button
          type='button'
          colorType='light'
          style={{ border: '1px solid #2C81C7' }}
          onClick={setTypeOfForm}
        >
          Stwórz nowy formularz
        </Button>
      </div>
      {selectedForm === 'create' && (
        <>
          <S.WrapperSections>
            <DragDropContext onDragEnd={onDragEnd}>
              {sections.map(({ accepts, elements, title, id }, index) => (
                <Droppable key={index} droppableId={`${index}`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      <SingleSection
                        key={id}
                        id={id}
                        accept={accepts}
                        onDrop={item => handleDrop(index, item)}
                        selectedDictionaries={elements}
                        errors={errors}
                        defaultValues={defaultValues?.sections?.[index]}
                        control={control}
                        index={index}
                        deleteSection={deleteSection}
                        title={title}
                        deleteSubSection={deleteSubSection}
                        onDragEnd={onDragEnd}
                        advId={advId}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
            <div>
              <Button
                type='button'
                colorType='light'
                style={{ border: '1px solid #2C81C7', color: theme.secondary }}
                padding='10px 16px'
                fontWeight='500'
                margin='30px auto 0'
                onClick={addSection}
              >
                + Dodaj sekcje
              </Button>
            </div>
          </S.WrapperSections>
        </>
      )}
    </S.WrapperFirstStep>
  );
};

export default SecondStep;
