import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as S from '../Administration.css';
import EditableSection from './EditableSection';
import { messageType } from '../constantsAdministrations';
import Indicator from 'components/atoms/Indicator';
import { api } from 'API';
import { TOAST_POSITION } from 'constans/constantsToast';

const CommunicationSettings = () => {
  const [defaultMessages, setDefaultMessages] = useState();

  useEffect(() => {
    try {
      const getAll = async () => {
        const { data } = await api.getAllDefaultMessages();
        setDefaultMessages(data);
      };
      getAll();
    } catch (err) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas pobierania danych.</p>
          {err.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  }, []);

  const mapSections = messageType.map(type => (
    <EditableSection
      key={type.type}
      type={type}
      title={type.value}
      defaultValues={
        defaultMessages?.find(v => v.type === type.type)?.content ||
        'Brak domyślnej wiadomości, bądź wystąpił problem z pobieraniem wcześniej ustalonej wiadomości.'
      }
    />
  ));

  return (
    <S.BookmarkWrapper>
      {!defaultMessages ? <Indicator /> : mapSections}
    </S.BookmarkWrapper>
  );
};

export default CommunicationSettings;
