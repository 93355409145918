import styled from 'styled-components';
import { centerFlex } from 'styles/mixins';

export const SubsectionWrapper = styled.div``;

export const DictTitle = styled.div`
  border: none;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: none !important; // safari bg fix

  h3 {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 1.8rem;
  }

  svg {
    transform: ${({ isdictvisible }) =>
      isdictvisible ? 'rotate(-180deg)' : null};
    transition: 0.3s;
    margin-right: 20px;
  }
`;

export const DeleteButtonSubSection = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;
  opacity: 0.4;
  transition: 0.3s;
  font-size: 1.4rem;

  ${centerFlex}

  svg {
    fill: ${({ theme }) => theme.grayLight};
    margin-right: 5px;
  }

  :hover {
    opacity: 0.8;
  }
`;

export const SubsectionDetails = styled.div`
  height: ${({ isdictvisible }) => (isdictvisible ? 'auto' : 0)};
  overflow: ${({ isdictvisible }) => (isdictvisible ? 'visible' : 'hidden')};
`;
