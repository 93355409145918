import styled from 'styled-components';
import { device } from 'styles/devices';
import { centerFlex, scroll } from 'styles/mixins';

export const Main = styled.div`
  background-color: ${({ theme }) => theme.colorLighter};
  display: flex;
  font-family: 'Libre Franklin', sans-serif;
  position: relative;
  overflow-y: auto;
  max-height: 80vh;
`;

export const DictionaryListWrapper = styled.aside`
  background-color: ${({ theme }) => theme.colorLight};
  width: 400px;
  min-height: 500px;
  max-height: 100%;
  position: sticky;
  top: 0;

  @media ${device.tablet} {
    min-width: 400px;
  }
`;

export const DictionaryListTitle = styled.h3`
  background-color: ${({ theme }) => theme.secondary};
  color: #fff;
  padding: 16px;
  font-weight: 600;
  font-size: 1.6rem;
  margin: 0;
`;

export const DictionaryListSearch = styled.div`
  position: relative;
  display: flex;
  box-shadow: 1px 1px 15px rgba(0, 4, 40, 0.03);
  border-bottom: 1px solid rgba(149, 150, 151, 0.2);

  button {
    position: absolute;
    left: 5px;
    top: 4px;
    background-color: transparent;
    border: none;
    height: 100%;
  }

  input {
    background-color: transparent;
    display: block;
    border: none;
    width: 100%;
    height: 100%;
    padding: 16px;
    font-size: 1.6rem;
    font-family: Open Sans;
    padding-left: 50px;
  }

  svg {
    fill: ${({ theme }) => theme.grayLight};
  }
`;

export const DictionaryList = styled.ul`
  min-height: 500px;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  ${scroll};
`;

export const DictionaryListitem = styled.li`
  transition: 0.3s;

  div {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
    padding: 20px;
    text-align: left;
    cursor: pointer;
  }

  svg {
    margin-right: 20px;
  }

  :hover {
    transform: translateX(10px);
  }
`;

export const Form = styled.div`
  flex-grow: 1;
`;

export const WrapperFirstStep = styled.div`
  padding: 40px;
`;

export const WrapperSections = styled.div`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  padding-bottom: 30px;
`;

export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.8rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 2px;
  margin-bottom: ${({ bigMargin }) => bigMargin && '40px'};

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => theme.inputBorder};
  }
`;

export const WrapperSingleSection = styled.div`
  background-color: #fff;
  padding: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.lighter};

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${({ theme }) => theme.secondary};
  }
`;

export const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  h2 {
    color: ${({ theme }) => theme.secondary};
    margin: 0;
    font-size: 2.1rem;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;

  input {
    padding: 5px 10px;

    color: ${({ theme }) => theme.secondary};
    border: 1px solid
      ${({ isedit, theme }) => (isedit ? theme.inputBorder : 'transparent')};
    background-color: transparent;
    font-weight: 700;
    font-size: 2.1rem;
    font-family: Open Sans;
  }

  svg {
    fill: ${({ theme }) => theme.secondary};
    margin-right: 20px;
    font-size: 3rem;
    transform: ${({ issubionsvisible }) =>
      issubionsvisible ? 'rotate(-180deg)' : null};
    transition: 0.3s;
  }
`;

export const ButtonsTitleSection = styled.div`
  width: 100px;
  display: flex;
  align-items: space-between;
  justify-content: center;
`;

export const SectionDetails = styled.div`
  height: ${({ issectionvisible }) => (issectionvisible ? 'auto' : 0)};
  overflow: ${({ issectionvisible }) =>
    issectionvisible ? 'visible' : 'hidden'};

  div {
    -webkit-appearance: none; //safari button background fix
  }
`;

export const DropContainer = styled.div`
  ${centerFlex}

  padding: 30px;
  margin: 20px 0;
  border: 3px dashed black;
`;
