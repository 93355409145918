import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Td, Tr } from 'react-super-responsive-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { api } from 'API';
import Modal from 'components/shared/Modal';
import ModalTableTools from 'components/tables/ModalTableTools';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import HiddenMenu from 'components/shared/HiddenMenu';
import { TOAST_POSITION } from 'constans/constantsToast';

const RowStyled = styled(Tr)`
  background-color: ${({ selected }) => (selected ? '#E6F0F8' : 'transparent')};
  transition: 0.4s;

  :hover {
    border-bottom: 1px solid #ebebeb;
  }
`;

const DictionaryTableRow = ({
  row,
  cols,
  selectedRows,
  handleOnChangeSelectedRows,
  dragOver,
  getValues,
  type,
  category,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const deleteSingleDict = async () => {
    try {
      await api.deleteSingleDictionary(row.id);
      setModalOpen(false);
      getValues(type, null, category);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas usuwania słowników.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const cells = Object.entries(row).map(([, v], idx) => (
    <Td key={v} dragOver={cols[idx] === dragOver}>
      {idx === 0 && (
        <>
          <input
            id={`checkbox_${v}`}
            type='checkbox'
            checked={selectedRows?.includes(row.id)}
            onChange={() => handleOnChangeSelectedRows(row.id)}
          />
          <label htmlFor={`checkbox_${v}`} style={{ display: 'none' }}>
            Zaznacz
          </label>
        </>
      )}

      {row[cols[idx].fieldName]}
    </Td>
  ));

  return (
    <>
      <RowStyled
        style={{
          backgroundColor: `${
            selectedRows?.includes(row.id) ? '#E6F0F8' : 'transparent'
          }`,
        }}
      >
        {cells}
        <Td>
          <Link to={`/admin/dictionary/create-field/${row.id}`}>
            <EditIcon
              style={{ color: '#1690F2', cursor: 'pointer' }}
              aria-hidden='false'
            >
              <title>Edytuj</title>
            </EditIcon>
            <HiddenMenu text='Edytuj' />
          </Link>
        </Td>
        <Td>
          <DeleteIcon
            style={{ color: '#D5001F', cursor: 'pointer' }}
            onClick={() => setModalOpen(true)}
            aria-hidden='false'
          />
        </Td>
      </RowStyled>
      <Modal open={isModalOpen} setOpen={setModalOpen}>
        <ModalTableTools
          title='Czy na pewno chcesz zamknąć wybrany słownik?'
          buttonLabel='Usuń'
          confirmFunc={deleteSingleDict}
          setOpen={setModalOpen}
        />
      </Modal>
    </>
  );
};

export default DictionaryTableRow;
