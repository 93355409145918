import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import * as S from './Singles.css';
import styled from 'styled-components';

const Label = styled.label`
  margin: 0 10px;
  color: ${({ theme }) => theme.tableFileds};
`;

const SingleChoice = ({ label, content }) => {
  return (
    <S.SingleWrapper>
      <FormControlLabel
        control={<Checkbox color='primary' checked={content?.content} />}
        label={<Label>{label}</Label>}
      />
    </S.SingleWrapper>
  );
};

export default SingleChoice;
