import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Redirect, Link, useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ControlForm, ErrorAlert } from 'm-web-components';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Wrapper,
  Form,
  SubmitButton,
  FieldsWrap,
  FormWrap,
  ForgotPass,
  GoTo,
  Title,
  Description,
  ZoomButtons,
} from './Login.css';
import { AccessibilityLabel } from 'components/tables/Table.css';

import { InputMui } from 'components/atoms/Input';
import useAuth from 'auth/useAuth';
import { api } from 'API';

import Logo from 'assets/logo-zabrze.png';

import { ReactComponent as AMinus } from 'assets/icons/A_minus.svg';
import { ReactComponent as APlus } from 'assets/icons/A_plus.svg';
import { TOAST_POSITION } from 'constans/constantsToast';

const LoginInput = styled(InputMui)`
  label {
    font-size: 1.5rem;
    transform: none;
    color: ${({ theme }) => theme.primary};
    position: relative;
    font-weight: 600;
  }

  input {
    border: 1px solid #b2b2b2;
    height: 48px;
    padding: 0 5px;
    width: 100%;
  }
`;

const formFields = [
  {
    as: (
      <LoginInput
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),
    name: 'email',
    label: 'E-mail',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    as: (
      <LoginInput
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),
    name: 'password',
    label: 'Hasło',
    rules: { required: 'To pole jest wymagane' },
    type: 'password',
  },
];

const Login = ({ changeZoom }) => {
  const [isContactModalOpen, setContactModalOpen] = useState(false);

  const auth = useSelector(state => state.auth);
  const { token } = useParams();
  const { login } = useAuth();
  const { handleSubmit, errors, control } = useForm();
  const history = useHistory();

  const [isLogin, setIsLogin] = useState(auth.isAuthenticated);
  const [errorsMessages, setErrorsMessages] = useState([]);

  useEffect(() => {
    const isToken = async () => {
      if (token) {
        try {
          await api.sendToken(token);

          toast.success(
            'Konto zostało aktywowane! Teraz należy się zalogować.',
            TOAST_POSITION
          );
        } catch (e) {
          toast.error(
            'Coś poszło nie tak. Na Twój e-mail wysłaliśmy nowy link aktywacyjny.',
            TOAST_POSITION
          );
        }
      }
    };
    isToken();
  }, []);

  useEffect(() => {
    document.title = 'Zaloguj się - Portal Rekrutacyjny Zabrze';
  }, [isLogin]);

  useEffect(() => {
    setIsLogin(auth.isAuthenticated);
  }, [auth.isAuthenticated]);

  const onSubmit = async data => {
    try {
      const resp = await login(data);

      if (resp.status === 403) {
        toast.error(
          'Twoje konto nie jest nieaktywne. Skorzystaj z linku, który został wysłany na Twój adres mailowy',
          TOAST_POSITION
        );
        return;
      }

      setErrorsMessages(resp?.data?.errors);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  };

  const fields = formFields.map(el => (
    <AccessibilityLabel muiinput>
      <p> {el.name}</p>
      <ControlForm key={el.name} {...el} errors={errors} control={control} />
    </AccessibilityLabel>
  ));

  if (isLogin) {
    return <Redirect to={history.location.state?.from || '/'} />;
  }

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <ZoomButtons>
        <APlus
          onClick={() => changeZoom(0.1)}
          style={{ marginRight: '20', cursor: 'pointer' }}
        />
        <AMinus
          onClick={() => changeZoom(-0.1)}
          style={{ cursor: 'pointer' }}
        />
      </ZoomButtons>
      <Form>
        <FormWrap>
          <Link to='/' style={{ cursor: 'pointer' }}>
            <img src={Logo} alt='logo Zabrze' />
          </Link>
          <Title>Zaloguj się</Title>
          <Description>
            Zaloguj się do portalu rekrutacyjnego aby złożyć lub przejrzeć
            aplikacje.
          </Description>
          <FieldsWrap>
            {fields}
            {errorsMessages?.map(error => (
              <ErrorAlert
                key={error.field}
                type='simple'
                error={error.defaultMessage}
                maxWidth='380px'
              />
            ))}
            <ForgotPass>
              <Link to='/forgotPassword'>Zapomniałeś hasła?</Link>
            </ForgotPass>
            <SubmitButton>Zaloguj się</SubmitButton>
          </FieldsWrap>
          <GoTo>
            Nie masz konta? <Link to='/signup'>Zarejestruj się!</Link>
          </GoTo>
        </FormWrap>
      </Form>
    </Wrapper>
  );
};

export default Login;
