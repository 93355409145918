export const bookmarks = {
  generator: {
    name: 'generator',
    label: 'Generowanie raportów',
  },
  library: {
    name: 'library',
    label: 'Biblioteka raportów',
  },
};
