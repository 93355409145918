import React from 'react';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import EditIcon from '@material-ui/icons/Edit';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

export const statusName = {
  NEW: {
    label: 'Złożono',
    value: 'NEW',
  },

  INTERVIEW_INV: {
    label: 'Zaproszony na rozmowę',
    value: 'INTERVIEW_INV',
  },

  INTERVIEW_INV_CONFIRM: {
    label: 'Zaproszony na rozmowę',
    value: 'INTERVIEW_INV_CONFIRM',
  },

  INTERVIEW_INV_REJECT: {
    label: 'Zaproszony na rozmowę',
    value: 'INTERVIEW_INV_REJECT',
  },

  TEST_INV: {
    label: 'Zaproszony na test',
    value: 'TEST_INV',
  },

  TEST_INV_CONFIRM: {
    label: 'Zaproszony na test',
    value: 'TEST_INV_CONFIRM',
  },

  TEST_INV_REJECT: {
    label: 'Zaproszony na test',
    value: 'TEST_INV_REJECT',
  },

  FULFILL_REQUIREMENTS: {
    label: 'Spełnia wymagania',
    value: 'FULFILL_REQUIREMENTS',
  },

  NOT_FULFILL_REQUIREMENTS: {
    label: 'Nie spełnia wymagań',
    value: 'NOT_FULFILL_REQUIREMENTS',
  },

  RESIGN: {
    label: 'Rezygnacja',
    value: 'RESIGN',
  },
};

export const confirmStatus = ['TEST_INV', 'INTERVIEW_INV'];

export const mailStatus = {
  CONFIRMED: {
    label: 'Potwierdzenie',
    value: 'CONFIRM',
  },

  REJECT: {
    label: 'Rezygnacja',
    value: 'REJECT',
  },

  PENDING: {
    label: 'Oczekuje',
    value: 'PENDING',
  },
};

export const menuItems = [
  {
    icon: (
      <VisibilityOutlinedIcon aria-hidden='false'>
        <title>Zobacz aplikację</title>
      </VisibilityOutlinedIcon>
    ),
    label: 'Zobacz aplikację',
    visible: 'active',
    to: '/user/single-my-applications',
  },
  {
    icon: (
      <EditIcon aria-hidden='false'>
        <title>Edytuj aplikację</title>
      </EditIcon>
    ),
    label: 'Edytuj aplikację',
    visible: 'active',
    to: '/user/edit-my-application',
  },
  {
    icon: (
      <HighlightOffOutlinedIcon aria-hidden='false'>
        <title>Zrezygnuj</title>
      </HighlightOffOutlinedIcon>
    ),
    label: 'Zrezygnuj',
  },
  {
    icon: (
      <RotateLeftIcon aria-hidden='false'>
        <title>Wznów</title>
      </RotateLeftIcon>
    ),
    label: 'Wznów',
    visible: 'notDefault',
    advId: 0,
  },
  {
    icon: (
      <PrintOutlinedIcon aria-hidden='false'>
        <title>Drukuj</title>
      </PrintOutlinedIcon>
    ),
    label: 'Drukuj aplikację',
    visible: 'active',
    to: '/user/print-my-application',
  },
];
