import React from 'react';
import * as S from './UserHome.css';

const TextSection = () => {
  return (
    <S.TextSection>
      <p>
        Zgodnie z Regulaminem naboru na wolne stanowiska urzędnicze, w tym
        kierownicze stanowiska urzędnicze, pomocnicze i obsługi w Urzędzie
        Miejskim w Zabrzu oraz na wolne stanowiska dyrektorów miejskich
        jednostek organizacyjnych nabór odbywa się na podstawie systemu
        elektronicznego.
      </p>
      <p>
        Zapraszamy do zapoznania się z aktualnymi ofertami pracy, które są
        opublikowane w zakładce Ogłoszenia.
      </p>
    </S.TextSection>
  );
};

export default TextSection;
