import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import styled from 'styled-components';
import { format } from 'date-fns';

import theme from 'styles/theme';
import { statusOptionsWithColor } from 'constans/constansAdv';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

const SubStatus = styled.span`
  text-align: center;
  align-self: flex-start;
  border: 1px solid
    ${({ color, theme }) =>
      color === 'red'
        ? '#d5001f'
        : color === 'green'
        ? '#459937'
        : theme.secondary};
  color: ${({ color, theme }) =>
    color === 'red'
      ? '#d5001f'
      : color === 'green'
      ? '#459937'
      : theme.secondary};
  border-radius: 2px;
  padding: 1px 10px;
  margin: 5px;
`;

const CommunicationRow = ({ row, cols, dragOver }) => {
  const cells = Object.entries(row).map(([, v], idx) => {
    return (
      <Td key={v.name || v} dragOver={cols[idx] === dragOver}>
        {cols[idx]?.fieldName === 'status' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              color: theme[row.invitationStatus],
            }}
          >
            {row?.status?.applicationStatus && (
              <SubStatus
                color={
                  statusOptionsWithColor[row?.status?.applicationStatus]?.color
                }
              >
                {statusOptionsWithColor[row?.status?.applicationStatus]?.label}
              </SubStatus>
            )}

            {row?.status?.invitationStatus && (
              <SubStatus
                color={
                  statusOptionsWithColor[row?.status?.invitationStatus]?.color
                }
              >
                {statusOptionsWithColor[row?.status?.invitationStatus]?.label}
              </SubStatus>
            )}
          </div>
        )}
        {cols[idx]?.fieldName === 'position' && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                color: theme.secondary,
                fontWeight: '600',
                marginTop: '5px',
              }}
            >
              <BusinessCenterIcon style={{ marginRight: '5px' }} />{' '}
              {row.position}
            </span>
          </div>
        )}

        {cols[idx]?.fieldName === 'date' && (
          <span>{format(new Date(row.date), 'yyyy-MM-dd HH:mm')}</span>
        )}

        {cols[idx]?.fieldName !== 'date' &&
          cols[idx]?.fieldName !== 'advertisementName' &&
          cols[idx]?.fieldName !== 'status' &&
          cols[idx]?.fieldName !== 'position' &&
          row[cols[idx]?.fieldName]}
      </Td>
    );
  });

  return <Tr>{cells}</Tr>;
};

export default CommunicationRow;
