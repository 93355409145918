import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from 'API';
import * as S from '../SingleAdvertisement.css';
import Indicator from 'components/atoms/Indicator';
import Table from 'components/tables/Table';
import styled from 'styled-components';
import { styledTable } from 'components/tables/helpers/changeStylesBrakpoints';
import { TOAST_POSITION } from 'constans/constantsToast';

const columns = [
  { title: 'Kandydat', fieldName: 'candidateDetails', draggable: true },
  { title: 'Etap rekrutacji', fieldName: 'type', draggable: true },
  { title: 'Wiadomość', fieldName: 'content', draggable: true },
  { title: 'Wysłano przez', fieldName: 'sendBy', draggable: true },
  { title: 'Data i godzina', fieldName: 'date', draggable: true },
  { title: 'Status', fieldName: 'invitationStatus', draggable: true },
];

const customTableStyle = {
  width: '100%',
};

const Wrapper = styled.div`
  //change breakpoint for mobile view
  @media screen and (max-width: 870px) {
    ${styledTable}
  }
`;

const TYPE = 'invitation';

const AdvInvitations = ({ id }) => {
  const [users, setUsers] = useState();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const { data } = await api.getAdvertisementInvitations(id);
      setUsers(data);
    } catch (err) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas pobierania danych.</p>
          {err.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  return (
    <Wrapper>
      {!users ? (
        <Indicator />
      ) : (
        <S.MainWrapper style={{ padding: 0 }}>
          <Table
            type={TYPE}
            columns={columns}
            data={users}
            tableToolData={''}
            tableWrapperStyle={customTableStyle}
            tableStyle={{ border: 'none' }}
            simple
          />
        </S.MainWrapper>
      )}
    </Wrapper>
  );
};

export default AdvInvitations;
