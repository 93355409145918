import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Indicator from 'components/atoms/Indicator';
import AdvDescDetails from 'components/shared/AdvertisementDetails/AdvDetails';
import { SaveButton } from 'components/atoms/Buttons';
import MainWrapper from 'components/atoms/MainWrapper';
import { api } from 'API';
import * as S from 'components/guest/Advertisements/Advertisement.css';
import { Button } from 'm-web-components';
import { TOAST_POSITION } from 'constans/constantsToast';

const SingleAdvertisement = () => {
  const { id } = useParams();
  const history = useHistory();
  const [advData, setAdvData] = useState(null);
  useEffect(() => {
    const getAdv = async () => {
      try {
        const { data } = await api.getAdvertisement(id);
        setAdvData(data);
      } catch (err) {
        toast.error(
          'Wystąpił problem podczas pobierania informacji o ogłoszeniu.',
          TOAST_POSITION
        );
      }
    };

    getAdv();
  }, []);

  useEffect(() => {
    document.title = advData?.title + ' - Portal Rekrutacyjny Zabrze';
  }, [advData]);

  const applyButton = (
    <Link
      to={`/user/application-form/${id}`}
      style={{ textDecoration: 'none' }}
    >
      <SaveButton isVisible={advData?.status === 'ACTIVE'}>Aplikuj</SaveButton>
    </Link>
  );

  return (
    <MainWrapper>
      {!advData ? (
        <Indicator />
      ) : (
        <>
          <S.ContentHeader isSelectedAdv={true}>
            <S.Title>Oferty pracy</S.Title>
          </S.ContentHeader>
          <S.SingleWrapper>
            <S.GoBack onClick={() => history.goBack()}>
              <ArrowBackIosIcon aria-hidden='false'>
                <title>Wróć</title>
              </ArrowBackIosIcon>
              Wróć do listy ofert
            </S.GoBack>
            <S.BlankContainer>
              <S.SingleWrapperHeader>
                <S.SingleTitle>
                  <h2>{advData.formDetails?.label}</h2>
                  <p>{advData.info.proposalNr}</p>
                </S.SingleTitle>
                <div>
                  {applyButton}
                  <Link
                    to={`/print-adv/${id}`}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Button
                      type='button'
                      margin='10px 0 0'
                      colorType='light'
                      padding='5px 10px'
                      width='100px'
                    >
                      <S.PrintText>Drukuj</S.PrintText>
                    </Button>
                  </Link>
                </div>
              </S.SingleWrapperHeader>
              <AdvDescDetails data={advData.details} print />
              <br />
              {applyButton}
            </S.BlankContainer>
          </S.SingleWrapper>
        </>
      )}
    </MainWrapper>
  );
};

export default SingleAdvertisement;
