import styled from 'styled-components';
import { device } from 'styles/devices';

export const ListWrapper = styled.div`
  width: 100%;
  display: block;
`;

export const ListElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  cursor: pointer;

  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.secondary};
  }

  p {
    display: flex;
    justify-content: flex-start;
    font-size: 1.6rem;

    svg {
      margin: auto 10px auto 0px;
    }
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
    transform: translateX(-100%);
  }
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 30px !important;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.desktop} {
    transform: ${({ isSelectedAdv }) => !isSelectedAdv && 'translateY(85px)'};
    margin-left: ${({ isSelectedAdv }) => isSelectedAdv && '45px'};
  }
`;

// Single

export const SingleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px;
  background-color: ${({ theme }) => theme.colorLighter};
`;

export const GoBack = styled.button`
  max-width: 200px;
  margin: auto auto 30px 0px;
  border: none;
  display: flex;
  align-content: center;
  background-color: transparent;
  color: ${({ theme }) => theme.secondary};
  font-size: 1.8rem;
  cursor: pointer;

  svg {
    width: 16px;
    margin: auto auto 10px 0px;
  }
`;

export const SingleWrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const SingleTitle = styled.div`
  display: block;

  h2 {
    font-size: 2.4rem;
    font-weight: 600;
  }

  p {
    font-size: 1.6rem;
  }
`;

export const BlankContainer = styled.div`
  width: 100%;
  padding: 45px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.inputBorder};
`;

export const PrintText = styled.span`
  color: ${({ theme }) => theme.secondary};
`;
