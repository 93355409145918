import styled from 'styled-components';
import { device } from 'styles/devices';

export const MainWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  padding: 40px;

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

export const BlankContainer = styled.div`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.lighter};
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  min-width: 250px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;

export const Main = styled.div`
  flex-grow: 1;
`;

export const Aside = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;

    div {
      margin-right: 20px;
    }
  }

  @media ${device.desktop} {
    flex-direction: column;
    width: 300px;
    margin-left: 40px;

    div {
      margin-right: 0;
    }
  }
`;

export const ContainerTitle = styled.h3`
  color: ${({ theme }) => theme.grayLight};
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 30px;
`;

export const StatusInfo = styled.p`
  display: flex;
  align-items: center;

  svg {
    margin-right: 20px;
  }
`;

export const SingleMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media ${device.tablet} {
    flex-direction: row;
  }

  p {
    margin-bottom: 10px;
    width: 250px;
    font-weight: 600;
    color: ${({ theme }) => theme.grayDark};
  }

  span {
    color: ${({ theme }) => theme.grayLight};
  }
`;

export const Button = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  background: #fff;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
`;
