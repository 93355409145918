import axios from 'axios';
import qs from 'qs';

export const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1`;
export const ACCESS_TOKEN = 'accessToken';

export const sendRequest = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'DENY',
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
    Expires: 0,
    'X-XSS-Protection': '1;mode=block',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'Expect-CT': 'enforce, max-age=86400',
  },
});

export const sendRequestBlob = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/octet-stream',
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'DENY',
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
    Expires: 0,
    'X-XSS-Protection': '1;mode=block',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'Expect-CT': 'enforce, max-age=86400',
  },
});

sendRequest.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  },
  error => {
    if (
      (window.location.pathname.includes('login') ||
        window.location.pathname.includes('signup')) &&
      error?.response?.status === 401
    ) {
      return;
    }
    if (error?.response?.status === 401) {
      window.location.href = '/login';
      return;
    }

    console.log(error?.response.data);
    throw error;
  }
);

sendRequestBlob.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.responseType = 'blob';
    return config;
  },
  error => {
    if (
      (window.location.pathname.includes('login') ||
        window.location.pathname.includes('signup')) &&
      error?.response?.status === 401
    ) {
      return;
    }
    if (error?.response?.status === 401) {
      window.location.href = '/login';
      return;
    }

    console.log(error?.response.data);
    throw error;
  }
);

export const api = {
  login: body => sendRequest.post('/auth/login', body),
  signup: body => sendRequest.post('/auth/register', body),
  me: () => sendRequest.get('/auth/me'),
  remindPassword: body => sendRequest.post('/auth/remind', body),

  postContactForm: body => sendRequest.post('/contact', body),

  sendToken: token => sendRequest.get(`/auth/verify-email?token=${token}`),
  refreshApplication: appId => sendRequest.put(`application/${appId}/refresh`),

  getTableResults({ name, category, suffix, ...paramsObj }) {
    const params = qs.stringify(paramsObj, { arrayFormat: 'repeat' });
    return sendRequest.get(`/${category}${name}${suffix}?${params}`);
  },

  getDepartment: () => sendRequest.get('/recruiter/department'),

  putAccountDetails: body => sendRequest.put('/user/details', body),
  getAccountDetails: () => sendRequest.get('/user/details'),
  putAccountPassword: body => sendRequest.put('/user/password', body),
  getCode: () => sendRequest.get('/user/code'),
  deleteAccount: body => sendRequest.delete('/user/delete', body),

  //dictionary
  postSingleDictionary: body => sendRequest.post('/recruiter/dictionary', body),
  getSingleDictionary: id => sendRequest.get(`/recruiter/dictionary/${id}`),
  putSingleDictionary: (id, body) =>
    sendRequest.put(`/recruiter/dictionary/${id}`, body),
  deleteSingleDictionary: id =>
    sendRequest.delete(`/recruiter/dictionary/${id}`),

  //advertisements
  getAllDictionary: dictName =>
    sendRequest.get(
      `/recruiter/dictionary/creator?dType=${dictName}&page=1&size=1000`
    ),
  getSingleAdvertisementDetails: id =>
    sendRequest.get(`/recruiter/notice/${id}/details`),
  deleteAdvertisement: aId => sendRequest.delete(`/recruiter/notice/${aId}`),
  putCloseAdvertisement: aId =>
    sendRequest.put(`/recruiter/notice/${aId}/close`),
  putPublishAdvertisement: (aId, body) =>
    sendRequest.put(`/recruiter/notice/${aId}/publish`, body),
  putArchivizeAdvertisement: (aId, body) =>
    sendRequest.put(`/recruiter/notice/${aId}/archive`, body),
  getAdvertisementInvitations: id =>
    sendRequest.get(`/recruiter/notice/${id}/invitations`),

  getUserInvitations: uId =>
    sendRequest.get(`/recruiter/application/${uId}/invitations`),

  // creating adv
  getSingleAdvertisementForCreator: id =>
    sendRequest.get(`/recruiter/notice/${id}/creator`),
  postSingleAdvertisement: body => sendRequest.post(`/recruiter/notice/`, body),
  putSingleAdvertisement: (id, body) =>
    sendRequest.put(`/recruiter/notice/${id}`, body),
  advertisemenetToArchive: id =>
    sendRequest.put(`/recruiter/notice/${id}/archive`),

  //candidates
  changeCandidateStatus: (appId, body) =>
    sendRequest.put(`/recruiter/application/${appId}/status`, body),
  getSingleCandidateApplication: id =>
    sendRequest.get(`recruiter/application/${id}/details`),
  getAllCandidateApplications: uId =>
    sendRequest.get(`recruiter/application/${uId}/names`),
  putCandidateInvite: (aId, uId, body) =>
    sendRequest.put(
      `recruiter/application/${aId}/user/${uId}/invitation`,
      body
    ),
  getCandidatesFiltersOptions: () =>
    sendRequest.get(`/recruiter/application/filters`),

  deleteCandidateApplication: appId =>
    sendRequest.delete(`/recruiter/application/${appId}`),

  putArchivizeNotice: (aId, body) =>
    sendRequest.put(`/recruiter/notice/${aId}/archive`, body),

  saveNote: (id, body) =>
    sendRequest.put(`recruiter/application/${id}/note `, body),

  putInformFulfilled: (appId, body) =>
    sendRequest.put(`recruiter/application/${appId}/fulfilled/message`, body),
  //forms
  getAllForms: () => sendRequest.get('/recruiter/form'),
  getSingleFormForCreator: id =>
    sendRequest.get(`/recruiter/form/${id}/creator`),
  postSingleForm: body => sendRequest.post(`/recruiter/form/`, body),
  putSingleForm: (id, body) => sendRequest.put(`/recruiter/form/${id}`, body),
  deleteForm: fId => sendRequest.delete(`/recruiter/form/${fId}`),

  //file
  urlDownloadFile: fId => sendRequestBlob.get(`/file/${fId}`),

  putCandidateResign: appId =>
    sendRequest.put(`/candidate/applications/${appId}/resign`),
  putCandidateRenew: appId =>
    sendRequest.put(`/candidate/applications/${appId}/resume`),

  //administration
  putDefaultMessage: (mType, body) =>
    sendRequest.put(`/admin/message/${mType}`, body),
  getSingleDefaultMessage: mType =>
    sendRequest.get(`/recruiter/message/${mType}`),
  getAllDefaultMessages: () => sendRequest.get('/admin/message'),

  deleteUser: uId => sendRequest.delete(`/admin/user/${uId}/remove`),
  putActivate: uId => sendRequest.put(`/admin/user/${uId}/activate`),
  putResetPassword: uId => sendRequest.put(`/admin/user/${uId}/reset/password`),
  putChangePassword: (uId, body) =>
    sendRequest.put(`/admin/user/${uId}/change/password`, body),
  putChangeEmail: (uId, body) =>
    sendRequest.put(`/admin/user/${uId}/change/email`, body),
  putChangeRole: (uId, body) =>
    sendRequest.put(`/admin/user/${uId}/change/role`, body),

  postCreateUser: (userType, body) =>
    sendRequest.post(`/admin/register/${userType}`, body),

  getLogFile: fName => sendRequestBlob.get(`/admin/logs/${fName}`),

  // reports
  genereteRaport: body => sendRequest.post(`/recruiter/report`, body),
  getSingleReport: (id, params) =>
    sendRequest.get(`/recruiter/report/${id}?${params ? params : ''}`),
  delteSingleReport: id => sendRequest.delete(`/recruiter/report/${id}`),
  getAllPositions: () => sendRequest.get(`/recruiter/report/list/adv`),
  getReportFilterOptions: () =>
    sendRequest.get(`/recruiter/report/education/filter`),
  //========================================
  // USER ==================================
  //========================================

  getSingleApplicationForm: id => sendRequest.get(`/notice/${id}/form`),
  saveSingleApplicationForm: (id, body) =>
    sendRequest.post(`/notice/${id}/apply`, body),

  //edit application
  getSingleApplicationToEdit: id =>
    sendRequest.get(`/candidate/application/${id}/edit`),
  putSingleApplicationToEdit: (id, body) =>
    sendRequest.put(`/candidate/application/${id}/edit`, body),

  getSingleApplicationView: id =>
    sendRequest.get(`/candidate/application/${id}/details`),
  putAnswerInvitation: (appId, status) =>
    sendRequest.put(`/invitation/${appId}/status/${status}`),

  getAllCandidateInvites: () =>
    sendRequest.get('candidate/applications/invitations'),

  putNotifications: () => sendRequest.put('/user/newsletter/switch'),

  //========================================
  // GUEST ==================================
  //========================================

  getAdvertisement: id => sendRequest.get(`/notice/${id}`),
  getPublicFile: type => sendRequestBlob.get(`/public/file/${type}`),
};
