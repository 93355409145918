import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import styled from 'styled-components';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import theme from 'styles/theme';

import MenuCell from './MenuCell';
import { statusOptionsWithColor } from 'constans/constansAdv';

const SubStatus = styled.span`
  align-self: flex-start;
  border: 1px solid
    ${({ color, theme }) =>
      color === 'red'
        ? '#d5001f'
        : color === 'green'
        ? '#459937'
        : theme.secondary};
  color: ${({ color, theme }) =>
    color === 'red'
      ? '#d5001f'
      : color === 'green'
      ? '#459937'
      : theme.secondary};
  border-radius: 2px;
  padding: 1px 10px;
  margin: auto 5px 5px 5px;
`;

const menuItems = [
  {
    icon: <VisibilityOutlinedIcon />,
    label: 'Zobacz ogłoszenie',
    visible: 'active',
  },
  {
    icon: <VisibilityOutlinedIcon />,
    label: 'Zobacz aplikacje',
    visible: 'active',
  },
];

const UserApplicationsTableRow = ({
  row,
  cols,
  selectedRows,
  dragOver,
  setMenuFunctions,
  setActiveBookmark,
}) => {
  const cells = Object.entries(row).map(([, v], idx) => {
    return (
      <Td key={v} dragOver={cols[idx] === dragOver}>
        {cols[idx].fieldName === 'title' && (
          <span style={{ color: theme.secondary, fontWeight: '600' }}>
            {row[cols[idx].fieldName]}
          </span>
        )}

        {cols[idx].fieldName === 'status' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              color: theme[row.status.applicationStatus],
            }}
          >
            {row?.status?.applicationStatus && (
              <SubStatus
                color={
                  statusOptionsWithColor[row?.status?.applicationStatus]?.color
                }
              >
                {statusOptionsWithColor[row?.status?.applicationStatus]?.label}
              </SubStatus>
            )}

            {row?.status?.invitationStatus && (
              <SubStatus
                color={
                  statusOptionsWithColor[row?.status?.invitationStatus]?.color
                }
              >
                {statusOptionsWithColor[row?.status?.invitationStatus]?.label}
              </SubStatus>
            )}
          </div>
        )}

        {cols[idx].fieldName !== 'title' &&
          cols[idx].fieldName !== 'status' &&
          row[cols[idx].fieldName]}
      </Td>
    );
  });

  return (
    <Tr
      style={{
        backgroundColor: `${
          selectedRows?.includes(row.id) ? '#E6F0F8' : 'transparent'
        }`,
      }}
    >
      {cells}
      <Td>
        <MenuCell
          setMenuFunctions={setMenuFunctions}
          row={row}
          menuItems={menuItems}
          setActiveBookmark={setActiveBookmark}
        />
      </Td>
    </Tr>
  );
};

export default UserApplicationsTableRow;
