import MainWrapper from 'components/atoms/MainWrapper';
import EditApplicationForm from 'components/user/ApplicationForm/EditApplicationForm';
import React, { useEffect } from 'react';

const EditApplicationFormPage = () => {
  useEffect(() => {
    document.title = 'Edytuj aplikację - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <EditApplicationForm />
    </MainWrapper>
  );
};

export default EditApplicationFormPage;
