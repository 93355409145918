import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';

import { styledTable } from 'components/tables/helpers/changeStylesBrakpoints';
import { JobOffersFiltersTable } from './JobOffersFiltersTable';
import HiddenMenu from 'components/shared/HiddenMenu';

const Wrapper = styled.div`
  //change breakpoint for mobile view
  @media screen and (max-width: 1100px) {
    ${styledTable}
  }
`;

const CATEGORY = '';
const TYPE = 'notice';

const JobOffersTable = () => {
  const dispatch = useDispatch();
  const { usernotice: dataTable } = useSelector(
    state => state.currentPageTables
  );
  const filtersValues = useSelector(
    state => state.filtersTable?.userAdvertisement
  );

  const getValues = (type = TYPE, newValue, category = CATEGORY) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(
        type,
        newValue,
        category,
        '',
        'user'
      )
    );
  };

  useEffect(() => {
    getValues(TYPE, { ...filtersValues }, CATEGORY);
  }, []);

  const columns = [
    {
      title: 'Nr naboru',
      fieldName: 'recruitNr',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Nr wniosku',
      fieldName: 'proposalNr',
      sorting: true,
      draggable: true,
    },
    { title: 'Stanowisko', fieldName: 'title', sorting: true, draggable: true },

    {
      title: 'Data opublikowania',
      fieldName: 'publicationDate',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Termin składania',
      fieldName: 'submitDeadline',
      sorting: true,
      draggable: true,
    },
    { title: 'Status', fieldName: 'status', draggable: true },
    { title: <HiddenMenu />, fieldName: 'menu', draggable: false },
  ];

  return (
    <Wrapper>
      {!dataTable ? (
        <Indicator />
      ) : (
        <>
          <Table
            type={TYPE}
            reduxNameHelper='user'
            category={CATEGORY}
            columns={columns}
            data={dataTable}
            getValues={getValues}
            searchPlaceholder='Szukaj'
            filtersTable={JobOffersFiltersTable()}
            tableToolData={''}
          />
        </>
      )}
    </Wrapper>
  );
};

export default JobOffersTable;
