import React from 'react';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CancelIcon from '@material-ui/icons/Cancel';

export const candidatesType = {
  GENERAL: 'GENERAL',
  SPECIFIC: 'SPECIFIC',
};

export const statusName = {
  NEW: {
    label: 'Nowy',
    value: 'NEW',
  },

  INTERVIEW_INV: {
    label: 'Zaproszony na rozmowę',
    value: 'INTERVIEW_INV',
  },

  INTERVIEW_INV_CONFIRM: {
    label: 'Zaproszony na rozmowę',
    value: 'INTERVIEW_INV_CONFIRM',
  },

  INTERVIEW_INV_REJECT: {
    label: 'Zaproszony na rozmowę',
    value: 'INTERVIEW_INV_REJECT',
  },

  TEST_INV: {
    label: 'Zaproszony na test',
    value: 'TEST_INV',
  },

  TEST_INV_CONFIRM: {
    label: 'Zaproszony na test',
    value: 'TEST_INV_CONFIRM',
  },

  TEST_INV_REJECT: {
    label: 'Zaproszony na test',
    value: 'TEST_INV_REJECT',
  },

  FULFILL_REQUIREMENTS: {
    label: 'Spełnia wymagania',
    value: 'FULFILL_REQUIREMENTS',
  },

  NOT_FULFILL_REQUIREMENTS: {
    label: 'Nie spełnia wymagań',
    value: 'NOT_FULFILL_REQUIREMENTS',
  },

  RESIGN: {
    label: 'Rezygnacja',
    value: 'RESIGN',
  },
};

export const mailStatus = {
  CONFIRMED: {
    label: 'Potwierdzenie',
    value: 'CONFIRM',
  },

  REJECT: {
    label: 'Rezygnacja',
    value: 'REJECT',
  },

  PENDING: {
    label: 'Oczekuje',
    value: 'PENDING',
  },
};

export const confirmStatus = ['TEST_INV', 'INTERVIEW_INV'];

export const menuItems = [
  {
    icon: (
      <VisibilityOutlinedIcon aria-hidden='false'>
        <title>Zobacz kandydata</title>
      </VisibilityOutlinedIcon>
    ),
    label: 'Zobacz kandydata',
    notVisible: [],
    to: '/admin/advertisement',
    props: 'candidates',
  },
  {
    icon: (
      <MailOutlineIcon aria-hidden='false'>
        <title>Zaproś do następnego etapu</title>
      </MailOutlineIcon>
    ),
    label: 'Zaproś do następnego etapu',
    notVisible: ['RESIGN', 'NOT_FULFILL_REQUIREMENTS'],
  },

  {
    icon: (
      <MailOutlineIcon aria-hidden='false'>
        <title>Podziękuj za udział</title>
      </MailOutlineIcon>
    ),
    label: 'Podziękuj za udział',
    notVisible: [
      'TEST_INV',
      'INTERVIEW_INV',
      'RESIGN',
      'NEW',
      'FULFILL_REQUIREMENTS',
    ],
  },

  {
    icon: (
      <MailOutlineIcon aria-hidden='false'>
        <title>Wyślij wiadomość informacyjną</title>
      </MailOutlineIcon>
    ),
    label: 'Wyślij wiadomość informacyjną',
    notVisible: [],
  },

  {
    icon: (
      <CancelIcon aria-hidden='false'>
        <title>Odrzuć kandydata</title>
      </CancelIcon>
    ),
    label: 'Odrzuć kandydata',
    notVisible: ['RESIGN', 'NOT_FULFILL_REQUIREMENTS'],
  },
  {
    icon: (
      <PrintOutlinedIcon aria-hidden='false'>
        <title>Drukuj aplikację</title>
      </PrintOutlinedIcon>
    ),
    label: 'Drukuj aplikację',
    notVisible: [],
    to: '/admin/application-print',
  },
];

export const statusOptions = {
  ALL: {
    label: 'Wszystkie',
    value: 'ALL',
  },
  NEW: {
    label: 'Nowy',
    value: 'NEW',
  },

  INTERVIEW_INV: {
    label: 'Zaproszony na rozmowę',
    value: 'INTERVIEW_INV',
  },

  INTERVIEW_INV_CONFIRM: {
    label: 'Zaproszony na rozmowę - potwierdzony',
    value: 'INTERVIEW_INV_CONFIRM',
  },

  INTERVIEW_INV_REJECT: {
    label: 'Zaproszony na rozmowę - odmowa',
    value: 'INTERVIEW_INV_REJECT',
  },

  TEST_INV: {
    label: 'Zaproszony na test',
    value: 'TEST_INV',
  },

  TEST_INV_CONFIRM: {
    label: 'Zaproszony na test - potwierdzony',
    value: 'TEST_INV_CONFIRM',
  },

  TEST_INV_REJECT: {
    label: 'Zaproszony na test - odmowa',
    value: 'TEST_INV_REJECT',
  },

  FULFILL_REQUIREMENTS: {
    label: 'Spełnia wymagania',
    value: 'FULFILL_REQUIREMENTS',
  },

  NOT_FULFILL_REQUIREMENTS: {
    label: 'Nie spełnia wymagań',
    value: 'NOT_FULFILL_REQUIREMENTS',
  },

  RESIGN: {
    label: 'Rezygnacja',
    value: 'RESIGN',
  },
};
