import { ErrorMessage } from '@hookform/error-message';
import { Attachment, ErrorAlert } from 'm-web-components';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { api } from 'API';
import { toast } from 'react-toastify';

import * as S from './Singles.css';
import theme from 'styles/theme';
import { TOAST_POSITION } from 'constans/constantsToast';

const mimeTypeMap = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  png: 'image/png',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  doc:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  docx:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  odt: 'application/vnd.oasis.opendocument.text',
  pdf: 'application/pdf',
  rtf: 'text/html',
};

const attachmentContainerStyle = {
  width: '100%',
  display: 'flex',
  'align-items': 'start',
};

const SingleFile = ({
  data,
  errors,
  index,
  defaultValues,
  control,
  basicNameForm,
  ignoreRequired,
  changeValue,
  sectionIndex,
  dictIndex,
  readOnly,
  value, //for forms application
}) => {
  const [generatedUrls, setGeneratedUrls] = useState([]);
  const [toConvert, setToConvert] = useState(defaultValues?.content || value);
  const [defaultUrl, setDefaultUrl] = useState([]);

  const options = {
    multiple: false,
    extensions: '.pdf, .doc, .docx, .tiff, .tif, .odt, .jpg, .png, .jpeg',
    size: 1,
  };

  useEffect(() => {
    if (toConvert && !ignoreRequired && Array.isArray(toConvert))
      getDefaultUrls();
  }, [toConvert]);

  useEffect(() => {
    let tempDefaultUrl = [];
    if (Array.isArray(toConvert) && toConvert?.length) {
      tempDefaultUrl = toConvert?.map((file, index) => ({
        name: file.path,
        url: generatedUrls[index],
      }));
    }
    setDefaultUrl(tempDefaultUrl);
  }, [toConvert, generatedUrls]);

  const getFileExt = name => {
    const pattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    return name.match(pattern);
  };

  const getDefaultUrls = async () => {
    try {
      const blobs = await Promise.all(
        toConvert?.map(file => api.urlDownloadFile(file.fileId))
      );

      const urls = blobs.map((blob, index) =>
        URL.createObjectURL(
          new Blob([blob?.data], {
            type: mimeTypeMap[getFileExt(toConvert[index].path)[1]],
          })
        )
      );

      setGeneratedUrls(urls);
    } catch (err) {
      toast.error(
        'Wystąpił błąd podczas pobierania domyślnych plików.',
        TOAST_POSITION
      );
    }
  };

  return (
    <S.SingleWrapper>
      <S.Label>
        {data.label} {data.isRequired && <span>*</span>}
      </S.Label>
      {control ? (
        <>
          <Controller
            defaultValue={toConvert?.content}
            control={control}
            rules={
              !ignoreRequired && data.isRequired
                ? { required: 'To pole jest wymagane' }
                : null
            }
            name={`${basicNameForm}.elements[${index}].content`}
            render={({ onChange }) => (
              <Attachment
                customStyle={attachmentContainerStyle}
                defaultUrl={defaultUrl}
                options={options}
                onSubmit={data => onChange(data)}
                labelCancel='Anuluj'
                labelSubmit='Załącz'
                submitButtonProps={{ colorType: 'primary' }}
                attachmentButtonProps={{
                  colorType: 'primary',
                  type: 'button',
                }}
                attachmentButtonLabel={data.buttonLabel || 'Dodaj załącznik'}
                urlProps={{ target: '_blank' }}
                onRemoveAll={() => {
                  onChange(null);
                  setToConvert(null);
                }}
                removeAllButtonProps={{
                  type: 'button',
                  style: {
                    marginLeft: '10px',
                    color: theme.secondary,
                    borderColor: theme.secondary,
                  },
                }}
                removeAllButtonLabel='Usuń załączniki'
                buttonsContainerProps={{
                  style: { display: 'flex', flexDirection: 'row' },
                }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${basicNameForm}.elements[${index}].content`}
            render={({ message }) => (
              <ErrorAlert error={message} maxWidth='200px' type='simple' />
            )}
          />
        </>
      ) : (
        <Attachment
          defaultUrl={defaultUrl}
          customStyle={attachmentContainerStyle}
          name={`${basicNameForm}.elements[${index}].content`}
          options={options}
          onSubmit={value => {
            if (!readOnly)
              changeValue(value, sectionIndex, dictIndex, index, 'att');
          }}
          labelCancel='Anuluj'
          labelSubmit='Załącz'
          submitButtonProps={{ colorType: 'primary' }}
          attachmentButtonProps={{ colorType: 'primary', type: 'button' }}
          attachmentButtonLabel={data.buttonLabel || 'Dodaj załącznik'}
          urlProps={{ target: '_blank' }}
          onRemoveAll={() => {
            if (!readOnly) {
              changeValue(null, sectionIndex, dictIndex, index, 'att');
              setToConvert(null);
            }
          }}
          removeAllButtonProps={{
            type: 'button',
            style: {
              marginLeft: '10px',
              color: theme.secondary,
              borderColor: theme.secondary,
            },
          }}
          removeAllButtonLabel='Usuń załączniki'
          buttonsContainerProps={{
            style: { display: 'flex', flexDirection: 'row' },
          }}
        />
      )}
    </S.SingleWrapper>
  );
};

export default SingleFile;
