import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import Table from 'components/tables/Table';
import { dictionaryType } from 'constans/constansDictionary';
import Indicator from 'components/atoms/Indicator';
import { api } from 'API';

import { toggleSelectAllRows } from 'components/tables/helpers/checkboxesFunc';
import { dictionariesFiltersTable } from './dictionariesFiltersTable';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { styledTable } from 'components/tables/helpers/changeStylesBrakpoints';
import { AccessibilityLabel } from '../../tables/Table.css';
import { TOAST_POSITION } from 'constans/constantsToast';

const Wrapper = styled.div`
  //change breakpoint for mobile view
  @media screen and (max-width: 860px) {
    ${styledTable}
  }
`;

const CATEGORY = 'recruiter/';
const TYPE = 'dictionary';

const DictionaryTable = () => {
  const dispatch = useDispatch();
  const { dictionary: dataTable } = useSelector(
    state => state.currentPageTables
  );
  const [currentDictionaryTypes, setCurrentDistionaryTypes] = useState(
    dictionaryType.ADV
  );
  const [selectedRows, setSelectedRows] = useState([]);

  const getValues = (type, newValue, category = CATEGORY) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(type, newValue, category)
    );
  };

  useEffect(() => {
    getValues(TYPE, null, CATEGORY);
  }, []);

  const toolsButtons = [
    {
      label: 'Usuń',
      colorType: 'gray',
      titleModal: 'Czy na pewno chcesz usunąć wybrane słowniki?',
      buttonLabelModal: 'Usuń',
      onClick: async () => {
        try {
          await api.deleteSingleDictionary(selectedRows.join(','));
          getValues(TYPE, null, CATEGORY);
          setSelectedRows([]);
        } catch (e) {
          toast.error(
            <div>
              <p>Wystąpił błąd podczas usuwania słowników.</p>
              {e.response?.data?.errors?.map(err => (
                <p>{err.defaultMessage}</p>
              ))}
            </div>,
            TOAST_POSITION
          );
        }
      },
    },
  ];

  const columns = [
    {
      component: (
        <>
          <AccessibilityLabel>
            <p>Zaznacz wszystkie</p>
            <input
              id='dictCheckbox'
              type='checkbox'
              onChange={() =>
                toggleSelectAllRows({
                  selectedRows,
                  dataTable,
                  setSelectedRows,
                })
              }
              checked={selectedRows?.length === dataTable?.length}
            />
          </AccessibilityLabel>
        </>
      ),
      fieldName: 'checkAll',
      draggable: false,
    },
    { title: 'Tytuł pola', fieldName: 'title', sorting: true, draggable: true },
    { title: 'Format danych', fieldName: 'dataFormat', draggable: true },
    {
      title: 'Data utworzenia',
      fieldName: 'dateCreated',
      sorting: true,
      draggable: true,
    },
    { title: 'Opis', fieldName: 'description', draggable: true },
    {
      title: <span style={{ color: 'transparent' }}>Edytuj</span>,
      fieldName: 'edit',
      draggable: false,
    },
    {
      title: <span style={{ color: 'transparent' }}>Usuń</span>,
      fieldName: 'delete',
      draggable: false,
    },
  ];

  const changeDictionaryType = dType => {
    getValues(TYPE, { dType }, CATEGORY);
    setCurrentDistionaryTypes(dType);
    setSelectedRows([]);
  };

  const horizontalMenu = {
    curentType: currentDictionaryTypes,
    setCurrentType: changeDictionaryType,
    horizontalMenuButtons: [
      { type: dictionaryType.ADV, label: 'Ogłoszenia' },
      { type: dictionaryType.FORM, label: 'Formularze' },
    ],
  };

  return (
    <Wrapper>
      {!dataTable ? (
        <Indicator />
      ) : (
        <>
          <Table
            type={TYPE}
            category={CATEGORY}
            columns={columns}
            data={dataTable}
            getValues={getValues}
            searchPlaceholder='Szukaj'
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            filtersTable={dictionariesFiltersTable}
            horizontalMenu={horizontalMenu}
            tableToolData={{
              countPosition: selectedRows.length,
              buttons: toolsButtons,
            }}
            addButton={{
              label: '+ Stwórz nowe pole',
              to: '/admin/dictionary/create-field/',
            }}
          />
        </>
      )}
    </Wrapper>
  );
};

export default DictionaryTable;
