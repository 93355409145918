import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Advertisements from 'pages/guest/Advertisements';

import { role } from 'auth/constans';
import { routes } from 'routes';
import useAuth from 'auth/useAuth';
import Indicator from 'components/atoms/Indicator';

const Home = ({ setContactModalOpen }) => {
  const { user } = useSelector(state => state.auth);
  const { getCurrentUser } = useAuth();

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    document.title = 'Portal Rekrutacyjny Zabrze';
  }, []);

  if (!user) {
    return <Advertisements setContactModalOpen={setContactModalOpen} />;
  }

  if (user.role === role.user) {
    return <Redirect to={routes.user.home} />;
  }

  if (user.role === role.admin) {
    return <Redirect to={routes.admin.home} />;
  }

  if (user.role === role.recruiter) {
    return <Redirect to={routes.admin.home} />;
  }

  return <Indicator />;
};
export default Home;
