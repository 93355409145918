import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { EditButton } from 'components/atoms/Buttons';
import { statusOptionsWithColor } from 'constans/constansAdv';
import { device } from 'styles/devices';

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserHeader = styled.div`
  padding: 10px 10px 25px 0px;

  h2 {
    color: ${({ theme }) => theme.secondary};
    font-weight: 600;
    font-size: 20px;
    margin: auto 20px auto 0px !important;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  padding-top: 15px;

  div {
    font-size: 15px;
    margin-right: 40px;
    display: flex;

    svg {
      color: ${({ theme }) => theme.secondary};
      margin: auto 10px 0px 0px;
    }
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const UserInfoTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  width: 250px;
`;

const UserInfoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserInfoData = styled.div`
  display: inline;
  span {
    margin: auto 0px auto 10px;
  }
`;

const SubStatus = styled.span`
  align-self: flex-start;
  border: 1px solid
    ${({ color, theme }) =>
      color === 'red'
        ? '#d5001f'
        : color === 'green'
        ? '#459937'
        : theme.secondary};
  color: ${({ color, theme }) =>
    color === 'red'
      ? '#d5001f'
      : color === 'green'
      ? '#459937'
      : theme.secondary};
  border-radius: 2px;
  padding: 1px 10px;
  margin: auto 5px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    flex-direction: row;
  }
`;

const MAX_LENGTH = 1;

const CandidateCell = ({ userData }) => {
  const [length, setLength] = useState({});

  useEffect(() => {
    userData?.positions?.forEach(element => {
      setLength(prev => ({ ...prev, [element?.label]: MAX_LENGTH }));
    });
  }, [userData]);

  const formInfo = userData?.positions?.map(el => (
    <UserInfo>
      <UserInfoTitle>{el?.label}</UserInfoTitle>
      <UserInfoDataContainer>
        {el?.values?.slice(0, length[el?.label]).map(fields => (
          <UserInfoData>
            {fields?.map((single, idx, fields) => (
              <>
                {single?.content &&
                  (single.type !== 'FILE' ? (
                    <span>
                      {single?.content === 'true' ? (
                        <DoneIcon />
                      ) : single?.content === 'false' ? (
                        <CloseIcon />
                      ) : (
                        single?.content
                      )}
                      {fields[idx + 1] && <span>&bull;</span>}
                    </span>
                  ) : (
                    <>
                      <span>
                        {single?.content?.split('yp[.]ck')[0] === 'null '
                          ? 'Brak pliku'
                          : single?.content?.split('yp[.]ck')[0]}
                      </span>
                      {fields[idx + 1] && <span>&bull;</span>}
                    </>
                  ))}
              </>
            ))}
          </UserInfoData>
        ))}

        {el?.values?.length > MAX_LENGTH &&
          length[el.label] !== el?.values?.length && (
            <EditButton
              fontSize='14px'
              isVisible={true}
              margin={'auto auto auto 0px'}
              onClick={() =>
                setLength(prev => ({ ...prev, [el.label]: el?.values?.length }))
              }
            >
              Zobacz więcej
            </EditButton>
          )}

        {el?.values?.length > MAX_LENGTH &&
          length[el.label] === el?.values?.length && (
            <EditButton
              fontSize='14px'
              isVisible={true}
              margin={'auto auto auto 0px'}
              onClick={() =>
                setLength(prev => ({ ...prev, [el.label]: MAX_LENGTH }))
              }
            >
              Zobacz mniej
            </EditButton>
          )}
      </UserInfoDataContainer>
    </UserInfo>
  ));

  return (
    <CellWrapper>
      <UserHeader>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h2>{`${userData?.name} ${userData?.surname}`}</h2>
          <StatusWrapper>
            {userData?.status?.applicationStatus && (
              <SubStatus
                color={
                  statusOptionsWithColor[userData?.status?.applicationStatus]
                    ?.color
                }
              >
                {
                  statusOptionsWithColor[userData?.status?.applicationStatus]
                    ?.label
                }
              </SubStatus>
            )}

            {userData?.status?.invitationStatus && (
              <SubStatus
                color={
                  statusOptionsWithColor[userData?.status?.invitationStatus]
                    ?.color
                }
              >
                {
                  statusOptionsWithColor[userData?.status?.invitationStatus]
                    ?.label
                }
              </SubStatus>
            )}
          </StatusWrapper>
        </div>
        <ContactInfo>
          <div>
            <PhoneOutlinedIcon />
            {userData?.phone}
          </div>
          <div>
            <MailOutlineIcon />
            {userData?.email}
          </div>
        </ContactInfo>
      </UserHeader>
      {formInfo}
    </CellWrapper>
  );
};

export default CandidateCell;
