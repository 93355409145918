import MainWrapper from 'components/atoms/MainWrapper';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { menuItems, menuButtons } from './menuItems';
import { api } from 'API';
import GetAppIcon from '@material-ui/icons/GetApp';
import { device } from 'styles/devices';
import Slider from 'hamburger-react';
import { TOAST_POSITION } from 'constans/constantsToast';

const Wrapper = styled(MainWrapper)`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const MenuWrapper = styled.ul`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorLight};

  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 100px;
  right: ${({ isMenuOpen }) => (isMenuOpen ? 0 : '-100%')};
  transition: 0.3s;

  @media ${device.tablet} {
    top: 0;
    right: 0;
    position: relative;
    display: flex;
    margin-top: 147px;
    margin-bottom: 0px;
    width: 30vw;
  }
`;

const HamburgerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  transform: translateY(100%);
  color: ${({ theme }) => theme.secondary};

  @media ${device.tablet} {
    display: none;
  }
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  min-height: 52px;
  background-color: ${({ isActive, theme }) => isActive && theme.secondary};
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.lighter};
  }

  a {
    display: block;
    width: 100%;
    color: ${({ isActive }) => (isActive ? 'white' : 'black')};
    padding: 10px 20px;
    font-weight: 500;
    font-size: 1.5rem;
  }

  button {
    display: flex;
    width: 100%;
    border: none;
    background-color: transparent;
    text-align: start;
    color: ${({ isActive }) => (isActive ? 'white' : 'black')};
    padding: 10px 20px;
    font-weight: 500;
    font-size: 1.5rem;
    cursor: pointer;

    svg {
      width: 2rem;
      height: 2rem;
      margin: auto 5px auto 0px;
    }
  }
`;

const HomeMenuLayout = ({ children, setContactModalOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(prev => !prev);

  const isActive = link => {
    const { pathname } = window.location;

    return pathname === link;
  };

  const openFile = async (type, name) => {
    try {
      const { data } = await api.getPublicFile(type);

      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast.error('Wystąpił problem podczas pobierania pliku.', TOAST_POSITION);
    }
  };

  const items = menuItems?.map(item => (
    <MenuItem isActive={isActive(item.to)} onClick={toggleMenu}>
      {item.label === 'Formularz kontaktowy' ? (
        <button onClick={() => setContactModalOpen(prev => !prev)}>
          {item.label}
        </button>
      ) : (
        <NavLink to={item.to}>{item.label}</NavLink>
      )}
    </MenuItem>
  ));

  const buttons = menuButtons?.map(item => (
    <MenuItem onClick={toggleMenu}>
      <button onClick={() => openFile(item.file, item.label)}>
        <GetAppIcon aria-hidden='false'>
          <title>Pobierz</title>
        </GetAppIcon>
        {item.label}
      </button>
    </MenuItem>
  ));

  return (
    <Wrapper>
      {children}
      <HamburgerWrapper>
        <Slider toggled={isMenuOpen} toggle={toggleMenu} />
      </HamburgerWrapper>
      <MenuWrapper isMenuOpen={isMenuOpen}>
        {items}
        {buttons}
      </MenuWrapper>
    </Wrapper>
  );
};

export default HomeMenuLayout;
