import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import GetAppIcon from '@material-ui/icons/GetApp';
import styled from 'styled-components';
import { api } from 'API';
import { toast } from 'react-toastify';
import { TOAST_POSITION } from 'constans/constantsToast';

export const DownloadButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  align-items: center;
  color: ${({ theme }) => theme.secondary};
  cursor: pointer;

  svg {
    margin: auto 10px auto 0px;
  }
`;

const CandidatesTableRow = ({ row, cols }) => {
  const getFile = async fName => {
    try {
      const { data } = await api.getLogFile(fName);
      saveFile(fName, data);

      toast.success(`Plik ${fName} został pobrany.`, TOAST_POSITION);
    } catch (err) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas pobierania pliku.</p>
          {err.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const saveFile = (fName, data) => {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const blob = new Blob([data], { type: 'application/gzip' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const cells = Object.entries(cols).map(([, v], idx) => (
    <Td key={v}>
      {cols[idx].fieldName === 'download' && (
        <DownloadButton onClick={() => getFile(row.fullName)}>
          <GetAppIcon /> POBIERZ
        </DownloadButton>
      )}
      {cols[idx].fieldName !== 'download' && row[cols[idx].fieldName]}
    </Td>
  ));

  return <Tr>{cells}</Tr>;
};

export default CandidatesTableRow;
