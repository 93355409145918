import React from 'react';
import { Link } from 'react-router-dom';
import ApplicationDetails from 'components/shared/ApplicationDetails/ApplicationDetails';
import { Button } from 'm-web-components';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import * as S from './CandidateDetails.css';

const Application = ({ userDetails, applicationDetails, id }) => (
  <S.CandidateMainSection>
    <S.SectionHeader>
      <h1>
        {userDetails.name} {userDetails.surname}
      </h1>
      <S.ContactInfo>
        <div>
          <PhoneOutlinedIcon aria-hidden='false'>
            <title>Numer telefonu</title>
          </PhoneOutlinedIcon>
          {userDetails.phone}
        </div>
        <div>
          <MailOutlineIcon aria-hidden='false'>
            <title>E-mail</title>
          </MailOutlineIcon>
          {userDetails.email}
        </div>
      </S.ContactInfo>
    </S.SectionHeader>
    <div style={{ display: 'flex' }}>
      <S.AppNumber style={{ margin: '20px 10px' }}>
        <h2 style={{ fontWeight: 700, fontSize: '18px' }}>Numer aplikacji</h2>
        <span>{applicationDetails.applicationNumber}</span>
      </S.AppNumber>
      <Link to={`/admin/application-print/${id}`}>
        <Button
          padding='10px 30px'
          margin='10px 0 10px auto'
          colorType='primary'
        >
          Drukuj
        </Button>
      </Link>
    </div>
    <ApplicationDetails data={applicationDetails.details} appId={id} />
  </S.CandidateMainSection>
);

export default Application;
