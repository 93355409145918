import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalSendInvite from 'components/shared/ModalSendInvite/ModalSendInvite';
import ModalSendThanks from 'components/shared/ModalSendThanks/ModalSendThanks';
import Modal from 'components/shared/RowMenuModal/Modal';

import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';
import allActions from 'actions';
import { AdvCandidatesFiltersTable } from './AdvCandidatesTableFilters';
import CandidateDetails from './CandidateDetails/CandidateDetails';
import { useParams } from 'react-router';
import ModalSendFulfill from 'components/shared/ModalSendFulfill/ModalSendFulfill';

const CATEGORY = 'recruiter/';
const TYPE = 'notice';
const SECONDARY_TYPE = 'candidates';

const AdvCandidatesTable = ({ id, advData, setActiveBookmark }) => {
  const SUFFIX = `/${id}/${SECONDARY_TYPE}`;
  const { appId } = useParams();

  const dispatch = useDispatch();

  const [selectedCandidate, setSelectedCandidate] = useState(appId || null);

  const [invitationDetails, setInvitationDetails] = useState({
    advertisementName: advData?.formDetails?.label,
    aId: id,
  });

  const [userApplications, setUserApplications] = useState([
    { id: id, name: advData?.formDetails?.label },
  ]);

  const [menuFunctions, setMenuFunctions] = useState({
    invite: false,
    thanks: false,
    fulfill: false,
  });

  const { candidatesnotice: dataTable } = useSelector(
    state => state.currentPageTables
  );

  useEffect(() => {
    getValues(TYPE, { page: 1 });
  }, []);

  useEffect(() => {
    if (appId) setSelectedCandidate(appId);
  }, [appId]);

  const getValues = (
    type = TYPE,
    newValue = null,
    category = CATEGORY,
    suffix = SUFFIX
  ) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(
        type,
        newValue,
        category,
        suffix,
        'candidates'
      )
    );
  };

  const cancelFunc = () => {
    setMenuFunctions({
      invite: false,
      thanks: false,
      fulfill: false,
    });
  };

  const handleDisplayCandidate = id => {
    setSelectedCandidate(id);
  };

  const columns = [
    {
      title: '',
      fieldName: 'userData',
      draggable: false,
    },
    { title: '', fieldName: 'menu', draggable: false },
  ];

  return (
    <>
      {selectedCandidate ? (
        <CandidateDetails
          goBack={() => setSelectedCandidate(null)}
          id={selectedCandidate}
          setActiveBookmark={setActiveBookmark}
        />
      ) : dataTable ? (
        <Table
          type={TYPE}
          reduxNameHelper='candidates'
          secondaryType={SECONDARY_TYPE}
          category={CATEGORY}
          columns={columns}
          rows={dataTable}
          getValues={getValues}
          searchPlaceholder='Szukaj'
          filtersTable={AdvCandidatesFiltersTable}
          tableToolData={{
            buttons: [],
          }}
          data={dataTable}
          setInvitationDetails={setInvitationDetails}
          setUserApplications={setUserApplications}
          menuFunctions={menuFunctions}
          setMenuFunctions={setMenuFunctions}
          isCandidates={true}
          handleDisplayCandidate={handleDisplayCandidate}
        />
      ) : (
        <Indicator />
      )}

      {menuFunctions.fulfill && (
        <Modal open={menuFunctions.fulfill} cancelFunc={cancelFunc}>
          <ModalSendFulfill
            cancelFunc={cancelFunc}
            invitationDetails={invitationDetails}
            userApplications={userApplications}
            getValues={getValues}
            type={TYPE}
            category={CATEGORY}
          />
        </Modal>
      )}

      {menuFunctions.invite && (
        <Modal open={menuFunctions.invite} cancelFunc={cancelFunc}>
          <ModalSendInvite
            cancelFunc={cancelFunc}
            invitationDetails={invitationDetails}
            userApplications={userApplications}
            getValues={getValues}
            type={TYPE}
            category={CATEGORY}
          />
        </Modal>
      )}

      {menuFunctions.thanks && (
        <Modal open={menuFunctions.thanks} cancelFunc={cancelFunc}>
          <ModalSendThanks
            cancelFunc={cancelFunc}
            invitationDetails={invitationDetails}
            userApplications={userApplications}
            getValues={getValues}
            type={TYPE}
            category={CATEGORY}
          />
        </Modal>
      )}
    </>
  );
};

export default AdvCandidatesTable;
