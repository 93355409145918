import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import 'react-nice-dates/build/style.css';
import styled from 'styled-components';
import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import * as S from './Singles.css';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorAlert } from 'm-web-components';
import { formatDate } from 'helpers/newDateFormat';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl);

const TextFiledStyled = styled(TextField)`
  input {
    padding: 10px 15px;
    background-color: #fff;
  }

  input:disabled {
    border: none;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px;
    background-color: #fff;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgb(242, 242, 242);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SingleDateFromTo = ({
  data,
  errors,
  index,
  defaultValues,
  control,
  basicNameForm,
  ignoreRequired,
  changeValue,
  sectionIndex,
  dictIndex,
  readOnly,
  value, //for forms application
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [isCurrent, setIsCurrent] = useState(false);

  useEffect(() => {
    if (value) {
      if (value.dateFrom) setStartDate(new Date(value.dateFrom));
      if (value.dateTo) setEndDate(new Date(value.dateTo));
      if (value.isCurrent) setIsCurrent(value.isCurrent);
    }

    if (defaultValues?.content) {
      if (defaultValues.content.dateFrom)
        setStartDate(new Date(defaultValues.content.dateFrom));
      if (control) {
        control.setValue(
          `${basicNameForm}.elements[${index}].content.dateFrom`,
          defaultValues.content.dateFrom
        );
      }

      if (defaultValues.content.dateTo) {
        if (defaultValues.content.dateTo)
          setEndDate(new Date(defaultValues.content.dateTo));
        if (control) {
          control.setValue(
            `${basicNameForm}.elements[${index}].content.dateTo`,
            defaultValues.content.dateTo
          );
        }
      }

      if (defaultValues.content.isCurrent) setIsCurrent(true);
    }
  }, []);

  const setValueStartDate = date => {
    setStartDate(date);

    if (changeValue) {
      changeValue(
        {
          dateFrom: formatDate(date),
          dateTo: endDate ? formatDate(endDate) : null,
          isCurrent,
        },
        sectionIndex,
        dictIndex,
        index
      );
    }

    if (control) {
      control.setValue(
        `${basicNameForm}.elements[${index}].content.dateFrom`,
        formatDate(date)
      );
    }
  };

  const setValueEndDate = date => {
    setEndDate(date);

    if (changeValue)
      changeValue(
        {
          dateFrom: startDate ? formatDate(startDate) : null,
          dateTo: formatDate(date),
          isCurrent,
        },
        sectionIndex,
        dictIndex,
        index
      );

    if (control) {
      control.setValue(
        `${basicNameForm}.elements[${index}].content.dateTo`,
        formatDate(date)
      );
    }
  };

  return (
    <S.SingleWrapper>
      <S.Label>
        {data.label} {data.isRequired && <span>*</span>}
      </S.Label>
      <Wrapper>
        {control ? (
          <>
            <Controller
              name={`${basicNameForm}.elements[${index}].content.dateFrom`}
              control={control}
              rules={
                !ignoreRequired && data.isRequired
                  ? { required: 'To pole jest wymagane' }
                  : null
              }
              render={() => (
                <ReactDatePicker
                  locale='pl'
                  dateFormat='yyyy-MM-dd'
                  placeholderText='2021-01-01'
                  selected={startDate || null}
                  onChange={date => setValueStartDate(date)}
                  customInput={<TextFiledStyled variant='outlined' />}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`${basicNameForm}.elements[${index}].content.dateFrom`}
              render={({ message }) => (
                <ErrorAlert error={message} maxWidth='200px' type='simple' />
              )}
            />

            <Controller
              name={`${basicNameForm}.elements[${index}].content.dateTo`}
              control={control}
              rules={
                !ignoreRequired && data.isRequired
                  ? { required: 'To pole jest wymagane' }
                  : null
              }
              render={() => (
                <ReactDatePicker
                  locale='pl'
                  dateFormat='yyyy-MM-dd'
                  placeholderText='2021-01-01'
                  selected={endDate || null}
                  onChange={date => setValueEndDate(date)}
                  disabled={isCurrent}
                  customInput={
                    <TextFiledStyled
                      variant='outlined'
                      style={{ margin: '0 20px' }}
                    />
                  }
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`${basicNameForm}.elements[${index}].content.dateTo`}
              render={({ message }) => (
                <ErrorAlert error={message} maxWidth='200px' type='simple' />
              )}
            />
          </>
        ) : (
          <>
            <ReactDatePicker
              locale='pl'
              dateFormat='yyyy-MM-dd'
              placeholderText='2021-01-01'
              selected={startDate || null}
              required={ignoreRequired ? false : data.isRequired}
              onChange={date => setValueStartDate(date)}
              customInput={
                <TextFiledStyled variant='outlined' readOnly={readOnly} />
              }
            />
            <ReactDatePicker
              locale='pl'
              dateFormat='yyyy-MM-dd'
              placeholderText='2021-01-01'
              selected={endDate || null}
              required={ignoreRequired ? false : data.isRequired}
              onChange={date => setValueEndDate(date)}
              disabled={isCurrent}
              customInput={
                <TextFiledStyled
                  variant='outlined'
                  style={{ margin: '0 20px' }}
                  readOnly={readOnly}
                />
              }
            />
          </>
        )}
        {control ? (
          <>
            <Controller
              name={`${basicNameForm}.elements[${index}].content.isCurrent`}
              control={control}
              rules={
                !ignoreRequired && data.isRequired
                  ? { required: 'To pole jest wymagane' }
                  : null
              }
              defaultValue={defaultValues?.content?.isCurrent}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      {...props}
                      checked={props.value}
                      onChange={e => {
                        props.onChange(e.target.checked);
                        setIsCurrent(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <S.Label>
                      {data.content}
                      {!ignoreRequired && data.isRequired && <span>*</span>}
                    </S.Label>
                  }
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`${basicNameForm}.elements[${index}].content.isCurrent`}
              render={({ message }) => (
                <ErrorAlert error={message} maxWidth='200px' type='simple' />
              )}
            />
          </>
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                checked={isCurrent}
                onChange={e => {
                  setIsCurrent(e.target.checked);
                  if (changeValue)
                    changeValue(
                      {
                        dateFrom: startDate ? formatDate(startDate) : null,
                        dateTo: endDate ? formatDate(endDate) : null,
                        isCurrent: e.target.checked,
                      },
                      sectionIndex,
                      dictIndex,
                      index
                    );
                }}
                color='primary'
              />
            }
            label='Obecnie'
          />
        )}
      </Wrapper>
    </S.SingleWrapper>
  );
};

export default SingleDateFromTo;
