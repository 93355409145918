import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import styled from 'styled-components';

const Wrapper = styled.div`
  div.ReactTags__tags {
    position: relative;
  }

  /* Styles for the input */
  div.ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
    width: 100%;
  }
  div.ReactTags__tagInput input.ReactTags__tagInputField,
  div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    width: 100%;
    padding: 10px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 2px;
  }

  .tag-label {
    margin: 10px 0;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  div.ReactTags__selected {
    margin-top: 10px;
  }

  /* Styles for selected tags */
  div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    cursor: move;
    border-radius: 2px;
    margin: 0 10px 10px 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
  div.ReactTags__selected .ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
    border: none;
  }

  /* Styles for suggestions */
  div.ReactTags__suggestions {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 100%;
  }
  div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }
`;

const Label = styled.label`
  margin: 10px 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary};
`;

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Tags = ({
  tags,
  handleAddition,
  handleDelete,
  label,
  placeholder,
  handleDrag,
  suggestions,
}) => {
  const tagsRender = tags.map(tag => {
    return {
      id: tag.dataFormat || tag.id,
      text: suggestions.filter(
        sug => sug.id === tag.dataFormat || sug.id === tag.id
      )[0].text,
    };
  });

  return (
    <Wrapper>
      <Label className='tag-label'>{label}</Label>
      <ReactTags
        tags={tagsRender}
        suggestions={suggestions}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        placeholder={placeholder}
        allowUnique={false}
        autofocus={false}
        inputFieldPosition='top'
        minQueryLength={0}
        allowDragDrop={true}
      />
    </Wrapper>
  );
};

export default Tags;
