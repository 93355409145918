import React from 'react';
import styled from 'styled-components';
import AdvertisementsList from 'components/guest/Advertisements/AdvertisementList';
import HomeMenuLayout from 'components/guest/HomeMenuLayout';

const ContentWrapper = styled.div`
  padding: 30px auto;
  width: 100%;
`;

export const Advertisements = ({ setContactModalOpen }) => {
  return (
    <HomeMenuLayout setContactModalOpen={setContactModalOpen}>
      <ContentWrapper>
        <AdvertisementsList />
      </ContentWrapper>
    </HomeMenuLayout>
  );
};

export default Advertisements;
