import React, { useState } from 'react';

import { useDrop } from 'react-dnd-latest';

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import SingleSubSection from './SingleSubSection';

import * as S from '../CreateAdvertisement.css';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorAlert } from 'm-web-components';
import { Controller } from 'react-hook-form';
import Hint from 'components/shared/Hint';

const SingleSection = ({
  accept,
  onDrop,
  selectedDictionaries,
  errors,
  defaultValues,
  control,
  index: sectionIndex,
  deleteSection,
  id,
  deleteSubSection,
  advId,
}) => {
  const [isEditableTitle, setIsEditableTitle] = useState(false);
  const [isSubsectionsVisible, setSubsectionsVisible] = useState(true);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  //to style (background container)
  const isActive = isOver && canDrop;
  let backgroundColor = '#fff';
  if (isActive) {
    backgroundColor = '#b9e0ff';
  } else if (canDrop) {
    backgroundColor = '#EFF5FA';
  }

  const subSectionsRender = selectedDictionaries?.map((item, index) => (
    <SingleSubSection
      id={`${item.id}-${index}`}
      index={index}
      sectionIndex={sectionIndex}
      dictionaryId={item.dictionaryId}
      formProps={{
        errors,
        defaultValues: defaultValues?.dictionaries?.[index],
        control,
      }}
      deleteSubSection={deleteSubSection}
      advId={advId}
    />
  ));

  return (
    <S.WrapperSingleSection
      ref={drop}
      role='Dustbin'
      style={{ backgroundColor }}
    >
      <S.TitleSection>
        <S.InputWrapper
          isedit={isEditableTitle}
          issubionsvisible={isSubsectionsVisible}
        >
          <Hint hint='Naciśnij strzałkę by zwinąć/rozwinąć' top>
            <button
              onClick={() => setSubsectionsVisible(!isSubsectionsVisible)}
              type='button'
            >
              <ArrowDropDownOutlinedIcon />
            </button>
          </Hint>
          <div>
            <Controller
              errors={errors}
              defaultValue={
                defaultValues?.title || `Sekcja ${sectionIndex + 1}`
              }
              control={control}
              rules={{ required: 'To pole jest wymagane' }}
              name={`sections[${sectionIndex}].title`}
              render={({ onChange, value }) => (
                <>
                  <S.Input
                    onChange={onChange}
                    value={value}
                    placeholder='Wpisz tytuł'
                    disabled={!isEditableTitle}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`sections[${sectionIndex}].title`}
              render={({ message }) => (
                <ErrorAlert error={message} maxWidth='200px' type='simple' />
              )}
            />
          </div>
        </S.InputWrapper>
        {isEditableTitle && (
          <button type='button' onClick={() => setIsEditableTitle(false)}>
            <CheckCircleOutlineOutlinedIcon
              fontSize='large'
              style={{ color: '#3B9833', opacity: 0.7 }}
            />
          </button>
        )}
        {!isEditableTitle && (
          <S.ButtonsTitleSection>
            <button type='button' onClick={() => setIsEditableTitle(true)}>
              <EditIcon style={{ color: '#1690F2', cursor: 'pointer' }} />
            </button>
            <button type='button' onClick={() => deleteSection(id)}>
              <DeleteIcon style={{ color: '#D5001F', cursor: 'pointer' }} />
            </button>
          </S.ButtonsTitleSection>
        )}
      </S.TitleSection>
      {selectedDictionaries.length === 0 && (
        <S.DropContainer>Tutaj przenieś pierwszy element</S.DropContainer>
      )}
      <S.SectionDetails issectionvisible={isSubsectionsVisible}>
        {subSectionsRender}
      </S.SectionDetails>
    </S.WrapperSingleSection>
  );
};

export default SingleSection;
