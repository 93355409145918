import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from 'API';
import Indicator from 'components/atoms/Indicator';
import { TOAST_POSITION } from 'constans/constantsToast';

const RenewApplication = () => {
  const [redirectRenew, setRedirectRenew] = useState();
  const { appId } = useParams();
  useEffect(() => {
    const isExtend = async () => {
      if (appId) {
        try {
          await api.refreshApplication(appId);
          setRedirectRenew('/');
          toast.success(
            'Aplikacja została przedłużona pomyślnie.',
            TOAST_POSITION
          );
        } catch (e) {
          setRedirectRenew('/');
          toast.error(
            <div>
              <p>Wystąpił błąd podczas zmiany przedłużania aplikacji.</p>
              {e.response?.data?.errors?.map(err => (
                <p>{err.defaultMessage}</p>
              ))}
            </div>,
            TOAST_POSITION
          );
        }
      }
    };
    isExtend();
  }, [appId]);

  if (redirectRenew) {
    return <Redirect to={redirectRenew} />;
  }

  return <Indicator />;
};

export default RenewApplication;
