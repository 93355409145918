import React, { useState } from 'react';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import SingleSubSection from './SingleSubSection';

import * as S from '../../admin/CreateAdverisement/CreateAdvertisement.css';

const SingleSection = ({ title, dictionaries, advId, print }) => {
  const [isSubsectionsVisible, setSubsectionsVisible] = useState(true);
  let visibleTitles = [];

  const subSectionsRender = dictionaries?.map((item, index) => {
    const isVisibleTitle = !visibleTitles.includes(item.dictionaryTitle);
    visibleTitles = [...visibleTitles, item.dictionaryTitle];

    return (
      <SingleSubSection
        id={`${item.id}-${index}`}
        data={item}
        advId={advId}
        print={print}
        isVisibleTitle={isVisibleTitle}
      />
    );
  });

  return (
    <S.WrapperSingleSection role='Dustbin'>
      <S.TitleSection>
        <S.InputWrapper issubionsvisible={isSubsectionsVisible}>
          {!print && (
            <button
              onClick={() => setSubsectionsVisible(!isSubsectionsVisible)}
              type='button'
            >
              <ArrowDropDownOutlinedIcon aria-hidden='false'>
                <title>Rozwiń</title>
              </ArrowDropDownOutlinedIcon>
              <span style={{ display: 'none' }}>Rozwiń</span>
            </button>
          )}
          <h2>{title}</h2>
        </S.InputWrapper>
      </S.TitleSection>
      {isSubsectionsVisible && subSectionsRender}
    </S.WrapperSingleSection>
  );
};

export default SingleSection;
