import React, { useState } from 'react';
import styled from 'styled-components';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { api } from 'API';

import { Button, MenuLink } from 'components/shared/MenuInTable';
import { toast } from 'react-toastify';
import HiddenMenu from 'components/shared/HiddenMenu';
import { TOAST_POSITION } from 'constans/constantsToast';

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  color: ${({ theme }) => theme.primary};
  background: none;
  border: none;

  svg {
    margin-right: 10px;
  }

  :hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

const MenuCell = ({
  row,
  menuItems,
  setInvitationDetails,
  setUserApplications,
  type,
  category,
  getValues,
  setMenuFunctions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);

  const invitationDetails = {
    appId: row.id,
    aId: row.advertisementName?.id,
    advertisementName: row.advertisementName?.name,
    userId: row.user?.userId,
    name: row.user?.name,
    surname: row.user?.surname,
  };

  const getAllApplications = async uId => {
    const resp = await api.getAllCandidateApplications(uId);
    setUserApplications(resp.data);
  };

  const handleInvModal = () => {
    setInvitationDetails(invitationDetails);
    setMenuFunctions(prev => ({ ...prev, invite: true }));
    setAnchorEl(null);
    getAllApplications(invitationDetails.userId);
  };

  const handleThanksModal = () => {
    setInvitationDetails(invitationDetails);
    setMenuFunctions(prev => ({ ...prev, thanks: true }));
    setAnchorEl(null);
    getAllApplications(invitationDetails.userId);
  };

  const handleInformFulfill = () => {
    setInvitationDetails(invitationDetails);
    setMenuFunctions(prev => ({ ...prev, fulfill: true }));
    setAnchorEl(null);
    getAllApplications(invitationDetails.userId);
  };

  const handleRejectCandidate = async () => {
    const body = {
      status: 'NOT_FULFILL_REQUIREMENTS',
      invitationStatus: null,
    };

    try {
      await api.changeCandidateStatus(invitationDetails.appId, body);
      toast.success('Kandydat odrzucony pomyślnie.', TOAST_POSITION);
      setAnchorEl(null);
      getValues(type, null, category);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      toast.error(
        <div>
          <p>Wystąpił błąd podczas zmiany statusu.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
      return false;
    }
  };

  return (
    <>
      <Button
        type='button'
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon style={{ color: '#888794' }} aria-hidden='false'>
          <title>Menu</title>
        </MoreVertIcon>
        <HiddenMenu />
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(el => (
          <MenuItem key={el.label} style={{ padding: 0 }}>
            {el.label === 'Zaproś do następnego etapu' ? (
              <MenuButton onClick={handleInvModal}>
                {el.icon}
                {el.label}
              </MenuButton>
            ) : el.label === 'Podziękuj za udział' ? (
              <MenuButton onClick={handleThanksModal}>
                {el.icon}
                {el.label}
              </MenuButton>
            ) : el.label === 'Wyślij wiadomość informacyjną' ? (
              <MenuButton onClick={handleInformFulfill}>
                {el.icon}
                {el.label}
              </MenuButton>
            ) : el.label === 'Odrzuć kandydata' ? (
              <MenuButton onClick={handleRejectCandidate}>
                {el.icon}
                {el.label}
              </MenuButton>
            ) : el.label === 'Zobacz kandydata' ? (
              <MenuLink
                to={{
                  pathname: el.to
                    ? `${el.to}/${row.advertisementName?.id}/${row.id}`
                    : '/',
                  state: {
                    activeBookmark: el.props,
                    appId: row.id,
                  },
                }}
              >
                {el.icon}
                {el.label}
              </MenuLink>
            ) : (
              <MenuLink to={el.to ? `${el.to}/${row.id}` : '/'}>
                {el.icon}
                {el.label}
              </MenuLink>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuCell;
