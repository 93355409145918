export const basicFields = [
  {
    accepts: ['subsection'],
    title: 'Podstawowe dane',
    elements: [
      {
        dictionaryId: 102,
        elements: [{ content: { canDuplicate: false } }],
      },
      {
        dictionaryId: 112,
        elements: [{ content: { canDuplicate: false } }],
      },
    ],
  },
  {
    accepts: ['subsection'],
    title: 'Wykształcenie i doświadczenie',
    elements: [
      {
        dictionaryId: 117,
        elements: [{ content: { canDuplicate: true } }],
      },
      {
        dictionaryId: 114,
        elements: [{ content: { canDuplicate: true } }],
      },
      {
        dictionaryId: 115,
        elements: [{ content: { canDuplicate: true } }],
      },
    ],
  },
  {
    accepts: ['subsection'],
    title: 'Inne',
    elements: [
      {
        dictionaryId: 118,
        elements: [{ content: { canDuplicate: false } }],
      },
    ],
  },
];

export const basicDefaultValues = {
  title: null,
  form: [
    {
      accepts: ['subsection'],
      title: 'Podstawowe dane',
      dictionaries: [
        {
          dictionaryId: 102,
          elements: [{ content: { canDuplicate: false } }],
        },
        {
          dictionaryId: 112,
          elements: [{ content: { canDuplicate: false } }],
        },
      ],
    },
    {
      accepts: ['subsection'],
      title: 'Wykształcenie i doświadczenie',
      dictionaries: [
        {
          dictionaryId: 117,
          elements: [{ content: { canDuplicate: true } }],
        },
        {
          dictionaryId: 114,
          elements: [{ content: { canDuplicate: true } }],
        },
        {
          dictionaryId: 115,
          elements: [{ content: { canDuplicate: true } }],
        },
      ],
    },
    {
      accepts: ['subsection'],
      title: 'Inne',
      dictionaries: [
        {
          dictionaryId: 118,
          elements: [{ content: { canDuplicate: false } }],
        },
      ],
    },
  ],
};
