import React, { useEffect } from 'react';

import AdvertisementsTable from 'components/admin/Advertisements/AdvertisementsTable';
import MainWrapper from 'components/atoms/MainWrapper';

const UserAdvertisementsPage = () => {
  useEffect(() => {
    document.title = 'Ogłoszenia - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <AdvertisementsTable />
    </MainWrapper>
  );
};

export default UserAdvertisementsPage;
