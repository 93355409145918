import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from 'API';
import * as S from '../../../SingleAdvertisement.css';
import Indicator from 'components/atoms/Indicator';
import Table from 'components/tables/Table';
import { TOAST_POSITION } from 'constans/constantsToast';

const columns = [
  { title: 'Pozycja', fieldName: 'position', draggable: true },
  { title: 'Wiadomość', fieldName: 'content', draggable: true },
  { title: 'Data i godzina', fieldName: 'date', draggable: true },
  { title: 'Status', fieldName: 'status', draggable: true },
];

const customTableStyle = {
  width: '100%',
};

const TYPE = 'communication';

const CommunicationTable = ({ id, isModalOpen }) => {
  const [users, setUsers] = useState();

  useEffect(() => {
    getUsers();
  }, [isModalOpen]);

  const getUsers = async () => {
    try {
      const { data } = await api.getUserInvitations(id);
      setUsers(data);
    } catch (err) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas pobierania danych.</p>
          {err.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  return (
    <>
      {!users ? (
        <Indicator />
      ) : (
        <S.MainWrapper style={{ padding: 0 }}>
          <Table
            type={TYPE}
            columns={columns}
            data={users}
            tableToolData={''}
            tableWrapperStyle={customTableStyle}
            tableStyle={{ border: 'none' }}
            simple
          />
        </S.MainWrapper>
      )}
    </>
  );
};

export default CommunicationTable;
