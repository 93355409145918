import { statusName } from 'constans/user/constansAdv';

const statusOptions = Object.values(statusName);

export const JobOffersFiltersTable = () => [
  {
    filterType: 'select',
    nameFilter: 'aStatus',
    nameTable: 'userAdvertisement',
    label: 'Status',
    placeholder: 'Wszystkie ogłoszenia',
    options: statusOptions,
  },
  {
    filterType: 'dataPicker',
    nameFilter: 'd',
    nameTable: 'userAdvertisement',
    label: 'Data składania',
  },
];
