export const routes = {
  login: '/login/:token?',
  signup: '/signup',
  forgotpassword: '/forgotPassword',
  forgotpasswordsuccess: '/forgotpasswordsuccess',

  home: '/',
  jobOffer: '/job-offer/:id',
  contactForm: '/contact-form',
  printAdv: '/print-adv/:id',

  admin: {
    home: '/admin',
    adv: '/admin/job-offers',
    singleAdv: '/admin/advertisement/:id/:appId?',
    singleAdvPrint: '/admin/advertisement-print/:id',
    singleAppPrint: '/admin/application-print/:id',
    createAdv: '/admin/create-advertisement/:id?',
    candidates: '/admin/candidates',
    forms: '/admin/forms',
    createForm: '/admin/create-form/:id?',
    dictionary: '/admin/dictionary',
    dictionaryCreateField: '/admin/dictionary/create-field/:id?',
    reports: '/admin/reports',
    reportPrint:
      '/admin/report-print/:id/:page?/:size?/:sortDirection?/:sortBy?',
    administration: '/admin/administration',
    settings: '/admin/settings',
  },

  user: {
    home: '/user',
    settings: '/user/settings',
    adv: '/user/job-offers',
    singleAdv: '/user/single-job-offers/:id',
    applications: '/user/my-applications',
    singleApplications: '/user/single-my-applications/:id',
    printApplication: '/user/print-my-application/:id',
    editApplication: '/user/edit-my-application/:id',
    applicationForm: '/user/application-form/:id',
    refreshApplication: '/refresh/:appId',
    answerInvitation: '/invitation/:answer?',
    inbox: '/user/inbox',
  },
};
