import { UserInput, UserInputSmall } from '../Administration.css';
import { Select } from 'm-web-components';

export const formSelect = options => [
  {
    as: (
      <Select
        options={options}
        placeholder='Typ użytkownika'
        styles={{ control: base => ({ ...base, height: '50px' }) }}
      />
    ),
    type: 'select',
    name: 'userType',
    label: 'Typ użytkownika*',
    rules: { required: 'To pole jest wymagane' },
  },
];

export const formSmallFields = [
  {
    as: (
      <UserInputSmall
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          autoComplete: 'off',
        }}
      />
    ),
    name: 'name',
    label: 'Imię*',
  },
  {
    as: (
      <UserInputSmall
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          autoComplete: 'off',
        }}
      />
    ),

    name: 'surname',
    label: 'Nazwisko*',
  },
];

export const formFields = [
  {
    as: (
      <UserInput
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          autoComplete: 'off',
        }}
      />
    ),
    name: 'email',
    label: 'Email*',
    rules: { required: 'To pole jest wymagane' },
  },
  {
    as: (
      <UserInput
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          type: 'password',
          autocomplete: 'new-password',
        }}
      />
    ),
    name: 'password',
    label: 'Hasło*',
    rules: { required: 'To pole jest wymagane' },
  },
  {
    as: (
      <UserInput
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          type: 'password',
          autocomplete: 'new-password',
        }}
      />
    ),
    name: 'repeatPassword',
    label: 'Powtórz hasło*',
    rules: { required: 'To pole jest wymagane' },
  },
];
