import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Td, Tr } from 'react-super-responsive-table';
import { Button } from 'm-web-components';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';

import Modal from 'components/shared/Modal';
import ModalTableTools from 'components/tables/ModalTableTools';
import { exportFile } from 'helpers/export';
import { api } from 'API';
import theme from 'styles/theme';
import { TOAST_POSITION } from 'constans/constantsToast';

const RowStyled = styled(Tr)`
  background-color: ${({ selected }) => (selected ? '#E6F0F8' : 'transparent')};
  transition: 0.4s;

  :hover {
    border-bottom: 1px solid #ebebeb;
  }
`;

const ReportsLibraryTableRow = ({
  row,
  cols,
  selectedRows,
  dragOver,
  getValues,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const deleteSingleDict = async () => {
    try {
      await api.delteSingleReport(row.id);
      getValues();
      setModalOpen(false);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas usuwania raportu.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const downloadReport = async () => {
    const { data } = await api.getSingleReport(row.id);

    exportFile(data.content);
  };

  const cells = Object.entries(row).map(([, v], idx) => (
    <Td key={v} dragOver={cols[idx] === dragOver}>
      {row[cols[idx].fieldName] === true
        ? 'SZCZEGÓŁOWY'
        : row[cols[idx].fieldName] === false
        ? 'PODSTAWOWY'
        : row[cols[idx].fieldName]}
    </Td>
  ));

  return (
    <>
      <RowStyled
        style={{
          backgroundColor: `${
            selectedRows?.includes(row.id) ? '#E6F0F8' : 'transparent'
          }`,
        }}
      >
        {cells}
        <Td>
          <Button
            colorType='light'
            border='none'
            padding='5px 10px'
            margin='0'
            onClick={downloadReport}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: theme.secondary,
            }}
          >
            <GetAppIcon
              style={{
                color: theme.secondary,
                cursor: 'pointer',
                marginRight: '10px',
              }}
            />
            Pobierz
          </Button>
        </Td>
        <Td>
          <Link
            to={`/admin/report-print/${row.id}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: theme.secondary,
            }}
          >
            <VisibilityOutlinedIcon
              style={{
                color: theme.secondary,
                cursor: 'pointer',
                marginRight: '10px',
              }}
            />
            Podgląd
          </Link>
        </Td>
        <Td
          style={{
            display: 'flex',
            alignItems: 'center',
            color: '#D5001F',
            cursor: 'pointer',
          }}
          onClick={() => setModalOpen(true)}
        >
          <DeleteIcon
            style={{ color: '#D5001F', cursor: 'pointer', marginRight: '10px' }}
          />
          Usuń
        </Td>
      </RowStyled>
      <Modal open={isModalOpen} setOpen={setModalOpen}>
        <ModalTableTools
          title='Czy na pewno chcesz usunąć wybrany raport?'
          buttonLabel='Usuń'
          confirmFunc={deleteSingleDict}
          setOpen={setModalOpen}
        />
      </Modal>
    </>
  );
};

export default ReportsLibraryTableRow;
