import React, { memo } from 'react';
import { useDrag } from 'react-dnd-latest';
import { v4 as uuidv4 } from 'uuid';

import AddIcon from '@material-ui/icons/Add';

import * as S from './CreateAdvertisement.css';

const SingleDict = memo(function Box({ name, type, id, elements }) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name, dictionaryId: id, id: uuidv4(), elements },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, type]
  );

  return (
    <S.DictionaryListitem ref={drag} role='subsection' style={{ opacity }}>
      <div>
        <AddIcon aria-hidden='false'>
          <title>Przeciągnij by dodać</title>
        </AddIcon>{' '}
        {name}
      </div>
    </S.DictionaryListitem>
  );
});

export default SingleDict;
