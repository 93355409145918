import { api } from 'API';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { invitationStatus } from 'constans/user/constansInv';

import * as S from './Inbox.css';
import CheckIcon from '@material-ui/icons/Check';
import { format } from 'date-fns/esm';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';

import Modal from 'components/shared/Modal';
import ModalTableTools from 'components/tables/ModalTableTools';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'actions';
import { TOAST_POSITION } from 'constans/constantsToast';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 30px 60px;
  border: 1px solid ${({ theme }) => theme.lighter};
  background-color: ${({ theme }) => theme.colorLighter};
  margin-top: 50px;
  min-height: 80vh;

  @media (max-width: 768px) {
    padding: 30px 10px;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: 0;
  }
`;

const Inbox = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);

  const now = new Date();

  useEffect(() => {
    dispatch(allActions.notificationsActions.getNotifications());
  }, []);

  const confirmInvite = async appId => {
    const status = 'CONFIRM';
    try {
      await api.putAnswerInvitation(appId, status);
    } catch (e) {
      toast.error(
        <div>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }

    dispatch(allActions.notificationsActions.getNotifications());
  };

  const handleRejectInvite = async appId => {
    setModalOpen(prev => !prev);
    setApplicationId(appId);
  };

  const rejectInvite = async appId => {
    const status = 'REJECT';
    try {
      await api.putAnswerInvitation(appId, status);
    } catch (e) {
      toast.error(
        <div>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
    setModalOpen(prev => !prev);

    dispatch(allActions.notificationsActions.getNotifications());
  };

  let candidateInvitesList;
  if (notifications !== null) {
    candidateInvitesList = notifications.map((el, i) => {
      return (
        <S.InvitationWrap key={i}>
          <S.InvTitle>
            <S.InvPosition> {el.position}</S.InvPosition>

            <S.InvType>
              {el.status.applicationStatus === 'TEST_INV'
                ? invitationStatus.TEST_INV
                : invitationStatus.INTERVIEW_INV}
            </S.InvType>
          </S.InvTitle>
          <S.InvDetails>
            <S.FieldName>Wiadomość</S.FieldName>
            <div>{el.content}</div>
            <S.FieldName>Data i godzina</S.FieldName>
            <div>
              {format(parseISO(el.date), 'dd-MM-yyyy') +
                ' godz. ' +
                format(parseISO(el.date), 'HH:mm')}
            </div>
          </S.InvDetails>
          {parseISO(el.date) < now ? (
            <S.InvNotif> Czas na odpowiedź upłynął</S.InvNotif>
          ) : el.status.invitationStatus === 'CONFIRM' ? (
            <S.InvNotif green>
              <CheckIcon /> Potwierdziłeś/-aś swoje przybycie na
              {el.status.applicationStatus === 'TEST_INV'
                ? ' test'
                : ' rozmowę'}
            </S.InvNotif>
          ) : el.status.invitationStatus === 'REJECT' ? (
            <S.InvNotif>
              Zrezygnowałeś/-aś z aplikacji na to stanowisko
            </S.InvNotif>
          ) : (
            <S.Buttons>
              <S.ResignButton onClick={() => handleRejectInvite(el.id)}>
                Rezygnuję z rekrutacji
              </S.ResignButton>
              <S.ConfirmButton onClick={() => confirmInvite(el.id)}>
                Potwierdzam, że będę
              </S.ConfirmButton>
            </S.Buttons>
          )}
        </S.InvitationWrap>
      );
    });
  }

  return (
    <Wrapper>
      {notifications.length !== 0 ? (
        candidateInvitesList
      ) : (
        <div>Brak nowych zaproszeń</div>
      )}

      <Modal open={isModalOpen} setOpen={setModalOpen}>
        <ModalTableTools
          title={'Czy na pewno chcesz zrezygnować z dalszej rekrutacji?'}
          text={
            'Rezygnacja spowoduje, że zostaniesz usunięty z listy kandydatow na to stanowisko.'
          }
          buttonLabel={'Zrezygnuj'}
          setOpen={setModalOpen}
          confirmFunc={() => rejectInvite(applicationId)}
        />
      </Modal>
    </Wrapper>
  );
};

export default Inbox;
