import React from 'react';
import SystemLogsTable from './SystemLogsTable';
import * as S from '../Administration.css';

const SystemLogs = () => {
  return (
    <S.BookmarkWrapper>
      <SystemLogsTable />
    </S.BookmarkWrapper>
  );
};

export default SystemLogs;
