import { api } from 'API';
import { Button } from 'm-web-components';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { centerFlex } from 'styles/mixins';
import { statusName } from 'constans/constansCandidates';
import theme from 'styles/theme';
import * as S from 'components/tables/Table.css';
import { TOAST_POSITION } from 'constans/constantsToast';

const Wrapper = styled.form`
  background-color: #fff;
  padding: 20px;
  color: ${({ theme }) => theme.primary};

  h4 {
    font-size: 16px;
  }
`;

export const SelectStatusWrap = styled.div`
  background: #eee;
  display: flex;
  flex-direction: column;

  input[type='radio'] {
    display: none;
  }

  input[type='radio']:checked + label {
    background: rgba(230, 240, 248, 1);
    border-width: 2px;
    border-style: solid;
  }

  label {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  label:hover {
    background: rgb(230, 240, 248);
  }

  @media (min-width: 400px) {
    width: 400px;
  }
`;

const Buttons = styled.div`
  ${centerFlex}
  margin-top: 30px;
`;

const ModalChangeStatus = ({ id, setModalOpen }) => {
  const statusNameArr = Object.values(statusName);
  const [selectedStatus, setSelectedStatus] = useState();
  const [invStatus, setInvStatus] = useState(null);

  const onSubmit = async e => {
    e.preventDefault();

    const body = {
      status: selectedStatus,
      invitationStatus: invStatus,
    };

    if (body.status !== undefined) {
      try {
        await api.changeCandidateStatus(id, body);
        setModalOpen(prev => !prev);
        toast.success('Status zmieniono pomyślnie.', TOAST_POSITION);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        toast.error(
          <div>
            <p>Wystąpił błąd podczas zmiany statusu.</p>
            {e.response?.data?.errors?.map(err => (
              <p>{err.defaultMessage}</p>
            ))}
          </div>,
          TOAST_POSITION
        );
        return false;
      }
    }
  };

  const handleChange = e => {
    setSelectedStatus(e.target.value);
    setInvStatus(null);

    if (e.target.value === 'TEST_INV' || e.target.value === 'INTERVIEW_INV') {
      setInvStatus('PENDING');
    }

    if (e.target.value === 'TEST_INV_CONFIRM') {
      setSelectedStatus('TEST_INV');
      setInvStatus('CONFIRM');
    }

    if (e.target.value === 'INTERVIEW_INV_CONFIRM') {
      setSelectedStatus('INTERVIEW_INV');
      setInvStatus('CONFIRM');
    }

    if (e.target.value === 'TEST_INV_REJECT') {
      setSelectedStatus('TEST_INV');
      setInvStatus('REJECT');
    }

    if (e.target.value === 'INTERVIEW_INV_REJECT') {
      setSelectedStatus('INTERVIEW_INV');
      setInvStatus('REJECT');
    }
  };

  const statusList = statusNameArr.map((el, i) => (
    <>
      <input
        type='radio'
        name='changeStatus'
        id={'status' + i}
        key={i}
        value={el.value}
        onChange={handleChange}
      />
      <label
        htmlFor={'status' + i}
        style={{
          color: theme[el.value],
          borderColor: theme[el.value],
        }}
      >
        {el.label}
        {(el.value === 'TEST_INV_CONFIRM' ||
          el.value === 'INTERVIEW_INV_CONFIRM') &&
          ' (Potwierdzenie)'}
        {(el.value === 'INTERVIEW_INV_REJECT' ||
          el.value === 'TEST_INV_REJECT') && (
          <span style={{ color: '#D5001F', marginLeft: '2px' }}>
            (Rezygnacja)
          </span>
        )}
      </label>
    </>
  ));

  return (
    <Wrapper>
      <h4>Zmień status</h4>
      <SelectStatusWrap>{statusList}</SelectStatusWrap>

      <Buttons>
        <S.FiltersButtonToggle
          type='button'
          onClick={() => setModalOpen(prev => !prev)}
        >
          Anuluj
        </S.FiltersButtonToggle>
        <Button
          type='button'
          colorType='primary'
          onClick={onSubmit}
          padding='5px 10px'
          margin='0 0 0 20px'
          fontWeight='600'
          style={{ border: 'none' }}
        >
          Zapisz
        </Button>
      </Buttons>
    </Wrapper>
  );
};

export default ModalChangeStatus;
