import React from 'react';
import {
  RedSpan,
  RegisterInput,
  RegisterInputSmall,
  RegulationsButton,
} from './Login.css.js';
import { Checkbox } from '@material-ui/core';

export const formSmallFields = [
  {
    as: (
      <RegisterInputSmall
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),
    name: 'name',
    label: (
      <>
        Imię<RedSpan>*</RedSpan>
      </>
    ),
  },
  {
    as: (
      <RegisterInputSmall
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),

    name: 'surname',
    label: (
      <>
        Nazwisko<RedSpan>*</RedSpan>
      </>
    ),
  },
];

export const formFields = [
  {
    as: (
      <RegisterInput
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),
    name: 'password',
    label: (
      <>
        Hasło<RedSpan>*</RedSpan>
      </>
    ),
    rules: { required: 'To pole jest wymagane' },
    type: 'password',
  },
  {
    as: (
      <RegisterInput
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),
    name: 'repeatPassword',
    label: (
      <>
        Powtórz hasło<RedSpan>*</RedSpan>
      </>
    ),
    rules: { required: 'To pole jest wymagane' },
    type: 'password',
  },
  {
    as: (
      <RegisterInput
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),
    name: 'email',
    label: (
      <>
        Email<RedSpan>*</RedSpan>
      </>
    ),
    rules: { required: 'To pole jest wymagane' },
  },
];

export const formCheckboxFields = showRegulation => [
  {
    as: <Checkbox id='regulations' />,
    name: 'agree',
    rules: { required: 'To pole jest wymagane' },
    label: (
      <span style={{ fontSize: '1.5rem' }}>
        Akceptuję
        <RegulationsButton type='button' onClick={showRegulation}>
          regulamin
        </RegulationsButton>
        korzystania z portalu.
        <RedSpan>*</RedSpan>
      </span>
    ),
    type: 'checkbox',
  },
  {
    as: <Checkbox id='newsletter' />,
    name: 'newsletter',
    label: (
      <span style={{ fontSize: '1.5rem' }}>
        Wyrażam zgodę na otrzymywanie powiadomień o nowych ofertach pracy.
      </span>
    ),
    type: 'checkbox',
  },
];
