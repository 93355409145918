import styled from 'styled-components';
import { device } from 'styles/devices';

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  z-index: 2;

  @media ${device.desktop} {
    padding: 15px;
  }
`;

export const Logo = styled.img`
  margin-right: 10px;
`;

export const Nav = styled.nav`
  position: absolute;
  top: 0;
  right: ${({ isopen }) => (isopen ? 0 : '-100%')};
  transition: 0.3s;

  background-color: ${({ theme }) => theme.secondary};
  height: 100vh;
  width: 70vw;
  color: white;

  @media ${device.desktop} {
    position: relative;
    right: 0;
    height: auto;
    background-color: transparent;
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 100px 0;

  @media ${device.desktop} {
    flex-direction: row;
    position: relative;
    padding: 0 0 0 20px;
  }
`;

export const NavItem = styled.li`
  a {
    display: block;
    color: white;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 1.5rem;
  }

  @media ${device.desktop} {
    a {
      color: ${({ theme }) => theme.light};
      padding: 15px;

      &.active {
        border-bottom: 3px solid ${({ theme }) => theme.secondary};
        color: ${({ theme }) => theme.secondary};
      }
    }
  }
`;

export const Aside = styled.aside`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  svg {
    margin-right: 2px;
    margin-left: 2px;
    margin-top: 5px;
    cursor: pointer;

    @media ${device.mobileM} {
      margin-top: auto;
      margin-right: 5px;
    }

    @media ${device.tablet} {
      margin-right: 20px;
    }
  }

  @media ${device.mobileL} {
    flex-direction: row;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const Mail = styled.div`
  align-items: center;
  display: flex;
  padding: 0 10px;
  color: ${({ theme }) => theme.light};
  border-right: 1px solid ${({ theme }) => theme.light};

  svg {
    width: 2rem;
    height: 2rem;
    margin: auto;
  }

  a {
    display: flex;
  }

  span {
    color: ${({ theme }) => theme.secondary};
    margin-left: 10px;
    font-size: 1.6rem;
    font-weight: 700;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary};
  padding: 0 10px;

  h3 {
    margin: 0;

    @media ${device.desktopL} {
      min-width: 200px;
    }
  }
`;
