import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';
import { toggleSelectAllRows } from 'components/tables/helpers/checkboxesFunc';

import { styledTable } from 'components/tables/helpers/changeStylesBrakpoints';
import { ApplicationsFiltersTable } from './ApplicationsFiltersTable';

import { api } from 'API';
import RowMenuModal from 'components/shared/RowMenuModal/RowMenuModal';
import Modal from 'components/shared/RowMenuModal/Modal';
import { toast } from 'react-toastify';
import HiddenMenu from 'components/shared/HiddenMenu';
import { AccessibilityLabel } from '../../tables/Table.css';
import { TOAST_POSITION } from 'constans/constantsToast';

const Wrapper = styled.div`
  //change breakpoint for mobile view
  @media screen and (max-width: 1000px) {
    ${styledTable}
  }
`;

const CATEGORY = 'candidate/';
const TYPE = 'applications';

const ApplicationsTable = () => {
  const dispatch = useDispatch();
  const { applications: dataTable } = useSelector(
    state => state.currentPageTables
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const [menuFunctions, setMenuFunctions] = useState({
    resign: false,
    currentRow: null,
    renew: false,
  });

  const resignFunc = async selectedRows => {
    try {
      await api.putCandidateResign(selectedRows);
      if (menuFunctions.resign) {
        setMenuFunctions(prev => ({ ...prev, resign: false }));
      }
      getValues(TYPE, null, CATEGORY);
      setSelectedRows([]);
      toast.success('Pomyślnie zrezygnowano z aplikacji.', TOAST_POSITION);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas zmiany statusu aplikacji.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const renewFunc = async selectedRows => {
    try {
      await api.putCandidateRenew(selectedRows);
      if (menuFunctions.renew) {
        setMenuFunctions(prev => ({ ...prev, renew: false }));
      }
      getValues(TYPE, null, CATEGORY);
      setSelectedRows([]);
      toast.success('Pomyślnie wznowiono aplikację.', TOAST_POSITION);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas zmiany statusu aplikacji.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const toolsButtons = [
    {
      label: 'Zrezygnuj',
      colorType: 'gray',
      titleModal: 'Czy na pewno chcesz zrezygnować z wybranych aplikacji?',
      textModal:
        'Rezygnacja z aplikacji oznacza usunięcie z listy kandydatów na dane stanowisko.',
      buttonLabelModal: 'OK',
      onClick: async () => {
        resignFunc(selectedRows);
      },
    },
  ];

  const getValues = (type = TYPE, newValue, category = CATEGORY) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(type, newValue, category)
    );
  };

  const cancelFunc = () => {
    setMenuFunctions(prev => ({
      ...prev,
      resign: false,
      renew: false,
      currentRow: null,
    }));
    setSelectedRows([]);
  };

  useEffect(() => {
    getValues(TYPE, null, CATEGORY);
    setSelectedRows([]);
  }, []);

  const columns = [
    {
      component: (
        <>
          <AccessibilityLabel>
            <p>Zaznacz</p>
            <input
              type='checkbox'
              onChange={() =>
                toggleSelectAllRows({
                  selectedRows,
                  dataTable,
                  setSelectedRows,
                })
              }
              checked={selectedRows.length === dataTable?.length}
              id='checkbox_userApp'
            />
          </AccessibilityLabel>
        </>
      ),
      fieldName: 'checkAll',
      draggable: false,
    },
    {
      title: 'Nr aplikacji',
      fieldName: 'id',
      sorting: true,
      draggable: true,
    },
    { title: 'Stanowisko', fieldName: 'title', sorting: true, draggable: true },

    {
      title: 'Data złożenia aplikacji',
      fieldName: 'applicationDate',
      sorting: true,
      draggable: true,
    },
    { title: <HiddenMenu />, fieldName: 'menu', draggable: false },
  ];

  return (
    <>
      <Wrapper>
        {!dataTable ? (
          <Indicator />
        ) : (
          <>
            <Table
              type={TYPE}
              category={CATEGORY}
              columns={columns}
              data={dataTable}
              getValues={getValues}
              searchPlaceholder='Szukaj'
              filtersTable={ApplicationsFiltersTable()}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              tableToolData={{
                countPosition: selectedRows.length,
                buttons: toolsButtons,
              }}
              addButton={{
                label: '+ Złóż aplikację ogólną',
                to: '/user/application-form/0',
              }}
              setMenuFunctions={setMenuFunctions}
            />
          </>
        )}
        <Modal open={menuFunctions.resign} cancelFunc={() => cancelFunc()}>
          <RowMenuModal
            title={'Czy na pewno chcesz zrezygnować z wybranych aplikacji?'}
            text={
              'Rezygnacja z aplikacji oznacza usunięcie z listy kandydatów na dane stanowisko.'
            }
            buttonLabel={'OK'}
            confirmFunc={() => resignFunc(menuFunctions.currentRow)}
            cancelFunc={() => cancelFunc()}
            setOpen={menuFunctions.resign}
          />
        </Modal>
        <Modal open={menuFunctions.renew} cancelFunc={() => cancelFunc()}>
          <RowMenuModal
            title={'Czy na pewno chcesz wznowić aplikację?'}
            text={
              'Aplikacja zostanie wznowiona i ponownie będzie brana pod uwagę podczas procesu rekrutacji.'
            }
            buttonLabel={'OK'}
            confirmFunc={() => renewFunc(menuFunctions.currentRow)}
            cancelFunc={() => cancelFunc()}
            setOpen={menuFunctions.renew}
          />
        </Modal>
      </Wrapper>
    </>
  );
};

export default ApplicationsTable;
