import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import styled from 'styled-components';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import * as S from './Advertisement.css';
import { useHistory } from 'react-router';

const TdCustom = styled(Td)`
  padding: 20px !important;

  .tdBefore {
    display: none;
  }
`;

const GuestAdvertisementsTableRow = ({ row, cols }) => {
  const history = useHistory();
  const cells = Object.entries(cols).map(() => {
    return (
      <TdCustom key={row?.title}>
        <S.ListElement onClick={() => history.push(`/job-offer/${row.id}`)}>
          <h2>{row.title}</h2>
          <p>
            <AccessTimeIcon aria-hidden='false'>
              <title>Data publikacji</title>
            </AccessTimeIcon>
            {row.publicationDate}
          </p>
        </S.ListElement>
      </TdCustom>
    );
  });
  return <Tr>{cells}</Tr>;
};

export default GuestAdvertisementsTableRow;
