import {
  SET_FILTER_TABLE,
  CLEAR_FILTER_TABLE,
} from 'actions/filtersTable.action';

const INITIAL_STATE = {
  adminAdvertisement: {
    aStatus: '',
    dId: '',
    pFrom: null,
    pTo: null,
    cFrom: null,
    cTo: null,
    dFrom: null,
    dTo: null,
  },
  adminDictionaries: {
    cFrom: null,
    cTo: null,
  },
  adminCandidates: {
    appStatus: '',
    aId: '',
    dId: '',
    cFrom: null,
    cTo: null,
  },
  adminForms: {
    cFrom: null,
    cTo: null,
  },
  adminLogs: {},

  adminUsers: {
    userRole: '',
    chIn: '',
  },

  userAdvertisement: {
    aStatus: 'ACTIVE',
    dId: '',
    dFrom: null,
    dTo: null,
  },

  userApplications: {
    aStatus: '',
    cFrom: null,
    cTo: null,
  },

  advertisementCandidates: {
    chIn: '',
    appStatus: '',
    cFrom: null,
    cTo: null,
  },

  guestAdvertisement: {
    aStatus: 'ACTIVE',
    pFrom: null,
    pTo: null,
  },
};

const filtersTableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILTER_TABLE:
      return {
        ...state,
        [action.item.nameTable]: {
          ...state[action.item.nameTable],
          [action.item.nameFilter]: action.item.value,
        },
      };

    case CLEAR_FILTER_TABLE:
      return {
        ...state,
        [action.item]: INITIAL_STATE[action.item],
      };

    default:
      return state;
  }
};

export default filtersTableReducer;
