export const statusName = {
  ACTIVE: {
    label: 'Aktywne',
    value: 'ACTIVE',
  },
  ARCHIVAL: {
    label: 'Archiwalne',
    value: 'ARCHIVAL',
  },
};

const statusOptions = Object.values(statusName);

export const filtersTable = [
  {
    filterType: 'select',
    nameFilter: 'aStatus',
    nameTable: 'guestAdvertisement',
    label: 'Status',
    placeholder: 'Wszystkie ogłoszenia',
    options: statusOptions,
  },
  {
    filterType: 'dataPicker',
    nameFilter: 'p',
    nameTable: 'guestAdvertisement',
    label: 'Data opublikowania',
  },
];
