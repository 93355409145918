export const getAttachmentsFromForm = form => {
  let attachments = [];
  form.sections?.forEach(section => {
    section?.dictionaries?.forEach(dict => {
      dict?.elements?.forEach(el => {
        if (typeof el?.content?.[0]?.name == 'string')
          attachments.push(...el.content);
      });
    });
  });

  return attachments;
};

export const getAttachmentsFromFormApplicate = form => {
  let attachments = [];
  form.sections?.forEach(section => {
    section?.dictionaries?.forEach(dict => {
      dict?.elements?.forEach(el => {
        if (el?.content?.content) {
          if (typeof el?.content?.content?.[0].name === 'string')
            attachments.push(...el.content.content);
        }
      });
    });
  });

  return attachments;
};
