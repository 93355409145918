import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { api } from 'API';
import Indicator from 'components/atoms/Indicator';
import AdvDescDetails from 'components/shared/AdvertisementDetails/AdvDetails';
import PrintComponent from 'components/shared/PrintComponent';
import { TOAST_POSITION } from 'constans/constantsToast';

const SingleAdvertisementPrint = () => {
  const { id } = useParams();
  const [advData, setAdvData] = useState();

  const getAdvertisement = async () => {
    try {
      const { data } = await api.getAdvertisement(id);
      setAdvData(data);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas pobierania informacji o ogłoszeniu.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  useEffect(() => {
    getAdvertisement();
  }, []);

  useEffect(() => {
    document.title = 'Drukuj ogłoszenie - Portal Rekrutacyjny Zabrze';
  }, [advData]);

  return (
    <>
      {!advData ? (
        <Indicator />
      ) : (
        <PrintComponent
          title={advData?.formDetails?.label}
          componentToPrint={<AdvDescDetails data={advData.details} print />}
        />
      )}
    </>
  );
};

export default SingleAdvertisementPrint;
