import React from 'react';
import styled from 'styled-components';

const ShortAnswer = styled.div`
  font-family: 'Calibri';

  svg {
    font-size: 10px;
    margin-right: 10px;
  }

  span {
    padding-right: 10px;
  }
`;

const SingleShortAnswer = ({ content, noBall, label }) => {
  return (
    <ShortAnswer style={{ margin: '0 10px' }}>
      {label && !noBall && (
        <span style={{ margin: '5px 0px', fontWeight: '600' }}>{label}:</span>
      )}
      {content?.content ||
        content?.label ||
        content?.[0]?.path ||
        (typeof content === 'string' ? content : '')}
    </ShortAnswer>
  );
};

export default SingleShortAnswer;
