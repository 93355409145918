import React, { useEffect } from 'react';

import ApplicationsTable from 'components/user/Applications/ApplicationsTable';
import MainWrapper from 'components/atoms/MainWrapper';

const ApplicationsPage = () => {
  useEffect(() => {
    document.title = 'Moje aplikacje - Portal Rekrutacyjny Zabrze';
  }, []);
  return (
    <MainWrapper>
      <ApplicationsTable />
    </MainWrapper>
  );
};

export default ApplicationsPage;
