import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';

const TYPE = 'logs';
const CATEGORY = 'admin/';

const AdvertisementsList = () => {
  const dispatch = useDispatch();
  const { logs: dataTable } = useSelector(state => state.currentPageTables);

  const getValues = (type = TYPE, newValue, category = CATEGORY) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(type, newValue, category)
    );
  };

  useEffect(() => {
    getValues(TYPE, null);
  }, []);

  const columns = [
    {
      title: 'Nazwa dokumentu',
      fieldName: 'fullName',
      sorting: false,
      draggable: false,
    },
    {
      title: 'Data utworzenia',
      fieldName: 'display',
      sorting: false,
      draggable: false,
    },
    {
      title: '',
      fieldName: 'download',
      sorting: false,
      draggable: false,
    },
  ];

  return (
    <>
      {!dataTable ? (
        <div>Nie znaleziono logów.</div>
      ) : (
        <Table
          type={TYPE}
          category={CATEGORY}
          columns={columns}
          data={dataTable}
          getValues={getValues}
          searchPlaceholder='Szukaj'
          filtersTable={[]}
          tableToolData={{}}
          tableStyle={{ border: 'none' }}
          noFilters
        />
      )}
    </>
  );
};

export default AdvertisementsList;
