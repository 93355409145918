import React from 'react';
import styled from 'styled-components';

import MayerynLogoImg from 'assets/mayeryn.png';
import { device } from 'styles/devices';

const Mayeryn = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0.6;
  margin-top: 20px;

  p {
    font-size: 1rem;
    margin-bottom: 3px;
    padding: 0;
  }

  a {
    text-decoration: none;
    img {
      max-width: 150px;
    }
  }

  @media ${device.tablet} {
    text-align: end;
  }
`;

const MayerynLogo = () => {
  return (
    <Mayeryn>
      <p>Projekt i realizacja</p>
      <a href='https://mportal.mayeryn.com/r/DdN'>
        <img src={MayerynLogoImg} alt='logo-mayeryn' />
      </a>
    </Mayeryn>
  );
};

export default MayerynLogo;
