import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ThanksSentConfirmation from './ThanksSentConfirmation';
import SendThanksForm from './SendThanksForm';

import { api } from 'API';
import { toast } from 'react-toastify';
import { TOAST_POSITION } from 'constans/constantsToast';

export const Wrapper = styled.form`
  width: 450px;
  background-color: #fff;
  padding: 20px;
  color: ${({ theme }) => theme.primary};
  h4 {
    font-size: 16px;
    color: ${({ theme }) => theme.secondary};
  }
  > p {
    margin: 10px 0;
    text-transform: uppercase;
    display: flex;
    color: ${({ theme }) => theme.primary};
    flex-direction: column;
    font-size: 11px;
    font-weight: 600;

    span {
      margin-top: 10px;
      align-self: flex-start;
      background: ${({ theme }) => theme.secondary};
      padding: 5px;
      color: #fff;
      font-size: 15px;
      font-weight: 600;
    }
  }
`;

const TYPE = 'THANK';

const ModalSendThanks = ({
  setOpen,
  invitationDetails,
  userApplications,
  getValues,
  type,
  category,
  cancelFunc,
}) => {
  const placeholder = 'Brak wczytanej domyślnej wiadomości.';

  const [formData, setFormData] = useState({
    type: 'INTERVIEW',
    content: placeholder,
    uId: invitationDetails.userId,
    aId: invitationDetails.aId,
    appId: invitationDetails.appId,
    advertisementName: invitationDetails.advertisementName,
  });

  useEffect(() => {
    const getMessage = async () => {
      try {
        const { data } = await api.getSingleDefaultMessage(TYPE);
        setFormData(prev => ({ ...prev, content: data.content }));
      } catch {
        toast.error(
          'Nie udało się pobrać domyślnej wiadomości.',
          TOAST_POSITION
        );
      }
    };
    getMessage();
  }, []);

  const [isSent, setSent] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();

    const body = {
      content: formData.content,
    };

    try {
      await api.putCandidateInvite(formData.aId, formData.uId, body);
      setSent(true);
      getValues(type, null, category);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      toast.error(
        'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z administratorem.',
        TOAST_POSITION
      );
      return false;
    }
  };

  return (
    <Wrapper>
      {!isSent ? (
        <SendThanksForm
          invitationDetails={invitationDetails}
          userApplications={userApplications}
          setFormData={setFormData}
          formData={formData}
          setOpen={setOpen}
          setSent={setSent}
          onSubmit={onSubmit}
          cancelFunc={cancelFunc}
        />
      ) : (
        <>
          <ThanksSentConfirmation
            invitationDetails={invitationDetails}
            formData={formData}
          />
        </>
      )}
    </Wrapper>
  );
};

export default ModalSendThanks;
