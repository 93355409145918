import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';
import { toggleSelectAllRows } from 'components/tables/helpers/checkboxesFunc';

import { styledTable } from 'components/tables/helpers/changeStylesBrakpoints';
import { FormsFiltersTable } from './FormFiltersTable';

import RowMenuModal from 'components/shared/RowMenuModal/RowMenuModal';
import Modal from 'components/shared/RowMenuModal/Modal';

import { api } from 'API';
import { toast } from 'react-toastify';
import { AccessibilityLabel } from '../../tables/Table.css';
import { TOAST_POSITION } from 'constans/constantsToast';

const Wrapper = styled.div`
  //change breakpoint for mobile view
  @media screen and (max-width: 860px) {
    ${styledTable}
  }
`;

const CATEGORY = 'recruiter/';
const TYPE = 'form/table';

const FormsTable = () => {
  const dispatch = useDispatch();
  const { [TYPE]: dataTable } = useSelector(state => state.currentPageTables);

  const [selectedRows, setSelectedRows] = useState([]);
  const [menuFunctions, setMenuFunctions] = useState({
    delete: false,
    currentRow: null,
  });

  const deleteFunc = async selectedRows => {
    try {
      await api.deleteForm(selectedRows);
      if (menuFunctions.delete) {
        setMenuFunctions(prev => ({ ...prev, delete: false }));
      }
      getValues(TYPE, null, CATEGORY);
      setSelectedRows([]);
      toast.success('Pomyślnie usunięto formularz.', TOAST_POSITION);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas usuwania formularza.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const toolsButtons = [
    {
      label: 'Usuń',
      colorType: 'gray',
      titleModal:
        'Formularz zostanie trwale usunięty. Nie będzie możliwości jego przywrócenia.',
      buttonLabelModal: 'Usuń',
      onClick: () => {
        deleteFunc(selectedRows);
      },
    },
  ];

  const getValues = (type = TYPE, newValue, category = CATEGORY) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(type, newValue, category)
    );
  };

  useEffect(() => {
    getValues(TYPE, null, CATEGORY);
  }, []);

  const cancelFunc = () => {
    setMenuFunctions(prev => ({
      ...prev,
      delete: false,
      currentRow: null,
    }));
    setSelectedRows([]);
  };

  const columns = [
    {
      component: (
        <>
          <AccessibilityLabel>
            <p>Zaznacz wszystkie</p>
            <input
              id='formCheckbox'
              type='checkbox'
              onChange={() =>
                toggleSelectAllRows({
                  selectedRows,
                  dataTable,
                  setSelectedRows,
                })
              }
              checked={selectedRows.length === dataTable?.length}
            />
          </AccessibilityLabel>
        </>
      ),
      fieldName: 'checkAll',
      draggable: false,
    },
    {
      title: 'Nr wniosku',
      fieldName: 'proposalNr',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Nazwa formularza',
      fieldName: 'title',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Data utworzenia',
      fieldName: 'dateCreated',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Opis',
      fieldName: 'description',
      sorting: false,
      draggable: true,
    },
    {
      title: <span style={{ color: 'transparent' }}>Menu</span>,
      fieldName: 'menu',
      draggable: false,
    },
  ];

  return (
    <Wrapper>
      {!dataTable ? (
        <Indicator />
      ) : (
        <>
          <Table
            type={TYPE}
            category={CATEGORY}
            columns={columns}
            data={dataTable}
            getValues={getValues}
            searchPlaceholder='Szukaj'
            filtersTable={FormsFiltersTable()}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            tableToolData={{
              countPosition: selectedRows.length,
              buttons: toolsButtons,
            }}
            addButton={{
              label: '+ Stwórz formularz',
              to: '/admin/create-form/',
            }}
            setMenuFunctions={setMenuFunctions}
          />
        </>
      )}

      {menuFunctions.delete === true && (
        <Modal open={menuFunctions.delete} cancelFunc={() => cancelFunc()}>
          <RowMenuModal
            title={'Czy na pewno chcesz usunąć formularz?'}
            text={
              'Formularz zostanie trwale usunięty. Nie będzie możliwości jego przywrócenia.'
            }
            buttonLabel={'OK'}
            confirmFunc={() => deleteFunc(menuFunctions.currentRow)}
            cancelFunc={() => cancelFunc()}
            setOpen={menuFunctions.delete}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default FormsTable;
