import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AccountDetails,
  SettingsMenu,
  BookmarkWrapper,
  Section,
  SectionWrap,
  Title,
  UserDataInput,
  Td,
  SubmitButton,
  EditButton,
  NotificationSubtitle,
  NotificationWrapper,
} from './Settings.css';
import { userTypes } from 'constans/constantsAdministration';
import { useForm } from 'react-hook-form';
import { ControlForm } from 'm-web-components';
import { api } from 'API';
import allActions from 'actions';
import { toast } from 'react-toastify';
import { role } from 'auth/constans';
import { Switch } from '@material-ui/core';
import { TOAST_POSITION } from 'constans/constantsToast';

const Settings = ({ menuList }) => {
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);

  const { handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      name: user.name,
      surname: user.surname,
      email: user.email,
    },
  });

  const getCurrentUser = async () => {
    try {
      const { data } = await api.me();
      dispatch(allActions.authActions.setUser(data));
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    document.title = 'Ustawienia - Portal Rekrutacyjny Zabrze';
  }, []);

  const [isDisabled, setDisabled] = useState(true);

  const roleName = userTypes.map(el => el.value === user.role && el.label);

  const handleEnableEdit = () => {
    setDisabled(prev => !prev);
  };

  const cancelChanges = () => {
    getCurrentUser();
    reset();
    setDisabled(prev => !prev);
  };

  const switchNotifications = async () => {
    try {
      await api.putNotifications();
      getCurrentUser();
      toast.success('Zaktualizowano ustawienia powiadomień.', TOAST_POSITION);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas aktualizowania powiadomień.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const formFields = [
    {
      as: (
        <UserDataInput
          id='name'
          disabled={isDisabled}
          inputProps={{ maxLength: '50' }}
        />
      ),
      name: 'name',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
    {
      as: (
        <UserDataInput
          id='surname'
          disabled={isDisabled}
          inputProps={{ maxLength: '50' }}
        />
      ),
      name: 'surname',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },

    {
      as: (
        <UserDataInput
          id='email'
          disabled={isDisabled}
          inputProps={{ maxLength: '100' }}
        />
      ),
      name: 'email',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
  ];

  const fields = formFields.map(el => (
    <ControlForm key={el.name} {...el} errors={errors} control={control} />
  ));

  const onSubmit = async data => {
    try {
      const resp = await api.putAccountDetails(data);
      if (user.email !== data.email) {
        localStorage.setItem('accessToken', `${resp.data}`);
        delete resp.data;
      }
      toast.success('Dane użytkownika zostały zaktualizowane.', TOAST_POSITION);
      setDisabled(prev => !prev);
      getCurrentUser();
    } catch (e) {
      toast.error('Wybrany email znajduje się już w bazie', TOAST_POSITION);

      console.log(e);
    }
  };

  return (
    <>
      <BookmarkWrapper>
        <SectionWrap>
          <Section>
            <Title>Dane użytkownika</Title>
            <form onSubmit={handleSubmit(onSubmit)}>
              <AccountDetails>
                <tbody>
                  <tr>
                    <Td bold>
                      <label htmlFor='name'>Imię</label>
                    </Td>
                    <Td>{fields[0]}</Td>
                  </tr>
                  <tr>
                    <Td bold>
                      <label htmlFor='surname'>Nazwisko</label>
                    </Td>
                    <Td>{fields[1]}</Td>
                  </tr>
                  <tr>
                    <Td bold>
                      <label htmlFor='email'>Adres e-mail</label>
                    </Td>
                    <Td>{fields[2]}</Td>
                  </tr>
                  {user.role !== 'CANDIDATE' && (
                    <tr>
                      <Td bold>Rola</Td>
                      <Td>{roleName}</Td>
                    </tr>
                  )}
                </tbody>
              </AccountDetails>

              <EditButton
                onClick={isDisabled ? handleEnableEdit : cancelChanges}
                isDisabled={isDisabled}
                type='button'
              >
                {isDisabled ? 'Edytuj' : 'Anuluj'}
              </EditButton>

              <SubmitButton
                isDisabled={isDisabled}
                onSubmit={handleSubmit(onSubmit)}
                type='submit'
              >
                Zapisz
              </SubmitButton>
            </form>
          </Section>
          {user.role === role.user && (
            <Section>
              <Title>Powiadomienia</Title>
              <NotificationWrapper>
                <NotificationSubtitle htmlFor='notifSwitch'>
                  Wyrażam zgodę na otrzymywanie powiadomień o nowych ofertach
                  pracy.
                </NotificationSubtitle>
                <Switch
                  color='primary'
                  checked={user.newsletter}
                  onChange={switchNotifications}
                  id='notifSwitch'
                />
              </NotificationWrapper>
            </Section>
          )}
        </SectionWrap>
        <SettingsMenu>{menuList}</SettingsMenu>
      </BookmarkWrapper>
    </>
  );
};

export default Settings;
