import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Controller, useFieldArray } from 'react-hook-form';

import * as S from './SingleFormData.css';
import { ErrorAlert } from 'm-web-components';
import { ErrorMessage } from '@hookform/error-message';
import Hint from 'components/shared/Hint';

const SingleList = ({ errors, control, index, id, defaultValue }) => {
  const [isOpen, setOpen] = useState(true);
  const { id: paramId } = useParams();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'content',
  });

  useEffect(() => {
    if (defaultValue) {
      defaultValue?.content?.forEach(el => {
        append(el);
      });
    }
  }, []);

  return (
    <S.SingleWrapper>
      <Hint hint='Naciśnij by zwinąć/rozwinąć'>
        <S.Title isopen={isOpen} onClick={() => setOpen(prev => !prev)}>
          {id === 'MULTIPLE_CHOICE' && 'Wielokrotny wybór'}
          {id === 'LIST' && 'Lista rozwijana'}
          <ArrowDropUpIcon />
        </S.Title>
      </Hint>
      <S.Content isopen={isOpen}>
        <Controller
          id={id}
          name={`elements[${index}].dataFormat`}
          control={control}
          defaultValue={id}
        />

        <label>Etykieta</label>
        <Controller
          id={id}
          name={`elements[${index}].content.label`}
          control={control}
          defaultValue={defaultValue?.label}
          rules={{ required: 'To pole jest wymagane' }}
          render={props => (
            <S.Input
              aria-label='empty input'
              placeholder='Stanowisko'
              defaultValue={defaultValue?.label}
              onChange={e => props.onChange(e.target.value)}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={`elements[${index}].content.label`}
          render={({ message }) => (
            <ErrorAlert error={message} maxWidth='200px' type='simple' />
          )}
        />

        <label>Tekst pomocniczy</label>
        <Controller
          id={id}
          name={`elements[${index}].content.placeholder`}
          control={control}
          rules={{ required: 'To pole jest wymagane' }}
          defaultValue={defaultValue?.placeholder}
          render={props => (
            <S.Input
              aria-label='empty textarea'
              placeholder='np. Instruktor jazdy'
              defaultValue={defaultValue?.placeholder}
              onChange={e => props.onChange(e.target.value)}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={`elements[${index}].content.placeholder`}
          render={({ message }) => (
            <ErrorAlert error={message} maxWidth='200px' type='simple' />
          )}
        />
        <label>Pozycje na liście</label>
        <ul>
          {fields.map((field, i) => (
            <li key={field.id}>
              <Controller
                id={id}
                name={`elements[${index}].content.content[${i}]`}
                control={control}
                defaultValue={defaultValue?.content?.[i]}
                render={props => (
                  <S.ItemListContainer>
                    <S.SmallInput
                      value={props.value}
                      onChange={e => props.onChange(e.target.value)}
                      placeholder='Dodaj pozycję'
                      defaultValue={defaultValue?.content?.[i]}
                    />
                    <S.ButtonList type='button' onClick={() => remove(i)}>
                      <HighlightOffTwoToneIcon style={{ color: '#333' }} />
                    </S.ButtonList>
                  </S.ItemListContainer>
                )}
              />
            </li>
          ))}
        </ul>

        <S.ButtonList type='button' onClick={() => append({})}>
          {paramId && fields.length === 0 ? 'Pokaż opcje' : '+ Dodaj opcję'}
        </S.ButtonList>

        <Controller
          name={`elements[${index}].content.isRequired`}
          control={control}
          id={id}
          defaultValue={defaultValue?.isRequired || false}
          render={props => {
            const value = props.value === 'true' || props.value === true;
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={e => props.onChange(e.target.checked)}
                    color='primary'
                  />
                }
                label='Pole obowiązkowe'
              />
            );
          }}
        />
      </S.Content>
    </S.SingleWrapper>
  );
};

export default SingleList;
