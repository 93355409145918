import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { device } from 'styles/devices';

const LongAnswer = styled.div`
  max-width: 1000px;
  /* color: ${({ theme }) => theme.tableFileds}; */
  font-family: 'Calibri';

  * {
    line-height: 1.25em;
  }

  .ql-size-small {
    font-size: 0.75em;
  }

  .ql-size-large {
    font-size: 1.5em;
  }

  .ql-size-huge {
    font-size: 2.5em;
  }

  ul {
    padding-left: 38px;
    font-family: 'Calibri';
    margin: 0;

    li {
      list-style-type: disc;
      padding-left: 2px;
      font-family: 'Calibri';
    }
  }

  ol {
    padding-left: 38px;

    li {
      padding-left: 2px;
      font-family: 'Calibri';
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: 'Calibri';
  }

  p,
  li {
    font-size: 13px;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.67em;
  }

  strong {
    font-family: 'CalibriBold';
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-indent-1 {
    padding-left: 3em;
  }

  .ql-indent-2 {
    padding-left: 6em;
  }

  .ql-indent-3 {
    padding-left: 9em;
  }

  .ql-indent-4 {
    padding-left: 12em;
  }

  .ql-indent-5 {
    padding-left: 15em;
  }

  .ql-indent-6 {
    padding-left: 18em;
  }

  .ql-indent-7 {
    padding-left: 21em;
  }

  .ql-indent-8 {
    padding-left: 24em;
  }

  @media ${device.desktop} {
    ul {
      font-family: 'Calibri';
    }
    flex-direction: row;
    align-items: center;
  }
`;

const Wrapper = styled.div`
  margin-left: 10px;
`;

const SingleLongAnswer = ({ content, noBall, label }) => {
  return (
    <Wrapper>
      {label && !noBall && (
        <span
          style={{
            margin: '5px 0px',
            fontWeight: '600',
          }}
        >
          {label}:
        </span>
      )}
      <LongAnswer>{parse(content?.content || content?.label || '')}</LongAnswer>
    </Wrapper>
  );
};

export default SingleLongAnswer;
