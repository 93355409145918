import React, { useState } from 'react';
import { Button } from 'm-web-components';
import styled from 'styled-components';

import Modal from 'components/shared/Modal';
import ModalTableTools from './ModalTableTools';

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colorLighter};
  border-top: 1px solid ${({ theme }) => theme.lighter};
  padding: ${({ countPosition }) => (countPosition > 0 ? '30px' : '0 30px ')};
  margin-bottom: -1px;
  z-index: 20px;
  color: ${({ theme }) => theme.primary};
  height: ${({ countPosition }) => (countPosition > 0 ? '50px' : '0px')};
  transition: 0.3s;

  display: flex;
  align-items: center;

  span {
    margin-right: 30px;
  }
`;

const TableTools = ({
  countPosition,
  buttons = [],
  setMenuFunctions,
  menuFunctions,
  isCandidates,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState({});

  const handleButtonClick = buttonData => {
    setModalOpen(true);
    setModalDetails(buttonData);
  };

  const handleStatusButtonClick = () => {
    setMenuFunctions(prev => ({ ...prev, changeStatus: true }));
  };

  const handleDeleteButtonClick = buttonData => {
    setModalOpen(true);
    setModalDetails(buttonData);
    setMenuFunctions(prev => ({ ...prev, delete: true }));
  };

  const handleConfirmButtonClick = async inputs => {
    modalDetails.onClick(inputs);
    setModalOpen(false);
  };

  const renderButtons = buttons.map(button => {
    return isCandidates && button.label === 'Status' ? (
      <Button
        key={button.label}
        type='button'
        colorType={button.colorType}
        padding='5px 10px'
        margin='0 10px'
        style={{ border: 'none' }}
        onClick={() => handleStatusButtonClick()}
      >
        Status
      </Button>
    ) : isCandidates && button.label === 'Usuń' ? (
      <Button
        key={button.label}
        type='button'
        colorType={button.colorType}
        padding='5px 10px'
        margin='0 10px'
        style={{ border: 'none' }}
        onClick={() => handleDeleteButtonClick(button)}
      >
        {button.label}
      </Button>
    ) : (
      <Button
        key={button.label}
        type='button'
        colorType={button.colorType}
        padding='5px 10px'
        margin='0 10px'
        style={{ border: 'none' }}
        onClick={() => handleButtonClick(button)}
      >
        {button.label}
      </Button>
    );
  });

  return (
    <Wrapper countPosition={countPosition}>
      {countPosition ? (
        <>
          <span>
            {countPosition}
            {+countPosition === 1 ? ' wybrana pozycja' : ' wybrane pozycje'}
          </span>
          {renderButtons}
        </>
      ) : null}
      {!menuFunctions?.changeStatus && (
        <Modal open={isModalOpen} setOpen={setModalOpen}>
          <ModalTableTools
            title={modalDetails.titleModal}
            text={modalDetails.textModal}
            buttonLabel={modalDetails.buttonLabelModal}
            isDateInput={modalDetails.isDateInput}
            confirmFunc={handleConfirmButtonClick}
            setOpen={setModalOpen}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default TableTools;
