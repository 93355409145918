import React from 'react';

import styled from 'styled-components';
import TableMenu from 'components/atoms/TableMenu';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router';

const Wrapper = styled.div`
  position: relative;
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
`;

const Subtitle = styled.span`
  color: ${({ theme }) => theme.secondary};
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;

  svg {
    margin-right: 10px;
  }
`;

const Header = styled.header`
  display: flex;
  padding: 10px 30px;
`;

const Section = styled.section`
  background-color: ${({ theme }) => theme.colorLighter};
  min-height: 200px;
  border-top: 1px solid ${({ theme }) => theme.lighter};
  padding: 0px;
`;

const ContainerWithBookmarks = ({
  horizontalMenu,
  title,
  subtitle,
  children,
}) => {
  const history = useHistory();
  return (
    <>
      <Header>
        <Title onClick={() => history.goBack()}>{title}</Title>
        <Subtitle>
          {subtitle && <ArrowForwardIosIcon />} {subtitle}
        </Subtitle>
      </Header>
      <Wrapper>
        <TableMenu
          buttons={horizontalMenu.horizontalMenuButtons}
          onClick={horizontalMenu.setCurrentType}
          currentType={horizontalMenu.curentType}
          staticPos
        />
        <Section>{children}</Section>
      </Wrapper>
    </>
  );
};

export default ContainerWithBookmarks;
