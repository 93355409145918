import React, { useState } from 'react';
import styled from 'styled-components';
import { centerFlex } from 'styles/mixins';
import { Select } from 'm-web-components';
import * as S from 'components/tables/Table.css';

import { Button } from 'm-web-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';

import DateFnsUtils from '@date-io/date-fns';
import { pl } from 'date-fns/locale';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { AccessibilityLabel } from '../../tables/Table.css';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#2C81C7',
    },
  },
});

const BlueRadio = withStyles({
  root: {
    color: '#2C81C7',
    '&$checked': {
      color: '#2C81C7',
    },
  },
  checked: {},
})(props => <Radio color='default' {...props} />);

const InviteMessage = styled.textarea`
  margin-top: 20px;
  height: 160px;
  resize: none;
  padding: 10px;
  width: 100%;
  border: 1px solid hsl(0, 0%, 80%);
`;

const CharCounter = styled.div`
  color: #666;
  width: 100%;
  text-align: right;
`;

const Buttons = styled.div`
  ${centerFlex}
  margin-top: 30px;
`;

const SendInviteForm = ({
  setMenuFunctions,
  invitationDetails,
  userApplications,
  setFormData,
  formData,
  date,
  setDate,
  onSubmit,
  cancelFunc,
}) => {
  const [countChar, setCountChar] = useState(formData.content.length);

  const handleChange = e => {
    setFormData(prev => ({ ...prev, type: e.target.value }));
  };

  const handleSelectChange = e => {
    setFormData(prev => ({
      ...prev,
      aId: e.value,
      advertisementName: e.label,
    }));
  };

  const handleInvText = e => {
    setFormData(prev => ({ ...prev, content: e.target.value }));
    setCountChar(e.target.value.length);
  };

  const options = userApplications?.map(el => ({
    value: el.id,
    label: el.name,
  }));

  return (
    <>
      <h4>Zaproszenie do kandydata</h4>
      <p>
        Kandydat:
        <span>
          {invitationDetails.name} {invitationDetails.surname}
        </span>
      </p>
      <p>Stanowisko</p>
      <AccessibilityLabel>
        {' '}
        <p>Stanowisko</p>
        <Select
          options={options}
          value={options?.filter(obj => obj.value === formData.aId)}
          onChange={handleSelectChange}
        />
      </AccessibilityLabel>
      <AccessibilityLabel>
        <p>Wiadomość</p>
        <InviteMessage
          value={formData.content}
          onChange={handleInvText}
          maxLength='1000'
        />
      </AccessibilityLabel>
      <CharCounter>Pozostało {1000 - countChar} znaków </CharCounter>
      <p>Etap rekrutacji</p>
      <RadioGroup
        aria-label='invitation type'
        name='invitationType'
        value={formData.type}
        onChange={handleChange}
      >
        <label>
          <BlueRadio value='INTERVIEW' name='invitationType' />
          Zaproszenie na rozmowę
        </label>
        <label>
          <BlueRadio value='TEST' name='invitationType' />
          Zaproszenie na test
        </label>
      </RadioGroup>
      <p>Data i godzina</p>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
          <AccessibilityLabel>
            <p>Data i godzina</p>
            <KeyboardDateTimePicker
              margin='normal'
              format='yyyy-MM-dd HH:mm'
              ampm={false}
              value={date}
              onChange={setDate}
              disablePast={true}
              inputVariant={'outlined'}
              cancelLabel={'Anuluj'}
              style={{ margin: '0', padding: '0' }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDateMessage={'Nie można wybrać daty która już minęła'}
              invalidDateMessage={'Sprawdź poprawność wpisanej daty'}
            />
          </AccessibilityLabel>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
      <Buttons>
        <S.FiltersButtonToggle type='button' onClick={cancelFunc}>
          Anuluj
        </S.FiltersButtonToggle>
        <Button
          type='button'
          colorType='primary'
          onClick={onSubmit}
          padding='5px 10px'
          margin='0 0 0 20px'
          fontWeight='600'
          style={{ border: 'none' }}
        >
          Wyślij zaproszenie
        </Button>
      </Buttons>
    </>
  );
};

export default SendInviteForm;
