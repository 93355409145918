import React, { useState } from 'react';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { Button } from 'm-web-components';

import * as S from 'components/admin/FormsElements/FormsElements.css';
import Singles from 'components/admin/FormsElements/Singles';

const SingleSubSection = ({
  data,
  index: dictIndex,
  sectionIndex,
  advId,
  addDictionary,
  isVisibleTitle,
  canDuplicate,
  changeValue,
  deleteDictionary,
}) => {
  const [isDictVisible, setDictVisible] = useState(true);
  const basicNameForm = `sections[${sectionIndex}].dictionaries[${dictIndex}]`;
  const elementsRender = data.dictionaryData?.map((el, index) => {
    const props = {
      data: el.content,
      key: `${el.dataFormat}-${index}`,
      index,
      basicNameForm,
      advId,
      sectionIndex,
      dictIndex,
      changeValue,
      value: data?.elements?.[index]?.content,
    };

    switch (el.dataFormat) {
      case 'SINGLE_CHOICE':
        return <Singles.SingleChoice {...props} />;

      case 'SHORT_ANSWER':
        return <Singles.SingleShortAnswer {...props} />;

      case 'LONG_ANSWER':
        return <Singles.SingleLongAnswer {...props} />;

      case 'LIST':
        return <Singles.SingleSelect {...props} />;

      case 'DATE':
        return <Singles.SingleDate {...props} />;

      case 'DATE_FROM_TO':
        return <Singles.SingleDateFromTo {...props} />;

      case 'FILE':
        return <Singles.SingleFile {...props} />;

      default:
        return <div>coś poszlo nie tak ...</div>;
    }
  });

  return (
    <>
      <S.SubsectionWrapper>
        {isVisibleTitle && (
          <S.DictTitle isdictvisible={isDictVisible} type='button'>
            <h3>
              <ArrowDropDownOutlinedIcon
                onClick={() => setDictVisible(prev => !prev)}
              />
              {data.dictionaryTitle}
            </h3>

            <div>
              {canDuplicate && (
                <Button
                  type='button'
                  colorType='light'
                  border='none'
                  margin='0'
                  padding='5px'
                  onClick={() => addDictionary(sectionIndex, dictIndex, data)}
                >
                  + Dodaj pole tego samego rodzaju
                </Button>
              )}
            </div>
          </S.DictTitle>
        )}
        {data.isNew && (
          <Button
            type='button'
            color='red'
            border='none'
            margin='0'
            padding='5px'
            onClick={() => deleteDictionary(sectionIndex, dictIndex)}
          >
            - Usuń pozycję
          </Button>
        )}
        {isDictVisible && <>{elementsRender}</>}
      </S.SubsectionWrapper>
    </>
  );
};

export default SingleSubSection;
