import React, { useState } from 'react';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import SingleSubSection from './SingleSubSection';

import * as S from 'components/admin/CreateAdverisement/CreateAdvertisement.css';

const SingleSection = ({
  dictionaries,
  index: sectionIndex,
  title,
  advId,
  addDictionary,
  changeValue,
  deleteDictionary,
}) => {
  const [isSubsectionsVisible, setSubsectionsVisible] = useState(true);
  let visibleTitles = [];
  let visibleDuplicateButtons = [];

  const subSectionsRender = dictionaries.map((item, index) => {
    const isVisibleTitle = !visibleTitles.includes(item.dictionaryTitle);
    visibleTitles = [...visibleTitles, item.dictionaryTitle];

    let canDuplicate = false;

    if (
      item.canDuplicate &&
      !visibleDuplicateButtons.includes(item.dictionaryTitle)
    ) {
      canDuplicate = true;
      visibleDuplicateButtons.push(item.dictionaryTitle);
    }

    return (
      <SingleSubSection
        id={`${item.id}-${index}`}
        index={index}
        sectionIndex={sectionIndex}
        data={item}
        advId={advId}
        addDictionary={addDictionary}
        isVisibleTitle={isVisibleTitle}
        canDuplicate={canDuplicate}
        changeValue={changeValue}
        deleteDictionary={deleteDictionary}
      />
    );
  });

  return (
    <S.WrapperSingleSection>
      <S.TitleSection>
        <S.InputWrapper issubionsvisible={isSubsectionsVisible}>
          <button
            onClick={() => setSubsectionsVisible(!isSubsectionsVisible)}
            type='button'
          >
            <ArrowDropDownOutlinedIcon />
          </button>
          <h2>{title}</h2>
        </S.InputWrapper>
      </S.TitleSection>
      {isSubsectionsVisible && subSectionsRender}
    </S.WrapperSingleSection>
  );
};

export default SingleSection;
