import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Pagination } from 'm-web-components';
import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import * as S from './Table.css';
import SearchTable from './SearchTable';
import useSortResults from './useSortResults';
import FiltersTable from './Filters/FiltersContainer';
import TableMenu from 'components/atoms/TableMenu';
import TableTools from './TableTools';
import AdvertisementsTableRow from 'components/admin/Advertisements/AdvertisementsTableRow';
import CandidatesTableRow from 'components/admin/Candidates/CandidatesTableRow';
import { handleOnChangeSelectedRows } from './helpers/checkboxesFunc';
import SortIcon from 'components/atoms/SortIcon';
import {
  handleDragEnter,
  handleDragOver,
  handleDragStart,
  handleOnDrop,
} from './helpers/dragFunctions';
import DictionaryTableRow from 'components/admin/Dictionary/DictionaryTableRow';
import UserJobOffersTableRow from 'components/user/JobOffers/UserJobOffersTableRow';
import UserApplicationsTableRow from 'components/user/Applications/UserApplicationsTableRow';
import FormsTableRow from 'components/admin/Forms/FormsTableRow';
import AdvInvitationsTableRow from 'components/admin/SingleAdvertisement/AdvInvitations/AdvInvitationsTableRow';
import GuestAdvertisementsTableRow from 'components/guest/Advertisements/GuestAdvertisementsTableRow';
import SystemLogsRow from 'components/admin/Administration/SystemLogs/SystemLogsRow';
import AdvCandidatesRow from 'components/admin/SingleAdvertisement/AdvCandidates/AdvCandidatesRow';
import SingleReportTableRow from 'components/admin/Reports/SingleReport/SingleReportTableRow';
import ReportsLibraryTableRow from 'components/admin/Reports/ReportsLIbrary/ReportsLibraryTableRow';
import OtherApplicationsTableRow from 'components/admin/SingleAdvertisement/AdvCandidates/CandidateDetails/OtherApplications/OtherApplicationsTableRow';
import CommunicationRow from 'components/admin/SingleAdvertisement/AdvCandidates/CandidateDetails/Communication/CommunicationRow';
import UsersTableRow from 'components/admin/Administration/Users/UsersTableRow';
import Indicator from 'components/atoms/Indicator';

const options = [
  { label: '5 wyników', value: 5 },
  { label: '10 wyników', value: 10 },
  { label: '15 wyników', value: 15 },
  { label: '20 wyników', value: 20 },
  { label: '50 wyników', value: 50 },
];

const TableComponent = ({
  columns,
  data: rows,
  type,
  reduxNameHelper = '',
  secondaryType = '',
  getValues,
  searchPlaceholder,
  simple,
  filtersTable,
  horizontalMenu,
  category,
  tableToolData,
  addButton,
  selectedRows,
  setSelectedRows,
  setInvitationDetails,
  setUserApplications,
  menuFunctions,
  setMenuFunctions,
  isCandidates,
  tableWrapperStyle,
  tableStyle,
  noFilters,
  handleDisplayCandidate,
  setActiveBookmark,
  handleOpenModal,
}) => {
  const { changeSort } = useSortResults(type, category, getValues);
  const pagination = useSelector(state => state.tableResults);
  const parametersName = `${reduxNameHelper}${type}Parameters`;

  const [cols, setCols] = useState(columns);
  const [dragOver, setDragOver] = useState('');

  useEffect(() => {
    setCols(columns);
  }, [columns]);

  useEffect(() => {
    if (
      pagination[parametersName]?.page >
      +(
        pagination[parametersName]?.totalPages ||
        pagination[`${reduxNameHelper}defaultParameters`]?.totalPages
      )
    ) {
      if (getValues) getValues(type, { page: 1 }, category);
    }
  }, [pagination]);

  const changeSize = value =>
    getValues(type, { size: value.value, page: 1 }, category);
  const changePage = (event, value) =>
    getValues(type, { page: value }, category);

  const header = cols.map(col => (
    <S.CustomTh
      isSortable={col.sorting}
      key={col.fieldName}
      style={{
        borderLeft: `${
          col.fieldName === dragOver ? '5px solid blue' : '3px solid white'
        }`,
      }}
      id={col.fieldName}
      draggable={col.draggable}
      onDragStart={e => handleDragStart(e, cols)}
      onDragOver={e => handleDragOver(e)}
      onDrop={e => handleOnDrop(e, cols, setCols, setDragOver)}
      onDragEnter={e => handleDragEnter(e, setDragOver)}
      dragOver={col.fieldName === dragOver}
      onClick={col.sorting ? () => changeSort(col.fieldName) : null}
    >
      {col.component ? (
        col.component
      ) : (
        <>
          {col.title} {col.sorting && !simple && <SortIcon />}
        </>
      )}
    </S.CustomTh>
  ));

  const rowsRender = rows?.map(row => {
    const props = {
      row,
      cols,
      dragOver,
      selectedRows,
      getValues,
      type,
      category,
      setMenuFunctions,
      handleOnChangeSelectedRows: id =>
        handleOnChangeSelectedRows(id, selectedRows, setSelectedRows),
    };

    const combinedType = `${type}${secondaryType}`;

    switch (combinedType) {
      case 'dictionary':
        return <DictionaryTableRow key={row.id} {...props} />;

      case 'application':
        return (
          <CandidatesTableRow
            key={row.id}
            setInvitationDetails={setInvitationDetails}
            setUserApplications={setUserApplications}
            {...props}
          />
        );

      case 'applications':
        return <UserApplicationsTableRow key={row.id} {...props} />;

      case 'form/table':
        return <FormsTableRow key={row.id} {...props} />;

      case 'noticecandidates':
        return (
          <AdvCandidatesRow
            key={row.id}
            {...props}
            setInvitationDetails={setInvitationDetails}
            setUserApplications={setUserApplications}
            handleDisplayCandidate={handleDisplayCandidate}
          />
        );

      case 'noticeguest':
        return <GuestAdvertisementsTableRow key={row.id} {...props} />;

      case 'noticeadmin':
        return <AdvertisementsTableRow key={row.id} {...props} />;

      case 'notice':
        return <UserJobOffersTableRow key={row.id} {...props} />;

      case 'invitation':
        return <AdvInvitationsTableRow key={row.id} {...props} />;

      case 'logs':
        return <SystemLogsRow key={row.id} {...props} />;

      case 'report':
        return <SingleReportTableRow key={row.position} {...props} />;

      case 'report-generated':
        return <ReportsLibraryTableRow key={row.id} {...props} />;
      case 'applicationother':
        return (
          <OtherApplicationsTableRow
            key={row.id}
            {...props}
            setActiveBookmark={setActiveBookmark}
          />
        );

      case 'communication':
        return <CommunicationRow key={row.id} {...props} />;

      case 'users':
        return (
          <UsersTableRow
            key={row.id}
            handleOpenModal={handleOpenModal}
            {...props}
          />
        );

      default:
        return <div>coś poszło nie tak...</div>;
    }
  });

  // !simple - for table at home
  return (
    <>
      {!rows ? (
        <Indicator />
      ) : (
        <S.Wrapper tableWrapperStyle={tableWrapperStyle}>
          <S.Header>
            {/* placeholder - in future here can be e.g select */}
            <div />
            {!simple && !noFilters && (
              <aside style={{ margin: '20px 0' }}>
                {addButton && (
                  <Link to={addButton.to}>
                    <Button
                      colorType='primary'
                      padding='7px 20px'
                      fontWeight='600'
                      style={{ border: 'none' }}
                    >
                      {addButton.label}
                    </Button>
                  </Link>
                )}
                {searchPlaceholder && (
                  <SearchTable
                    searchPlaceholder={searchPlaceholder}
                    type={type}
                    category={category}
                    getValues={getValues}
                  />
                )}
                {filtersTable && (
                  <FiltersTable
                    getValues={getValues}
                    type={type}
                    filtersTable={filtersTable}
                    category={category}
                  />
                )}
              </aside>
            )}

            {horizontalMenu && (
              <TableMenu
                buttons={horizontalMenu.horizontalMenuButtons}
                onClick={horizontalMenu.setCurrentType}
                currentType={horizontalMenu.curentType}
              />
            )}
          </S.Header>

          {tableToolData && (
            <TableTools
              buttons={tableToolData.buttons}
              countPosition={tableToolData.countPosition}
              setMenuFunctions={setMenuFunctions}
              menuFunctions={menuFunctions}
              selectedRows={selectedRows}
              getValues={getValues}
              type={type}
              category={category}
              setSelectedRows={setSelectedRows}
              isCandidates={isCandidates}
            />
          )}

          <Table style={tableStyle}>
            <Thead>
              <Tr>{header}</Tr>
            </Thead>
            <Tbody>
              {rows?.length > 0 ? (
                rowsRender
              ) : (
                <Tr>
                  <S.EmptyTd>Brak wyników wyszukiwania.</S.EmptyTd>
                </Tr>
              )}
            </Tbody>
          </Table>
          {!simple && (
            <S.AccessibilityLabel>
              <p>Paginacja</p>
              <Pagination
                changePage={changePage}
                changeSize={changeSize}
                countPages={
                  +(
                    pagination[parametersName]?.totalPages ||
                    pagination.defaultParameters?.totalPages
                  )
                }
                size={
                  +(
                    pagination[parametersName]?.size ||
                    pagination.defaultParameters?.size
                  )
                }
                optionsPagination={options}
                customStyles={S.customStyles}
                customSelectStyles={S.customSelectStyles}
                defaultPage={1}
                page={pagination[parametersName]?.page || 1}
              />
            </S.AccessibilityLabel>
          )}
        </S.Wrapper>
      )}
    </>
  );
};

export default TableComponent;
