import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';
import { toggleSelectAllRows } from 'components/tables/helpers/checkboxesFunc';

import { styledTable } from 'components/tables/helpers/changeStylesBrakpoints';
import { api } from 'API';
import { advertisementsFiltersTable } from './advertisementsFiltersTable';
import { toast } from 'react-toastify';
import RowMenuModal from 'components/shared/RowMenuModal/RowMenuModal';
import Modal from 'components/shared/RowMenuModal/Modal';
import { format } from 'date-fns';
import PublishAdvModal from 'components/shared/RowMenuModal/PublishAdvModal';
import HiddenMenu from 'components/shared/HiddenMenu';
import { AccessibilityLabel } from '../../tables/Table.css';
import { TOAST_POSITION } from 'constans/constantsToast';

const Wrapper = styled.div`
  //change breakpoint for mobile view
  @media screen and (max-width: 1469px) {
    ${styledTable}
  }
`;

const CATEGORY = 'recruiter/';
const TYPE = 'notice';

const AdvertisementsTable = () => {
  const dispatch = useDispatch();
  const { notice: dataTable } = useSelector(state => state.currentPageTables);

  const [selectedRows, setSelectedRows] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [menuFunctions, setMenuFunctions] = useState({
    archivize: false,
    close: false,
    publish: false,
    currentRow: null,
  });

  const getValues = (type = TYPE, newValue, category = CATEGORY) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(type, newValue, category)
    );
  };

  const getDepartmentOptions = async () => {
    const { data } = await api.getDepartment();
    setDepartmentOptions(data.map(el => ({ label: el.name, value: el.id })));
  };

  useEffect(() => {
    getValues(TYPE, { sortBy: 'dateCreated', sortDirection: 'DESC' }, CATEGORY);
    getDepartmentOptions();
  }, []);

  const archivizeFunc = async (selectedRows, inputs) => {
    let body;
    try {
      if (!isNaN(inputs.date)) {
        body = {
          date: format(inputs.date, 'yyyy-MM-dd'),
        };
      }
      await api.putArchivizeNotice(selectedRows, body);
      if (menuFunctions.archivize) {
        setMenuFunctions(prev => ({ ...prev, archivize: false }));
      }
      getValues(TYPE, null, CATEGORY);
      setSelectedRows([]);
      toast.success('Pomyślnie zarchiwizowano ogłoszenie.', TOAST_POSITION);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas archiwizowania ogłoszenia.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const closeAdvFunc = async selectedRows => {
    try {
      await api.putCloseAdvertisement(selectedRows);
      if (menuFunctions.close) {
        setMenuFunctions(prev => ({ ...prev, close: false }));
      }
      getValues(TYPE, null, CATEGORY);
      setSelectedRows([]);
      toast.success('Pomyślnie zamknięto ogłoszenie.', TOAST_POSITION);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas zamykania ogłoszenia.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const publishFunc = async (e, selectedRows, date) => {
    e.preventDefault();
    try {
      await api.putPublishAdvertisement(selectedRows, { date });
      if (menuFunctions.publish) {
        setMenuFunctions(prev => ({ ...prev, publish: false }));
      }
      getValues(TYPE, null, CATEGORY);
      setSelectedRows([]);
      toast.success('Pomyślnie opublikowano ogłoszenie.', TOAST_POSITION);
    } catch (e) {
      toast.error(
        <div>
          <p>Dokończ ogłoszenie aby móc je opublikować</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const cancelFunc = () => {
    setMenuFunctions(prev => ({
      ...prev,
      archivize: false,
      close: false,
      publish: false,
    }));
    setSelectedRows([]);
  };

  const toolsButtons = [
    {
      label: 'Zamknij',
      colorType: 'darkGray',
      titleModal: 'Czy na pewno chcesz zamknąć wybrane ogłoszenia?',
      textModal: 'Ogłoszenie zmieni status na "Przedarchiwalne".',
      buttonLabelModal: 'Zamknij',
      onClick: () => {
        closeAdvFunc(selectedRows);
      },
    },

    {
      label: 'Archiwizuj',
      colorType: 'gray',
      titleModal: 'Czy na pewno chcesz zarchiwizować wybrane ogłoszenia?',
      textModal: 'Ogłoszenie zmieni status na "Archiwalne".',
      buttonLabelModal: 'Archiwizuj',
      isDateInput: true,
      onClick: async inputs => {
        archivizeFunc(selectedRows, inputs);
      },
    },

    {
      label: 'Usuń',
      colorType: 'gray',
      titleModal: 'Czy na pewno chcesz usunąć wybrane ogłoszenia?',
      buttonLabelModal: 'Usuń',
      textModal:
        'UWAGA: Usunięte zostaną również aplikacje oraz formularze powiązane z ogłoszeniem.',
      onClick: async () => {
        try {
          await api.deleteAdvertisement(selectedRows.join(','));
          getValues(TYPE, null, CATEGORY);
          setSelectedRows([]);
          toast.success('Pomyślnie usunięto ogłoszenie.');
        } catch (e) {
          toast.error(
            <div>
              <p>Wystąpił błąd podczas usuwania ogłoszenia.</p>
              {e.response?.data?.errors?.map(err => (
                <p>{err.defaultMessage}</p>
              ))}
            </div>
          );
        }
      },
    },
  ];

  const columns = [
    {
      component: (
        <>
          <AccessibilityLabel>
            <p>Zaznacz wszystkie</p>
            <input
              id='advCheckbox'
              type='checkbox'
              onChange={() =>
                toggleSelectAllRows({
                  selectedRows,
                  dataTable,
                  setSelectedRows,
                })
              }
              checked={selectedRows.length === dataTable?.length}
            />
          </AccessibilityLabel>
        </>
      ),
      fieldName: 'checkAll',
      draggable: false,
    },
    {
      title: 'Nr naboru',
      fieldName: 'recruitNr',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Nr wniosku',
      fieldName: 'proposalNr',
      sorting: true,
      draggable: true,
    },
    { title: 'Stanowisko', fieldName: 'title', sorting: true, draggable: true },
    {
      title: 'Data utworzenia',
      fieldName: 'dateCreated',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Termin składania',
      fieldName: 'submitDeadline',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Data opublikowania',
      fieldName: 'publicationDate',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Aplikacje',
      fieldName: 'applications',
      sorting: true,
      draggable: true,
    },
    { title: 'Status', fieldName: 'status', draggable: true },
    { title: <HiddenMenu />, fieldName: 'menu', draggable: false },
  ];

  return (
    <Wrapper>
      {!dataTable ? (
        <Indicator />
      ) : (
        <>
          <Table
            type={TYPE}
            secondaryType='admin'
            category={CATEGORY}
            columns={columns}
            data={dataTable}
            getValues={getValues}
            searchPlaceholder='Szukaj'
            filtersTable={advertisementsFiltersTable(departmentOptions)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            tableToolData={{
              countPosition: selectedRows.length,
              buttons: toolsButtons,
            }}
            addButton={{
              label: '+ Stwórz ogłoszenie',
              to: '/admin/create-advertisement/',
            }}
            setMenuFunctions={setMenuFunctions}
          />
        </>
      )}

      {menuFunctions.archivize && (
        <Modal open={menuFunctions.archivize} cancelFunc={() => cancelFunc()}>
          <RowMenuModal
            title={'Czy na pewno chcesz zarchiwizować wybrane ogłoszenie?'}
            text={'Ogłoszenie zmieni status na "Archiwalne".'}
            buttonLabel={'OK'}
            confirmFunc={async inputs =>
              archivizeFunc(menuFunctions.currentRow, inputs)
            }
            cancelFunc={() => cancelFunc()}
            isDateInput
          />
        </Modal>
      )}
      {menuFunctions.close && (
        <Modal open={menuFunctions.close} cancelFunc={() => cancelFunc()}>
          <RowMenuModal
            title={'Czy na pewno chcesz zamknąć wybrane ogłoszenie?'}
            text={'Ogłoszenie zmieni status na "Przedarchiwalne".'}
            buttonLabel={'OK'}
            confirmFunc={() => closeAdvFunc(menuFunctions.currentRow)}
            cancelFunc={() => cancelFunc()}
          />
        </Modal>
      )}
      {menuFunctions.publish && (
        <Modal open={menuFunctions.publish} cancelFunc={() => cancelFunc()}>
          <PublishAdvModal
            title={'Czy na pewno chcesz opublikować wybrane ogłoszenie?'}
            text={'Ogłoszenie zmieni status na "Aktywne".'}
            buttonLabel={'OK'}
            confirmFunc={(e, date) =>
              publishFunc(e, menuFunctions.currentRow, date)
            }
            cancelFunc={() => cancelFunc()}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default AdvertisementsTable;
