import { ErrorMessage } from '@hookform/error-message';
import { ErrorAlert } from 'm-web-components';
import React from 'react';
import { Controller } from 'react-hook-form';

import * as S from './Singles.css';

const SingleShortAnswer = ({
  data,
  errors,
  index,
  defaultValues,
  control,
  basicNameForm,
  ignoreRequired,
  changeValue,
  sectionIndex,
  dictIndex,
  value,
  readOnly,
}) => {
  return (
    <S.SingleWrapper>
      <S.Label>
        {data.label} {data.isRequired && <span>*</span>}
      </S.Label>

      {control ? (
        <>
          <Controller
            name={`${basicNameForm}.elements[${index}].content.content`}
            control={control}
            rules={
              !ignoreRequired && data.isRequired
                ? { required: 'To pole jest wymagane' }
                : null
            }
            defaultValue={defaultValues?.content?.content}
            render={props => (
              <S.Input
                aria-label='empty textarea'
                placeholder={data.placeholder}
                value={props.value || defaultValues?.content?.content}
                onChange={e => props.onChange(e.target.value)}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${basicNameForm}.elements[${index}].content.content`}
            render={({ message }) => (
              <ErrorAlert error={message} maxWidth='200px' type='simple' />
            )}
          />
        </>
      ) : (
        <S.Input
          name={`${basicNameForm}.elements[${index}].content.content`}
          aria-label='empty input'
          placeholder={data.placeholder}
          onChange={e =>
            changeValue(
              { content: e.target.value },
              sectionIndex,
              dictIndex,
              index
            )
          }
          value={value?.content ? value.content : ''}
          readOnly={readOnly}
        />
      )}
    </S.SingleWrapper>
  );
};

export default SingleShortAnswer;
