import React from 'react';
import styled from 'styled-components';
import { device } from 'styles/devices';
import { Button } from 'm-web-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import theme from 'styles/theme';

const Header = styled.header`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media ${device.mobileL} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Subtitle = styled.span`
  color: ${({ theme }) => theme.secondary};
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;

  svg {
    margin-right: 10px;
  }
`;

const CreatePageHeader = ({
  title,
  history,
  subTitle,
  submitLabel,
  middleButon,
  prevButton,
}) => {
  return (
    <Header>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title onClick={() => history.goBack()}>
          <ArrowBackIcon />
          {title}
        </Title>

        <Subtitle>
          {subTitle && <ArrowForwardIosIcon />} {subTitle}
        </Subtitle>
      </div>
      <Buttons>
        <Button
          type='button'
          color={theme.secondary}
          margin='0 15px'
          padding='5px 10px'
          fontWeight='600'
          style={{ border: `${middleButon ? 'none' : '1px solid #2C81C7'}` }}
          onClick={() => history.goBack()}
        >
          Anuluj
        </Button>

        {middleButon && (
          <Button
            type='button'
            color={theme.secondary}
            margin='0 15px'
            padding='5px 10px'
            fontWeight='600'
            style={{ border: '1px solid #2C81C7' }}
            onClick={() => middleButon.onClick(null, 'draft')}
          >
            {middleButon.label}
          </Button>
        )}

        {prevButton && (
          <Button
            type='button'
            colorType='middleColor'
            margin='0 15px'
            padding='5px 10px'
            fontWeight='600'
            style={{ border: 'none' }}
            onClick={() => prevButton.onClick(null, 'prev')}
          >
            {prevButton.label}
          </Button>
        )}

        {/* submit from form */}
        <Button
          type='submit'
          colorType='primary'
          padding='5px 10px'
          fontWeight='600'
          style={{ border: 'none' }}
        >
          {submitLabel || 'Zapisz'}
        </Button>
      </Buttons>
    </Header>
  );
};

export default CreatePageHeader;
