import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styled from 'styled-components';
import theme from 'styles/theme';
import GlobalStyle from 'styles/GlobalStyle';

import { routes } from 'routes';
import useAuth from 'auth/useAuth';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

// contact form modal
import Modal from 'components/shared/Modal';
import ContactFormModal from 'components/shared/ContactForm/ContactFormModal';

// protected Route
import AdminRoute from 'auth/AdminRoute';
import UserRoute from 'auth/UserRoute';

// pages
import Login from 'pages/auth/Login';
import Signup from 'pages/auth/Signup';
import Home from 'pages/Home';
import AdminHome from 'pages/admin/AdminHome';
import UserHome from 'pages/user/UserHome';
import DictionaryPage from 'pages/admin/Dictionary/DictionaryPage';
import AdvertisementsPage from 'pages/admin/Advertisements/AdvertisementsPage';
import DictionaryCreateFieldPage from 'pages/admin/Dictionary/DictionaryCreateField';
import CandidatesPage from 'pages/admin/CandidatesPage';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ForgotPasswordSuccess from 'pages/auth/ForgotPasswordSuccess';
import SingleAdvertisementPage from 'pages/admin/Advertisements/SingleAdvertisementPage';
import CreateAdvertisementPage from 'pages/admin/Advertisements/CreateAdvertisementPage';
import FormsPage from 'pages/admin/FormsPage';
import UserJobOffersPage from 'pages/user/UserJobOffersPage';
import UserApplicationsPage from 'pages/user/UserApplicationsPage';
import CreateForm from 'components/admin/CreateForm/CreateForm';
import AdministrationPage from 'pages/admin/AdministrationPage';
import UserApplicationsFormPage from 'pages/user/UserApplicationFormPage';
import SettingsPage from 'pages/Settings/SettingsPage';
import SingleAdvertisement from 'pages/guest/SingleAdvertisement';
import RenewApplication from 'components/user/RenewApplication/RenewApplication';
import ContactForm from 'pages/guest/ContactForm';
import SingleApplicationPrint from 'pages/admin/Advertisements/SingleApplicationPrintPage';
import ReportsPage from 'pages/admin/Reports/ReportsPage';
import ReportPrint from 'pages/admin/Reports/ReportPrintPage';
import SingleAdvertisementPrint from 'pages/guest/SingleAdvertisementPrintPage';
import SingleApplicationUserPage from 'pages/user/SingleApplicationUserPage';
import SingleApplicationPrintUser from 'pages/user/SingleApplicationPrintUserPage';
import AnswerInvitation from 'components/user/AnswerInvitation/AnswerInvitation';
import InboxPage from 'pages/user/InboxPage';
import EditApplicationForm from 'pages/user/EditApplicationForm';
import Error404 from 'pages/errors/Error404';

function App() {
  const [zoom, setZoom] = useState(1);
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const { getCurrentUser } = useAuth();

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    document.title = 'Portal Rekrutacyjny Zabrze';
  }, []);

  const changeZoom = value => {
    if (value > 0 && zoom > 2) return;
    if (value < 0 && zoom < 0.8) return;

    setZoom(prev => prev + value);
  };

  const Wrapper = styled.div`
    min-height: 100vh;
  `;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle zoom={zoom} />
      <Wrapper className='App' zoom={zoom}>
        <Router>
          <Header changeZoom={changeZoom} />
          <Switch>
            <Route
              exact
              path={routes.login}
              render={props => <Login changeZoom={changeZoom} {...props} />}
            />
            <Route
              exact
              path={routes.signup}
              render={props => <Signup changeZoom={changeZoom} {...props} />}
            />
            <Route
              exact
              path={routes.forgotpassword}
              render={props => (
                <ForgotPassword changeZoom={changeZoom} {...props} />
              )}
            />
            <Route
              exact
              path={routes.forgotpasswordsuccess}
              render={props => (
                <ForgotPasswordSuccess changeZoom={changeZoom} {...props} />
              )}
            />
            <Route
              exact
              path={routes.home}
              render={props => (
                <Home setContactModalOpen={setContactModalOpen} {...props} />
              )}
            />
            <Route
              exact
              path={routes.jobOffer}
              component={SingleAdvertisement}
            />
            <Route
              exact
              path={routes.printAdv}
              component={SingleAdvertisementPrint}
            />
            <Route exact path={routes.contactForm} component={ContactForm} />

            {/* ADMIN routes */}
            <AdminRoute exact path={routes.admin.home} component={AdminHome} />
            <AdminRoute
              exact
              path={routes.admin.adv}
              component={AdvertisementsPage}
            />
            <AdminRoute
              exact
              path={routes.admin.singleAdv}
              component={SingleAdvertisementPage}
            />
            <AdminRoute
              exact
              path={routes.admin.singleAdvPrint}
              component={SingleAdvertisementPrint}
            />
            <AdminRoute
              exact
              path={routes.admin.singleAppPrint}
              component={SingleApplicationPrint}
            />
            <AdminRoute
              exact
              path={routes.admin.createAdv}
              component={CreateAdvertisementPage}
            />
            <AdminRoute
              exact
              path={routes.admin.dictionary}
              component={DictionaryPage}
            />
            <AdminRoute
              exact
              path={routes.admin.dictionaryCreateField}
              component={DictionaryCreateFieldPage}
            />
            <AdminRoute
              exact
              path={routes.admin.candidates}
              component={CandidatesPage}
            />
            <AdminRoute exact path={routes.admin.forms} component={FormsPage} />
            <AdminRoute
              exact
              path={routes.admin.createForm}
              component={CreateForm}
            />
            <AdminRoute
              exact
              path={routes.admin.administration}
              component={AdministrationPage}
            />
            <AdminRoute
              exact
              path={routes.admin.settings}
              component={SettingsPage}
            />
            <AdminRoute
              exact
              path={routes.admin.reports}
              component={ReportsPage}
            />
            <AdminRoute
              exact
              path={routes.admin.reportPrint}
              component={ReportPrint}
            />

            {/* USER routes */}
            <UserRoute exact path={routes.user.home} component={UserHome} />
            <UserRoute
              exact
              path={routes.user.adv}
              component={UserJobOffersPage}
            />
            <UserRoute
              exact
              path={routes.user.applications}
              component={UserApplicationsPage}
            />
            <UserRoute
              exact
              path={routes.user.singleAdv}
              component={SingleAdvertisement}
            />
            <UserRoute
              exact
              path={routes.user.applicationForm}
              component={UserApplicationsFormPage}
            />
            <UserRoute
              exact
              path={routes.user.refreshApplication}
              component={RenewApplication}
            />
            <UserRoute
              exact
              path={routes.user.singleApplications}
              component={SingleApplicationUserPage}
            />
            <UserRoute
              exact
              path={routes.user.printApplication}
              component={SingleApplicationPrintUser}
            />
            <UserRoute
              exact
              path={routes.user.editApplication}
              component={EditApplicationForm}
            />
            <UserRoute
              exact
              path={routes.user.answerInvitation}
              component={AnswerInvitation}
            />

            <UserRoute
              exact
              path={routes.user.settings}
              component={SettingsPage}
            />

            <UserRoute exact path={routes.user.inbox} component={InboxPage} />

            <Route component={Error404} />
          </Switch>
        </Router>
        <Modal open={isContactModalOpen} setOpen={setContactModalOpen}>
          <ContactFormModal
            isModalOpen={isContactModalOpen}
            setOpen={setContactModalOpen}
          />
        </Modal>
        <Footer setModalOpen={setContactModalOpen} />
      </Wrapper>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
