import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

import DeleteIcon from '@material-ui/icons/Delete';
import * as S from 'components/admin/FormsElements/FormsElements.css';
import Singles from 'components/admin/FormsElements/Singles';
import { api } from 'API';
import Indicator from 'components/atoms/Indicator';
import Hint from 'components/shared/Hint';

const SingleSubSection = ({
  dictionaryId,
  index: dictIndex,
  formProps,
  sectionIndex,
  deleteSubSection,
  advId,
}) => {
  const [formData, setFormData] = useState(null);
  const [isDictVisible, setDictVisible] = useState(true);
  const basicNameForm = `sections[${sectionIndex}].dictionaries[${dictIndex}]`;

  useEffect(() => {
    const getOneDict = async () => {
      const { data } = await api.getSingleDictionary(dictionaryId);
      setFormData(data);
    };
    getOneDict();
    return () => setFormData({});
  }, [dictionaryId]);

  const elementsRender = formData?.elements?.map((el, index) => {
    const props = {
      data: el.content,
      key: `${el.dataFormat}-${index}`,
      index,
      basicNameForm,
      advId,
      ...formProps,
      defaultValues: formProps?.defaultValues?.elements?.[index],
    };

    switch (el.dataFormat) {
      case 'SINGLE_CHOICE':
        return <Singles.SingleChoice {...props} />;

      case 'SHORT_ANSWER':
        return <Singles.SingleShortAnswer {...props} />;

      case 'LONG_ANSWER':
        return <Singles.SingleLongAnswer {...props} textEditor />;

      case 'LIST':
        return <Singles.SingleSelect {...props} />;

      case 'DATE':
        return <Singles.SingleDate {...props} />;

      case 'DATE_FROM_TO':
        return <Singles.SingleDateFromTo {...props} />;

      case 'FILE':
        return <Singles.SingleFile {...props} />;

      default:
        return <div>coś poszlo nie tak ...</div>;
    }
  });

  return (
    <>
      {!formData ? (
        <Indicator />
      ) : (
        <S.SubsectionWrapper>
          <S.DictTitle
            isdictvisible={isDictVisible}
            onClick={() => setDictVisible(prev => !prev)}
            type='button'
          >
            <h3>
              <ArrowDropDownOutlinedIcon />
              {formData.title}
            </h3>
          </S.DictTitle>
          <Controller
            id={dictionaryId}
            name={`${basicNameForm}.dictionaryId`}
            control={formProps.control}
            defaultValue={dictionaryId}
          />

          <S.SubsectionDetails isdictvisible={isDictVisible}>
            {elementsRender}
            {formProps && (
              <S.DeleteButtonSubSection
                type='button'
                onClick={() => {
                  deleteSubSection(sectionIndex, dictIndex);
                }}
              >
                <DeleteIcon />
                Usuń
              </S.DeleteButtonSubSection>
            )}
          </S.SubsectionDetails>
        </S.SubsectionWrapper>
      )}
    </>
  );
};

export default SingleSubSection;
