import SingleChoice from './SingleChoice';
import SingleLongAnswer from './SingleLongAnswer';
import SingleShortAnswer from './SingleShortAnswer';
import SingleDateFromTo from './SingleDateFromTo';
import SingleFile from './SingleFile';

const Singles = {
  SingleChoice,
  SingleLongAnswer,
  SingleShortAnswer,
  SingleDateFromTo,
  SingleFile,
};

export default Singles;
