import React from 'react';
import styled from 'styled-components';
import WarningIcon from '@material-ui/icons/Warning';
import { centerFlex } from 'styles/mixins';

const Wrapper = styled.div`
  min-height: 70vh;
  width: 100vw;
  ${centerFlex}
`;

const MessageWrapper = styled.div`
  padding: 20px;

  svg {
    color: orange;
    width: 3rem;
    height: 3rem;
    margin-right: 20px;
  }

  h2 {
    display: flex;
    align-items: center;
  }

  a {
    color: ${({ theme }) => theme.secondary};
    font-weight: 700;
  }
`;

const Error404 = () => {
  return (
    <Wrapper>
      <MessageWrapper>
        <h2>
          <WarningIcon aria-hidden='false'>
            <title>Błąd</title>
          </WarningIcon>
          Przepraszamy, strona nie istnieje.
        </h2>
        <p>
          Szukana strona nie została odnaleziona, kliknij <a href='/'>tutaj</a>{' '}
          aby wrócić do strony głównej
        </p>
      </MessageWrapper>
    </Wrapper>
  );
};

export default Error404;
