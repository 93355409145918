import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ErrorAlert } from 'm-web-components';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';

import { api } from 'API';
import {
  dictionaryFiledsTypes,
  dictionaryTypes,
} from 'constans/constansDictionary';

import Tags from 'components/atoms/Tags';
import * as S from './Dictionary.css';
import { basicFields } from './dictionaryInputs';
import SingleFormDataDetails from './SingleFormDataDetails';
import FormSubsection from '../FormsElements/FormSubsection';
import Indicator from 'components/atoms/Indicator';
import Input from 'components/atoms/Input';
import CreatePageHeader from 'components/shared/CreatePageHeader';
import { TOAST_POSITION } from 'constans/constantsToast';

const CreateFieldDictionary = () => {
  const history = useHistory();
  const { id } = useParams();
  const [errorsMessages, setErrorsMessages] = useState({});
  const [defaultValues, setDeafultValues] = useState(null);

  const { control, handleSubmit, errors, watch } = useForm({
    defaultValues: defaultValues || {},
  });
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: 'elements',
  });

  const getSingleDict = async () => {
    try {
      const { data } = await api.getSingleDictionary(id);

      const values = {
        ...data,
        dictionaryType: dictionaryTypes.filter(
          el => el.value === data.dictionaryType
        )[0],
        elements: data?.elements?.map(el => ({
          ...el,
          id: el.dataFormat,
          text: dictionaryFiledsTypes.filter(x => x.id === el.dataFormat)[0]
            .text,
        })),
      };

      //id and text for tags
      data?.elements?.forEach(el => {
        const newEl = {
          ...el,
          id: el.dataFormat,
          text: dictionaryFiledsTypes.filter(x => x.id === el.dataFormat)[0]
            .text,
        };

        append(newEl);
      });

      setDeafultValues(values);
    } catch (e) {
      toast.error(
        <div>
          Coś poszło nie tak.
          {e.response?.data?.errors?.map(err => (
            <p>{err?.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  useEffect(() => {
    if (id) getSingleDict();
    else setDeafultValues({});
    return () => {
      setDeafultValues({});
    };
  }, [id]);

  // tag functions
  const addFieldToList = tag => {
    if (dictionaryFiledsTypes.includes(tag)) append(tag);
    else toast.info('możesz wybrać tylko opcje z listy', TOAST_POSITION);
  };

  const deleteFieldFromList = (i, e) => {
    if (e.keyCode) return;
    remove(i);
  };

  const changeOrderFields = (tag, currPos, newPos) => {
    swap(currPos, newPos);
  };

  const onSubmit = async values => {
    setErrorsMessages({});

    const body = {
      ...values,
      dictionaryType: values?.dictionaryType?.value,
    };

    try {
      const req = id
        ? api.putSingleDictionary(id, body)
        : api.postSingleDictionary(body);

      await req;

      toast.success(
        `Udało się! Słownik został ${id ? 'zaktualizowany.' : 'stworzony.'}`,
        TOAST_POSITION
      );

      history.push('/admin/dictionary');
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors?.forEach(el => {
          toast.error(el.defaultMessage, TOAST_POSITION);
        });
      } else {
        toast.error(
          'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
          TOAST_POSITION
        );
      }

      let errorsM = {};
      e.response?.data?.errors?.forEach(el => {
        errorsM = { ...errorsM, [el.field]: el.defaultMessage };
      });
      setErrorsMessages(errorsM);
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  };

  const creatingInputs = inputs => {
    return inputs.map(el => (
      <>
        <Controller
          key={el.name}
          errors={errors}
          defaultValue={defaultValues[el.name]}
          control={control}
          rules={el.rules}
          name={el.name}
          render={({ onChange, value }) => (
            <Input {...el} onChange={onChange} value={value} />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={el.name}
          render={({ message }) => (
            <ErrorAlert
              error={message || errorsMessages[el.name]}
              maxWidth='200px'
              type='simple'
            />
          )}
        />
      </>
    ));
  };

  return (
    <>
      {!defaultValues ? (
        <Indicator />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <CreatePageHeader
            history={history}
            title='Stwórz nowe pole do słownika'
          />
          <S.Main>
            {/* its a div */}
            <S.Form>
              {creatingInputs(basicFields)}

              <Tags
                tags={fields}
                suggestions={dictionaryFiledsTypes}
                label='Format danych'
                placeholder='Wybierz format'
                handleAddition={addFieldToList}
                handleDelete={deleteFieldFromList}
                handleDrag={changeOrderFields}
              />
              {errorsMessages?.elements && (
                <ErrorAlert
                  type='simple'
                  error={errorsMessages.elements}
                  maxWidth='380px'
                />
              )}
              <ul>
                {fields.map((item, index) => (
                  <li key={`${item.id}-${index}`}>
                    <SingleFormDataDetails
                      id={item.dataFormat || item.id}
                      defaultValue={item.content}
                      errors={errors}
                      control={control}
                      index={index}
                    />
                  </li>
                ))}
              </ul>
            </S.Form>
            <S.Preview>
              <S.PreviewHeader>Podgląd</S.PreviewHeader>
              <FormSubsection
                ignoreRequired
                formData={
                  Object.keys(watch()).length === 0 &&
                  Object.keys(defaultValues).length > 0
                    ? defaultValues
                    : watch()
                }
              />
            </S.Preview>
          </S.Main>
        </form>
      )}
    </>
  );
};

export default CreateFieldDictionary;
