import { api } from 'API';
import { Button } from 'm-web-components';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { statusName } from 'constans/constansAdv';
import theme from 'styles/theme';
import * as S from '../../tables/Table.css';
import {
  Wrapper,
  SelectStatusWrap,
  Buttons,
  RecruitDateWrap,
} from './ModalChangeStatus.css';

import DateFnsUtils from '@date-io/date-fns';
import { pl } from 'date-fns/locale';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { format } from 'date-fns';
import { TOAST_POSITION } from 'constans/constantsToast';

const blueTheme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#2C81C7',
    },
  },
});

const ModalChangeStatus = ({ aId, setModalOpen }) => {
  const statusNameArr = Object.values(statusName);
  const [selectedStatus, setSelectedStatus] = useState();
  const [date, setDate] = useState(new Date());
  const [recruitDate, setRecruitDate] = useState(null);

  useEffect(() => {
    setRecruitDate(date);
  }, [date]);

  const onSubmit = async () => {
    if (selectedStatus === 'ARCHIVAL') {
      let body;
      try {
        if (!isNaN(date)) {
          body = {
            date: format(recruitDate, 'yyyy-MM-dd'),
          };
        }
        await api.putArchivizeAdvertisement(aId, body);
        toast.success('Status zmieniono pomyślnie.', TOAST_POSITION);
        setModalOpen(prev => !prev);
      } catch (e) {
        console.error(e);
        toast.error(
          'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z administratorem.',
          TOAST_POSITION
        );
      }
    } else if (selectedStatus === 'PRE_ARCHIVE') {
      try {
        await api.putCloseAdvertisement(aId);
        toast.success('Status zmieniono pomyślnie.', TOAST_POSITION);
        setModalOpen(prev => !prev);
      } catch (e) {
        console.error(e);
        toast.error(
          <div>
            {e.response?.data?.errors?.map(err => (
              <p>{err.defaultMessage}</p>
            ))}
          </div>,
          TOAST_POSITION
        );
      }
    } else if (selectedStatus === 'ACTIVE') {
      try {
        let body;
        if (!isNaN(date)) {
          body = {
            date: format(recruitDate, 'yyyy-MM-dd'),
          };
        }
        await api.putPublishAdvertisement(aId, body);
        toast.success('Status zmieniono pomyślnie.', TOAST_POSITION);
        setModalOpen(prev => !prev);
      } catch (e) {
        console.error(e);
        toast.error(
          <div>
            {e.response?.data?.errors?.map(err => (
              <p>{err.defaultMessage}</p>
            ))}
          </div>,
          TOAST_POSITION
        );
      }
    }
  };

  const handleChange = e => {
    setSelectedStatus(e.target.value);
  };

  const statusList = statusNameArr.map((el, i) => {
    if (el.value !== 'DRAFT') {
      return (
        <>
          <input
            type='radio'
            name='changeStatus'
            id={'status' + i}
            key={i}
            value={el.value}
            onChange={handleChange}
          />
          <label
            htmlFor={'status' + i}
            style={{
              color: theme[el.value],
              borderColor: theme[el.value],
            }}
          >
            {el.label}
          </label>
        </>
      );
    } else return '';
  });

  return (
    <Wrapper>
      <h4>Zmień status</h4>
      <SelectStatusWrap>{statusList}</SelectStatusWrap>
      {selectedStatus === 'ARCHIVAL' && (
        <RecruitDateWrap>
          <label>Data zatrudnienia kandydata:</label>
          <ThemeProvider theme={blueTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
              <S.AccessibilityLabel>
                <p> Data zatrudnienia kandydata</p>
                <KeyboardDatePicker
                  margin='normal'
                  format='yyyy-MM-dd'
                  value={date}
                  onChange={setDate}
                  inputVariant={'outlined'}
                  cancelLabel={'Anuluj'}
                  style={{ margin: '0', padding: '0' }}
                  KeyboardButtonProps={{
                    'aria-label': 'Recruitment date',
                  }}
                  invalidDateMessage={'Sprawdź poprawność wpisanej daty'}
                />
              </S.AccessibilityLabel>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </RecruitDateWrap>
      )}
      {selectedStatus === 'ACTIVE' && (
        <RecruitDateWrap>
          <label>Podaj termin składania aplikacji:</label>
          <ThemeProvider theme={blueTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
              <S.AccessibilityLabel>
                <p> Podaj termin składania aplikacji</p>
                <KeyboardDatePicker
                  margin='normal'
                  format='yyyy-MM-dd'
                  value={date}
                  onChange={setDate}
                  inputVariant={'outlined'}
                  cancelLabel={'Anuluj'}
                  style={{ margin: '0', padding: '0' }}
                  KeyboardButtonProps={{
                    'aria-label': 'Recruitment date',
                  }}
                  invalidDateMessage={'Sprawdź poprawność wpisanej daty'}
                />
              </S.AccessibilityLabel>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </RecruitDateWrap>
      )}
      <Buttons>
        <S.FiltersButtonToggle
          type='button'
          onClick={() => setModalOpen(prev => !prev)}
        >
          Anuluj
        </S.FiltersButtonToggle>
        <Button
          type='button'
          colorType='primary'
          onClick={onSubmit}
          padding='5px 10px'
          margin='0 0 0 20px'
          fontWeight='600'
          style={{ border: 'none' }}
        >
          Zapisz
        </Button>
      </Buttons>
    </Wrapper>
  );
};

export default ModalChangeStatus;
