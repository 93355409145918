import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import InviteSentConfirmation from './InviteSentConfirmation';
import SendInviteForm from './SendInviteForm';

import format from 'date-fns/format';
import { api } from 'API';
import { toast } from 'react-toastify';
import { TOAST_POSITION } from 'constans/constantsToast';

export const Wrapper = styled.form`
  width: 450px;
  background-color: #fff;
  padding: 20px;
  color: ${({ theme }) => theme.primary};
  h4 {
    font-size: 16px;
    color: ${({ theme }) => theme.secondary};
  }
  > p {
    margin: 10px 0;
    text-transform: uppercase;
    display: flex;
    color: ${({ theme }) => theme.primary};
    flex-direction: column;
    font-size: 11px;
    font-weight: 600;

    span {
      margin-top: 10px;
      align-self: flex-start;
      background: ${({ theme }) => theme.secondary};
      padding: 5px;
      color: #fff;
      font-size: 15px;
      font-weight: 600;
    }
  }
`;

const DEFAULT_MESSAGE = {
  INTERVIEW: 'INTERVIEW_INVITATION',
  TEST: 'TEST_INVITATION',
};

const ModalSendInvite = ({
  setMenuFunctions,
  invitationDetails,
  userApplications,
  getValues,
  type,
  category,
  cancelFunc,
}) => {
  const placeholder = 'Brak wczytanej domyślnej wiadomości.';
  const [date, setDate] = useState(new Date());

  const [formData, setFormData] = useState({
    type: 'INTERVIEW',
    content: placeholder,
    dateTime: date,
    uId: invitationDetails.userId,
    aId: invitationDetails.aId,
    appId: invitationDetails.appId,
    advertisementName: invitationDetails.advertisementName,
  });

  useEffect(() => {
    const getMessage = async () => {
      try {
        const { data } = await api.getSingleDefaultMessage(
          DEFAULT_MESSAGE[formData.type]
        );
        setFormData(prev => ({ ...prev, content: data.content }));
      } catch {
        toast.error(
          'Nie udało się pobrać domyślnej wiadomości.',
          TOAST_POSITION
        );
      }
    };
    getMessage();
  }, [formData.type]);

  useEffect(() => {
    setFormData({ ...formData, dateTime: date });
  }, [date]);

  const [isSent, setSent] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();

    if (formData.dateTime !== null && formData.dateTime > new Date()) {
      const body = {
        content: formData.content,
        type: formData.type,
        dateTime: format(formData.dateTime, 'yyyy-MM-dd HH:mm'),
      };

      try {
        await api.putCandidateInvite(formData.aId, formData.uId, body);
        setSent(true);
        getValues(type, null, category);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        toast.error(
          'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z administratorem.',
          TOAST_POSITION
        );
        return false;
      }
    } else {
      toast.error('Sprawdź poprawność uzupełnionych pól.', TOAST_POSITION);
    }
  };

  return (
    <Wrapper>
      {!isSent ? (
        <SendInviteForm
          invitationDetails={invitationDetails}
          userApplications={userApplications}
          setFormData={setFormData}
          formData={formData}
          date={date}
          setDate={setDate}
          setMenuFunctions={setMenuFunctions}
          setSent={setSent}
          onSubmit={onSubmit}
          cancelFunc={cancelFunc}
        />
      ) : (
        <>
          <InviteSentConfirmation
            invitationDetails={invitationDetails}
            formData={formData}
            date={date}
          />
        </>
      )}
    </Wrapper>
  );
};

export default ModalSendInvite;
