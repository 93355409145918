import styled from 'styled-components';
import { device } from 'styles/devices';

export const CandidateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.desktop} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const CandidateMainSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  margin: 0px auto auto auto;

  @media ${device.desktop} {
    width: 70%;
  }
`;

export const CandidateSecondarySection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  margin: 0px auto auto auto;

  @media ${device.desktop} {
    width: 20%;
  }
`;

export const GoBackButton = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 30px;
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.secondary};
  cursor: pointer;

  svg {
    width: 15px;
    margin: auto 10px 0px 0px;
  }
`;

export const SectionHeader = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.secondary};
  color: white;
`;

export const ContactInfo = styled.div`
  display: flex;

  div {
    font-size: 15px;
    margin-right: 40px;
    display: flex;

    svg {
      margin: auto 10px 0px 0px;
    }
  }
`;

export const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h4 {
    font-size: 18px;
    font-weight: 700;
    margin: 40px auto 20px 0px;
  }

  p {
    font-size: 16px;
    margin-bottom: 40px;
  }
`;

export const SecondarySectionButton = styled.button`
  width: 100%;
  display: flex;
  padding: 20px;
  margin: 0px;
  border: none;
  background-color: ${({ theme, blue }) =>
    blue ? theme.secondary : 'transparent'};
  color: ${({ blue }) => blue && 'white'};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  svg {
    width: 16px;
    margin: auto 10px auto 0px;
  }
`;

export const SecondarySectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    border-top: 1px solid lightgray;
  }

  @media ${device.desktop} {
    min-height: 20vh;
  }
`;

export const StatusWrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: start;
  align-items: center;
  padding: 20px;
`;

export const StatusTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10%;
  margin-bottom: 10%;

  h2 {
    margin: auto auto auto 0px;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const NotesWrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: start;
  align-items: center;
  padding: 20px;

  h2 {
    margin: auto;
    font-size: 16px;
    font-weight: 600;
  }

  textarea {
    min-height: 90%;
    min-width: 100%;
    background-color: white;
    padding: 10px;
  }
`;

export const AppNumber = styled.div`
  margin: 20px 0;
  padding-left: ${({ nomargin }) => (nomargin ? '20px' : '40px')};
  flex-grow: 1;

  h2 {
    display: block;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
  }
`;
