import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import styled from 'styled-components';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import theme from 'styles/theme';
import { statusName } from 'constans/constansCandidates';
import { confirmStatus, menuItems } from 'constans/constansCandidates';

import MenuCell from './CandidatesMenu';

const RowStyled = styled(Tr)`
  background-color: ${({ selected }) => (selected ? '#E6F0F8' : 'transparent')};
  transition: 0.4s;

  :hover {
    border-bottom: 1px solid #ebebeb;
  }
`;

export const CandidateWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.secondary};
  font-weight: 600;

  div {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.placeholderColor};
    margin: 5px 0;
  }

  svg {
    align-self: flex-start;
    font-size: 1.3rem !important;
    margin-right: 7px;
  }
`;

export const SubStatus = styled.span`
  align-self: flex-start;
  border: 1px solid #459937;
  border-radius: 2px;
  padding: 1px 10px;
  margin: 2px 0;
`;

export const SubStatusRed = styled(SubStatus)`
  border: 1px solid #d5001f;
  color: #d5001f;
`;

const CandidatesTableRow = ({
  row,
  cols,
  selectedRows,
  handleOnChangeSelectedRows,
  dragOver,
  setModalOpen,
  setThanksModalOpen,
  setInvitationDetails,
  setUserApplications,
  type,
  category,
  getValues,
  setMenuFunctions,
}) => {
  const remapped = {
    id: row.id,
    advertisementName: row.advertisementName?.name,
    userId: row.user?.userId,
    user: {
      name: row.user?.name,
      surname: row.user?.surname,
      phone: row.user?.phone,
      email: row.user?.email,
    },
    date: row.date,
    status: {
      applicationStatus: row.status?.applicationStatus,
      invitationStatus: row.status?.invitationStatus,
    },
  };

  const cells = Object.entries(remapped).map(([, v], idx) => (
    <Td key={v} dragOver={cols[idx] === dragOver}>
      {idx === 0 && (
        <>
          <input
            id={`checkbox_${v}`}
            type='checkbox'
            checked={selectedRows?.includes(remapped.id)}
            onChange={() => handleOnChangeSelectedRows(remapped.id)}
          />
          <label htmlFor={`checkbox_${v}`} style={{ display: 'none' }}>
            Zaznacz
          </label>
        </>
      )}

      {cols[idx].fieldName === 'status' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            color: theme[remapped.status.applicationStatus],
            fontWeight: 600,
          }}
        >
          {statusName[remapped.status.applicationStatus].label}
          {remapped.status.invitationStatus === 'CONFIRM' &&
            confirmStatus.includes(remapped.status.applicationStatus) && (
              <SubStatus>Potwierdzenie</SubStatus>
            )}
          {remapped.status.invitationStatus === 'REJECT' &&
            confirmStatus.includes(remapped.status.applicationStatus) && (
              <SubStatusRed>Rezygnacja</SubStatusRed>
            )}
        </div>
      )}

      {cols[idx].fieldName === 'userName' && (
        <CandidateWrap>
          {remapped.user.name} {remapped.user.surname}
          <div>
            <BusinessCenterIcon aria-hidden='false'>
              <title>Stanowisko</title>
            </BusinessCenterIcon>
            {remapped.advertisementName}
          </div>
        </CandidateWrap>
      )}
      {cols[idx].fieldName === 'phone' && remapped.user.phone}
      {cols[idx].fieldName === 'email' && remapped.user.email}
      {cols[idx].fieldName !== 'status' && remapped[cols[idx].fieldName]}
    </Td>
  ));

  return (
    <RowStyled
      style={{
        backgroundColor: `${
          selectedRows?.includes(remapped.id) ? '#E6F0F8' : 'transparent'
        }`,
      }}
    >
      {cells}
      <Td>
        <MenuCell
          row={row}
          menuItems={menuItems.filter(el => {
            const isVisible =
              el.notVisible?.includes(row.status.invitationStatus) ||
              el.notVisible?.includes(row.status.applicationStatus);
            return !isVisible;
          })}
          setInvitationDetails={setInvitationDetails}
          setUserApplications={setUserApplications}
          type={type}
          getValues={getValues}
          category={category}
          setMenuFunctions={setMenuFunctions}
        />
      </Td>
    </RowStyled>
  );
};

export default CandidatesTableRow;
