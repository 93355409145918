import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';

import { menuItems } from 'constans/constansForms';

import MenuCell from './FormsMenu';
import styled from 'styled-components';

const RowStyled = styled(Tr)`
  background-color: ${({ selected }) => (selected ? '#E6F0F8' : 'transparent')};
  transition: 0.4s;

  :hover {
    border-bottom: 1px solid #ebebeb;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.tableFileds};
  display: block;
  height: 100%;
  transition: 0.4s;

  :hover {
    color: ${({ theme }) => theme.tableHeader};
  }
`;

const CandidatesTableRow = ({
  row,
  cols,
  selectedRows,
  handleOnChangeSelectedRows,
  dragOver,
  setMenuFunctions,
}) => {
  const cells = Object.entries(row).map(([, v], idx) => (
    <Td key={v} dragOver={cols[idx] === dragOver}>
      {idx === 0 && (
        <>
          <input
            id={`checkbox_${v}`}
            type='checkbox'
            checked={selectedRows?.includes(row.id)}
            onChange={() => handleOnChangeSelectedRows(row.id)}
          />

          <label htmlFor={`checkbox_${v}`} style={{ display: 'none' }}>
            Zaznacz
          </label>
        </>
      )}

      {cols[idx].fieldName && row[cols[idx].fieldName] && (
        <StyledLink to={`/admin/create-form/${row.id}`}>
          {cols[idx].fieldName && row[cols[idx].fieldName]}
        </StyledLink>
      )}
    </Td>
  ));

  return (
    <RowStyled selected={selectedRows?.includes(row.id)}>
      {cells}
      <Td>
        <MenuCell
          row={row}
          menuItems={menuItems}
          setMenuFunctions={setMenuFunctions}
        />
      </Td>
    </RowStyled>
  );
};

export default CandidatesTableRow;
