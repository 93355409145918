import React from 'react';
import Input from 'components/atoms/Input';

import * as S from './Singles.css';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorAlert } from 'm-web-components';

const SingleSelect = ({
  data,
  errors,
  index,
  defaultValues,
  control,
  basicNameForm,
  ignoreRequired,
  changeValue,
  sectionIndex,
  dictIndex,
  value,
}) => {
  const options = data?.content?.map(el => ({ label: el, value: el }));

  return (
    <S.SingleWrapper>
      <S.Label>
        {data.label} {data.isRequired && <span>*</span>}
      </S.Label>

      {control ? (
        <>
          <Controller
            defaultValue={defaultValues?.content}
            control={control}
            rules={
              !ignoreRequired && data.isRequired
                ? { required: 'To pole jest wymagane' }
                : null
            }
            name={`${basicNameForm}.elements[${index}].content`}
            render={({ onChange, value }) => (
              <Input
                onChange={onChange}
                value={value}
                options={options}
                placeholder={data.placeholder}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${basicNameForm}.elements[${index}].content`}
            render={({ message }) => (
              <ErrorAlert error={message} maxWidth='200px' type='simple' />
            )}
          />
        </>
      ) : (
        <Input
          options={options}
          placeholder={data.placeholder}
          onChange={value => {
            if (changeValue)
              changeValue({ ...value }, sectionIndex, dictIndex, index);
          }}
          value={value ? value : null}
        />
      )}
    </S.SingleWrapper>
  );
};

export default SingleSelect;
