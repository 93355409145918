import React, { useState } from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Controller } from 'react-hook-form';

import * as S from './SingleFormData.css';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorAlert } from 'm-web-components';
import Hint from 'components/shared/Hint';

const SingleChoice = ({ errors, control, index, id, defaultValue }) => {
  const [isOpen, setOpen] = useState(true);
  return (
    <S.SingleWrapper>
      <Hint hint='Naciśnij by zwinąć/rozwinąć'>
        <S.Title isopen={isOpen} onClick={() => setOpen(prev => !prev)}>
          Pole wyboru
          <ArrowDropUpIcon />
        </S.Title>
      </Hint>
      <S.Content isopen={isOpen}>
        <Controller
          id={id}
          name={`elements[${index}].dataFormat`}
          control={control}
          defaultValue={id}
        />

        <label>Treść pola *</label>
        <Controller
          id={id}
          name={`elements[${index}].content.content`}
          control={control}
          rules={{ required: 'To pole jest wymagane' }}
          defaultValue={defaultValue?.content}
          render={props => (
            <TextareaAutosize
              aria-label='empty textarea'
              placeholder='Wpisz treść pola...'
              rowsMin={3}
              defaultValue={defaultValue?.content}
              onChange={e => props.onChange(e.target.value)}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={`elements[${index}].content.content`}
          render={({ message }) => (
            <ErrorAlert error={message} maxWidth='200px' type='simple' />
          )}
        />
        <Controller
          name={`elements[${index}].content.isRequired`}
          control={control}
          id={id}
          defaultValue={defaultValue?.isRequired || false}
          render={props => {
            const value = props.value === 'true' || props.value === true;
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={e => props.onChange(e.target.checked)}
                    color='primary'
                  />
                }
                label='Pole obowiązkowe'
              />
            );
          }}
        />
      </S.Content>
    </S.SingleWrapper>
  );
};

export default SingleChoice;
