import { SET_NOTIFICATIONS } from 'actions/notification.action';

const INITIAL_STATE = [];

const moviesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return action.item;

    default:
      return state;
  }
};

export default moviesReducer;
