import styled from 'styled-components';
import { scroll } from 'styles/mixins';

export const Wrapper = styled.div`
  padding: 0 40px 40px;
`;

export const Label = styled.label`
  display: block;
  margin: 30px 0;
  color: ${({ theme }) => theme.grayDarker};
  font-weight: 700;
  font-size: 16px;
`;

export const Input = styled.input`
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid rgb(242, 242, 242);
  padding: 10px 15px;
  width: 600px;
`;

export const WrapperPositions = styled.div`
  background-color: #fff;
  height: 350px;
  width: 1000px;
  border: 1px solid rgba(149, 150, 151, 0.2);
  overflow-y: auto;
  padding: 20px 10px;

  ${scroll}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: 0.3s;

  input {
    margin-right: 30px;
    cursor: pointer;
  }

  label {
    padding: 5px 20px;
    display: block;
    height: 100%;
    cursor: pointer;
  }

  :hover {
    border-bottom: 1px solid #666;
  }
`;
