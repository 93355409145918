import { useCallback, useState } from 'react';
import update from 'immutability-helper';
import { move, reorder } from './dndFunctions';
import { v4 as uuidv4 } from 'uuid';

const useDndAdvCreating = ({ sections, setSections }) => {
  const [droppedBoxNames, setDroppedBoxNames] = useState([]);

  const addSection = () =>
    setSections(prev => [
      ...prev,
      {
        accepts: ['subsection'],
        elements: [],
        title: `Sekcja ${prev.length + 1}`,
        id: uuidv4(),
      },
    ]);

  const deleteSection = id => {
    const tempArray = sections.filter(el => el.id !== id);
    setSections(tempArray);
  };

  const isDropped = boxName => {
    return droppedBoxNames.indexOf(boxName) > -1;
  };

  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item;
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
      );

      setSections(
        update(sections, {
          [index]: {
            elements: {
              $push: [item],
            },
          },
        })
      );
    },
    [sections]
  );

  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(
        sections[sInd].elements,
        source.index,
        destination.index
      );
      const newState = [...sections];
      newState[sInd].elements = items;
      setSections(newState);
    } else {
      const result = move(
        sections[sInd].elements,
        sections[dInd].elements,
        source,
        destination
      );

      const newState = [...sections];

      newState[sInd].elements = result[sInd];
      newState[dInd].elements = result[dInd];
      setSections(newState);
    }
  };

  const deleteSubSection = useCallback(
    (sectionIndex, subSectionIndex) => {
      setSections(
        update(sections, {
          [sectionIndex]: {
            elements: {
              $splice: [[subSectionIndex, 1]],
            },
          },
        })
      );
    },
    [sections]
  );

  return {
    deleteSubSection,
    onDragEnd,
    isDropped,
    addSection,
    handleDrop,
    deleteSection,
  };
};

export default useDndAdvCreating;
