import React from 'react';
import TextAnswer from './SinglesFormData/SingleTextAnswer';
import SingleChoice from './SinglesFormData/SingleChoice';
import SingleList from './SinglesFormData/SingleList';
import SingleDate from './SinglesFormData/SingleDate';
import SingleFile from './SinglesFormData/SingleFile';

const SingleFormDataDetails = ({
  id,
  errors,
  control,
  index,
  defaultValue,
}) => {
  switch (id) {
    case 'SINGLE_CHOICE':
      return (
        <SingleChoice
          errors={errors}
          control={control}
          index={index}
          id={id}
          defaultValue={defaultValue}
        />
      );

    case 'SHORT_ANSWER':
      return (
        <TextAnswer
          errors={errors}
          control={control}
          index={index}
          id={id}
          defaultValue={defaultValue}
        />
      );

    case 'LONG_ANSWER':
      return (
        <TextAnswer
          errors={errors}
          control={control}
          index={index}
          id={id}
          defaultValue={defaultValue}
        />
      );

    case 'LIST':
      return (
        <SingleList
          errors={errors}
          control={control}
          index={index}
          id={id}
          defaultValue={defaultValue}
        />
      );

    case 'DATE':
      return (
        <SingleDate
          errors={errors}
          control={control}
          index={index}
          id={id}
          defaultValue={defaultValue}
        />
      );

    case 'DATE_FROM_TO':
      return (
        <SingleDate
          errors={errors}
          control={control}
          index={index}
          id={id}
          defaultValue={defaultValue}
        />
      );

    case 'FILE':
      return (
        <SingleFile
          errors={errors}
          control={control}
          index={index}
          id={id}
          defaultValue={defaultValue}
        />
      );

    default:
      return <div />;
  }
};

export default SingleFormDataDetails;
