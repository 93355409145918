import React, { useEffect } from 'react';

import MainWrapper from 'components/atoms/MainWrapper';
import CreateFieldDictionary from 'components/admin/Dictionary/CreateFieldDictionary';

const DictionaryCreateFieldPage = () => {
  useEffect(() => {
    document.title = 'Stwórz nowe pole - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <CreateFieldDictionary />
    </MainWrapper>
  );
};

export default DictionaryCreateFieldPage;
