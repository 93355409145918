import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';
import { styledTable } from 'components/tables/helpers/changeStylesBrakpoints';

const Wrapper = styled.div`
  //change breakpoint for mobile view
  @media screen and (max-width: 1000px) {
    ${styledTable}
  }
`;

const CATEGORY = 'recruiter/';
const TYPE = 'report';

const ReportsLibraryTable = () => {
  const SUFFIX = `/generated`;

  const dispatch = useDispatch();
  const { report: dataTable } = useSelector(state => state.currentPageTables);

  const [selectedRows, setSelectedRows] = useState([]);

  const getValues = (
    type = TYPE,
    newValue = null,
    category = CATEGORY,
    suffix = SUFFIX
  ) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(
        type,
        newValue,
        category,
        suffix
      )
    );
  };

  useEffect(() => {
    getValues(TYPE, null, CATEGORY);
  }, []);

  const columns = [
    {
      title: 'Nazwa raportu',
      fieldName: 'name',
      raggable: true,
    },
    {
      title: 'Data wygenerowania',
      fieldName: 'date',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Użytkownik',
      fieldName: 'user',
      draggable: true,
    },
    {
      title: 'Raport szczegółowy',
      fieldName: 'isDetailed',
      draggable: true,
    },
    {
      title: '',
      fieldName: 'download',
    },
    {
      title: '',
      fieldName: 'preview',
    },
    {
      title: '',
      fieldName: 'delete',
    },
    {
      title: '',
      fieldName: 'delete-1',
    },
  ];

  return (
    <Wrapper>
      {!dataTable ? (
        <Indicator />
      ) : (
        <>
          <Table
            type={TYPE}
            category={CATEGORY}
            columns={columns}
            data={dataTable}
            getValues={getValues}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            secondaryType='-generated'
          />
        </>
      )}
    </Wrapper>
  );
};

export default ReportsLibraryTable;
