import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

export const SingleWrapper = styled.div`
  margin: 20px 0;
  font-family: 'Calibri';
`;

export const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.inputBorder};
  padding: 10px;
  border-radius: 2px;
  width: 100%;
`;

export const Label = styled.label`
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;

  span {
    color: ${({ theme }) => theme.red};
  }
`;

export const Textarea = styled(TextareaAutosize)`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 2px;
  width: 100%;
`;

export const DeleteButtonSubSection = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  opacity: 0.6;

  svg {
    fill: ${({ theme }) => theme.grayLight};
  }

  :hover {
    opacity: 1;
  }
`;
