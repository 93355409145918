import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CommunicationSettings from 'components/admin/Administration/CommunicationSettings/CommunicationSettings';
import CreateAccount from 'components/admin/Administration/CreateAccount/CreateAccount';
import SystemLogs from 'components/admin/Administration/SystemLogs/SystemLogs';
import UsersTable from 'components/admin/Administration/Users/UsersTable';
import MainWrapper from 'components/atoms/MainWrapper';
import TableMenu from 'components/atoms/TableMenu';
import Title from 'components/atoms/Title';
import {
  dictionaryType,
  buttons,
} from 'components/admin/Administration/constantsAdministrations';
import { role } from 'auth/constans';

const AdministrationPage = () => {
  const auth = useSelector(state => state.auth);
  const [currentBookmark, setCurrentBookmark] = useState(
    dictionaryType.SETTINGS
  );

  useEffect(() => {
    document.title = 'Administracja - Portal Rekrutacyjny Zabrze';
  }, []);

  const horizontalMenu = {
    currentType: currentBookmark,
    onClick: value => setCurrentBookmark(value),
    buttons:
      auth?.user?.role === role.admin
        ? buttons
        : buttons.filter(el => el.type === 'SETTINGS'),
  };

  const renderBookmark = () => {
    switch (currentBookmark) {
      case dictionaryType.SETTINGS:
        return <CommunicationSettings />;
      case dictionaryType.CREATE:
        if (auth?.user?.role === role.admin) return <CreateAccount />;
        break;
      case dictionaryType.LOGS:
        if (auth?.user?.role === role.admin) return <SystemLogs />;
        break;
      case dictionaryType.USERS:
        if (auth?.user?.role === role.admin) return <UsersTable />;
        break;

      default:
        console.log('coś poszło nie tak ');
    }
  };

  return (
    <MainWrapper>
      <Title>Administracja</Title>
      <TableMenu {...horizontalMenu} staticPos />
      {renderBookmark()}
    </MainWrapper>
  );
};

export default AdministrationPage;
