import styled from 'styled-components';
import { centerFlex } from 'styles/mixins';

export const Wrapper = styled.form`
  background-color: #fff;
  padding: 20px;
  color: ${({ theme }) => theme.primary};
  font-weight: 600;

  h4 {
    font-size: 16px;
  }
`;

export const SelectStatusWrap = styled.div`
  background: #eee;
  display: flex;
  flex-direction: column;

  input[type='radio'] {
    display: none;
  }

  input[type='radio']:checked + label {
    background: rgba(230, 240, 248, 1);
    border-width: 2px;
    border-style: solid;
  }

  label {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  label:hover {
    background: rgb(230, 240, 248);
  }

  @media (min-width: 400px) {
    width: 400px;
  }
`;

export const RecruitDateWrap = styled.div`
  display: flex;
  flex-direction: column;
  label {
    padding: 10px 0;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
  }
  div {
    height: 42px;
  }
`;

export const Buttons = styled.div`
  ${centerFlex}
  margin-top: 30px;
`;
