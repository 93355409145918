import React from 'react';
import StepAfterFirst from './StepAfterFirst/StepAfterFirst';
import FirstStep from './StepFirst/FirstStep';
import SecondStep from './StepSecond/StepSecond';
import ThirdStep from './StepThird/ThirdStep';

export const stepperData = (
  errors,
  defaultValues,
  control,
  advId,

  // adv
  sections,
  sectionsHooks,

  // preview
  sectionsPreview,
  setSectionsPreview,

  //form
  sectionsForm,
  sectionsFormHooks
) => [
  {
    title: 'Edytuj ogłoszenie',
    component: (
      <FirstStep
        sections={sections}
        errors={errors}
        defaultValues={defaultValues}
        control={control}
        advId={advId}
        {...sectionsHooks}
      />
    ),
  },
  {
    title: 'Zmień kolejność elementów (podgląd)',
    component: (
      <StepAfterFirst
        advId={advId}
        sections={sectionsPreview}
        setSections={setSectionsPreview}
      />
    ),
  },
  {
    title: 'Wybierz formularz',
    component: (
      <SecondStep
        errors={errors}
        defaultValues={defaultValues.form}
        formDetails={defaultValues.formDetails}
        control={control}
        sections={sectionsForm}
        advId={advId}
        {...sectionsFormHooks}
      />
    ),
  },
  {
    title: 'Publikuj ogłoszenie',
    component: (
      <ThirdStep
        errors={errors}
        defaultValues={defaultValues}
        control={control}
      />
    ),
  },
];
