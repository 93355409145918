import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';
import { filtersTable } from './AdvertisementFilter';
import * as S from './Advertisement.css';
import HiddenMenu from 'components/shared/HiddenMenu';

const TYPE = 'notice';

const AdvertisementsList = () => {
  const dispatch = useDispatch();
  const { guestnotice: dataTable } = useSelector(
    state => state.currentPageTables
  );
  const filtersValues = useSelector(
    state => state.filtersTable?.guestAdvertisement
  );

  const getValues = (type = TYPE, newValue) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(
        type,
        newValue,
        '',
        '',
        'guest'
      )
    );
  };

  useEffect(() => {
    getValues(TYPE, { ...filtersValues });
  }, [filtersValues]);

  const columns = [
    {
      title: <HiddenMenu text='Lista ogłoszeń' />,
      fieldName: 'advertisement',
      sorting: false,
      draggable: false,
    },
  ];

  return (
    <S.ListWrapper>
      <S.ContentHeader isSelectedAdv={false}>
        <S.Title>Oferty pracy</S.Title>
      </S.ContentHeader>
      {!dataTable ? (
        <Indicator />
      ) : (
        <Table
          type={TYPE}
          secondaryType='guest'
          reduxNameHelper='guest'
          category=''
          columns={columns}
          data={dataTable}
          getValues={getValues}
          searchPlaceholder='Szukaj'
          filtersTable={filtersTable}
          tableToolData={{}}
          tableStyle={{ border: 'none' }}
        />
      )}
    </S.ListWrapper>
  );
};

export default AdvertisementsList;
