import React, { useEffect } from 'react';

import FormsTable from 'components/admin/Forms/FormsTable';
import MainWrapper from 'components/atoms/MainWrapper';

const FormsPage = () => {
  useEffect(() => {
    document.title = 'Formularze - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <FormsTable />
    </MainWrapper>
  );
};

export default FormsPage;
