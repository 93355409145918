import tableResults from 'actions/tableResults.action';
import { TOAST_POSITION } from 'constans/constantsToast';
import { toast } from 'react-toastify';

export const SET_CURRENT_PAGE_TABLES = 'SET_CURRENT_PAGE_TABLES';

const setCurrentPageTables = item => ({
  type: SET_CURRENT_PAGE_TABLES,
  item,
});

const tableGetAction = (
  type,
  newValue,
  category,
  suffix = '',
  reduxNameHelper = ''
) => async dispatch => {
  try {
    const data = await dispatch(
      tableResults.getTableResultsAction(
        type,
        newValue,
        category,
        suffix,
        reduxNameHelper
      )
    );

    dispatch(
      setCurrentPageTables({
        content: data?.content,
        type: `${reduxNameHelper}${type}`,
      })
    );
  } catch (e) {
    toast.error(
      'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
      TOAST_POSITION
    );
  }
};

export default {
  tableGetAction,
  setCurrentPageTables,
};
