import React from 'react';
import styled from 'styled-components';
import { centerFlex } from 'styles/mixins';

import Arrow from 'assets/icons/next.png';

const Header = styled.header`
  height: 50px;
  border: 2px solid ${({ theme }) => theme.lighter};
  display: flex;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colorLight};
`;

const StepItem = styled.div`
  position: relative;
  ${centerFlex}
  flex-grow: 1;
  color: ${({ active, theme }) => (active ? theme.secondary : theme.light)};

  p {
    display: flex;
    margin: 0;
  }

  span {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    font-weight: 600;

    ${centerFlex}

    color: #fff;
    background-color: ${({ active, theme }) =>
      active ? theme.secondary : theme.light};
  }
`;

const Img = styled.img`
  display: block;
  position: absolute;
  right: -20px;
  top: -3px;
  height: 54px;
  width: 40px;
  opacity: 0.1;
`;

const Stepper = ({ steps, currentStep }) => {
  const stepsRender = steps.map((el, i) => (
    <StepItem key={el.title} active={currentStep >= i}>
      <p>
        <span>{i + 1}</span> {el.title}
      </p>
      {i !== steps.length - 1 && <Img src={Arrow} alt='Strzałka' />}
    </StepItem>
  ));

  return (
    <>
      <Header>{stepsRender}</Header>
      {steps[currentStep].component}
    </>
  );
};

export default Stepper;
