import React, { useEffect } from 'react';

import JobOffersTable from 'components/user/JobOffers/JobOffersTable';
import MainWrapper from 'components/atoms/MainWrapper';

const JobOffersPage = () => {
  useEffect(() => {
    document.title = 'Ogłoszenia - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <JobOffersTable />
    </MainWrapper>
  );
};

export default JobOffersPage;
