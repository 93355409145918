import styled from 'styled-components';
import { device } from 'styles/devices';

export const Main = styled.div`
  background-color: ${({ theme }) => theme.colorLighter};
  padding: 30px;

  display: flex;
  align-items: flex-start;
`;

export const Form = styled.div`
  @media ${device.mobileL} {
    max-width: 60%;
  }
  @media ${device.tablet} {
    width: 40%;
    max-width: 500px;
  }
`;

export const Preview = styled.ul`
  flex-grow: 1;
  margin: 20px 40px;
  background-color: #fff;
  border: 1px solid rgba(149, 150, 151, 0.2);
  border-radius: 2px;
`;

export const PreviewHeader = styled.header`
  color: #fff;
  background-color: ${({ theme }) => theme.secondary};
  padding: 20px;
  font-weight: 600;
  font-size: 18px;
`;
