import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import qs from 'qs';
import Select from 'react-select';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { api } from 'API';

import Indicator from 'components/atoms/Indicator';
import PrintComponent from 'components/shared/PrintComponent';
import { Wrapper } from 'components/tables/Table.css';
import { Label } from 'components/admin/Reports/Reports.css';
import { statusOptions } from 'constans/constansCandidates';
import { Button } from 'm-web-components';
import styled from 'styled-components';
import { TOAST_POSITION } from 'constans/constantsToast';

const columns = [
  { title: 'Stanowisko', fieldName: 'position' },
  { title: 'Wszystkie aplikacje', fieldName: 'all' },
  { title: 'Spełnia wymagania', fieldName: 'fulfill' },
  { title: 'Nie spełnia wymagań', fieldName: 'notFulfill' },
  { title: 'Zaproszenia na rozmowę', fieldName: 'testInvitation' },
  { title: 'Zaproszenia na test', fieldName: 'interviewInvitation' },
];

const columnsDetailes = [
  { title: 'Kandydat', fieldName: 'user' },
  { title: 'Ogłoszenie', fieldName: 'position' },
  { title: 'Status', fieldName: 'status' },
  { title: 'Notatka', fieldName: 'note' },
  { title: 'Poziom edukacji', fieldName: 'education' },
  { title: 'Lata doświadczenia', fieldName: 'experience' },
];

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  width: 210px;
  border-radius: 4px;
`;

const ReportPrint = () => {
  const { id, page, size, sortBy, sortDirection } = useParams();
  const [reportData, setReportData] = useState();
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const [educationFilter, setEducationFilter] = useState(null);
  const [experienceFilter, setExerienceFilter] = useState({
    from: null,
    to: null,
  });

  const [educationOptions, setEducationOptions] = useState([]);

  const getReport = async () => {
    const paramsObj = {
      page,
      size,
      sortBy: sortBy !== 'undefined' ? sortBy : '',
      sortDirection: sortDirection !== 'undefined' ? sortDirection : '',
    };
    const params = qs.stringify(paramsObj, { arrayFormat: 'repeat' });

    try {
      const { data } = await api.getSingleReport(id, params);
      setReportData(data.content);
      setRows(data.content);

      if (data.content[0]?.user) setCols(columnsDetailes);
      else setCols(columns);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas pobierania informacji o aplikacji.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const clearFilters = () => {
    setStatusFilter(null);
    setEducationFilter(null);
    setExerienceFilter({
      from: '',
      to: '',
    });
    getReport();
  };

  const setFilters = async () => {
    try {
      const { data } = await api.getReportFilterOptions();
      const filters = [{ label: 'wszystkie', value: 'ALL' }];
      filters.push.apply(filters, data);
      setEducationOptions(filters);
    } catch (e) {
      toast.error('Wystąpił błąd przy pobieraniu filtrów', TOAST_POSITION);
    }
  };

  function filterByExperience(value) {
    const pattern = /([0-9]+)\slat\s/i;
    const match = value.experience.match(pattern);

    if (!match) {
      if (!experienceFilter.from || +experienceFilter.from === 0) {
        return true;
      } else {
        return false;
      }
    }

    const exp = match[1];

    if (experienceFilter.from && exp < +experienceFilter.from) {
      return false;
    }

    if (experienceFilter.to && exp > +experienceFilter.to) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    document.title = 'Drukuj raport - Portal Rekrutacyjny Zabrze';
  }, []);

  useEffect(() => {
    setFilters();
    getReport();
  }, []);

  useEffect(() => {
    let newDataTable = reportData;

    if (statusFilter) {
      newDataTable = newDataTable?.filter(el =>
        statusFilter
          ? el.status === statusFilter.value || statusFilter.value === 'ALL'
          : true
      );
    }

    if (educationFilter) {
      newDataTable = newDataTable?.filter(
        el =>
          el.education === educationFilter.value ||
          educationFilter.value === 'ALL'
      );
    }

    if (experienceFilter.from || experienceFilter.to) {
      newDataTable = newDataTable?.filter(filterByExperience);
    }

    setRows(newDataTable);
  }, [statusFilter, reportData, educationFilter, experienceFilter]);

  const header = cols.map(col => (
    <Th key={col.fieldName} id={col.fieldName}>
      {col.title}
    </Th>
  ));

  const rowsRender = rows?.map(row => (
    <Tr key={row.position}>
      {Object.entries(cols).map(([, v], idx) => (
        <Td key={v}>
          {cols[idx].fieldName === 'status' &&
            statusOptions[row[cols[idx].fieldName]]?.label}
          {cols[idx].fieldName !== 'download' &&
            cols[idx].fieldName !== 'status' &&
            row[cols[idx].fieldName]}
        </Td>
      ))}
    </Tr>
  ));

  return (
    <>
      {!reportData ? (
        <Indicator />
      ) : (
        <div>
          {reportData?.[0]?.user && (
            <>
              <div style={{ padding: '20px 20px 0', maxWidth: '500px' }}>
                <Label>Filrtowanie po ilości lat doświadczenia</Label>
                <Input
                  id='outlined-basic'
                  placeholder='Od'
                  variant='outlined'
                  type='number'
                  value={experienceFilter.from}
                  onChange={e =>
                    setExerienceFilter(prev => ({
                      ...prev,
                      from: e.target.value,
                    }))
                  }
                />
                <Input
                  id='outlined-basic'
                  placeholder='Do'
                  variant='outlined'
                  type='number'
                  style={{ marginLeft: '40px' }}
                  value={experienceFilter.to}
                  onChange={e =>
                    setExerienceFilter(prev => ({
                      ...prev,
                      to: e.target.value,
                    }))
                  }
                />
              </div>
              <div style={{ padding: '20px 20px 0', maxWidth: '500px' }}>
                <Label>Filrtowanie po statusie aplikacji</Label>
                <Select
                  placeholder='Wybierz status'
                  value={statusFilter}
                  onChange={value => setStatusFilter(value)}
                  options={Object.values(statusOptions)}
                />
              </div>
              <div style={{ padding: '20px 20px 0', maxWidth: '500px' }}>
                <Label>Filrtowanie po wykształceniu</Label>
                <Select
                  placeholder='Wybierz wykształcenie'
                  value={educationFilter}
                  onChange={value => setEducationFilter(value)}
                  options={educationOptions}
                />
              </div>
              <Button
                colorType='light'
                margin='40px 20px'
                onClick={clearFilters}
              >
                Wyczyść filtry
              </Button>
            </>
          )}
          <PrintComponent
            report
            componentToPrint={
              <Wrapper>
                <Table>
                  <Thead>
                    <Tr>{header}</Tr>
                  </Thead>
                  <Tbody>{rowsRender}</Tbody>
                </Table>
              </Wrapper>
            }
          />
        </div>
      )}
    </>
  );
};

export default ReportPrint;
