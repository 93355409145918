import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

import DeleteIcon from '@material-ui/icons/Delete';
import * as S from 'components/admin/FormsElements/FormsElements.css';
import Singles from 'components/admin/FormsElements/Singles';
import { api } from 'API';
import Indicator from 'components/atoms/Indicator';

const SingleSubSection = ({
  dictionaryId,
  index: dictIndex,
  formProps,
  sectionIndex,
  deleteSubSection,
  advId,
  canDuplicate,
  item,
}) => {
  const [formData, setFormData] = useState(null);
  const [isDictVisible, setDictVisible] = useState(true);
  const basicNameForm = `form[${sectionIndex}].dictionaries[${dictIndex}]`;

  const notDelete =
    dictionaryId === 117 ||
    dictionaryId === 114 ||
    dictionaryId === 112 ||
    dictionaryId === 115 ||
    dictionaryId === 118 ||
    dictionaryId === 102;

  useEffect(() => {
    const getOneDict = async () => {
      const { data } = await api.getSingleDictionary(dictionaryId);
      setFormData(data);
    };
    getOneDict();
    return () => setFormData({});
  }, [dictionaryId]);

  const elementsRender = formData?.elements?.map((el, index) => {
    const props = {
      data: el.content,
      key: `${el.dataFormat}-${index}`,
      index,
      basicNameForm,
      ignoreRequired: true,
      ...formProps,
      defaultValues: formProps?.defaultValues?.elements?.[index],
      advId,
    };

    switch (el.dataFormat) {
      case 'SINGLE_CHOICE':
        return <Singles.SingleChoice {...props} />;

      case 'SHORT_ANSWER':
        return <Singles.SingleShortAnswer {...props} />;

      case 'LONG_ANSWER':
        return <Singles.SingleLongAnswer {...props} />;

      case 'LIST':
        return <Singles.SingleSelect {...props} />;

      case 'DATE':
        return <Singles.SingleDate {...props} />;

      case 'DATE_FROM_TO':
        return <Singles.SingleDateFromTo {...props} />;

      case 'FILE':
        return <Singles.SingleFile {...props} />;

      default:
        return <div>coś poszlo nie tak ...</div>;
    }
  });

  return (
    <>
      {!formData ? (
        <Indicator />
      ) : (
        <S.SubsectionWrapper>
          <S.DictTitle
            isdictvisible={isDictVisible}
            onClick={() => setDictVisible(prev => !prev)}
            type='button'
          >
            <h3>
              <ArrowDropDownOutlinedIcon />
              {formData.title}
            </h3>
          </S.DictTitle>
          <Controller
            id={dictionaryId}
            name={`${basicNameForm}.dictionaryId`}
            control={formProps.control}
            defaultValue={dictionaryId}
          />
          <S.SubsectionDetails isdictvisible={isDictVisible}>
            {elementsRender}

            {formProps && (
              <div>
                <Controller
                  name={`${basicNameForm}.elements[${dictIndex}].content.canDuplicate`}
                  control={formProps.control}
                  defaultValue={canDuplicate}
                  render={props => (
                    <FormControlLabel
                      control={
                        <Switch
                          color='primary'
                          {...props}
                          checked={props.value}
                          onChange={e => {
                            props.onChange(e.target.checked);
                            item.elements[0].content.canDuplicate =
                              e.target.checked;
                          }}
                        />
                      }
                      label='Dodanie pozycji'
                    />
                  )}
                />
                {!notDelete && (
                  <S.DeleteButtonSubSection
                    type='button'
                    onClick={() => {
                      deleteSubSection(sectionIndex, dictIndex);
                    }}
                  >
                    <DeleteIcon />
                    Usuń
                  </S.DeleteButtonSubSection>
                )}
              </div>
            )}
          </S.SubsectionDetails>
        </S.SubsectionWrapper>
      )}
    </>
  );
};

export default SingleSubSection;
