import React, { useEffect, useState } from 'react';
import { Button, ErrorAlert } from 'm-web-components';
import { ErrorMessage } from '@hookform/error-message';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useHistory, useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { DndProvider } from 'react-dnd-latest';
import { HTML5Backend } from 'react-dnd-html5-backend-latest';
import { v4 as uuidv4 } from 'uuid';

import DictionaryList from '../CreateAdverisement/DictionaryList';
import useDndAdvCreating from '../CreateAdverisement/useDnDAdvCreating';
import { getListStyle } from '../CreateAdverisement/dndFunctions';
import SingleSection from './SingleSection';
import CreatePageHeader from 'components/shared/CreatePageHeader';
import { api } from 'API';

import * as S from 'components/admin/CreateAdverisement/CreateAdvertisement.css';
import { toast } from 'react-toastify';
import { basicFields, basicDefaultValues } from './basicFields';
import Indicator from 'components/atoms/Indicator';
import theme from 'styles/theme';
import { TOAST_POSITION } from 'constans/constantsToast';

const CreateForm = () => {
  const history = useHistory();
  const { id } = useParams();

  const [formId, setFormId] = useState(id);
  const [defaultValues, setDeafultValues] = useState(
    id ? null : basicDefaultValues
  );
  const [sectionsForm, setSectionsForm] = useState(id ? [] : basicFields);

  const { control, handleSubmit, errors, watch, setValue } = useForm({
    defaultValues: defaultValues || {},
  });

  const {
    onDragEnd,
    isDropped,
    deleteSection,
    deleteSubSection,
    handleDrop,
    addSection,
  } = useDndAdvCreating({
    sections: sectionsForm,
    setSections: setSectionsForm,
  });

  const getSingleForm = async () => {
    try {
      const { data } = await api.getSingleFormForCreator(formId);

      const sections = data.sections?.map(section => ({
        accepts: ['subsection'],
        elements: section.dictionaries.map(dict => ({
          ...dict,
          ...dict.dictionaryData,
        })),
        title: section.data,
        id: uuidv4(),
      }));

      setDeafultValues(data);
      setSectionsForm(sections);
      setValue('title', data.title);
    } catch (e) {
      console.log(e);
      toast.error(
        <div>
          <p>Wystąpił błąd podczas pobierania danych formularza.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  useEffect(() => {
    document.title = 'Stwórz formularz - Portal Rekrutacyjny Zabrze';
  }, []);

  useEffect(() => {
    if (formId) getSingleForm();
    else setDeafultValues({});
    return () => setDeafultValues({});
  }, [formId]);

  const onSubmit = async values => {
    const body = {
      title: values.title,
      sections: sectionsForm.map((section, idx) => ({
        ...section,
        title: values.sections[idx]?.title,
        dictionaries: section.elements,
      })),
    };

    const req = formId
      ? api.putSingleForm(formId, body)
      : api.postSingleForm(body);

    try {
      const { data } = await req;
      setFormId(data.formId);
      toast.success('Formularz został zapisany', TOAST_POSITION);
    } catch (e) {
      console.log(e);
      toast.error(
        <div>
          <p>Wystąpił błąd podczas zapisywania formularza.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  return (
    <>
      {!sectionsForm ? (
        <Indicator />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <CreatePageHeader
            title='Stwórz nowy formularz'
            history={history}
            subTitle={watch('title')}
          />
          <DndProvider backend={HTML5Backend}>
            <S.Main>
              <DictionaryList isDropped={isDropped} currentStep={1} />
              <S.Form style={{ padding: '40px' }}>
                <S.Label>Tytuł formularza</S.Label>
                <Controller
                  errors={errors}
                  defaultValue={defaultValues?.title}
                  control={control}
                  rules={{ required: 'To pole jest wymagane' }}
                  name='title'
                  render={({ onChange, value }) => (
                    <S.Input
                      onChange={onChange}
                      value={value}
                      placeholder='Wpisz tytuł'
                      bigMargin
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='title'
                  render={({ message }) => (
                    <ErrorAlert
                      error={message}
                      maxWidth='200px'
                      type='simple'
                    />
                  )}
                />

                <S.WrapperSections>
                  <DragDropContext onDragEnd={onDragEnd}>
                    {sectionsForm.map(
                      ({ accepts, elements, title, id }, index) => (
                        <Droppable key={index} droppableId={`${index}`}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}
                            >
                              <SingleSection
                                key={id}
                                id={id}
                                accept={accepts}
                                onDrop={item => handleDrop(index, item)}
                                selectedDictionaries={elements}
                                index={index}
                                deleteSection={deleteSection}
                                title={title}
                                deleteSubSection={deleteSubSection}
                                onDragEnd={onDragEnd}
                                advId={formId}
                                errors={errors}
                                defaultValues={
                                  id
                                    ? defaultValues?.sections?.[index]
                                    : basicDefaultValues?.sections?.[index]
                                }
                                control={control}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )
                    )}
                  </DragDropContext>
                  <div>
                    <Button
                      type='button'
                      colorType='light'
                      style={{
                        border: '1px solid #2C81C7',
                        color: theme.secondary,
                      }}
                      padding='10px 16px'
                      fontWeight='500'
                      margin='30px auto 0'
                      onClick={addSection}
                    >
                      + Dodaj sekcje
                    </Button>
                  </div>
                </S.WrapperSections>
              </S.Form>
            </S.Main>
          </DndProvider>
        </form>
      )}
    </>
  );
};

export default CreateForm;
