import React, { useEffect } from 'react';

import CandidatesTable from 'components/admin/Candidates/CandidatesTable';
import MainWrapper from 'components/atoms/MainWrapper';

const CandidatesPage = () => {
  useEffect(() => {
    document.title = 'Kandydaci - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <CandidatesTable />
    </MainWrapper>
  );
};

export default CandidatesPage;
