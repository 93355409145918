import React from 'react';

import SingleSection from './SingleSection';

const ApplicationDetails = ({ data, advId, print }) => {
  const sections = data.map(({ dictionaries, title }) => {
    return (
      <SingleSection
        key={title}
        title={title}
        advId={advId}
        dictionaries={dictionaries}
        print={print}
      />
    );
  });

  return <>{sections}</>;
};

export default ApplicationDetails;
