import React from 'react';
import { Search } from 'm-web-components';

import SearchIcon from '@material-ui/icons/Search';
import * as S from './Table.css';

const SearchTable = ({ searchPlaceholder, type, category, getValues }) => {
  const onSubmitSearch = ({ search }) => {
    const data = { chIn: search };

    getValues(type, { ...data, page: 1 }, category);
  };

  return (
    <S.SearchWrapper>
      <S.AccessibilityLabel>
        <p>Szukaj</p>
        <Search
          searchSubmit={onSubmitSearch}
          paddingInput='5px 10px'
          buttonComponent={null}
          placeholder={searchPlaceholder}
          aria-label='Szukaj'
        />
      </S.AccessibilityLabel>
      <SearchIcon aria-hidden='false'>
        <title>Szukaj</title>
      </SearchIcon>
    </S.SearchWrapper>
  );
};

export default SearchTable;
