import styled from 'styled-components';

export const InvitationWrap = styled.div`
  width: 80%;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.grayLighter};
  padding: 30px;
  :not(:last-child) {
    border-bottom: none;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const InvTitle = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.secondary};
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const InvPosition = styled.h3`
  display: flex;
  font-size: 2rem;
  color: ${({ theme }) => theme.secondary};
  margin: 0;
`;

export const InvType = styled.span`
  min-width: 200px;
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.5rem;
  font-weight: normal;
  padding: 3px 10px;
  border: 1px solid ${({ theme }) => theme.secondary};
  margin-left: 30px;

  @media (max-width: 768px) {
    margin: 15px 0 0 0;
  }
`;

export const InvDetails = styled.div`
  width: 70%;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-row-gap: 15px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FieldName = styled.div`
  font-weight: 600;
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

export const ConfirmButton = styled.button`
  padding: 8px 20px;
  border: none;
  color: #fff;
  background: ${({ theme }) => theme.green};
  cursor: pointer;
`;

export const ResignButton = styled.button`
  margin-right: 20px;
  padding: 8px 20px;
  border: none;
  color: #fff;
  background: ${({ theme }) => theme.grayLight};
  cursor: pointer;
`;

export const InvNotif = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-weight: 600;
  color: ${({ green, theme }) => (green ? theme.green : theme.grayLight)};
  svg {
    margin-right: 10px;
  }
`;
