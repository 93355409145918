import { createGlobalStyle } from 'styled-components';

import Calibri from 'assets/fonts/calibri-font-family/calibri-regular.ttf';
import CalibriBold from 'assets/fonts/calibri-font-family/calibri-bold.ttf';

const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Calibri';
        src: url(${Calibri}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: auto;
    }
    @font-face {
        font-family: 'CalibriBold';
        src: url(${CalibriBold}) format('truetype');
        font-weight: 600;
        font-style: normal;
        font-display: auto;
    }


    * {
        ::placeholder {
            color: ${({ theme }) => theme.placeholderColor} !important; 
        }

        div[class*='placeholder'] {
            color: ${({ theme }) => theme.placeholderColor};
        }
    /*temporary helper
    uncomment to see borders of every HTML element on the website, helps with positioning*/
    /* border: 1px blue solid;  */
    }

    *, *:before, *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
    }

    :root{
        font-size: calc(10px * ${({ zoom }) => zoom});
    }

    body, html {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }

    body{
        font-size: 1.4rem;

        .MuiInputBase-root{
            font-size: 1.4rem;
        }

        .MuiMenuItem-root{
            font-size: 1.4rem;
        }

        .MuiButtonBase-root{
            font-size: 1.4rem;
        }

        h1{
            font-size: 3.2rem;
        }

        h2{
            font-size: 2.4rem;
        }

        h3{
            font-size: 1.9rem;
        }
        
        h4{
            font-size: 1.6rem;
        }
        
        h5{
            font-size: 1.3rem;
        }
        
        h6{
            font-size: 1rem;
        }        
                
        .react-datepicker__current-month,
        .react-datepicker{
            font-size: 1.2rem;
        }

        .react-datepicker__month{
            margin: 0.8rem;
        }

        .react-datepicker__day-name,
        .react-datepicker__day{
            width: 2.2rem;
            line-height: 2.2rem;
        }

        .MuiTypography-root{
            font-size: 1.4rem;
        }

        .MuiSvgIcon-root{
            font-size: 1.8rem;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    h1, h2, h3, h4 {
        font-family: 'Libre Franklin', sans-serif;
    }

    .ql-align-center {
        text-align: center;
    }

`;

export default GlobalStyle;
