import React, { useEffect } from 'react';

import DictionaryTable from 'components/admin/Dictionary/DictionaryTable';
import MainWrapper from 'components/atoms/MainWrapper';

const DictionaryPage = () => {
  useEffect(() => {
    document.title = 'Słowniki - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <DictionaryTable />
    </MainWrapper>
  );
};

export default DictionaryPage;
