import React, { useEffect } from 'react';
import { api } from 'API';
import Indicator from 'components/atoms/Indicator';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getListStyle } from '../dndFunctions';
import useDndAdvCreating from '../useDnDAdvCreating';

import SingleSection from './SingleSection';

const StepAfterFirst = ({ advId, sections, setSections }) => {
  const { onDragEnd, deleteSection, deleteSubSection } = useDndAdvCreating({
    sections,
    setSections,
  });
  const getSingleAdv = async () => {
    const { data } = await api.getSingleAdvertisementForCreator(advId);
    const newSections = data.sections.map(el => ({
      ...el,
      elements: el.dictionaries,
    }));
    setSections(newSections);
  };

  useEffect(() => {
    if (advId) getSingleAdv();
  }, [advId]);

  return (
    <>
      {!sections ? (
        <Indicator />
      ) : (
        <div style={{ maxWidth: '800px' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            {sections.map(({ elements, title, id }, index) => (
              <Droppable key={index} droppableId={`${index}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <SingleSection
                      key={id}
                      id={id}
                      dictionaries={elements}
                      index={index}
                      deleteSection={deleteSection}
                      title={title}
                      deleteSubSection={deleteSubSection}
                      onDragEnd={onDragEnd}
                      advId={advId}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </div>
      )}
    </>
  );
};

export default StepAfterFirst;
