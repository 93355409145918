import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';

import { styledTable } from 'components/tables/helpers/changeStylesBrakpoints';
import { filtersTable } from './OtherApplicationsTableFilters';

const Wrapper = styled.div`
  //change breakpoint for mobile view
  @media screen and (max-width: 1000px) {
    ${styledTable}
  }
`;

const CATEGORY = 'recruiter/';
const TYPE = 'application';

const OtherApplicationsTable = ({ uId, setActiveBookmark, isModalOpen }) => {
  const dispatch = useDispatch();
  const { application: dataTable } = useSelector(
    state => state.currentPageTables
  );

  const getValues = (type = TYPE, newValue = null, category = CATEGORY) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(
        type,
        newValue,
        category,
        `/${uId}`
      )
    );
  };

  useEffect(() => {
    getValues();
  }, [isModalOpen]);

  const columns = [
    {
      title: 'Nr aplikacji',
      fieldName: 'id',
      draggable: true,
    },
    {
      title: 'Stanowisko',
      fieldName: 'title',
      draggable: true,
    },

    {
      title: 'Data złożenia aplikacji',
      fieldName: 'applicationDate',
      draggable: true,
    },
    {
      title: 'Status',
      fieldName: 'status',
      draggable: true,
    },
    { title: '', fieldName: 'menu', draggable: false },
  ];

  return (
    <>
      <Wrapper>
        {!dataTable ? (
          <Indicator />
        ) : (
          <>
            <Table
              type={TYPE}
              category={CATEGORY}
              secondaryType={'other'}
              columns={columns}
              data={dataTable}
              getValues={getValues}
              searchPlaceholder='Szukaj'
              filtersTable={filtersTable}
              tableToolData={{}}
              setActiveBookmark={setActiveBookmark}
              tableStyle={{ borderLeft: 'none', borderRight: 'none' }}
            />
          </>
        )}
      </Wrapper>
    </>
  );
};

export default OtherApplicationsTable;
