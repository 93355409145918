export const dictionaryType = {
  SETTINGS: 'SETTINGS',
  PRIVACY: 'PRIVACY',
};

export const privacySettings = {
  CHANGEPASSWORD: 'CHANGEPASSWORD',
  DELETEACCOUNT: 'DELETEACCOUNT',
};

export const buttons = [
  { type: 'SETTINGS', label: 'Ustawienia konta' },
  { type: 'PRIVACY', label: 'Prywatność i zarządzanie kontem' },
];
