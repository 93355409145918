import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

import { ReactComponent as CheckmarkIcon } from 'assets/icons/CheckmarkIcon.svg';

const ThankYou = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40%;
  padding: 20px;
`;

const InvitationSent = styled.p`
  text-align: center;
`;

const InvitationSummary = styled.div`
  border-top: 1px solid #959697;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60%;
  padding: 20px;

  span {
    font-weight: 600;
  }

  p {
    overflow-wrap: break-word;
  }
`;

const InviteSentConfirmation = ({ invitationDetails, formData, date }) => {
  return (
    <>
      <ThankYou>
        <CheckmarkIcon />
        <h4>Zaproszenie zostało wysłane.</h4>
        <InvitationSent>
          Zaproszenie zostało dostarczone do kandydata i oczekuje na
          potwierdzenie. Zostaniesz poinformowany kiedy kandydat odpowie.
        </InvitationSent>
      </ThankYou>
      <InvitationSummary>
        <p>
          <span>Kandydat: </span>
          {invitationDetails.name} {invitationDetails.surname}
        </p>
        <p>
          <span>Stanowisko: </span>
          {formData.advertisementName}
        </p>
        <p>
          <span>Etap rekrutacji: </span>
          {formData?.invType === 'INTERVIEW_INV'
            ? 'Zaproszenie na rozmowę'
            : 'Zaproszenie na test'}
        </p>
        <p>
          <span>Data i godzina: </span>
          {format(date, 'dd-MM-yyyy') + ' godz. ' + format(date, 'HH:mm')}
        </p>
        <p>
          <span>Twoja wiadomość: </span>
          <br />
          {formData.content}
        </p>
      </InvitationSummary>
    </>
  );
};

export default InviteSentConfirmation;
