import React from 'react';

import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { ReactComponent as PublishIcon } from 'assets/icons/publish.svg';

export const statusName = {
  ACTIVE: {
    label: 'Aktywne',
    value: 'ACTIVE',
  },
  ARCHIVAL: {
    label: 'Archiwalne',
    value: 'ARCHIVAL',
  },
  PRE_ARCHIVE: {
    label: 'Przedarchiwalne',
    value: 'PRE_ARCHIVE',
  },
  DRAFT: {
    label: 'Robocze',
    value: 'DRAFT',
  },
};

export const menuItems = [
  {
    icon: <VisibilityOutlinedIcon />,
    label: 'Zobacz szczegóły',
    notVisible: [],
  },
  {
    icon: <EditIcon />,
    label: 'Edytuj ogłoszenie',
    to: '/admin/create-advertisement',
    notVisible: ['ARCHIVAL', 'PRE_ARCHIVE', 'ACTIVE'],
  },
  {
    icon: <PeopleAltOutlinedIcon />,
    label: 'Zobacz aplikacje',
    notVisible: ['ARCHIVAL', 'DRAFT'],
    to: '/admin/advertisement',
    props: 'candidates',
  },
  {
    icon: <HighlightOffOutlinedIcon />,
    label: 'Zamknij ogłoszenie',
    notVisible: ['PRE_ARCHIVE', 'ARCHIVAL', 'DRAFT'],
  },
  {
    icon: <FolderOpenOutlinedIcon />,
    label: 'Przenieś do archiwalnych',
    notVisible: ['ARCHIVAL', 'DRAFT'],
  },
  {
    icon: <PublishIcon />,
    label: 'Publikuj ogłoszenie',
    notVisible: ['PRE_ARCHIVE', 'ARCHIVAL', 'ACTIVE'],
  },
  {
    icon: <PrintOutlinedIcon />,
    label: 'Drukuj ogłoszenie',
    notVisible: ['PRE_ARCHIVE', 'ARCHIVAL', 'DRAFT'],
    to: '/admin/advertisement-print',
  },
];

export const singleAdvBookmarks = {
  desc: {
    name: 'desc',
    label: 'Opis stanowiska',
  },
  candidates: {
    name: 'candidates',
    label: 'Kandydaci',
  },
  invitations: {
    name: 'invitations',
    label: 'Zaproszenia',
  },
  reports: {
    name: 'reports',
    label: 'Raporty',
  },
};

export const invitationStatus = {
  CONFIRM: 'Potwierdzono',

  REJECT: 'Rezygnacja',

  PENDING: 'Wysłano',
};

export const statusOptions = [
  {
    label: 'Nowy',
    value: 'NEW',
  },

  {
    label: 'Zaproszony na rozmowę',
    value: 'INTERVIEW_INV',
  },

  {
    label: 'Zaproszony na rozmowę - potwierdzony',
    value: 'INTERVIEW_INV_CONFIRM',
  },

  {
    label: 'Zaproszony na rozmowę - odmowa',
    value: 'INTERVIEW_INV_REJECT',
  },

  {
    label: 'Zaproszony na test',
    value: 'TEST_INV',
  },

  {
    label: 'Zaproszony na test - potwierdzony',
    value: 'TEST_INV_CONFIRM',
  },

  {
    label: 'Zaproszony na test - odmowa',
    value: 'TEST_INV_REJECT',
  },

  {
    label: 'Spełnia wymagania',
    value: 'FULFILL_REQUIREMENTS',
  },

  {
    label: 'Nie spełnia wymagań',
    value: 'NOT_FULFILL_REQUIREMENTS',
  },

  {
    label: 'Rezygnacja',
    value: 'RESIGN',
  },
];

export const statusOptionsWithColor = {
  NEW: {
    label: 'Nowy',
    value: 'NEW',
    color: 'blue',
  },

  PENDING: {
    label: 'Oczekuje',
    value: 'PENDING',
    color: 'blue',
  },

  INTERVIEW_INV: {
    label: 'Zaproszony na rozmowę',
    value: 'INTERVIEW_INV',
    color: 'green',
  },

  INTERVIEW_INV_CONFIRM: {
    label: 'Zaproszony na rozmowę - potwierdzony',
    value: 'INTERVIEW_INV_CONFIRM',
    color: 'green',
  },

  INTERVIEW_INV_REJECT: {
    label: 'Zaproszony na rozmowę - odmowa',
    value: 'INTERVIEW_INV_REJECT',
    color: 'red',
  },

  TEST_INV: {
    label: 'Zaproszony na test',
    value: 'TEST_INV',
    color: 'green',
  },

  TEST_INV_CONFIRM: {
    label: 'Zaproszony na test - potwierdzony',
    value: 'TEST_INV_CONFIRM',
    color: 'green',
  },

  CONFIRM: {
    label: 'Potwierdzony',
    value: 'CONFIRM',
    color: 'green',
  },

  TEST_INV_REJECT: {
    label: 'Zaproszony na test - odmowa',
    value: 'TEST_INV_REJECT',
    color: 'red',
  },

  FULFILL_REQUIREMENTS: {
    label: 'Spełnia wymagania',
    value: 'FULFILL_REQUIREMENTS',
    color: 'green',
  },

  NOT_FULFILL_REQUIREMENTS: {
    label: 'Nie spełnia wymagań',
    value: 'NOT_FULFILL_REQUIREMENTS',
    color: 'red',
  },

  RESIGN: {
    label: 'Rezygnacja',
    value: 'RESIGN',
    color: 'black',
  },

  REJECT: {
    label: 'Odmowa',
    value: 'REJECT',
    color: 'red',
  },
};
