import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { EditButton } from 'components/atoms/Buttons';
import * as S from './CandidateDetails.css';

import { api } from 'API';
import { statusOptions } from 'constans/constansCandidates';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import theme from 'styles/theme';
import Indicator from 'components/atoms/Indicator';
import { toast } from 'react-toastify';

import Application from './Application';
import OtherApplicationsTable from './OtherApplcations';
import { TextArea } from 'components/admin/Administration/Administration.css';
import { Button } from 'm-web-components';
import CommunicationTable from './Communication/CommunicationTable';

import Modal from 'components/shared/Modal';
import ModalChangeStatus from './ModalChangeStatus';
import { TOAST_POSITION } from 'constans/constantsToast';

const CandidateDetails = ({ goBack, id, setActiveBookmark }) => {
  const [applicationDetails, setApplicationDetails] = useState(null);
  const [userDetails, setUserDetaild] = useState(null);
  const [bookmark, setBookmark] = useState('Aplikacja');
  const [noteValue, setNoteValue] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  const getApplicationDetails = async () => {
    try {
      const { data } = await api.getSingleCandidateApplication(id);
      setApplicationDetails(data);
      setUserDetaild(data.userDetails);
      setNoteValue(data.note || '');
    } catch {
      toast.error(
        `Coś poszło nie tak podczas pobierania szczegółów aplikacji.
        Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania.`,
        TOAST_POSITION
      );
    }
  };

  const bookmarks = [
    { label: 'Aplikacja', onClick: () => setBookmark('Aplikacja') },
    {
      label: 'Zaproszenia / komunikacja',
      onClick: () => setBookmark('Zaproszenia / komunikacja'),
    },
    {
      label: 'Aplikacje na inne stanowiska',
      onClick: () => setBookmark('Aplikacje na inne stanowiska'),
    },
  ];

  const renderButtons = bookmarks.map(el => (
    <S.SecondarySectionButton blue={el.label === bookmark} onClick={el.onClick}>
      <ArrowBackIosIcon aria-hidden='false'>
        <title>Wybierz</title>
      </ArrowBackIosIcon>
      {el.label}
    </S.SecondarySectionButton>
  ));

  const saveNote = async e => {
    e.preventDefault();
    try {
      await api.saveNote(id, { content: noteValue });
      toast.success('Notatka została zapisana', TOAST_POSITION);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas dodawania notatki.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const renderContent = () => {
    switch (bookmark) {
      case 'Aplikacja':
        return (
          <Application
            id={id}
            userDetails={userDetails}
            applicationDetails={applicationDetails}
          />
        );
      case 'Aplikacje na inne stanowiska':
        return (
          <OtherApplicationsTable
            uId={applicationDetails.userId}
            setActiveBookmark={setActiveBookmark}
            isModalOpen={isModalOpen}
          />
        );
      case 'Zaproszenia / komunikacja':
        return (
          <S.CandidateMainSection>
            <CommunicationTable
              id={userDetails?.userId}
              isModalOpen={isModalOpen}
            />
          </S.CandidateMainSection>
        );
      default:
        return (
          <Application
            id={id}
            userDetails={userDetails}
            applicationDetails={applicationDetails}
          />
        );
    }
  };

  useEffect(() => {
    getApplicationDetails();
    setBookmark('Aplikacja');
    return () => {
      setApplicationDetails(null);
    };
  }, [id]);

  useEffect(() => {
    getApplicationDetails();
  }, [isModalOpen]);

  return (
    <>
      {!applicationDetails || !userDetails ? (
        <Indicator />
      ) : (
        <>
          <S.GoBackButton onClick={goBack}>
            <ArrowBackIosIcon aria-hidden='false'>
              <title>Wróc</title>
            </ArrowBackIosIcon>{' '}
            Wróć do listy kandydatów
          </S.GoBackButton>
          <S.CandidateWrapper>
            <S.CandidateSecondarySection>
              <S.SecondarySectionWrapper>
                {renderButtons}
              </S.SecondarySectionWrapper>
              <S.SecondarySectionWrapper>
                <S.StatusWrapper>
                  <S.StatusTitleWrapper>
                    <h2>Status Kandydata</h2>
                    <EditButton
                      isVisible={true}
                      onClick={() => setModalOpen(prev => !prev)}
                    >
                      Zmień
                    </EditButton>
                  </S.StatusTitleWrapper>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FiberManualRecordIcon
                      style={{
                        fill:
                          theme[applicationDetails?.status?.applicationStatus],
                        marginRight: '15px',
                      }}
                      aria-hidden='false'
                    >
                      <title>Status</title>
                    </FiberManualRecordIcon>
                    {
                      statusOptions[
                        applicationDetails?.status?.applicationStatus
                      ].label
                    }
                  </div>
                </S.StatusWrapper>
                <form onSubmit={saveNote}>
                  <h2>Notatki</h2>
                  <TextArea
                    aria-label='empty textarea'
                    placeholder='dodaj notatkę'
                    rowsMin={3}
                    onChange={e => setNoteValue(e.target.value)}
                    value={noteValue}
                  />
                  <Button
                    colorType='primary'
                    margin='0'
                    border='none'
                    padding='5px 10px'
                  >
                    Zapisz notatkę
                  </Button>
                </form>
              </S.SecondarySectionWrapper>
            </S.CandidateSecondarySection>
            {renderContent()}
          </S.CandidateWrapper>
          <Modal open={isModalOpen} setOpen={setModalOpen}>
            <ModalChangeStatus setModalOpen={setModalOpen} id={id} />
          </Modal>
        </>
      )}
    </>
  );
};

export default CandidateDetails;
