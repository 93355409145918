import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';

import theme from 'styles/theme';
import { statusName } from 'constans/constansAdv';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MenuCell from 'components/user/JobOffers/JobOffersTableMenu';
import { menuItems } from 'constans/user/constansAdv';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const RowStyled = styled(Tr)`
  background-color: ${({ selected }) => (selected ? '#E6F0F8' : 'transparent')};
  transition: 0.4s;

  :hover {
    border-bottom: 1px solid #ebebeb;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.tableFileds};
  display: block;
  height: 100%;
  transition: 0.4s;

  :hover {
    color: ${({ theme }) => theme.tableHeader};
  }
`;

const UserJobOffersTableRow = ({ row, cols, dragOver }) => {
  const remapped = {
    id: row.id,
    recruitNr: row.recruitNr,
    proposalNr: row.proposalNr,
    title: row.title,
    publicationDate: row.publicationDate,
    submitDeadline: row.submitDeadline,
    status: row.status,
  };

  const cells = Object.entries(remapped).map(([, v], idx) => (
    <Td key={v} dragOver={cols[idx] === dragOver}>
      {cols[idx].fieldName === 'status' && (
        <p style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <FiberManualRecordIcon
            style={{ marginRight: '5px', color: theme[remapped.status] }}
            aria-hidden='false'
          >
            <title>Status</title>
          </FiberManualRecordIcon>
          {statusName[remapped.status].label}
        </p>
      )}

      {cols[idx].fieldName !== 'status' &&
        cols[idx].fieldName !== 'title' &&
        remapped[cols[idx].fieldName]}

      {cols[idx].fieldName === 'title' && (
        <StyledLink to={`/user/single-job-offers/${row.id}`}>
          <span style={{ color: theme.secondary, fontWeight: '600' }}>
            {remapped[cols[idx].fieldName]}
          </span>
        </StyledLink>
      )}
    </Td>
  ));

  return (
    <RowStyled>
      {cells}
      <Td>
        <MenuCell
          rowId={row.id}
          menuItems={menuItems.filter(el => {
            const isVisible = el.notVisible?.includes(row.status);
            return !isVisible;
          })}
        />
      </Td>
    </RowStyled>
  );
};

export default UserJobOffersTableRow;
