import { statusOptions as statusName } from 'constans/constansCandidates';

const statusOptions = Object.values(statusName);

export const candidatesFiltersTable = options => [
  {
    filterType: 'selectMulti',
    nameFilter: 'aId',
    nameTable: 'adminCandidates',
    label: 'Stanowisko',
    placeholder: 'Wszystkie stanowiska',
    options: options.advertisements,
  },
  {
    filterType: 'select',
    nameFilter: 'appStatus',
    nameTable: 'adminCandidates',
    label: 'Status',
    placeholder: 'Wszystkie ogłoszenia',
    options: statusOptions,
  },
  {
    filterType: 'selectMulti',
    nameFilter: 'dId',
    nameTable: 'adminCandidates',
    label: 'Wydział',
    placeholder: 'Wybierz wydział',
    options: options.departments,
  },
  {
    filterType: 'dataPicker',
    nameFilter: 'c',
    nameTable: 'adminCandidates',
    label: 'Data przesłania aplikacji',
  },
];

export const candidatesFiltersTableGeneral = [
  {
    filterType: 'selectMulti',
    nameFilter: 'appStatus',
    nameTable: 'adminCandidates',
    label: 'Status',
    placeholder: 'Wszystkie ogłoszenia',
    options: statusOptions,
  },
  {
    filterType: 'dataPicker',
    nameFilter: 'c',
    nameTable: 'adminCandidates',
    label: 'Data przesłania aplikacji',
  },
];
