import React, { useEffect } from 'react';

import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import SingleHomeTail from 'components/atoms/SingleHomeTail';
import styled from 'styled-components';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import VerticalSplitOutlinedIcon from '@material-ui/icons/VerticalSplitOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const Wrapper = styled.main`
  padding: 40px;
  min-height: 70vh;
  max-width: 100vw;

  @media (max-width: 375px) {
    padding: 20px;
  }
`;

const SingleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.secondary};
  font-weight: bold;
  text-transform: uppercase;
  margin: 60px 0 20px;
`;

const AdminHome = () => {
  useEffect(() => {
    document.title = 'Portal Rekrutacyjny Zabrze';
  }, []);

  const tailsCreateing = [
    {
      id: 1,
      title: 'Stwórz ogłoszenie',
      to: '/admin/create-advertisement/',
      icon: <NoteAddOutlinedIcon />,
    },
    {
      id: 1,
      title: 'Stwórz formularz',
      to: '/admin/create-form/',
      icon: <ViewListOutlinedIcon />,
    },
    {
      id: 1,
      title: 'Stwórz słownik',
      to: '/admin/dictionary/create-field/',
      icon: <ViewAgendaOutlinedIcon />,
    },
    {
      id: 1,
      title: 'Stwórz raport',
      to: '/admin/reports/',
      icon: <AssessmentOutlinedIcon />,
    },
  ];

  const tailsWatch = [
    {
      id: 1,
      title: 'Przeglądaj ogłoszenia',
      to: '/admin/job-offers/',
      icon: <ListAltOutlinedIcon />,
    },
    {
      id: 1,
      title: 'Przeglądaj formularze',
      to: '/admin/forms/',
      icon: <DnsOutlinedIcon />,
    },
    {
      id: 1,
      title: 'Przeglądaj kandydatów',
      to: '/admin/candidates',
      icon: <PeopleOutlinedIcon />,
    },
    {
      id: 1,
      title: 'Przeglądaj słowniki',
      to: '/admin/dictionary/',
      icon: <VerticalSplitOutlinedIcon />,
    },
  ];
  const tailsSettings = [
    {
      id: 1,
      title: 'Ustawienia',
      to: '/admin/settings',
      icon: <SettingsOutlinedIcon />,
    },
  ];

  return (
    <Wrapper>
      <Title>Stwórz nowe elementy</Title>
      <SingleGrid>
        {tailsCreateing.map(el => (
          <SingleHomeTail key={el.id} {...el} />
        ))}
      </SingleGrid>

      <Title>Przeglądaj </Title>
      <SingleGrid>
        {tailsWatch.map(el => (
          <SingleHomeTail key={el.id} {...el} />
        ))}
      </SingleGrid>

      <Title>Przejdź do ustawień </Title>
      <SingleGrid>
        {tailsSettings.map(el => (
          <SingleHomeTail key={el.id} {...el} />
        ))}
      </SingleGrid>
    </Wrapper>
  );
};

export default AdminHome;
