import { api } from 'API';
import Indicator from 'components/atoms/Indicator';
import ApplicationDetails from 'components/shared/ApplicationDetails/ApplicationDetails';
import PrintComponent from 'components/shared/PrintComponent';
import { TOAST_POSITION } from 'constans/constantsToast';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

const SingleApplicationPrintUser = () => {
  const { id } = useParams();

  useEffect(() => {
    document.title = 'Drukuj aplikację - Portal Rekrutacyjny Zabrze';
  }, []);

  const [advData, setAdvData] = useState();
  const getApplication = async () => {
    try {
      const { data } = await api.getSingleApplicationView(id);
      setAdvData(data);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas pobierania informacji o aplikacji.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  useEffect(() => {
    getApplication();
  }, []);

  return (
    <>
      {!advData ? (
        <Indicator />
      ) : (
        <PrintComponent
          componentToPrint={<ApplicationDetails data={advData.details} print />}
        />
      )}
    </>
  );
};

export default SingleApplicationPrintUser;
