import styled from 'styled-components';
import { Button } from 'm-web-components';
import { InputMui } from 'components/atoms/Input';
import Bg from 'assets/zabrze_bg.svg';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth};
  background: url(${Bg}) no-repeat;
  background-position: right top;
  min-height: 100vh;
  width: 100vw;

  /* zoom: ${({ zoom }) => zoom}; */

  @media (max-width: 1366px) {
    background-size: auto 50%;
  }

  @media (max-width: 768px) {
    background: none;
  }
`;

export const FieldsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;
  }

  div {
    width: 100%;
    margin-top: 10px;
  }

  div:before {
    border: none;
  }
`;

export const Form = styled.form`
  width: 50%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  @media (max-width: 1366px) {
    width: 100%;
  }
`;

export const FormWrap = styled.div`
  max-width: 480px;
  height: 100%;
  display: flex;
  flex-direction: column;

  img {
    align-self: flex-start;
    margin: 5vh 0;
  }

  a {
    color: ${({ theme }) => theme.addButtonBg};
  }

  @media (max-width: 1366px) {
    width: 100%;
  }
`;

export const ForgotPass = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  a {
    color: ${({ theme }) => theme.secondary};
  }
`;

export const SubmitButton = styled(Button)`
  background: ${({ theme }) => theme.primaryButtonBg};
  margin: 3vh 0;
  height: 42px;
  width: 100%;
  border: none;
  color: #fff;
  font-weight: 600;
`;

export const GoTo = styled.div`
  color: ${({ theme }) => theme.primary};
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1vh 0;

  a {
    color: ${({ theme }) => theme.secondary};
  }
`;

export const Title = styled.h1`
  font-size: 3.2rem;
  line-height: 40px;
  min-height: 32px;
  margin: 2vh 0;
`;

export const Description = styled.p`
  color: #666666;
  margin-bottom: 50px;
  font-size: 1.7rem;
`;

//Register form styles

export const RegisterDesc = styled(Description)`
  margin-bottom: 0;
  font-weight: 600;
`;

export const RegisterForm = styled(Form)`
  > p:first-of-type {
    margin: 0;
  }

  > div {
    width: 100%;
  }

  @media (max-width: 768px) {
    > div {
      width: 100%;
    }
  }

  //checkbox color
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.primaryButtonBg};
  }
`;

export const GoToLogin = styled(GoTo)`
  margin: 2vh 0;
`;

export const RegisterInput = styled(InputMui)`
  width: 100%;
  label {
    font-size: 1.5rem;
    transform: none;
    color: ${({ theme }) => theme.primary};
    position: relative;
    font-weight: 600;
  }

  input {
    border: 1px solid #b2b2b2;
    height: 48px;
    padding: 0 5px;
    width: 100%;
  }

  div:before {
    border: none;
  }
`;

export const RegisterInputSmall = styled(RegisterInput)`
  width: 100%;

  :nth-of-type(2) {
    justify-self: flex-end;
  }
`;

export const SmallInputsWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100px;
  grid-auto-rows: min-content;

  > div,
  label {
    width: 98%;
  }

  > label:nth-of-type(2),
  p:nth-of-type(2) {
    margin-left: 2%;
    grid-column: 2;
  }

  p {
    width: 98%;
    grid-row: 2;
  }

  @media (max-width: 768px) {
    grid-template-columns: 100%;

    > label:nth-of-type(2),
    p:nth-of-type(2) {
      margin-left: 0;
      grid-column: 1;
      grid-row: 3;
    }
    p:nth-of-type(2) {
      grid-row: 4;
    }
  }
`;

export const RegulationsButton = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.primaryButtonBg};
  text-decoration: underline;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const RedSpan = styled.span`
  color: ${({ theme }) => theme.red};
`;

export const ZoomButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 20px;
  right: 20px;

  @media (min-width: 768px) {
    top: 30px;
    right: auto;
    left: 30px;
    justify-content: flex-start;
  }
`;
