import styled from 'styled-components';

export const SingleWrapper = styled.div`
  margin: 10px 0;
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.grayDark};

  svg {
    transform: ${({ isopen }) => (isopen ? 'rotate(180deg)' : null)};
    transition: 0.3s;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  max-height: ${({ isopen }) => (isopen ? 'auto' : 0)};
  opacity: ${({ isopen }) => (isopen ? '1' : '0')};
  overflow: hidden;

  label {
    font-size: 14px;
    color: ${({ theme }) => theme.grayLight};
    font-weight: 600;
    margin: 15px 0 5px;
  }

  textarea {
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.grayLighter};
    border-radius: 2px;
  }
`;

export const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.grayLighter};
  padding: 10px;
  border-radius: 2px;
`;

export const SmallInput = styled.input`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.grayLighter};
  padding: 5px;
  border-radius: 2px;
`;

export const ButtonList = styled.button`
  border: none;
  background-color: transparent;
  align-self: flex-start;
  margin: 5px 0;
  color: ${({ theme }) => theme.secondary};
  cursor: pointer;
`;

export const ItemListContainer = styled.div`
  display: flex;
  align-items: center;
`;
