import styled, { css } from 'styled-components';
import { device } from 'styles/devices';
import { Button } from 'm-web-components';
import { InputMui } from 'components/atoms/Input';

export const BookmarkWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  padding: 30px 60px;
  border: 1px solid ${({ theme }) => theme.lighter};
  background-color: ${({ theme }) => theme.colorLighter};
  margin-top: 50px;
  min-height: 80vh;

  @media (max-width: 768px) {
    padding: 30px 10px;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: 0;
  }
`;

export const SettingsMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
  padding: 0 40px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  height: 50px;
  background: ${({ active, theme }) => (active ? theme.secondary : 'none')};
  color: ${({ active, theme }) => (active ? '#fff' : theme.grayDark)};
  border: none;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  cursor: pointer;

  svg {
    margin-right: 20px;
  }
`;
// white block sections

export const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Section = styled.div`
  background: #fff;
  border: 1px solid ${({ theme }) => theme.lighter};
  padding: 20px;
  position: relative;
  margin-bottom: 50px;
  width: 100%;
`;

export const Title = styled.h2`
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.grayDarker};
  margin-bottom: 50px;

  @media (max-width: 420px) {
    font-size: 1.6rem;
  }
`;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  background: ${({ theme, secondary }) =>
    secondary ? '#fff' : theme.secondary};
  color: ${({ theme, secondary }) => (secondary ? theme.secondary : '#fff')};
  border: none;
  font-weight: 600;
  margin-left: 20px;
  font-family: 'Open Sans', sans-serif;
`;

// forms
export const AccountDetails = styled.table`
  font-size: 1.5rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Td = styled.td`
  padding: 0 20px 0 5px;
  color: ${({ theme, bold }) => (bold ? theme.grayDark : theme.grayDarker)};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  height: 48px;

  > div {
    margin: 5px 0;
  }

  :nth-of-type(2n) {
    padding-right: 0;
  }
`;

export const EditButton = styled(Button)`
  background: none;
  color: ${({ theme }) => theme.secondary};
  top: 20px;
  right: 100px;
  margin: 0;
  border: ${({ theme }) => `1px solid ${theme.secondary}`};
  padding: 7px 10px;
  position: absolute;

  @media (max-width: 420px) {
    padding: 3px 5px;
    right: 80px;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      right: 20px;
      border-radius: 0px;
      padding: 3px;
      border: none;
      border-bottom: ${({ theme }) => `1px solid ${theme.secondary}`};
      @media (max-width: 420px) {
        right: 20px;
      }
    `}
`;

export const SubmitButton = styled(Button)`
  top: 20px;
  right: 20px;
  padding: 8px 10px;
  margin: 0;
  border: none;
  display: ${({ isDisabled }) => (isDisabled ? 'none' : 'block')};
  background: ${({ theme }) => theme.secondary};
  color: #fff;
  font-weight: 600;
  position: absolute;
  @media (max-width: 420px) {
    padding: 4px 5px;
  }
`;

export const UserDataInput = styled(InputMui)`
  .MuiInputBase-input,
  .Mui-disabled {
    height: 30px;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    color: ${({ theme }) => theme.grayDarker};
  }

  .MuiInput-underline.Mui-disabled:before,
  .MuiInput-underline:before {
    border: none;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      .MuiInputBase-input {
        height: 48px;
        padding: 0 10px;
        border: ${({ theme }) => `1px solid ${theme.grayLight}`};
        border-radius: 2px;
        @media (max-width: 768px) {
          height: 40px;
        }
      }

      .MuiInput-underline:before {
        border: none;
      }

      .MuiInput-underline:after {
        border-color: ${({ theme }) => theme.secondary};
      }

      .MuiFormLabel-root.Mui-focused {
        color: ${({ theme }) => theme.secondary};
      }
    `};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;

  background-color: white;
  padding: 20px;
  border-radius: 10px;

  > p {
    margin: 0px auto 0px 0px;
  }

  > div {
    width: 100%;
  }

  @media ${device.desktop} {
    width: 400px;
  }
`;

export const Code = styled.div`
  background: #2c81c733;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 600;
`;

export const ButtonBack = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-weight: 600;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.secondary};
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
`;

export const StyledInput = styled(InputMui)`
  width: 100%;
  label {
    font-size: 1.5rem;
    transform: none;
    color: ${({ theme }) => theme.primary};
    position: relative;
  }

  input {
    border: 1px solid #b2b2b2;
    height: 48px;
    padding: 0 5px;
    width: 100%;
  }

  .MuiInput-underline:before {
    border: none;
  }

  .MuiInput-underline:after {
    border-color: ${({ theme }) => theme.secondary};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.secondary};
  }
`;

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${({ theme }) => theme.secondary};
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

export const NotificationSubtitle = styled.label`
  color: ${({ theme }) => theme.grayDark};
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-right: 40px;
`;
