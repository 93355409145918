import React, { useEffect, useState } from 'react';
import { api } from 'API';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ControlForm, ErrorAlert } from 'm-web-components';
import { useForm } from 'react-hook-form';
import {
  Wrapper,
  RegisterForm,
  SubmitButton,
  Title,
  RegisterDesc,
  GoToLogin,
  SmallInputsWrap,
  FormWrap,
  ZoomButtons,
} from './Login.css';
import { AccessibilityLabel } from 'components/tables/Table.css';

import useAuth from 'auth/useAuth';

import { formSmallFields, formFields, formCheckboxFields } from './formFields';
import Logo from 'assets/logo-zabrze.png';

import { ReactComponent as AMinus } from 'assets/icons/A_minus.svg';
import { ReactComponent as APlus } from 'assets/icons/A_plus.svg';
import { TOAST_POSITION } from 'constans/constantsToast';

const FieldsWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;

  > div {
    margin: 2px 0;
  }
`;

const Signup = ({ changeZoom }) => {
  const [isContactModalOpen, setContactModalOpen] = useState(false);

  const auth = useSelector(state => state.auth);
  const { handleSubmit, errors, control } = useForm();
  const { signup } = useAuth();

  const [redirect, setRedirect] = useState(false);
  const [errorsMessages, setErrorsMessages] = useState({});

  useEffect(() => {
    document.title = 'Zarejestruj się - Portal Rekrutacyjny Zabrze';
  }, []);

  const onSubmit = async data => {
    try {
      const resp = await signup(data);

      if (resp === 'success') setRedirect('/login');

      let errorsM = {};
      resp?.data?.errors?.forEach(el => {
        errorsM = { ...errorsM, [el.field]: el.defaultMessage };
      });

      setErrorsMessages(errorsM);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  };

  const showRegulation = async () => {
    try {
      const { data } = await api.getPublicFile('TERMS_OF_USE_OF_THE_WEBSITE');

      const blob = new Blob([data], {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url);
    } catch (e) {
      toast.error('Wystąpił błąd podczas pobierania pliku', TOAST_POSITION);
    }
  };

  const smallFields = formSmallFields.map(el => (
    <>
      <AccessibilityLabel muiinput>
        <p> {el.name}</p>
        <ControlForm key={el.name} {...el} errors={errors} control={control} />
      </AccessibilityLabel>
      {errorsMessages[el.name]?.split('.,')?.map(msg => (
        <ErrorAlert type='simple' error={msg} maxWidth='380px' />
      ))}
    </>
  ));

  const fields = formFields.map(el => (
    <>
      <AccessibilityLabel muiinput>
        <p> {el.name}</p>
        <ControlForm key={el.name} {...el} errors={errors} control={control} />
      </AccessibilityLabel>
      {errorsMessages[el.name]?.split('.,')?.map(msg => (
        <ErrorAlert type='simple' error={msg} maxWidth='380px' />
      ))}
    </>
  ));

  const checkboxFields = formCheckboxFields(showRegulation).map(el => (
    <>
      <AccessibilityLabel muiinput htmlFor={el.id}>
        <p> {el.name}</p>
      </AccessibilityLabel>
      <ControlForm key={el.name} {...el} errors={errors} control={control} />
      {errorsMessages[el.name]?.split('.,')?.map(msg => (
        <ErrorAlert type='simple' error={msg} maxWidth='380px' />
      ))}
    </>
  ));

  if (auth.isAuthenticated) {
    return <Redirect to='/' />;
  }
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <ZoomButtons>
        <APlus
          onClick={() => changeZoom(0.1)}
          style={{ marginRight: '20', cursor: 'pointer' }}
        />
        <AMinus
          onClick={() => changeZoom(-0.1)}
          style={{ cursor: 'pointer' }}
        />
      </ZoomButtons>
      <RegisterForm>
        <FormWrap>
          <Link to='/' style={{ cursor: 'pointer' }}>
            <img src={Logo} alt='logo Zabrze' />
          </Link>
          <Title>Zarejestruj się</Title>
          <RegisterDesc>
            Załóż konto w portalu rekrutacyjnym aby móc aplikować na stanowiska.
          </RegisterDesc>
          <FieldsWrap>
            <SmallInputsWrap>{smallFields}</SmallInputsWrap>
            {fields}
            {checkboxFields}
          </FieldsWrap>
          <SubmitButton>Załóż konto</SubmitButton>
          <GoToLogin>
            Masz już konto? <Link to='/login'>Zaloguj się!</Link>
          </GoToLogin>
        </FormWrap>
      </RegisterForm>
    </Wrapper>
  );
};

export default Signup;
