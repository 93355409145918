import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { Label } from 'components/admin/AdvertisementElements/Singles/Singles.css';
import { AppNumber } from 'components/admin/SingleAdvertisement/AdvCandidates/CandidateDetails/CandidateDetails.css';
import Indicator from 'components/atoms/Indicator';
import ApplicationDetails from 'components/shared/ApplicationDetails/ApplicationDetails';
import PrintComponent from 'components/shared/PrintComponent';

import { api } from 'API';
import { TOAST_POSITION } from 'constans/constantsToast';

const Wrapper = styled.div`
  width: 100%;
`;

const SingleApplicationPrint = () => {
  const { id } = useParams();

  const [advData, setAdvData] = useState();

  useEffect(() => {
    document.title = 'Drukuj ogłoszenie - Portal Rekrutacyjny Zabrze';
  }, []);

  const getApplication = async () => {
    try {
      const { data } = await api.getSingleCandidateApplication(id);
      setAdvData(data);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas pobierania informacji o aplikacji.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  useEffect(() => {
    getApplication();
  }, []);

  return (
    <Wrapper>
      {!advData ? (
        <Indicator />
      ) : (
        <PrintComponent
          application
          title={advData.advertisementTitle}
          componentToPrint={
            <>
              <AppNumber nomargin>
                <Label
                  style={{
                    fontWeight: 700,
                    fontSize: '18px',
                  }}
                >
                  Numer aplikacji
                </Label>
                <br />
                <span>{advData.applicationNumber}</span>
              </AppNumber>
              <ApplicationDetails data={advData.details} print />
            </>
          }
        />
      )}
    </Wrapper>
  );
};

export default SingleApplicationPrint;
