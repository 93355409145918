import React, { useState } from 'react';
import styled from 'styled-components';
import { centerFlex } from 'styles/mixins';
import { Select } from 'm-web-components';
import * as S from 'components/tables/Table.css';

import { Button } from 'm-web-components';
import { AccessibilityLabel } from '../../tables/Table.css';

const ThanksMessage = styled.textarea`
  margin-top: 20px;
  height: 160px;
  resize: none;
  padding: 10px;
  width: 100%;
  border: 1px solid hsl(0, 0%, 80%);
`;

const CharCounter = styled.div`
  color: #666;
  font-weight: 600;
  width: 100%;
  text-align: right;
`;

const Buttons = styled.div`
  ${centerFlex}
  margin-top: 30px;
`;

const SendThanksForm = ({
  invitationDetails,
  userApplications,
  setFormData,
  formData,
  onSubmit,
  cancelFunc,
}) => {
  const [countChar, setCountChar] = useState(formData.content.length);

  const handleSelectChange = e => {
    setFormData(prev => ({
      ...prev,
      aId: e.value,
      advertisementName: e.label,
    }));
  };

  const handleInvText = e => {
    setFormData(prev => ({ ...prev, content: e.target.value }));
    setCountChar(e.target.value.length);
  };

  const options = userApplications?.map(el => ({
    value: el.id,
    label: el.name,
  }));

  return (
    <>
      <h4>Podziękowanie za udział w rekrutacji</h4>
      <p>
        Kandydat:
        <span>
          {invitationDetails.name} {invitationDetails.surname}
        </span>
      </p>
      <p>Stanowisko</p>
      <AccessibilityLabel>
        <p>Stanowisko</p>
        <Select
          options={options}
          value={options?.filter(obj => obj.value === formData.aId)}
          onChange={handleSelectChange}
        />
      </AccessibilityLabel>
      <AccessibilityLabel>
        {' '}
        <p>Wiadomość</p>
        <ThanksMessage
          value={formData.content}
          onChange={handleInvText}
          maxLength='1000'
        />
      </AccessibilityLabel>
      <CharCounter>Pozostało {1000 - countChar} znaków </CharCounter>

      <Buttons>
        <S.FiltersButtonToggle type='button' onClick={cancelFunc}>
          Anuluj
        </S.FiltersButtonToggle>
        <Button
          type='button'
          colorType='primary'
          onClick={onSubmit}
          padding='5px 10px'
          margin='0 0 0 20px'
          fontWeight='600'
          style={{ border: 'none' }}
        >
          Wyślij podziękowanie
        </Button>
      </Buttons>
    </>
  );
};

export default SendThanksForm;
