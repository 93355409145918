import React, { useEffect } from 'react';
import MainWrapper from 'components/atoms/MainWrapper';
import Title from 'components/atoms/Title';
import Inbox from 'components/user/Inbox/Inbox';

export const InboxPage = () => {
  useEffect(() => {
    document.title = 'Zaproszenia - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <Title>Wiadomości</Title>
      <Inbox />
    </MainWrapper>
  );
};

export default InboxPage;
