import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import allActions from 'actions';
import { api } from 'API';
import {
  candidatesFiltersTable,
  candidatesFiltersTableGeneral,
} from './candidatesFiltersTable';

import Table from 'components/tables/Table';
import Indicator from 'components/atoms/Indicator';
import { toggleSelectAllRows } from 'components/tables/helpers/checkboxesFunc';
import { candidatesType } from 'constans/constansCandidates';
import { styledTable } from 'components/tables/helpers/changeStylesBrakpoints';

import Modal from 'components/shared/RowMenuModal/Modal';
import ModalSendInvite from 'components/shared/ModalSendInvite/ModalSendInvite';
import ModalSendThanks from 'components/shared/ModalSendThanks/ModalSendThanks';
import ModalSendFulfill from 'components/shared/ModalSendFulfill/ModalSendFulfill';
import ModalChangeStatus from './ModalChangeStatus';
import HiddenMenu from 'components/shared/HiddenMenu';
import { AccessibilityLabel } from '../../tables/Table.css';

const Wrapper = styled.div`
  //change breakpoint for mobile view
  @media screen and (max-width: 1000px) {
    ${styledTable}
  }
`;

const CATEGORY = 'recruiter/';
const TYPE = 'application';

const CandidatesTable = () => {
  const dispatch = useDispatch();
  const { application: dataTable } = useSelector(
    state => state.currentPageTables
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const [invitationDetails, setInvitationDetails] = useState();
  const [userApplications, setUserApplications] = useState();
  const [isStatusModalButton, setStatusModalButton] = useState(false);

  const [menuFunctions, setMenuFunctions] = useState({
    changeStatus: false,
    delete: false,
    invite: false,
    thanks: false,
    fulfill: false,
    currentRow: null,
  });

  const cancelFunc = () => {
    setMenuFunctions({
      changeStatus: false,
      delete: false,
      invite: false,
      thanks: false,
      fulfill: false,
      currentRow: null,
    });
    setSelectedRows([]);
  };

  //for filters
  const [options, setOptions] = useState({});
  const [currentCandidatesTypes, setCurrentCandidatesTypes] = useState(
    candidatesType.SPECIFIC
  );

  const getDepartmentOptions = async () => {
    const { data } = await api.getCandidatesFiltersOptions();
    const newData = {
      departments: data.departments.map(el => ({
        label: el.name,
        value: el.id,
      })),
      advertisements: data.advertisements.map(el => ({
        label: el.name,
        value: el.id,
      })),
    };
    setOptions(newData);
  };

  const getValues = (type, newValue, category = CATEGORY) => {
    dispatch(
      allActions.currentPageTableAction.tableGetAction(type, newValue, category)
    );
  };

  useEffect(() => {
    getValues(TYPE, null, CATEGORY);
    getDepartmentOptions();
  }, []);

  const toolsButtons = [
    {
      label: 'Status',
      colorType: 'gray',
      onClick: () => {
        setMenuFunctions(prev => ({ ...prev, changeStatus: true }));
      },
      titleModal: 'Zmiana statusu',
      buttonLabelModal: 'Zmień',
    },

    {
      label: 'Usuń',
      colorType: 'gray',
      titleModal:
        'Aplikacja zostanie trwale usunięta. Nie będzie możliwości jej przywrócenia.',
      buttonLabelModal: 'Usuń',
      onClick: async () => {
        await api.deleteCandidateApplication(selectedRows.join(','));
        getValues(TYPE, null, CATEGORY);
        setSelectedRows([]);
      },
    },
  ];

  const columns = [
    {
      component: (
        <>
          <AccessibilityLabel>
            <p>Zaznacz</p>
            <input
              id='candCheckbox'
              type='checkbox'
              onChange={() =>
                toggleSelectAllRows({
                  selectedRows,
                  dataTable,
                  setSelectedRows,
                })
              }
              checked={selectedRows.length === dataTable?.length}
            />
          </AccessibilityLabel>
        </>
      ),
      fieldName: 'checkAll',
      draggable: false,
    },
    {
      title: 'Kandydat',
      fieldName: 'userName',
      draggable: true,
    },
    {
      title: 'Data aplikacji',
      fieldName: 'date',
      sorting: true,
      draggable: true,
    },
    {
      title: 'Telefon kontaktowy',
      fieldName: 'phone',
      draggable: true,
    },
    {
      title: 'Adres E-mail',
      fieldName: 'email',
      draggable: true,
    },
    { title: 'Status', fieldName: 'status', draggable: true },
    { title: <HiddenMenu />, fieldName: 'menu', draggable: false },
  ];

  const changeCandidatesType = appType => {
    getValues(TYPE, { appType }, CATEGORY);
    setCurrentCandidatesTypes(appType);
    setSelectedRows([]);
  };

  const horizontalMenu = {
    curentType: currentCandidatesTypes,
    setCurrentType: changeCandidatesType,
    horizontalMenuButtons: [
      { type: candidatesType.SPECIFIC, label: 'Aplikacje na stanowiska' },
      { type: candidatesType.GENERAL, label: 'Aplikacje ogólne' },
    ],
  };

  return (
    <Wrapper>
      {!dataTable ? (
        <Indicator />
      ) : (
        <Table
          type={TYPE}
          category={CATEGORY}
          columns={columns}
          rows={dataTable}
          horizontalMenu={horizontalMenu}
          getValues={getValues}
          searchPlaceholder='Szukaj'
          filtersTable={
            currentCandidatesTypes === candidatesType.GENERAL
              ? candidatesFiltersTableGeneral
              : candidatesFiltersTable(options)
          }
          tableToolData={{
            countPosition: selectedRows.length,
            buttons: toolsButtons,
            isStatusModalButton: { isStatusModalButton },
          }}
          data={dataTable}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setInvitationDetails={setInvitationDetails}
          setUserApplications={setUserApplications}
          setStatusModalButton={setStatusModalButton}
          menuFunctions={menuFunctions}
          setMenuFunctions={setMenuFunctions}
          isCandidates={true}
        />
      )}

      {menuFunctions.fulfill && (
        <Modal open={menuFunctions.fulfill} cancelFunc={cancelFunc}>
          <ModalSendFulfill
            cancelFunc={cancelFunc}
            invitationDetails={invitationDetails}
            userApplications={userApplications}
            getValues={getValues}
            type={TYPE}
            category={CATEGORY}
          />
        </Modal>
      )}

      {menuFunctions.invite && (
        <Modal open={menuFunctions.invite} cancelFunc={cancelFunc}>
          <ModalSendInvite
            cancelFunc={cancelFunc}
            invitationDetails={invitationDetails}
            userApplications={userApplications}
            getValues={getValues}
            type={TYPE}
            category={CATEGORY}
          />
        </Modal>
      )}
      {menuFunctions.thanks && (
        <Modal open={menuFunctions.thanks} cancelFunc={cancelFunc}>
          <ModalSendThanks
            cancelFunc={cancelFunc}
            invitationDetails={invitationDetails}
            userApplications={userApplications}
            getValues={getValues}
            type={TYPE}
            category={CATEGORY}
          />
        </Modal>
      )}

      {menuFunctions.changeStatus && (
        <Modal open={menuFunctions.changeStatus} cancelFunc={cancelFunc}>
          <ModalChangeStatus
            closeFunc={cancelFunc}
            selectedRows={selectedRows}
            getValues={getValues}
            type={TYPE}
            category={CATEGORY}
            setSelectedRows={setSelectedRows}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default CandidatesTable;
