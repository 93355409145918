import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

import * as S from './Singles.css';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorAlert } from 'm-web-components';
import { formatDate } from 'helpers/newDateFormat';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl);

const TextFiledStyled = styled(TextField)`
  input {
    padding: 10px 15px;
    background-color: #fff;
  }

  input:disabled {
    border: none;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px;
    background-color: #fff;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgb(242, 242, 242);
  }
`;

const SingleDate = ({
  data,
  errors,
  index,
  defaultValues,
  control,
  basicNameForm,
  ignoreRequired,
  changeValue,
  sectionIndex,
  dictIndex,
  value, //for forms application
}) => {
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (value?.content) setStartDate(new Date(value.content));

    if (defaultValues) {
      if (defaultValues.content.content)
        setStartDate(new Date(defaultValues.content.content));
      if (control) {
        control.setValue(
          `${basicNameForm}.elements[${index}].content.content`,
          defaultValues.content.content
        );
      }
    }
  }, []);

  const setDate = date => {
    setStartDate(date);

    if (control) {
      control.setValue(
        `${basicNameForm}.elements[${index}].content.content`,
        formatDate(date)
      );
    }

    if (changeValue) {
      changeValue(
        { content: formatDate(date) },
        sectionIndex,
        dictIndex,
        index
      );
    }
  };

  return (
    <S.SingleWrapper>
      <S.Label>
        {data.label} {data.isRequired && <span>*</span>}
      </S.Label>

      {control ? (
        <>
          <Controller
            name={`${basicNameForm}.elements[${index}].content.content`}
            control={control}
            rules={
              !ignoreRequired && data.isRequired
                ? { required: 'To pole jest wymagane' }
                : null
            }
            defaultValue={startDate}
            render={() => (
              <ReactDatePicker
                locale='pl'
                dateFormat='yyyy-MM-dd'
                placeholderText='2021-01-01'
                selected={startDate || null}
                onChange={date => setDate(date)}
                customInput={<TextFiledStyled variant='outlined' />}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${basicNameForm}.elements[${index}].content.content`}
            render={({ message }) => (
              <ErrorAlert error={message} maxWidth='200px' type='simple' />
            )}
          />
        </>
      ) : (
        <ReactDatePicker
          locale='pl'
          dateFormat='yyyy-MM-dd'
          placeholderText='2021-01-01'
          selected={startDate || null}
          onChange={date => setDate(date)}
          required={ignoreRequired ? false : data.isRequired}
          customInput={<TextFiledStyled variant='outlined' />}
        />
      )}
    </S.SingleWrapper>
  );
};

export default SingleDate;
