import React, { useState } from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Controller } from 'react-hook-form';

import * as S from './SingleFormData.css';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorAlert } from 'm-web-components';
import Hint from 'components/shared/Hint';

const SingleTextAnswer = ({ errors, control, index, id, defaultValue }) => {
  const [isOpen, setOpen] = useState(true);
  return (
    <S.SingleWrapper>
      <Hint hint='Naciśnij by zwinąć/rozwinąć'>
        <S.Title isopen={isOpen} onClick={() => setOpen(prev => !prev)}>
          {id === 'SHORT_ANSWER' && 'Krótka odpowiedź'}
          {id === 'FILE' && 'Załącznik'}
          {id === 'LONG_ANSWER' && 'Długa odpowiedź'}
          {id === 'DATE' && 'Data'}
          <ArrowDropUpIcon />
        </S.Title>
      </Hint>
      <S.Content isopen={isOpen}>
        <Controller
          id={id}
          name={`elements[${index}].dataFormat`}
          control={control}
          defaultValue={id}
        />

        <label>Etykieta *</label>
        <Controller
          id={id}
          name={`elements[${index}].content.label`}
          control={control}
          defaultValue={defaultValue?.label}
          render={props => (
            <S.Input
              aria-label='empty input'
              placeholder='Stanowisko'
              rowsMin={3}
              defaultValue={defaultValue?.label}
              onChange={e => props.onChange(e.target.value)}
            />
          )}
        />

        <label>Tekst pomocniczy</label>
        <Controller
          id={id}
          name={`elements[${index}].content.placeholder`}
          control={control}
          defaultValue={defaultValue?.placeholder}
          render={props => (
            <S.Input
              aria-label='empty textarea'
              placeholder='np. Instruktor jazdy'
              rowsMin={3}
              defaultValue={defaultValue?.placeholder}
              onChange={e => props.onChange(e.target.value)}
            />
          )}
        />

        <Controller
          name={`elements[${index}].content.isRequired`}
          control={control}
          id={id}
          defaultValue={defaultValue?.isRequired || false}
          render={props => {
            const value = props.value === 'true' || props.value === true;
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={e => props.onChange(e.target.checked)}
                    color='primary'
                  />
                }
                label='Pole obowiązkowe'
              />
            );
          }}
        />
      </S.Content>
    </S.SingleWrapper>
  );
};

export default SingleTextAnswer;
