import { statusOptions } from 'constans/constansAdv';

export const AdvCandidatesFiltersTable = [
  {
    filterType: 'selectMulti',
    nameFilter: 'appStatus',
    nameTable: 'advertisementCandidates',
    label: 'Status',
    placeholder: 'Wszystkie ogłoszenia',
    options: statusOptions,
  },
  {
    filterType: 'dataPicker',
    nameFilter: 'c',
    nameTable: 'advertisementCandidates',
    label: 'Data przesłania aplikacji',
  },
];
