import React, { useState } from 'react';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import SingleSubSection from './SingleSubSection';

import * as S from '../CreateAdvertisement.css';
import { Draggable } from 'react-beautiful-dnd';
import { getItemStyle } from '../dndFunctions';
import Hint from 'components/shared/Hint';

const SingleSection = ({
  title,
  dictionaries,
  index: sectionIndex,
  deleteSubSection,
  advId,
}) => {
  const [isSubsectionsVisible, setSubsectionsVisible] = useState(true);
  const subSectionsRender = dictionaries?.map((item, index) => (
    <Draggable
      key={`${sectionIndex}-${index}`}
      draggableId={`${sectionIndex}-${index}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <SingleSubSection
            id={`${sectionIndex}-${index}`}
            index={index}
            sectionIndex={sectionIndex}
            data={item}
            deleteSubSection={deleteSubSection}
            advId={advId}
          />
        </div>
      )}
    </Draggable>
  ));

  return (
    <S.WrapperSingleSection role='Dustbin'>
      <S.TitleSection>
        <S.InputWrapper issubionsvisible={isSubsectionsVisible}>
          <Hint hint='Naciśnij strzałkę by zwinąć/rozwinąć' top>
            <button type='button'>
              <ArrowDropDownOutlinedIcon
                onClick={() => setSubsectionsVisible(!isSubsectionsVisible)}
              />
            </button>
          </Hint>
          <h3>{title}</h3>
        </S.InputWrapper>
      </S.TitleSection>
      {isSubsectionsVisible && subSectionsRender}
    </S.WrapperSingleSection>
  );
};

export default SingleSection;
