import React from 'react';
import * as S from './CandidateDetails.css';
import OtherApplicationsTable from './OtherApplications/OtherApplicationsTable';

const OtherApplications = ({ uId, setActiveBookmark, isModalOpen }) => {
  return (
    <S.CandidateMainSection>
      <OtherApplicationsTable
        uId={uId}
        setActiveBookmark={setActiveBookmark}
        isModalOpen={isModalOpen}
      />
    </S.CandidateMainSection>
  );
};

export default OtherApplications;
