import SingleChoice from './SingleChoice';
import SingleLongAnswer from './SingleLongAnswer';
import SingleSelect from './SingleSelect';
import SingleShortAnswer from './SingleShortAnswer';
import SingleDate from './SingleDate';
import SingleDateFromTo from './SingleDateFromTo';
import SingleFile from './SingleFile';

const Singles = {
  SingleChoice,
  SingleLongAnswer,
  SingleSelect,
  SingleShortAnswer,
  SingleDate,
  SingleDateFromTo,
  SingleFile,
};

export default Singles;
