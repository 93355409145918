import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import styled from 'styled-components';

import theme from 'styles/theme';
import { invitationStatus } from 'constans/constansAdv';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

const SubStatus = styled.span`
  align-self: flex-start;
  border: 1px solid black;
  border-radius: 2px;
  padding: 1px 10px;
  margin: 2px 0;
`;

const SubStatusBlue = styled(SubStatus)`
  border: 1px solid #2e9ad3;
  color: #2e9ad3;
`;

const SubStatusGreen = styled(SubStatus)`
  border: 1px solid #459937;
  color: #459937;
`;

const AdvInvitationsTableRow = ({ row, cols, dragOver }) => {
  const cells = Object.entries(row).map(([, v], idx) => {
    return (
      <Td key={v.name || v} dragOver={cols[idx] === dragOver}>
        {cols[idx]?.fieldName === 'invitationStatus' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              color: theme[row.invitationStatus],
            }}
          >
            {row.invitationStatus === 'CONFIRM' && (
              <SubStatusGreen>
                {invitationStatus[row.invitationStatus]}
              </SubStatusGreen>
            )}
            {row.invitationStatus === 'PENDING' && (
              <SubStatusBlue>
                {invitationStatus[row.invitationStatus]}
              </SubStatusBlue>
            )}
            {row.invitationStatus === 'REJECT' && (
              <SubStatus>{invitationStatus[row.invitationStatus]}</SubStatus>
            )}
          </div>
        )}
        {cols[idx]?.fieldName === 'candidateDetails' && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: theme.secondary, fontWeight: '600' }}>
              {row[cols[idx]?.fieldName]}
            </span>
            <span
              style={{
                color: theme.grayLight,
                fontWeight: '600',
                marginTop: '5px',
              }}
            >
              <BusinessCenterIcon style={{ marginRight: '5px' }} />{' '}
              {row.advertisementName}
            </span>
          </div>
        )}

        {cols[idx]?.fieldName === 'sendBy' && (
          <span style={{ color: theme.grayDark, fontWeight: '600' }}>
            {row[cols[idx]?.fieldName]}
          </span>
        )}

        {cols[idx]?.fieldName !== 'sendBy' &&
          cols[idx]?.fieldName !== 'advertisementName' &&
          cols[idx]?.fieldName !== 'invitationStatus' &&
          cols[idx]?.fieldName !== 'candidateDetails' &&
          row[cols[idx]?.fieldName]}
      </Td>
    );
  });

  return <Tr>{cells}</Tr>;
};

export default AdvInvitationsTableRow;
