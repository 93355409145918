import React, { useState } from 'react';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import SingleSubSection from './SingleSubSection';

import * as S from '../../admin/CreateAdverisement/CreateAdvertisement.css';

const SingleSection = ({ title, dictionaries, advId, print }) => {
  const [isSubsectionsVisible, setSubsectionsVisible] = useState(true);

  const subSectionsRender = dictionaries?.map((item, index) => (
    <SingleSubSection
      id={`${item.id}-${index}`}
      data={item}
      advId={advId}
      print={print}
    />
  ));

  return (
    <S.WrapperSingleSection role='Dustbin'>
      {!print && (
        <S.TitleSection>
          <S.InputWrapper issubionsvisible={isSubsectionsVisible}>
            <button
              onClick={() => setSubsectionsVisible(!isSubsectionsVisible)}
              type='button'
            >
              <ArrowDropDownOutlinedIcon aria-hidden='false'>
                <title>Rozwiń</title>
              </ArrowDropDownOutlinedIcon>
              <span style={{ display: 'none' }}>Rozwiń</span>
            </button>
            <h2>{title}</h2>
          </S.InputWrapper>
        </S.TitleSection>
      )}
      {isSubsectionsVisible && subSectionsRender}
    </S.WrapperSingleSection>
  );
};

export default SingleSection;
