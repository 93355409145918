import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { EditButton, CancelButton, SaveButton } from 'components/atoms/Buttons';
import * as S from '../Administration.css';
import { api } from 'API';
import styled from 'styled-components';
import { TOAST_POSITION } from 'constans/constantsToast';

const CharCounter = styled.div`
  color: #666;
  width: 100%;
  text-align: left;
  font-weight: 600;
`;

const EditableSection = ({ title, type, defaultValues }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [value, setValue] = useState(defaultValues);
  const [countChar, setCountChar] = useState(value.length);

  const toggleEdit = () => setIsEditOpen(prev => !prev);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const { data } = await api.putDefaultMessage(type.type, {
        content: value,
      });
      setValue(data?.content);
      toast.success('Dane zostały zaktualizowane.', TOAST_POSITION);
    } catch (err) {
      setValue(defaultValues);
      toast.error(
        <div>
          <p>Wystąpił błąd podczas zapisywania danych.</p>
          {err.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    } finally {
      toggleEdit();
    }
  };

  const handleChangeText = e => {
    setValue(e.target.value);
    setCountChar(e.target.value.length);
  };

  const handleCancel = () => {
    setValue(defaultValues);
    toggleEdit();
  };

  return (
    <S.EditableSectionWrapper>
      <S.EditableSectionHeader>
        <S.EditableSectionTitle htmlFor={title}>{title}</S.EditableSectionTitle>
        <EditButton onClick={toggleEdit} isVisible={!isEditOpen} right>
          Edytuj
        </EditButton>
      </S.EditableSectionHeader>
      <form onSubmit={handleSubmit}>
        <S.TextArea
          id={title}
          value={value}
          onChange={handleChangeText}
          disabled={!isEditOpen}
          maxLength={1000}
        />
        <S.EditableSectionFooter>
          {isEditOpen && (
            <CharCounter>Pozostało {1000 - countChar} znaków </CharCounter>
          )}
          <CancelButton
            type='button'
            onClick={handleCancel}
            isVisible={isEditOpen}
            right
          >
            Anuluj
          </CancelButton>
          <SaveButton
            type='submit'
            isVisible={isEditOpen}
            margin='auto 0px auto 10px'
          >
            Zapisz
          </SaveButton>
        </S.EditableSectionFooter>
      </form>
    </S.EditableSectionWrapper>
  );
};

export default EditableSection;
