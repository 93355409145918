import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import MainWrapper from 'components/atoms/MainWrapper';
import ContainerWithBookmarks from 'components/shared/ContainerWithBookmarks';
import { api } from 'API';

import { singleAdvBookmarks } from 'constans/constansAdv';
import AdvDesc from 'components/admin/SingleAdvertisement/AdvDesc';
import AdvInvitations from 'components/admin/SingleAdvertisement/AdvInvitations/AdvInvitations';
import AdvCandidatesTable from 'components/admin/SingleAdvertisement/AdvCandidates/AdvCandidatesTable';
import Indicator from 'components/atoms/Indicator';

const SingleAdvertisementPage = ({ location }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { id } = useParams();
  const [activeBookmark, setActiveBookmark] = useState(
    location?.state?.activeBookmark || singleAdvBookmarks.desc.name
  );

  const [advData, setAdvData] = useState();

  const getAdvertisement = async () => {
    const { data } = await api.getSingleAdvertisementDetails(id);
    setAdvData(data);
  };

  useEffect(() => {
    document.title = advData?.title + ' - Portal Rekrutacyjny Zabrze';
  }, [advData]);

  useEffect(() => {
    getAdvertisement();
  }, [id]);

  useEffect(() => {
    getAdvertisement();
  }, [isModalOpen]);

  const horizontalMenu = {
    curentType: activeBookmark,
    setCurrentType: setActiveBookmark,
    horizontalMenuButtons: [
      {
        type: singleAdvBookmarks.desc.name,
        label: singleAdvBookmarks.desc.label,
      },
      {
        type: singleAdvBookmarks.candidates.name,
        label: singleAdvBookmarks.candidates.label,
      },
      {
        type: singleAdvBookmarks.invitations.name,
        label: singleAdvBookmarks.invitations.label,
      },
    ],
  };

  const activeSection = () => {
    switch (activeBookmark) {
      case singleAdvBookmarks.desc.name:
        return (
          <AdvDesc
            id={id}
            advData={advData}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
          />
        );

      case singleAdvBookmarks.candidates.name:
        return (
          <AdvCandidatesTable
            id={id}
            advData={advData}
            appId={location?.state?.appId}
            setActiveBookmark={setActiveBookmark}
          />
        );

      case singleAdvBookmarks.invitations.name:
        return <AdvInvitations id={id} />;

      default:
        break;
    }
  };

  return (
    <MainWrapper>
      {!advData ? (
        <Indicator />
      ) : (
        <ContainerWithBookmarks
          title='Ogłoszenia'
          subtitle={advData?.title}
          horizontalMenu={horizontalMenu}
        >
          {activeSection()}
        </ContainerWithBookmarks>
      )}
    </MainWrapper>
  );
};

export default SingleAdvertisementPage;
