import MainWrapper from 'components/atoms/MainWrapper';
import React, { useEffect } from 'react';
import MainSection from 'components/user/UserHome/MainSection';
import TextSection from 'components/user/UserHome/TextSection';

const UserHome = () => {
  useEffect(() => {
    document.title = 'Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <MainSection />
      <TextSection />
    </MainWrapper>
  );
};

export default UserHome;
