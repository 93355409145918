import React from 'react';
import styled from 'styled-components';
import HomeMenuLayout from 'components/guest/HomeMenuLayout';

const ContentWrapper = styled.div`
  padding: 30px auto;
  width: 100%;
`;

const ContactForm = () => {
  return (
    <HomeMenuLayout>
      <ContentWrapper>Formularz kontaktowy</ContentWrapper>
    </HomeMenuLayout>
  );
};

export default ContactForm;
