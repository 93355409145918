import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';
import { ControlForm } from 'm-web-components';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Wrapper,
  Form,
  SubmitButton,
  FieldsWrap,
  GoTo,
  Title,
  Description,
  FormWrap,
  ZoomButtons,
} from './Login.css';

import { InputMui } from 'components/atoms/Input';
import { api } from 'API';

import Logo from 'assets/logo-zabrze.png';
import { AccessibilityLabel } from '../../components/tables/Table.css';
import { TOAST_POSITION } from 'constans/constantsToast';

import { ReactComponent as AMinus } from 'assets/icons/A_minus.svg';
import { ReactComponent as APlus } from 'assets/icons/A_plus.svg';

const LoginInput = styled(InputMui)`
  label {
    font-size: 1.5rem;
    transform: none;
    color: ${({ theme }) => theme.primary};
    position: relative;
    font-weight: 600;
  }

  input {
    border: 1px solid #b2b2b2;
    height: 48px;
    padding: 0 5px;
    width: 100%;
  }
`;

const ForgotPassword = ({ changeZoom }) => {
  const { handleSubmit, errors, control } = useForm();
  const [redirect, setRedirect] = useState('');
  const [isContactModalOpen, setContactModalOpen] = useState(false);

  useEffect(() => {
    document.title = 'Przypomnij hasło - Portal Rekrutacyjny Zabrze';
  }, []);

  const formFields = [
    {
      as: (
        <LoginInput
          InputLabelProps={{
            shrink: true,
          }}
        />
      ),
      name: 'email',
      label: 'E-mail',
      rules: { required: 'To pole jest wymagane' },
      type: 'email',
    },
  ];

  const onSubmit = async data => {
    try {
      const resp = await api.remindPassword(data);
      if (resp.status === 200) {
        setRedirect('/forgotPasswordSuccess');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      toast.error(
        'Podany adres e-mail nie istnieje w naszej bazie danych. Sprawdź poprawność wpisanego adresu lub załóż konto.',
        TOAST_POSITION
      );
    }
  };

  const fields = formFields.map(el => (
    <AccessibilityLabel muiinput>
      <p>{el.name}</p>
      <ControlForm key={el.name} {...el} errors={errors} control={control} />
    </AccessibilityLabel>
  ));

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <ZoomButtons>
        <APlus
          onClick={() => changeZoom(0.1)}
          style={{ marginRight: '20', cursor: 'pointer' }}
        />
        <AMinus
          onClick={() => changeZoom(-0.1)}
          style={{ cursor: 'pointer' }}
        />
      </ZoomButtons>
      <Form>
        <FormWrap>
          <Link to='/' style={{ cursor: 'pointer' }}>
            <img src={Logo} alt='logo Zabrze' />
          </Link>
          <Title>Zapomniałeś hasła?</Title>
          <Description>
            Podaj adres e-mail powiązany z Twoim kontem. W ciągu paru minut
            dostaniesz nowe hasło.
          </Description>
          <FieldsWrap>
            {fields}
            <SubmitButton>Wyślij</SubmitButton>
          </FieldsWrap>
          <GoTo>
            Rozwiązałeś problem z hasłem? <Link to='/login'>Zaloguj się.</Link>
          </GoTo>
        </FormWrap>
      </Form>
    </Wrapper>
  );
};

export default ForgotPassword;
