import React, { useEffect, useState } from 'react';
import { Fade as Hamburger } from 'hamburger-react';

import { menuItemsListAdmin, menuItemsListUser } from './menuItemList';
import ImgLogo from 'assets/logo-zabrze.png';
import { ReactComponent as ImgMail } from 'assets/icons/mail.svg';

import * as S from './Header.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';
import useWindowSize from 'hooks/useWindowSize';

import { device } from 'constans/pixelConstans';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as AMinus } from 'assets/icons/A_minus.svg';
import { ReactComponent as APlus } from 'assets/icons/A_plus.svg';
import { SaveButton } from 'components/atoms/Buttons';
import { role } from 'auth/constans';
import allActions from 'actions';
import { routes } from 'routes';

const Header = ({ changeZoom }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState();
  const [isOpen, setOpen] = useState(false);
  const [countNotif, setCountNotif] = useState(0);
  const [width] = useWindowSize();
  const { user } = useSelector(state => state.auth);
  const notifications = useSelector(state => state.notifications);
  const location = useLocation();

  useEffect(() => {
    if (user?.role === role.admin) setItems(menuItemsListAdmin);
    if (user?.role === role.recruiter) setItems(menuItemsListAdmin);
    if (user?.role === role.user) {
      setItems(menuItemsListUser);
      dispatch(allActions.notificationsActions.getNotifications());
    }
  }, [user]);

  useEffect(() => {
    setCountNotif(
      notifications?.filter(el => el.status.invitationStatus === 'PENDING')
        .length
    );
  }, [notifications]);

  useEffect(() => {
    if (user?.role === role.user) {
      dispatch(allActions.notificationsActions.getNotifications());
    }
  }, [location]);

  const menuItems = items?.map(item => (
    <S.NavItem key={item.to}>
      <NavLink to={item.to} exact>
        {item.label}
      </NavLink>
    </S.NavItem>
  ));

  if (
    location.pathname === '/login' ||
    location.pathname === routes.signup ||
    location.pathname === routes.forgotpassword ||
    location.pathname === routes.forgotpasswordsuccess
  )
    return <></>;

  return (
    <S.Header>
      <Link to='/' style={{ cursor: 'pointer' }}>
        <S.Logo src={ImgLogo} alt='logo zabrze' />
      </Link>

      {user ? (
        <>
          <S.Nav isopen={isOpen}>
            <S.NavList>{menuItems}</S.NavList>
          </S.Nav>

          <S.Aside public>
            {user.role === role.user && (
              <div style={{ display: 'flex' }}>
                <APlus onClick={() => changeZoom(0.1)} />
                <AMinus onClick={() => changeZoom(-0.1)} />
              </div>
            )}
            <S.DataWrapper>
              {user.role === role.user && (
                <S.Mail>
                  <Link to='/user/inbox'>
                    <ImgMail />
                    <span>{countNotif}</span>
                  </Link>
                </S.Mail>
              )}
              <S.ProfileInfo>
                <h3>
                  {width < device.desktopL && user
                    ? user?.name?.substr(0, 1) + user?.surname?.substr(0, 1)
                    : user?.name + ' ' + user?.surname}
                </h3>
                <HeaderMenu />
              </S.ProfileInfo>
              {width < device.desktop && (
                <Hamburger
                  toggled={isOpen}
                  toggle={setOpen}
                  color={isOpen ? '#fff' : '#2C81C7'}
                />
              )}
            </S.DataWrapper>
          </S.Aside>
        </>
      ) : (
        <>
          <S.Nav isopen={isOpen}></S.Nav>
          <S.Aside public>
            <div style={{ display: 'flex' }}>
              <APlus onClick={() => changeZoom(0.1)} />
              <AMinus onClick={() => changeZoom(-0.1)} />
            </div>
            <Link to='/login' style={{ textDecoration: 'none' }}>
              <SaveButton isVisible={true} padding='7px 10px'>
                Zaloguj się
              </SaveButton>
            </Link>
          </S.Aside>
        </>
      )}
    </S.Header>
  );
};

export default Header;
