import React from 'react';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export const statusName = {
  ACTIVE: {
    label: 'Aktywne',
    value: 'ACTIVE',
  },
  ARCHIVAL: {
    label: 'Archiwalne',
    value: 'ARCHIVAL',
  },
};

export const menuItems = [
  {
    icon: (
      <VisibilityOutlinedIcon aria-hidden='false'>
        <title>Zobacz szczegóły</title>
      </VisibilityOutlinedIcon>
    ),
    label: 'Zobacz szczegóły',
    notVisible: [],
    to: '/user/single-job-offers',
  },
  {
    icon: (
      <MailOutlineIcon aria-hidden='false'>
        <title>Aplikuj</title>
      </MailOutlineIcon>
    ),
    label: 'Aplikuj',
    notVisible: ['ARCHIVAL'],
    to: '/user/application-form',
  },
  {
    icon: (
      <PrintOutlinedIcon aria-hidden='false'>
        <title>Drukuj ogłoszenie</title>
      </PrintOutlinedIcon>
    ),
    label: 'Drukuj ogłoszenie',
    notVisible: [],
    to: '/print-adv',
  },
];
