import React from 'react';
import { useSelector } from 'react-redux';

import RadioFilter from './Singles/RadioFilter';
import ListFilter from './Singles/ListFilter';
import DataPickerFilter from './Singles/DataPickerFilter';
import SelectFilter from './Singles/SelectFilter';
import SelectMultiFilter from './Singles/SelectMultiFilter';

import { AccessibilityLabel } from '../Table.css';

const SingleFilter = ({
  filterType,
  nameFilter,
  nameTable,
  label,
  placeholder,

  // for list of checkboxes
  checkboxes = [],

  //for radio buttons
  radios = [],

  //for select list
  options = [],
}) => {
  const allFilters = useSelector(state => state.filtersTable[nameTable]);
  switch (filterType) {
    case 'dataPicker': {
      return (
        <>
          <DataPickerFilter
            prevStartDate={allFilters[`${nameFilter}From`]}
            prevEndDate={allFilters[`${nameFilter}To`]}
            label={label}
            nameFilter={nameFilter}
            nameTable={nameTable}
          />
        </>
      );
    }

    case 'list':
      return (
        <AccessibilityLabel>
          <p>{label}</p>
          <ListFilter
            prevArray={allFilters[nameFilter]}
            checkboxes={checkboxes}
            label={label}
            nameFilter={nameFilter}
            nameTable={nameTable}
          />
        </AccessibilityLabel>
      );

    case 'radio':
      return (
        <AccessibilityLabel>
          <p>{label}</p>
          <RadioFilter
            radios={radios}
            label={label}
            currentValue={allFilters[nameFilter]}
            nameFilter={nameFilter}
            nameTable={nameTable}
          />
        </AccessibilityLabel>
      );

    case 'select':
      return (
        <AccessibilityLabel>
          <p>{label}</p>
          <SelectFilter
            options={options}
            label={label}
            currentValue={allFilters[nameFilter]}
            nameFilter={nameFilter}
            nameTable={nameTable}
            placeholder={placeholder}
          />
        </AccessibilityLabel>
      );

    case 'selectMulti':
      return (
        <AccessibilityLabel>
          <p>{label}</p>
          <SelectMultiFilter
            options={options}
            label={label}
            currentValue={allFilters[nameFilter]}
            nameFilter={nameFilter}
            nameTable={nameTable}
            placeholder={placeholder}
          />
        </AccessibilityLabel>
      );

    default:
      break;
  }
};

export default SingleFilter;
