import { api } from 'API';

export const SET_NOTIFICATIONS = 'SET_NOTYFICATIONS';

const setNotifications = item => ({
  type: SET_NOTIFICATIONS,
  item,
});

const getNotifications = () => async dispatch => {
  try {
    const { data } = await api.getAllCandidateInvites();
    dispatch(setNotifications(data));
  } catch (e) {
    console.log(e);
  }
};

const actions = {
  setNotifications,
  getNotifications,
};

export default actions;
