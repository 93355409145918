import React from 'react';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CancelIcon from '@material-ui/icons/Cancel';

export const menuItems = [
  {
    icon: (
      <VisibilityOutlinedIcon aria-hidden='false'>
        <title>Zobacz formularz</title>
      </VisibilityOutlinedIcon>
    ),
    label: 'Zobacz formularz',
  },
  {
    icon: (
      <CancelIcon aria-hidden='false'>
        <title>Usuń formularz</title>
      </CancelIcon>
    ),
    label: 'Usuń formularz',
  },
];
