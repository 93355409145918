import React from 'react';

import * as S from './FormsElements.css';
import Singles from './Singles';

const FormSubsection = ({ formData, ignoreRequired }) => {
  const elementsRender = formData?.elements?.map((el, i) => {
    const props = {
      ignoreRequired,
      data: el.content,
      key: `${el.dataFormat}-${i}`,
      readOnly: true,
    };
    switch (el.dataFormat) {
      case 'SINGLE_CHOICE':
        return <Singles.SingleChoice {...props} />;

      case 'SHORT_ANSWER':
        return <Singles.SingleShortAnswer {...props} />;

      case 'LONG_ANSWER':
        return <Singles.SingleLongAnswer {...props} />;

      case 'LIST':
        return <Singles.SingleSelect {...props} />;

      case 'MULTIPLE_CHOICE':
        return <Singles.SingleSelect {...props} />;

      case 'DATE':
        return <Singles.SingleDate {...props} />;

      case 'DATE_FROM_TO':
        return <Singles.SingleDateFromTo {...props} />;

      case 'FILE':
        return <Singles.SingleFile {...props} />;

      default:
        return <div>coś poszlo nie tak ...</div>;
    }
  });

  return (
    <S.SubsectionWrapper>
      <h3>{formData.title}</h3>
      {elementsRender}
    </S.SubsectionWrapper>
  );
};

export default FormSubsection;
