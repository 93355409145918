import React, { useState } from 'react';

import Title from 'components/atoms/Title';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  SectionWrap,
  Section,
  StyledButton,
  ButtonsWrap,
  Form,
  StyledInput,
  ButtonBack,
} from 'components/shared/AccountSettings/Settings.css';
import { AccessibilityLabel } from 'components/tables/Table.css';
import { useForm } from 'react-hook-form';
import { ControlForm, ErrorAlert } from 'm-web-components';

import { api } from 'API';
import { toast } from 'react-toastify';
import { routes } from 'routes';
import { useHistory } from 'react-router';
import { TOAST_POSITION } from 'constans/constantsToast';

const ChangePassword = ({ setPrivacyOption }) => {
  const [errorsMessages, setErrorsMessages] = useState({});
  const { handleSubmit, errors, control } = useForm();
  const history = useHistory();

  const formFields = [
    {
      as: (
        <StyledInput
          aria-label='Stare hasło'
          InputLabelProps={{
            shrink: true,
          }}
          autoFocus
        />
      ),
      name: 'oldPassword',
      label: 'Stare hasło',
      rules: { required: 'To pole jest wymagane' },
      type: 'password',
    },
    {
      as: (
        <StyledInput
          aria-label='Nowe hasło'
          InputLabelProps={{
            shrink: true,
          }}
        />
      ),
      name: 'password',
      label: 'Nowe hasło',
      rules: { required: 'To pole jest wymagane' },
      type: 'password',
    },

    {
      as: (
        <StyledInput
          aria-label='Powtórz nowe hasło'
          InputLabelProps={{
            shrink: true,
          }}
        />
      ),
      name: 'repeatPassword',
      label: 'Powtórz nowe hasło',
      rules: { required: 'To pole jest wymagane' },
      type: 'password',
    },
  ];

  const changePassFunc = async data => {
    try {
      await api.putAccountPassword(data);
      return 'success';
    } catch (e) {
      if (!e?.data?.errors) {
        toast.error(
          'Coś poszło nie tak, spróbuj ponownie lub skontaktuj się z administratorem',
          TOAST_POSITION
        );
      }
      return e?.response;
    }
  };

  const onSubmit = async data => {
    try {
      const resp = await changePassFunc(data);
      if (resp === 'success') {
        toast.success('Udało się zmienić hasło.', TOAST_POSITION);
        return history.push(routes.user.home);
      }

      let errorsM = {};
      resp?.data?.errors?.forEach(el => {
        errorsM = { ...errorsM, [el.field]: el.defaultMessage };
      });
      setErrorsMessages(errorsM);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  };

  const fields = formFields.map(el => (
    <>
      <AccessibilityLabel muiinput>
        <p> {el.name}</p>
        <ControlForm key={el.name} {...el} errors={errors} control={control} />
      </AccessibilityLabel>
      <ErrorAlert
        type='simple'
        error={errorsMessages[el.name]}
        maxWidth='380px'
      />
    </>
  ));
  return (
    <>
      <SectionWrap>
        <ButtonBack onClick={() => setPrivacyOption('')}>
          <ChevronLeftIcon /> Wróć do prywatości i zarządzania kontem
        </ButtonBack>

        <Section>
          <Title>Zmiana hasła</Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {fields}

            <ButtonsWrap>
              <StyledButton
                secondary
                onClick={() => setPrivacyOption('')}
                type='button'
              >
                Anuluj
              </StyledButton>
              <StyledButton type='submit' onSubmit={handleSubmit(onSubmit)}>
                Zmień hasło
              </StyledButton>
            </ButtonsWrap>
          </Form>
        </Section>
      </SectionWrap>
    </>
  );
};

export default ChangePassword;
