import MenuCell from './MenuCell';
import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import CandidateCell from './CandidateCell';
import { menuItems } from 'constans/constansCandidates';
import styled from 'styled-components';

const StyledTr = styled(Tr)`
  td.pivoted {
    padding-left: 30px !important;
  }
`;

const CandidatesTableRow = ({
  type,
  getValues,
  category,
  setMenuFunctions,
  row,
  cols,
  setInvitationDetails,
  setUserApplications,
  handleDisplayCandidate,
}) => {
  const cells = Object.entries(cols).map(([, col], idx) => (
    <Td key={idx}>
      {col.fieldName === 'userData' && <CandidateCell userData={row} />}
    </Td>
  ));

  return (
    <StyledTr>
      {cells}
      <Td>
        <MenuCell
          row={row}
          menuItems={menuItems.filter(el => {
            const isVisible =
              el.notVisible?.includes(row.status.invitationStatus) ||
              el.notVisible?.includes(row.status.applicationStatus);
            return !isVisible;
          })}
          setInvitationDetails={setInvitationDetails}
          setUserApplications={setUserApplications}
          type={type}
          getValues={getValues}
          category={category}
          setMenuFunctions={setMenuFunctions}
          handleDisplayCandidate={handleDisplayCandidate}
        />
      </Td>
    </StyledTr>
  );
};

export default CandidatesTableRow;
