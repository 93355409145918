import React, { useEffect } from 'react';

import MainWrapper from 'components/atoms/MainWrapper';
import CreateAdvertisement from 'components/admin/CreateAdverisement/CreateAdvertisement';

const CreateAdvertisementPage = () => {
  useEffect(() => {
    document.title = 'Stwórz ogłoszenie - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <MainWrapper>
      <CreateAdvertisement />
    </MainWrapper>
  );
};

export default CreateAdvertisementPage;
