import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from 'API';
import Indicator from 'components/atoms/Indicator';
import qs from 'qs';
import { TOAST_POSITION } from 'constans/constantsToast';

const AnswerInvitation = ({ props }) => {
  const [redirect, setRedirect] = useState();
  const location = useLocation();

  let params = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    const isAnswer = async () => {
      if (params.applicationId) {
        try {
          await api.putAnswerInvitation(params.applicationId, params.status);
          setRedirect('/');
          if (params.status === 'CONFIRM') {
            toast.success(
              'Pomyślnie zaakceptowano zaproszenie.',
              TOAST_POSITION
            );
          } else {
            toast.success('Pomyślnie odrzucono zaproszenie.', TOAST_POSITION);
          }
        } catch (e) {
          setRedirect('/');
          toast.error(
            <div>
              {e.response?.data?.errors?.map(err => (
                <p>{err.defaultMessage}</p>
              ))}
            </div>,
            TOAST_POSITION
          );
        }
      }
    };
    isAnswer();
  }, [params.applicationId, params.status]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return <Indicator />;
};

export default AnswerInvitation;
