import React, { useEffect, useState } from 'react';

import { api } from 'API';
import SearchIcon from '@material-ui/icons/Search';
import SingleDict from './SingleDict';
import * as S from './CreateAdvertisement.css';
import HiddenMenu from 'components/shared/HiddenMenu';

const basicDicts = [117, 114, 112, 102, 115, 118];

const DictionaryList = ({ isDropped, currentStep }) => {
  const [searchValue, setSearchValue] = useState('');
  const [basicDictionaryList, setBasicDictionaryList] = useState([]);
  const [dictionariesList, setDictionariesList] = useState([]);

  const getDictionaries = async () => {
    const dictName = currentStep === 0 ? 'ADV' : 'FORM';

    const { data } = await api.getAllDictionary(dictName);

    let dictList = data.content;
    if (currentStep !== 0) {
      dictList = dictList.filter(el => !basicDicts.includes(el.id));
    }

    setDictionariesList(dictList);
    setBasicDictionaryList(dictList);
  };

  useEffect(() => {
    getDictionaries();
  }, [currentStep]);

  const handleOnChangeSearch = searchValue => {
    setSearchValue(searchValue);

    const newList = basicDictionaryList.filter(el =>
      el.title.toUpperCase().includes(searchValue.toUpperCase())
    );
    setDictionariesList(newList);
  };

  const dictionariesListRender = dictionariesList.map(el => (
    <SingleDict
      key={el.id}
      id={el.id}
      isDropped={isDropped(el.title)}
      role='subsection'
      type='subsection'
      name={el.title}
      elements={el.elements}
    />
  ));

  return (
    <S.DictionaryListWrapper>
      <S.DictionaryListTitle>Słowniki</S.DictionaryListTitle>
      <S.DictionaryListSearch>
        <button type='button'>
          <SearchIcon aria-hidden='false'>
            <title>Szukaj</title>
          </SearchIcon>
          <HiddenMenu text='Szukaj' />
        </button>
        <input
          id='search'
          placeholder='szukaj'
          value={searchValue}
          onChange={e => handleOnChangeSearch(e.target.value)}
        />
        <label htmlFor='search' style={{ display: 'none' }}>
          Szukaj
        </label>
      </S.DictionaryListSearch>
      <S.DictionaryList>{dictionariesListRender}</S.DictionaryList>
    </S.DictionaryListWrapper>
  );
};

export default DictionaryList;
