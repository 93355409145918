import React, { useState } from 'react';
import styled from 'styled-components';
import {
  BookmarkWrapper,
  Section,
  SectionWrap,
  SettingsMenu,
  Title,
} from '../Settings.css';
import { Button } from 'm-web-components';
import { privacySettings } from 'components/shared/AccountSettings/constansSettings';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.secondary};
  color: #fff;
  border: none;
  font-weight: 600;
`;

const Privacy = ({ menuList }) => {
  const [privacyOption, setPrivacyOption] = useState('');

  const changePassTab = () => {
    setPrivacyOption(privacySettings.CHANGEPASSWORD);
  };

  const deleteAccountTab = () => {
    setPrivacyOption(privacySettings.DELETEACCOUNT);
  };

  return (
    <>
      <BookmarkWrapper>
        {!privacyOption && (
          <>
            <SectionWrap>
              <Section>
                <Title>Prywatność</Title>
                <StyledButton onClick={changePassTab}>Zmień hasło</StyledButton>
              </Section>
              <Section>
                <Title>Zarządzanie kontem</Title>
                <StyledButton onClick={deleteAccountTab}>
                  Usuń konto
                </StyledButton>
              </Section>
            </SectionWrap>
            <SettingsMenu>{menuList}</SettingsMenu>
          </>
        )}
        {privacyOption === privacySettings.CHANGEPASSWORD && (
          <ChangePassword setPrivacyOption={setPrivacyOption} />
        )}

        {privacyOption === privacySettings.DELETEACCOUNT && (
          <DeleteAccount setPrivacyOption={setPrivacyOption} />
        )}
      </BookmarkWrapper>
    </>
  );
};

export default Privacy;
