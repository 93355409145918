import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CheckmarkIcon } from 'assets/icons/CheckmarkIcon.svg';

const ThankYou = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40%;
  padding: 20px;
`;

const InvitationSent = styled.p`
  text-align: center;
`;

const InvitationSummary = styled.div`
  border-top: 1px solid #959697;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60%;
  padding: 20px;

  span {
    font-weight: 600;
  }

  p {
    overflow-wrap: break-word;
  }
`;

const FulfillSentConfirmation = ({ invitationDetails, formData }) => {
  return (
    <>
      <ThankYou>
        <CheckmarkIcon />
        <h4>Wiadomość informacyjna została wysłana.</h4>
        <InvitationSent>
          Wiadomość informacyjna została dostarczona do kandydata.
        </InvitationSent>
      </ThankYou>
      <InvitationSummary>
        <h4>Szczegóły wiadomości</h4>
        <p>
          <span>Kandydat: </span>
          {invitationDetails.name} {invitationDetails.surname}
        </p>
        <p>
          <span>Stanowisko: </span>
          {formData.advertisementName}
        </p>
        <p>
          <span>Etap rekrutacji: </span>
          Wiadomość informacyjna.
        </p>
        <p>
          <span>Twoja wiadomość: </span>
          <br />
          {formData.content}
        </p>
      </InvitationSummary>
    </>
  );
};

export default FulfillSentConfirmation;
