import { statusName } from 'constans/constansAdv';

const statusOptions = Object.values(statusName);

export const advertisementsFiltersTable = departmentOptions => [
  {
    filterType: 'select',
    nameFilter: 'aStatus',
    nameTable: 'adminAdvertisement',
    label: 'Status',
    placeholder: 'Wszystkie ogłoszenia',
    options: statusOptions,
  },
  {
    filterType: 'selectMulti',
    nameFilter: 'dId',
    nameTable: 'adminAdvertisement',
    label: 'Wydział',
    placeholder: 'Wszystkie wydziały',
    options: departmentOptions,
  },
  {
    filterType: 'dataPicker',
    nameFilter: 'p',
    nameTable: 'adminAdvertisement',
    label: 'Data opublikowania',
  },
  {
    filterType: 'dataPicker',
    nameFilter: 'c',
    nameTable: 'adminAdvertisement',
    label: 'Data utworzenia',
  },
  {
    filterType: 'dataPicker',
    nameFilter: 'd',
    nameTable: 'adminAdvertisement',
    label: 'Data składania',
  },
];
