import { statusName } from 'constans/constansCandidates';
import exportFromJSON from 'export-from-json';

const dataToExport = data =>
  data.map(el => ({
    Stanowisko: el.position,
    'Wszystkie aplikacje': el.all,
    'Spełnia wymagania': el.fulfill,
    'Nie spełnia wymagań': el.notFulfill,
    'Zaproszenia na rozmowę': el.testInvitation,
    'Zaproszenia na test': el.interviewInvitation,
  }));

const dataDetailsToExport = data =>
  data.map(el => ({
    Kandydat: el.user,
    Ogłoszenie: el.position,
    Status: statusName[el.status]?.label,
    Notatka: el.note,
    'Poziom edukacji': el.education,
    'Lata doświadczenia': el.experience,
  }));

export const exportFile = data => {
  exportFromJSON({
    data: data[0].user ? dataDetailsToExport(data) : dataToExport(data),
    fileName: 'Raport',
    exportType: 'xls',
  });
};
