import React from 'react';
import { Button } from 'm-web-components';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { statusName } from 'constans/constansAdv';
import theme from 'styles/theme';

import * as S from './SingleAdvertisement.css';
import Indicator from 'components/atoms/Indicator';
import AdvDescDetails from 'components/shared/AdvertisementDetails/AdvDetails';
import { Link } from 'react-router-dom';

import Modal from 'components/shared/Modal';
import ModalChangeStatus from './ModalChangeStatus';

const buttonProps = {
  padding: '5px 10px',
  margin: '0',
};

const AdvDesc = ({ id, advData, isModalOpen, setModalOpen }) => {
  return (
    <>
      {!advData ? (
        <Indicator />
      ) : (
        <S.MainWrapper>
          <S.Main>
            <S.BlankContainer>
              <S.ContainerTitle>Podstawowe informacje</S.ContainerTitle>
              <S.SingleMainInfo>
                <p>Wydział</p>
                <span>{advData.info?.departmentName}</span>
              </S.SingleMainInfo>
              <S.SingleMainInfo>
                <p>Data utworzenia</p>
                <span>{advData.info?.createDate}</span>
              </S.SingleMainInfo>
              <S.SingleMainInfo>
                <p>Data opublikowania</p>
                <span>{advData.info?.publishDate}</span>
              </S.SingleMainInfo>
              <S.SingleMainInfo>
                <p>Termin składania aplikacji</p>
                <span>{advData.info?.deadlineDate}</span>
              </S.SingleMainInfo>
            </S.BlankContainer>
            <S.BlankContainer>
              <S.ContainerTitle>Szczegóły ogłoszenia</S.ContainerTitle>

              <AdvDescDetails data={advData.details} />
            </S.BlankContainer>
          </S.Main>
          <S.Aside>
            <S.ButtonContainer>
              {advData.status === 'DRAFT' && (
                <Link to={`/admin/create-advertisement/${id}`}>
                  <Button
                    style={{ border: '1px solid #2C81C7' }}
                    colorType='light'
                    {...buttonProps}
                  >
                    Edytuj
                  </Button>
                </Link>
              )}
              <Link to={`/admin/advertisement-print/${id}`}>
                <Button
                  {...buttonProps}
                  margin='0 0 0 20px'
                  colorType='primary'
                >
                  Drukuj
                </Button>
              </Link>
            </S.ButtonContainer>
            <S.BlankContainer>
              <p>Status ogłoszenia</p>

              <S.StatusInfo>
                <FiberManualRecordIcon
                  style={{ fill: theme[advData.status] }}
                />
                {statusName[advData.status]?.label}
              </S.StatusInfo>
              <S.Button
                {...buttonProps}
                onClick={() => setModalOpen(prev => !prev)}
              >
                Zmień
              </S.Button>
            </S.BlankContainer>
            <S.BlankContainer>
              <p>Formularz</p>
              <p>{advData.formDetails?.label}</p>
            </S.BlankContainer>
          </S.Aside>
          <Modal open={isModalOpen} setOpen={setModalOpen}>
            <ModalChangeStatus aId={id} setModalOpen={setModalOpen} />
          </Modal>
        </S.MainWrapper>
      )}
    </>
  );
};

export default AdvDesc;
