import React, { useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

import FiltersList from './FiltersList';
import * as S from '../Table.css';

const FiltersContainer = ({
  type,
  filtersTable,
  title = 'Filtry',
  right,
  category,
  getValues,
}) => {
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
      <S.FiltersContainer>
        <S.FiltersButtonToggle
          type='button'
          onClick={() => setOpenFilter(prev => !prev)}
        >
          <FilterIcon />
          {title}
        </S.FiltersButtonToggle>
        <S.FiltersStyled openFilter={openFilter} title={title} right={right}>
          <FiltersList
            type={type}
            filtersTable={filtersTable}
            category={category}
            getValues={getValues}
          />
        </S.FiltersStyled>
      </S.FiltersContainer>
    </ClickAwayListener>
  );
};

export default FiltersContainer;
