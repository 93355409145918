import React, { useEffect, useState } from 'react';

import ReportGenerator from 'components/admin/Reports/ReportGenerator';
import ReportsLibraryTable from 'components/admin/Reports/ReportsLIbrary/ReportsLibraryTable';
import MainWrapper from 'components/atoms/MainWrapper';
import ContainerWithBookmarks from 'components/shared/ContainerWithBookmarks';
import { bookmarks } from 'constans/constansReports';

const ReportsPage = () => {
  const [activeBookmark, setActiveBookmark] = useState(
    bookmarks.generator.name
  );

  useEffect(() => {
    document.title = 'Raporty - Portal Rekrutacyjny Zabrze';
  }, []);

  const horizontalMenu = {
    curentType: activeBookmark,
    setCurrentType: setActiveBookmark,
    horizontalMenuButtons: [
      {
        type: bookmarks.generator.name,
        label: bookmarks.generator.label,
      },
      {
        type: bookmarks.library.name,
        label: bookmarks.library.label,
      },
    ],
  };

  const activeSection = () => {
    switch (activeBookmark) {
      case bookmarks.generator.name:
        return <ReportGenerator />;

      case bookmarks.library.name:
        return <ReportsLibraryTable />;

      default:
        <div>Wystąpił problem...</div>;
    }
  };

  return (
    <MainWrapper>
      <ContainerWithBookmarks title='Raporty' horizontalMenu={horizontalMenu}>
        {activeSection()}
      </ContainerWithBookmarks>
    </MainWrapper>
  );
};

export default ReportsPage;
