import React, { useEffect, useState } from 'react';

import Title from 'components/atoms/Title';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  SectionWrap,
  Section,
  StyledButton,
  ButtonsWrap,
  Form,
  Code,
  StyledInput,
  ButtonBack,
} from 'components/shared/AccountSettings/Settings.css';
import { AccessibilityLabel } from 'components/tables/Table.css';
import { useForm } from 'react-hook-form';
import { ControlForm } from 'm-web-components';
import RowMenuModal from 'components/shared/RowMenuModal/RowMenuModal';
import Modal from 'components/shared/Modal';
import { api } from 'API';
import { toast } from 'react-toastify';
import useAuth from 'auth/useAuth';
import { TOAST_POSITION } from 'constans/constantsToast';

const DeleteAccount = ({ setPrivacyOption }) => {
  const { handleSubmit, errors, control } = useForm();
  const [isOpen, setModalOpen] = useState(false);
  const [code, setCode] = useState('');
  const { logout } = useAuth();

  const getCodeFunc = async () => {
    const resp = await api.getCode();
    setCode(resp.data);
  };

  useEffect(() => {
    getCodeFunc();
  }, []);

  const formFields = [
    {
      as: (
        <StyledInput
          aria-label='Wpisz swoje hasło'
          InputLabelProps={{
            shrink: true,
          }}
          autoFocus
        />
      ),
      name: 'password',
      label: 'Wpisz swoje hasło',
      rules: { required: 'To pole jest wymagane' },
      type: 'password',
    },
    {
      as: (
        <StyledInput
          aria-label='Przepisz kod'
          InputLabelProps={{
            shrink: true,
          }}
        />
      ),
      name: 'code',
      label: 'Przepisz kod',
      rules: { required: 'To pole jest wymagane' },
      type: 'text',
    },
  ];

  const onSubmit = async data => {
    if (data) {
      try {
        await api.deleteAccount({ data });
        logout();
        toast.success('Konto zostało usunięte.', TOAST_POSITION);
      } catch (e) {
        console.log(e);
        toast.error('Sprawdź poprawność uzupełnionych pól.', TOAST_POSITION);
      }
    }
    setModalOpen(false);
  };

  const fields = formFields.map(el => (
    <AccessibilityLabel muiinput>
      <p> {el.name}</p>
      <ControlForm key={el.name} {...el} errors={errors} control={control} />
    </AccessibilityLabel>
  ));
  return (
    <>
      <SectionWrap>
        <ButtonBack onClick={() => setPrivacyOption('')}>
          <ChevronLeftIcon aria-hidden='false'>
            <title>Wróć</title>
          </ChevronLeftIcon>
          Wróć do prywatości i zarządzania kontem
        </ButtonBack>

        <Section>
          <Title>Usuwanie konta</Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {fields[0]}
            <Code>{code}</Code>
            {fields[1]}

            <ButtonsWrap>
              <StyledButton
                secondary
                onClick={() => setPrivacyOption('')}
                type='button'
              >
                Anuluj
              </StyledButton>
              <StyledButton type='button' onClick={() => setModalOpen(true)}>
                Usuń konto
              </StyledButton>
            </ButtonsWrap>
          </Form>
        </Section>
        <Modal open={isOpen} setOpen={setModalOpen}>
          <RowMenuModal
            title={'Czy na pewno chcesz usunąć swoje konto?'}
            text={
              'Ta opcja jest nieodwracalna. Konto zostanie trwale usunięte i nie będzie możliwości przywrócenia danych.'
            }
            buttonLabel={'Usuń'}
            confirmFunc={handleSubmit(onSubmit)}
            cancelFunc={() => setModalOpen(false)}
          />
        </Modal>
      </SectionWrap>
    </>
  );
};

export default DeleteAccount;
