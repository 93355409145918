import { dictionaryTypes } from 'constans/constansDictionary';

export const basicFields = [
  {
    label: 'Wybierz słownik',
    options: dictionaryTypes,
    name: 'dictionaryType',
    rules: { required: 'To pole jest wymagane' },
  },
  {
    name: 'title',
    label: 'Nazwa pola',
    rules: { required: 'To pole jest wymagane' },
    type: 'text',
  },
  {
    tooltip:
      'Jest to opis pola do użytku wewnętrznego. Wgląd do opisów mają tylko rekruterzy.',
    name: 'description',
    label: 'Opis',
    type: 'text',
  },
];
