import ReactQuill from 'react-quill';
import { Controller } from 'react-hook-form';
import { ErrorAlert } from 'm-web-components';
import { ErrorMessage } from '@hookform/error-message';
import 'react-quill/dist/quill.snow.css';
import * as S from './Singles.css';
import styled, { css } from 'styled-components';

const TextEditorWrapper = styled.div`
  ${({ mappedLabels }) => css`
    ${mappedLabels}
  `}

  .ql-editor * {
    line-height: 1.25em;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item::before,
  .ql-snow .ql-picker.ql-size .ql-picker-label::before {
    content: 'Normalna' !important;
  }

  //headings

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: 'Normalny' !important;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
    content: 'Nagłówek 1' !important;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
    content: 'Nagłówek 2' !important;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
    content: 'Nagłówek 3' !important;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
    content: 'Nagłówek 4' !important;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
    content: 'Nagłówek 5' !important;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
    content: 'Nagłówek 6' !important;
  }
`;

const SingleLongAnswer = ({
  data,
  errors,
  index,
  defaultValues,
  control,
  basicNameForm,
  ignoreRequired,
  textEditor,
  changeValue,
  sectionIndex,
  dictIndex,
  value,
  readOnly,
}) => {
  const fonts = Array.from({ length: 18 }, (_, i) => i * 2 + 6);

  const Size = ReactQuill.Quill.import('attributors/style/size');
  Size.whitelist = fonts.map(el => `${el / 10}rem`);
  ReactQuill.Quill.register(Size, true);

  const mappedLabels = fonts.map(
    el => `
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='${
      el / 10
    }rem']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='${
      el / 10
    }rem']::before {
      content: '${el}px' !important;
    }
    `
  );

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],

      [{ size: [false, ...Size.whitelist] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [],
      [{ color: [] }, { background: [] }],

      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <S.SingleWrapper>
      <S.Label>
        {data.label} {data.isRequired && <span>*</span>}
      </S.Label>
      {control ? (
        <>
          <Controller
            name={`${basicNameForm}.elements[${index}].content.content`}
            control={control}
            rules={
              !ignoreRequired && data.isRequired
                ? { required: 'To pole jest wymagane' }
                : null
            }
            defaultValue={defaultValues?.content?.content}
            render={props => (
              <>
                {textEditor ? (
                  <TextEditorWrapper mappedLabels={mappedLabels}>
                    <ReactQuill
                      style={{ minHeight: '200px' }}
                      theme='snow'
                      value={props.value || ''}
                      onChange={props.onChange}
                      modules={modules}
                    />
                  </TextEditorWrapper>
                ) : (
                  <S.Textarea
                    aria-label='empty textarea'
                    placeholder={data.placeholder}
                    rowsMin={3}
                    onChange={e => props.onChange(e.target.value)}
                  />
                )}
              </>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${basicNameForm}.elements[${index}].content.content`}
            render={({ message }) => (
              <ErrorAlert error={message} maxWidth='200px' type='simple' />
            )}
          />
        </>
      ) : (
        <S.Textarea
          aria-label='empty textarea'
          placeholder={data.placeholder}
          rowsMin={3}
          onChange={e =>
            changeValue(
              { content: e.target.value },
              sectionIndex,
              dictIndex,
              index
            )
          }
          readOnly={readOnly}
          value={value?.content ? value.content : ''}
        />
      )}
    </S.SingleWrapper>
  );
};

export default SingleLongAnswer;
