import React, { useState } from 'react';
import { ControlForm, ErrorAlert } from 'm-web-components';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { api } from 'API';

import { formFields, formSmallFields, formSelect } from './formFields';
import { userTypes } from 'constans/constantsAdministration';
import * as S from '../Administration.css';
import { AccessibilityLabel } from '../../../tables/Table.css';
import { TOAST_POSITION } from 'constans/constantsToast';

const CreateAccount = () => {
  const { handleSubmit, errors, control, reset } = useForm();
  const [errorsMessages, setErrorsMessages] = useState({});

  const onSubmit = async (inputs, e) => {
    setErrorsMessages({});

    try {
      await api.postCreateUser(inputs.userType.value, inputs);
      reset();
      toast.success('Stworzono nowe konto użytkownika.', TOAST_POSITION);
    } catch ({ response }) {
      response?.data?.errors?.forEach(el =>
        setErrorsMessages(prev => ({ ...prev, [el.field]: el.defaultMessage }))
      );
      toast.error('Wystąpił błąd podczas tworzenia konta.', TOAST_POSITION);
    }
  };

  const smallFields = formSmallFields.map(el => (
    <>
      <AccessibilityLabel muiinput>
        <p>el.name</p>
        <ControlForm key={el.name} {...el} errors={errors} control={control} />
      </AccessibilityLabel>
      {errorsMessages[el.name]?.split('.,')?.map(msg => (
        <ErrorAlert type='simple' error={msg} maxWidth='380px' />
      ))}
    </>
  ));

  const fields = formFields.map(el => (
    <>
      <AccessibilityLabel muiinput>
        <p>{el.name}</p>
        <ControlForm key={el.name} {...el} errors={errors} control={control} />
      </AccessibilityLabel>
      {errorsMessages[el.name]?.split('.,')?.map(msg => (
        <ErrorAlert type='simple' error={msg} maxWidth='380px' />
      ))}
    </>
  ));

  const select = formSelect(userTypes).map(el => (
    <>
      <AccessibilityLabel muiinput>
        <p>{el.name}</p>
        <ControlForm key={el.name} {...el} errors={errors} control={control} />
      </AccessibilityLabel>
      <ErrorAlert
        type='simple'
        error={errorsMessages[el.name]}
        maxWidth='380px'
      />
    </>
  ));

  return (
    <S.BookmarkWrapper>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        {select}
        <S.SmallInputsWrapper>{smallFields}</S.SmallInputsWrapper>
        {fields}
        <S.CreateAccountButton isVisible={true}>
          Stwórz konto
        </S.CreateAccountButton>
      </S.Form>
    </S.BookmarkWrapper>
  );
};

export default CreateAccount;
