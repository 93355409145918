import styled from 'styled-components';
import { device } from 'styles/devices';

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colorLighter};

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    margin: auto;
    width: 100%;
  }

  aside {
    padding: 20px;
    position: absolute;
    width: 100%;
    background-color: rgba(44, 129, 199, 0.7);
    color: white;
    font-size: 20px;
    font-weight: 700;
    bottom: 50px;

    p {
      margin-top: 10px;
      font-style: italic;
      text-align: end;
    }
  }

  @media ${device.desktop} {
    width: 50%;
  }

  @media ${device.desktopL} {
    aside {
      font-size: 2.5rem;
      max-width: 400px;
      line-height: 2rem;
      right: -20%;
    }
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 45px;

  h1 {
    width: 80%;
    font-size: 3rem;
    margin: auto;
  }

  @media ${device.desktop} {
    padding-top: 0;
    width: 50%;
    h1 {
      font-size: 4rem;
    }
  }

  @media ${device.desktopL} {
    h1 {
      font-size: 5rem;
    }
  }
`;

export const TextSection = styled.div`
  width: 100%;
  min-height: 20vh;
  padding: 45px;

  p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.greyDark};
  }
`;
