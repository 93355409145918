import React from 'react';
import styled from 'styled-components';
import { Td, Tr } from 'react-super-responsive-table';

const RowStyled = styled(Tr)`
  background-color: ${({ selected }) => (selected ? '#E6F0F8' : 'transparent')};
  transition: 0.4s;

  :hover {
    border-bottom: 1px solid #ebebeb;
  }
`;

const SingleReportTableRow = ({ row, cols, selectedRows, dragOver }) => {
  const cells = Object.entries(row).map(([, v], idx) => (
    <Td key={v} dragOver={cols[idx] === dragOver}>
      {row[cols[idx]?.fieldName]}
    </Td>
  ));

  return (
    <>
      <RowStyled
        style={{
          backgroundColor: `${
            selectedRows?.includes(row.id) ? '#E6F0F8' : 'transparent'
          }`,
        }}
      >
        {cells}
      </RowStyled>
    </>
  );
};

export default SingleReportTableRow;
