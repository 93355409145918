import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Attachment } from 'm-web-components';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { api } from 'API';
import { TOAST_POSITION } from 'constans/constantsToast';

const attachmentContainerStyle = {
  display: 'inline-block',
};

const mimeTypeMap = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  png: 'image/png',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  doc:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  docx:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  odt: 'application/vnd.oasis.opendocument.text',
  pdf: 'application/pdf',
  rtf: 'text/html',
};

const ShortAnswer = styled.p`
  display: inline-block;
  margin: 0 10px;

  a {
    color: ${({ theme }) => theme.secondary};
  }

  svg {
    font-size: 10px;
    margin-right: 10px;
  }
`;

const SingleFile = ({ buttonLabel, content, noBall, label }) => {
  const [generatedUrls, setGeneratedUrls] = useState([]);

  const options = {
    multiple: false,
    extensions: '.pdf, .doc, .docx, .tiff, .tif, .odt, .jpg, .png, .jpeg',
    size: 1,
  };

  useEffect(() => {
    if (content && Array.isArray(content)) getDefaultUrls();
  }, [content]);

  const getFileExt = name => {
    const pattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    return name.match(pattern);
  };

  const getDefaultUrls = async () => {
    try {
      const blobs = await Promise.all(
        content?.map(file => api.urlDownloadFile(file.fileId))
      );
      const urls = blobs.map((blob, index) =>
        URL.createObjectURL(
          new Blob([blob?.data], {
            type: mimeTypeMap[getFileExt(content[index].path)[1]],
          })
        )
      );

      setGeneratedUrls(urls);
    } catch (err) {
      toast.error(
        'Wystąpił błąd podczas pobierania domyślnych plików.',
        TOAST_POSITION
      );
    }
  };

  const defaultUrl = () =>
    content?.map((file, index) => ({
      name: file.path,
      url: generatedUrls[index],
    }));

  return (
    <ShortAnswer>
      {label && !noBall && (
        <p style={{ margin: '5px 0px', fontWeight: '600' }}>{label}:</p>
      )}
      <Attachment
        defaultUrl={!Array.isArray(content) ? [] : defaultUrl()}
        customStyle={attachmentContainerStyle}
        options={options}
        onSubmit={a => console.log(a)}
        labelCancel='Anuluj'
        labelSubmit='Załącz'
        submitButtonProps={{ colorType: 'primary' }}
        attachmentButtonProps={{
          disabled: true,
          style: { display: 'none' },
        }}
        removeAllButtonProps={{
          disabled: true,
          style: { display: 'none' },
        }}
        attachmentButtonLabel={buttonLabel || 'Dodaj załącznik'}
        urlProps={{ target: '_blank' }}
      />
    </ShortAnswer>
  );
};

export default SingleFile;
