import React, { useEffect } from 'react';

import MainWrapper from 'components/atoms/MainWrapper';
import ApplicationForm from 'components/user/ApplicationForm/ApplicationForm';

const UserApplicationsFormPage = () => {
  useEffect(() => {
    document.title = 'Formularz aplikacyjny - Portal Rekrutacyjny Zabrze';
  }, []);
  return (
    <MainWrapper>
      <ApplicationForm />
    </MainWrapper>
  );
};

export default UserApplicationsFormPage;
