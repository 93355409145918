import React, { useState } from 'react';

import * as S from 'components/admin/FormsElements/FormsElements.css';
import Singles from 'components/admin/AdvertisementElements/Singles';

const SingleSubSection = ({ data, advId, isVisibleTitle, print }) => {
  const [isDictVisible, setDictVisible] = useState(true);
  const elementsRender = data?.dictionaryData?.map((el, index) => {
    const props = {
      key: `${el.dataFormat}-${index}`,
      label: el.content?.label || el.content?.content,
      content: data?.elements?.[index]?.content,
      noBall: false,
      buttonLabel: el.buttonLabel,
      advId,
    };

    switch (el.dataFormat) {
      case 'SINGLE_CHOICE':
        return <Singles.SingleChoice {...props} />;

      case 'SHORT_ANSWER':
        return <Singles.SingleShortAnswer {...props} />;

      case 'LONG_ANSWER':
        return <Singles.SingleLongAnswer {...props} />;

      case 'LIST':
        return <Singles.SingleShortAnswer {...props} />;

      case 'DATE':
        return <Singles.SingleShortAnswer {...props} />;

      case 'DATE_FROM_TO':
        return <Singles.SingleDateFromTo {...props} />;

      case 'FILE':
        return (
          <>
            {print ? (
              <Singles.SingleShortAnswer {...props} />
            ) : (
              <Singles.SingleFile {...props} />
            )}
          </>
        );

      default:
        return <span>Pojawił się błąd podczas wczytywania tej częsci</span>;
    }
  });

  return (
    <>
      <S.SubsectionWrapper style={{ padding: '10px 20px' }}>
        {isVisibleTitle && (
          <S.DictTitle
            isdictvisible={isDictVisible}
            onClick={() => setDictVisible(prev => !prev)}
            type='button'
          >
            <h3>{data.dictionaryTitle}</h3>
          </S.DictTitle>
        )}

        {isDictVisible && <>{elementsRender}</>}
      </S.SubsectionWrapper>
    </>
  );
};

export default SingleSubSection;
