import React from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import styled from 'styled-components';

import theme from 'styles/theme';
import {
  statusName,
  confirmStatus,
  menuItems,
} from 'constans/user/constansApplications';

import MenuCell from './ApplicationsMenu';
import { Link } from 'react-router-dom';
import HiddenMenu from 'components/shared/HiddenMenu';

const RowStyled = styled(Tr)`
  background-color: ${({ selected }) => (selected ? '#E6F0F8' : 'transparent')};
  transition: 0.4s;

  :hover {
    border-bottom: 1px solid #ebebeb;
  }
`;

export const SubStatus = styled.span`
  align-self: flex-start;
  border: 1px solid #459937;
  border-radius: 2px;
  padding: 1px 10px;
  margin: 2px 0;
`;

export const SubStatusRed = styled(SubStatus)`
  border: 1px solid #d5001f;
  color: #d5001f;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.tableFileds};
  display: block;
  height: 100%;
  transition: 0.4s;

  :hover {
    color: ${({ theme }) => theme.tableHeader};
  }
`;

const UserApplicationsTableRow = ({
  row,
  cols,
  selectedRows,
  handleOnChangeSelectedRows,
  dragOver,
  setMenuFunctions,
}) => {
  const cells = Object.entries(row).map(([, v], idx) => {
    return (
      <Td key={v} dragOver={cols[idx] === dragOver}>
        {idx === 0 && (
          <>
            <input
              type='checkbox'
              checked={selectedRows?.includes(row.id)}
              onChange={() => handleOnChangeSelectedRows(row.id)}
              id={`checkbox_${row?.id}`}
            />
            <label htmlFor={`checkbox_${row?.id}`} style={{ display: 'none' }}>
              Zaznacz
            </label>
          </>
        )}

        {cols[idx].fieldName === 'status' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              color: theme[row.status.applicationStatus],
            }}
          >
            {statusName[row.status.applicationStatus].label}
            {row.status.invitationStatus === 'CONFIRM' &&
              confirmStatus.includes(row.status.applicationStatus) && (
                <SubStatus>Potwierdzenie</SubStatus>
              )}
            {row.invitationStatus === 'REJECT' &&
              confirmStatus.includes(row.status.applicationStatus) && (
                <SubStatusRed>Rezygnacja</SubStatusRed>
              )}
          </div>
        )}

        {cols[idx].fieldName === 'title' && (
          <StyledLink to={`/user/single-my-applications/${row.id}`}>
            <span style={{ color: theme.secondary, fontWeight: '600' }}>
              {row[cols[idx].fieldName]}
            </span>
          </StyledLink>
        )}
        {cols[idx].fieldName !== 'title' &&
          cols[idx].fieldName !== 'status' &&
          row[cols[idx].fieldName]}
      </Td>
    );
  });

  return (
    <RowStyled
      style={{
        backgroundColor: `${
          selectedRows?.includes(row.id) ? '#E6F0F8' : 'transparent'
        }`,
      }}
    >
      {cells}
      <Td>
        <MenuCell
          setMenuFunctions={setMenuFunctions}
          row={row}
          menuItems={
            row.status.applicationStatus === 'RESIGN' ||
            row.status.applicationStatus === 'NOT_FULFILL_REQUIREMENTS'
              ? menuItems.filter(
                  el =>
                    el.visible === 'active' || el.advId === row.advertisementId
                )
              : menuItems.filter(el => el.visible !== 'notDefault')
          }
        />
      </Td>
    </RowStyled>
  );
};

export default UserApplicationsTableRow;
