// its only exmaple. You can change it
const theme = {
  primary: '#313131',
  secondary: '#2970AB',
  colorLight: '#EFF5FA',
  colorLighter: '#F9FBFD',
  light: 'rgba(149, 150, 151, 0.8)',
  lighter: 'rgba(149, 150, 151, 0.2)',
  tableHeader: 'rgba(49, 49, 49, 0.5)',
  tableFileds: 'rgba(49, 49, 49, 0.8)',
  grayDarker: 'rgba(49, 49, 49, 0.8)',
  grayDark: 'rgba(49, 49, 49, 1)',
  grayLight: 'rgba(49, 49, 49, 0.7)',
  grayLighter: 'rgba(49, 49, 49, 0.4)',
  red: '#CA001B',
  green: '#00800C',
  inputBorder: 'rgb(204, 204, 204)',
  placeholderColor: '#4A4A4A',
  buttonRadius: '2px',

  //buttons
  primaryButtonBg: '#2970AB',
  primaryButtonColor: '#fff',
  lightButtonBg: '#fff',
  lightButtonColor: '#2C81C7',
  grayButtonBg: 'rgba(212, 213, 215, 0.5)',
  grayButtonColor: '#959697',
  darkGrayButtonBg: '#9D9E9F',
  darkGrayButtonColor: '#fff',
  middleColorButtonBg: 'rgba(44, 129, 199, 0.2)',
  middleColorButtonColor: '#2C81C7',

  //color status - avd
  ACTIVE: '#4CCD38',
  ARCHIVAL: '#F2B517',
  PRE_ARCHIVAL: 'rgba(0, 0, 0, 0.37)',
  DRAFT: 'rgba(0, 0, 0, 0.15)',

  //color status - candidates
  NEW: '#2970AB',
  INTERVIEW_INV: '#00800C',
  INTERVIEW_INV_CONFIRM: '#00800C',
  INTERVIEW_INV_REJECT: '#00800C',
  TEST_INV: '#00800C',
  TEST_INV_CONFIRM: '#00800C',
  TEST_INV_REJECT: '#00800C',
  NOT_FULFILL_REQUIREMENTS: '#D5001F',
  FULFILL_REQUIREMENTS: '#00800C',
  RESIGNED: '#000',
};

export default theme;
