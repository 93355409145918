import { Button } from 'm-web-components';
import styled, { css } from 'styled-components';

export const DefaultButton = styled(Button)`
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => margin || 'auto'};
  padding: ${({ padding }) => padding || '6px 10px'};
  font-size: 1.5rem;
  font-weight: 500;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}

  ${({ right }) =>
    right &&
    css`
      margin-right: 0px;
    `}
`;

export const EditButton = styled(DefaultButton)`
  background-color: transparent;
  text-decoration: underline;
  border: none;
  color: ${({ theme }) => theme.secondary};
`;

export const SaveButton = styled(DefaultButton)`
  background-color: ${({ theme }) => theme.secondary};
  border: none;
  color: white;
`;

export const CancelButton = styled(DefaultButton)`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.secondary};
`;
