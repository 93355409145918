import React from 'react';
import styled from 'styled-components';
import { Select } from 'm-web-components';
import ReactTooltip from 'react-tooltip';

import { device } from 'styles/devices';

import TextField from '@material-ui/core/TextField';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const InputMui = styled(TextField)`
  width: 300px;

  @media ${device.desktop} {
    width: 380px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.primary};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 20px;
  }

  .MuiTooltip-tooltip {
    background-color: red;
    font-size: 30px;
  }
`;

const InputStyled = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 2px;

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => theme.inputBorder};
  }
`;

const Label = styled.label`
  font-weight: 600;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary};
`;

const Tooltip = styled.div`
  opacity: ${({ tooltip }) => (tooltip ? 1 : 0)};
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const Input = ({
  name,
  label,
  options,
  value,
  onChange,
  tooltip,
  multi,
  ...rest
}) => {
  return (
    <Wrapper>
      {label && (
        <Label htmlFor={name}>
          {label}
          <Tooltip tooltip={tooltip}>
            <span data-tip={tooltip}>
              <InfoOutlinedIcon fontSize='large' />
            </span>
            <ReactTooltip place='right' type='info' effect='solid' />
          </Tooltip>
        </Label>
      )}
      <InputWrapper>
        {options ? (
          <Select
            options={options}
            placeholder={label}
            value={value}
            onChange={value => onChange(value)}
            isMulti={multi}
            {...rest}
          />
        ) : (
          <InputStyled
            name={name}
            placeholder={label}
            value={value}
            onChange={value => onChange(value)}
            {...rest}
          />
        )}
      </InputWrapper>
    </Wrapper>
  );
};

export default Input;
