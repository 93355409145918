export const dictionaryType = {
  ADV: 'ADV',
  FORM: 'FORM',
};

export const dictionaryFiledsTypes = [
  { id: 'SHORT_ANSWER', text: 'Krótka odpowiedź' },
  { id: 'LONG_ANSWER', text: 'Długa odpowiedź' },
  { id: 'LIST', text: 'Lista rozwijana' },
  { id: 'SINGLE_CHOICE', text: 'Pole wyboru' },
  { id: 'DATE', text: 'Pojedyncza data' },
  { id: 'DATE_FROM_TO', text: 'Data (od-do)' },
  { id: 'FILE', text: 'Załącznik' },
];

export const dictionaryTypes = [
  { value: 'FORM', label: 'Formularze' },
  { value: 'ADV', label: 'Ogłoszenia' },
];
