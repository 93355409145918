import React, { useState } from 'react';
import styled from 'styled-components';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { api } from 'API';

import { Button, MenuLink } from 'components/shared/MenuInTable';
import { toast } from 'react-toastify';
import { TOAST_POSITION } from 'constans/constantsToast';

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  color: ${({ theme }) => theme.primary};
  background: none;
  border: none;

  svg {
    margin-right: 10px;
  }

  :hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

const MenuCell = ({
  row,
  menuItems,
  setInvitationDetails,
  setUserApplications,
  type,
  category,
  getValues,
  setMenuFunctions,
  handleDisplayCandidate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);

  const invitationDetails = {
    appId: row?.applicationId,
    userId: row?.userId,
    name: row?.name,
    surname: row?.surname,
  };

  const handleInvModal = () => {
    setInvitationDetails(prev => ({ ...prev, ...invitationDetails }));
    setMenuFunctions(prev => ({ ...prev, invite: true }));
    setAnchorEl(null);
  };

  const handleThanksModal = () => {
    setInvitationDetails(prev => ({ ...prev, ...invitationDetails }));
    setMenuFunctions(prev => ({ ...prev, thanks: true }));
    setAnchorEl(null);
  };

  const handleFulfillModal = () => {
    setInvitationDetails(prev => ({ ...prev, ...invitationDetails }));
    setMenuFunctions(prev => ({ ...prev, fulfill: true }));
    setAnchorEl(null);
  };

  const handleRejectCandidate = async () => {
    const body = {
      status: 'NOT_FULFILL_REQUIREMENTS',
      invitationStatus: null,
    };

    try {
      await api.changeCandidateStatus(invitationDetails.appId, body);
      toast.success('Kandydat odrzucony pomyślnie.', TOAST_POSITION);
      setAnchorEl(null);
      getValues(type, null);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas zmiany statusu.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
      return false;
    }
  };

  return (
    <>
      <Button
        type='button'
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon style={{ color: '#2C81C7', transform: 'scale(2)' }} />
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(el => (
          <MenuItem key={el.label} style={{ padding: 0 }}>
            {el.label === 'Zaproś do następnego etapu' ? (
              <MenuButton onClick={handleInvModal}>
                {el.icon}
                {el.label}
              </MenuButton>
            ) : el.label === 'Podziękuj za udział' ? (
              <MenuButton onClick={handleThanksModal}>
                {el.icon}
                {el.label}
              </MenuButton>
            ) : el.label === 'Wyślij wiadomość informacyjną' ? (
              <MenuButton onClick={handleFulfillModal}>
                {el.icon}
                {el.label}
              </MenuButton>
            ) : el.label === 'Odrzuć kandydata' ? (
              <MenuButton onClick={handleRejectCandidate}>
                {el.icon}
                {el.label}
              </MenuButton>
            ) : el.label === 'Zobacz kandydata' ? (
              <MenuButton
                onClick={() => handleDisplayCandidate(row?.applicationId)}
              >
                {el.icon}
                {el.label}
              </MenuButton>
            ) : (
              <MenuLink to='/'>
                {el.icon}
                {el.label}
              </MenuLink>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuCell;
