import React from 'react';
import Singles from '.';

const SingleDateFromTo = ({ label, content, noBall }) => {
  const value = `${content.dateFrom} - ${
    content.isCurrent ? 'obecnie' : content.dateTo
  }`;

  return (
    <>
      <Singles.SingleShortAnswer
        content={value}
        noBall={noBall}
        label={label}
      />
    </>
  );
};

export default SingleDateFromTo;
