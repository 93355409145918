import React, { useState } from 'react';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

import * as S from 'components/admin/FormsElements/FormsElements.css';
import Singles from 'components/admin/AdvertisementElements/Singles';
import Hint from 'components/shared/Hint';

const SingleSubSection = ({ data, advId }) => {
  const [isDictVisible, setDictVisible] = useState(true);

  const elementsRender = data?.dictionaryData?.map((el, index) => {
    const props = {
      key: `${el.dataFormat}-${index}`,
      label: el.content?.label || el.content?.content,
      content: data?.elements?.[index]?.content,
      noBall: index === 0,
      buttonLabel: el.buttonLabel,
      advId,
    };

    switch (el.dataFormat) {
      case 'SINGLE_CHOICE':
        return <Singles.SingleChoice {...props} />;

      case 'SHORT_ANSWER':
        return <Singles.SingleShortAnswer {...props} />;

      case 'LONG_ANSWER':
        return <Singles.SingleLongAnswer {...props} />;

      case 'LIST':
        return <Singles.SingleShortAnswer {...props} />;

      case 'DATE':
        return <Singles.SingleShortAnswer {...props} />;

      case 'DATE_FROM_TO':
        return <Singles.SingleDateFromTo {...props} />;

      case 'FILE':
        return <Singles.SingleFile {...props} />;

      default:
        return <div>Pojawił się błąd podczas wyczytywania tej częsci</div>;
    }
  });

  return (
    <>
      <S.SubsectionWrapper>
        <S.DictTitle isdictvisible={isDictVisible} type='button'>
          <h3>
            <ArrowDropDownOutlinedIcon
              onClick={() => setDictVisible(prev => !prev)}
            />
            {data.dictionaryTitle}
          </h3>
        </S.DictTitle>

        {isDictVisible && <>{elementsRender}</>}
      </S.SubsectionWrapper>
    </>
  );
};

export default SingleSubSection;
