import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import * as S from './Singles.css';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorAlert } from 'm-web-components';

const SingleChoice = ({
  data,
  errors,
  index,
  defaultValues,
  control,
  basicNameForm,
  ignoreRequired,
  changeValue,
  sectionIndex,
  dictIndex,
  value,
}) => {
  return (
    <S.SingleWrapper>
      {control ? (
        <>
          <Controller
            name={`${basicNameForm}.elements[${index}].content.content`}
            control={control}
            rules={
              !ignoreRequired && data.isRequired
                ? { required: 'To pole jest wymagane' }
                : null
            }
            defaultValue={defaultValues?.content?.content}
            render={props => (
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    {...props}
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                }
                label={
                  <S.Label style={{ margin: 0 }}>
                    {data.content}
                    {data.isRequired && <span>*</span>}
                  </S.Label>
                }
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${basicNameForm}.elements[${index}].content.content`}
            render={({ message }) => (
              <ErrorAlert error={message} maxWidth='200px' type='simple' />
            )}
          />
        </>
      ) : (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              onChange={e => {
                e.target.setCustomValidity(
                  !e.target.checked ? 'To pole jest wymagane.' : ''
                );

                if (changeValue)
                  changeValue(
                    { content: e.target.checked },
                    sectionIndex,
                    dictIndex,
                    index
                  );
              }}
              required={!ignoreRequired && data.isRequired}
              checked={value?.content}
            />
          }
          label={
            <S.Label>
              {data.content} {data.isRequired && <span>*</span>}
            </S.Label>
          }
        />
      )}
    </S.SingleWrapper>
  );
};

export default SingleChoice;
