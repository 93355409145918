import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ApplicationDetails from 'components/shared/ApplicationDetails/ApplicationDetails';
import { api } from 'API';
import { toast } from 'react-toastify';
import Indicator from 'components/atoms/Indicator';
import { TOAST_POSITION } from 'constans/constantsToast';

const SingleApplicationUserPage = () => {
  const [applicationDetails, setApplicationDetails] = useState(null);
  useEffect(() => {
    document.title = 'Szczegóły aplikacji - Portal Rekrutacyjny Zabrze';
  }, []);

  const { id } = useParams();

  const getApplicationDetails = async () => {
    try {
      const { data } = await api.getSingleApplicationView(id);
      setApplicationDetails(data);
    } catch {
      toast.error(
        `Coś poszło nie tak podczas pobierania szczegółów aplikacji.
        Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania.`,
        TOAST_POSITION
      );
    }
  };

  useEffect(() => {
    getApplicationDetails();
  }, []);

  return (
    <>
      {!applicationDetails ? (
        <Indicator />
      ) : (
        <ApplicationDetails data={applicationDetails.details} advId={id} />
      )}
    </>
  );
};

export default SingleApplicationUserPage;
