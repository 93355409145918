import React from 'react';
import ReactDOMServer from 'react-dom/server';
import parse from 'html-react-parser';
import Frame, { FrameContextConsumer } from 'react-frame-component';

import { Button } from 'm-web-components';
import styled, { css, StyleSheetManager } from 'styled-components';
import PrintIcon from '@material-ui/icons/Print';

const styles = css`
  color: rgba(0, 0, 0, 0.87);

  * {
    font-family: Calibri, sans-serif;
    line-height: 1.25em;
  }

  .ql-size-small {
    font-size: 0.75em;
  }

  .ql-size-large {
    font-size: 1.5em;
  }

  .ql-size-huge {
    font-size: 2.5em;
  }

  ul {
    padding-left: 38px;
    margin: 0;

    li {
      list-style-type: disc;
      padding-left: 2px;
    }
  }

  ol {
    padding-left: 38px;

    li {
      padding-left: 2px;
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p,
  li {
    font-size: 13px;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.67em;
  }

  strong {
    * {
      font-family: CalibriBold, sans-serif;
      font-weight: bolder;
    }
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-indent-1 {
    padding-left: 3em;
  }

  .ql-indent-2 {
    padding-left: 6em;
  }

  .ql-indent-3 {
    padding-left: 9em;
  }

  .ql-indent-4 {
    padding-left: 12em;
  }

  .ql-indent-5 {
    padding-left: 15em;
  }

  .ql-indent-6 {
    padding-left: 18em;
  }

  .ql-indent-7 {
    padding-left: 21em;
  }

  .ql-indent-8 {
    padding-left: 24em;
  }

  ${({ application }) =>
    application &&
    css`
      svg {
        max-width: 16px;
        max-height: 16px;
        margin-left: 3px;
        margin-right: 3px;
      }

      p {
        display: inline-block;
        white-space: nowrap;
      }
    `}
`;

const Wrapper = styled.div`
  width: ${({ report }) => (report ? 'auto' : '210mm')};
  max-width: ${({ report }) => (report ? 'auto' : '210mm')};
  ${styles};

  ${({ report }) =>
    !report &&
    css`
      > div {
        padding: 30px;
        border: 1px solid black;
      }
    `}
`;

const InFrame = styled.div`
  ${styles}

  div {
    padding: 5px;

    > div > div {
      padding: 0;
    }
  }

  span {
    padding-right: 10px;
  }

  ${({ application }) =>
    application &&
    css`
      > div {
        border-bottom: 1px solid black;
      }
    `}

  ${({ report }) =>
    report &&
    css`
      .tdBefore {
        display: none;
      }
    `}

  .MuiSvgIcon-root {
    display: none;
  }

  @media print {
    ${styles};
  }
`;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ButtonLabel = styled.span`
  font-weight: 700;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 18px;
    max-height: 18px;
    margin: 0 5px 3px 0px;
  }
`;

const PrintComponent = ({ componentToPrint, application, report, title }) => {
  const html = ReactDOMServer.renderToStaticMarkup(componentToPrint);

  const customPrint = () => {
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;

    const doc = pri.document;
    const root = doc.querySelector(':root');
    if (application) {
      root.style.fontSize = '16px';
    } else {
      root.style.fontSize = '10px';
    }

    pri.focus();
    pri.print();
  };

  return (
    <PageWrapper>
      <Wrapper application={application} report={report}>
        <Button
          type='button'
          colorType='primary'
          margin='50px 0 10px auto'
          onClick={customPrint}
        >
          <ButtonLabel>
            <PrintIcon aria-hidden='false'>
              <title>Drukuj</title>
            </PrintIcon>
            Drukuj
          </ButtonLabel>
        </Button>
        <div id='toPrint'>
          {title && <h1 style={{ paddingLeft: '20px' }}>{title}</h1>}
          {parse(html)}
        </div>
        {!report && (
          <Button
            type='button'
            colorType='primary'
            margin='50px 0 10px auto'
            width='100%'
            onClick={customPrint}
          >
            <ButtonLabel>
              <PrintIcon aria-hidden='false'>
                <title>Drukuj</title>
              </PrintIcon>
              Drukuj
            </ButtonLabel>
          </Button>
        )}
        <Frame id='ifmcontentstoprint' style={{ display: 'none' }}>
          <FrameContextConsumer>
            {frameContext => (
              <StyleSheetManager target={frameContext.document.head}>
                <InFrame application={application} report={report}>
                  {title && <h1>{title}</h1>}
                  {parse(html)}
                </InFrame>
              </StyleSheetManager>
            )}
          </FrameContextConsumer>
        </Frame>
      </Wrapper>
    </PageWrapper>
  );
};

export default PrintComponent;
