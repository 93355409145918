import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  Wrapper,
  FormWrap,
  SubmitButton,
  GoTo,
  Title,
  Description,
  ZoomButtons,
} from './Login.css';

import Logo from 'assets/logo-zabrze.png';

import { ReactComponent as AMinus } from 'assets/icons/A_minus.svg';
import { ReactComponent as APlus } from 'assets/icons/A_plus.svg';

const DescriptionGreen = styled(Description)`
  color: #459937;
`;

const SmallWrap = styled.div`
  width: 50%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  > div {
    max-width: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;

    img {
      align-self: flex-start;
      margin: 5vh 0;
    }

    a {
      color: ${({ theme }) => theme.addButtonBg};
    }
  }

  @media (max-width: 1366px) {
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

const ForgotPasswordSuccess = ({ changeZoom }) => {
  const [isContactModalOpen, setContactModalOpen] = useState(false);

  useEffect(() => {
    document.title = 'Przypomnij hasło - Portal Rekrutacyjny Zabrze';
  }, []);

  return (
    <Wrapper>
      <ZoomButtons>
        <APlus
          onClick={() => changeZoom(0.1)}
          style={{ marginRight: '20', cursor: 'pointer' }}
        />
        <AMinus
          onClick={() => changeZoom(-0.1)}
          style={{ cursor: 'pointer' }}
        />
      </ZoomButtons>
      <SmallWrap>
        <FormWrap>
          <img src={Logo} alt='logo Zabrze' />
          <Title>Nowe hasło zostało wysłane na podany e-mail.</Title>
          <DescriptionGreen>
            Wysłaliśmy Ci wiadomość z nowym hasłem. Sprawdź ją i wróć do
            logowania.
          </DescriptionGreen>
          <Link to='/login'>
            <SubmitButton>Wróć do logowania</SubmitButton>
          </Link>
          <GoTo>
            Nie dostałeś żadnej wiadomości?
            <Link to='/forgotPassword'> Wyślij ponownie.</Link>
          </GoTo>
        </FormWrap>
      </SmallWrap>
    </Wrapper>
  );
};

export default ForgotPasswordSuccess;
