import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ErrorAlert, Select } from 'm-web-components';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

import * as S from '../CreateAdvertisement.css';
import ReactDatePicker from 'react-datepicker';
import { ErrorMessage } from '@hookform/error-message';
import { formatDate } from 'helpers/newDateFormat';
import { api } from 'API';

const TextFiledStyled = styled(TextField)`
  input {
    padding: 10px 15px;
    background-color: #fff;
  }

  input:disabled {
    border: none;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px;
    background-color: #fff;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgb(242, 242, 242);
  }
`;

const ThirdStep = ({ errors, defaultValues, control }) => {
  const [date, setDate] = useState();
  const [departmentsList, setDepartmentsList] = useState([]);

  const getDepartmentOptions = async () => {
    const { data } = await api.getDepartment();
    setDepartmentsList(data.map(el => ({ label: el.name, value: el.id })));
  };

  useEffect(() => {
    getDepartmentOptions();

    if (defaultValues?.finishData?.deadlineDate)
      setDate(new Date(defaultValues?.finishData?.deadlineDate));

    control.setValue(
      `finishData.deadlineDate`,
      defaultValues?.finishData?.deadlineDate
    );
  }, []);

  const setDateValue = date => {
    setDate(date);

    if (control) {
      control.setValue(`finishData.deadlineDate`, formatDate(date));
    }
  };

  return (
    <S.WrapperFirstStep>
      <S.Label>Wydział</S.Label>
      <Controller
        errors={errors}
        defaultValue={defaultValues?.finishData?.department}
        rules={{ required: 'To pole jest wymagane' }}
        control={control}
        name={`finishData.department`}
        render={({ onChange, value }) => (
          <div style={{ width: '400px' }}>
            <Select
              onChange={onChange}
              value={value}
              placeholder='Wybierz wydział'
              bigMargin
              options={departmentsList}
            />
          </div>
        )}
      />
      <ErrorMessage
        errors={errors}
        name={`finishData.department`}
        render={({ message }) => (
          <ErrorAlert error={message} maxWidth='200px' type='simple' />
        )}
      />

      <S.Label style={{ marginTop: '30px' }}>
        Termin składania aplikacji
      </S.Label>
      <Controller
        name={`finishData.deadlineDate`}
        control={control}
        defaultValue={date}
        render={() => (
          <ReactDatePicker
            dateFormat='yyyy-MM-dd'
            placeholderText='2021-01-01'
            selected={date}
            onChange={date => setDateValue(date)}
            customInput={<TextFiledStyled variant='outlined' />}
          />
        )}
      />

      <S.Label style={{ marginTop: '20px' }}>Numer naboru</S.Label>
      <Controller
        errors={errors}
        defaultValue={defaultValues?.finishData?.recruitNr}
        control={control}
        rules={{
          required: 'To pole jest wymagane',
          pattern: {
            message: 'Wartością musi być liczba całkowita',
          },
        }}
        name='finishData.recruitNr'
        render={({ onChange, value }) => (
          <S.Input
            onChange={onChange}
            value={value}
            placeholder='Wpisz numer naboru'
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name='finishData.recruitNr'
        render={({ message }) => (
          <ErrorAlert error={message} maxWidth='200px' type='simple' />
        )}
      />

      <S.Label style={{ marginTop: '20px' }}>Numer wniosku</S.Label>
      <Controller
        errors={errors}
        defaultValue={defaultValues?.finishData?.proposalNr}
        control={control}
        rules={{
          required: 'To pole jest wymagane',
        }}
        name='finishData.proposalNr'
        render={({ onChange, value }) => (
          <S.Input
            onChange={onChange}
            value={value}
            placeholder='Wpisz numer wniosku'
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name='finishData.proposalNr'
        render={({ message }) => (
          <ErrorAlert error={message} maxWidth='200px' type='simple' />
        )}
      />
    </S.WrapperFirstStep>
  );
};

export default ThirdStep;
