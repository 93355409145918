import { routes } from 'routes';

export const menuItemsListAdmin = [
  {
    label: 'Panel rekrutacyjny',
    to: routes.admin.home,
  },
  {
    label: 'Ogłoszenia',
    to: routes.admin.adv,
  },
  {
    label: 'Kandydaci',
    to: routes.admin.candidates,
  },
  {
    label: 'Formularze',
    to: routes.admin.forms,
  },
  {
    label: 'Słowniki',
    to: routes.admin.dictionary,
  },
  {
    label: 'Raporty',
    to: routes.admin.reports,
  },
  {
    label: 'Administracja',
    to: routes.admin.administration,
  },
];

export const menuItemsListUser = [
  {
    label: 'Panel powitalny',
    to: routes.user.home,
  },
  {
    label: 'Moje aplikacje',
    to: routes.user.applications,
  },
  {
    label: 'Ogłoszenia',
    to: routes.user.adv,
  },
];
