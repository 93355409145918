import React from 'react';
import styled from 'styled-components';
import { Td, Tr } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';

import theme from 'styles/theme';
import { statusName } from 'constans/constansAdv';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MenuCell from './AdvertisementsMenu';
import { menuItems } from 'constans/constansAdv';

const RowStyled = styled(Tr)`
  background-color: ${({ selected }) => (selected ? '#E6F0F8' : 'transparent')};
  transition: 0.4s;

  :hover {
    border-bottom: 1px solid #ebebeb;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.tableFileds};
  display: block;
  height: 100%;
  transition: 0.4s;

  :hover {
    color: ${({ theme }) => theme.tableHeader};
  }
`;

const AdvertisementsTableRow = ({
  row,
  cols,
  selectedRows,
  handleOnChangeSelectedRows,
  dragOver,
  setMenuFunctions,
}) => {
  const cells = Object.entries(row).map(([, v], idx) => (
    <Td key={v} dragOver={cols[idx] === dragOver}>
      {idx === 0 && (
        <>
          <input
            id={`checkbox_${v}`}
            type='checkbox'
            checked={selectedRows?.includes(row.id)}
            onChange={() => handleOnChangeSelectedRows(row.id)}
          />

          <label htmlFor={`checkbox_${v}`} style={{ display: 'none' }}>
            Zaznacz
          </label>
        </>
      )}

      {cols[idx].fieldName === 'status' && (
        <p style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <FiberManualRecordIcon
            style={{ marginRight: '5px', color: theme[row.status] }}
            aria-hidden='false'
          >
            <title>Status</title>
          </FiberManualRecordIcon>
          {statusName[row.status].label}
        </p>
      )}

      {idx !== 0 && cols[idx].fieldName !== 'status' && (
        <StyledLink to={`/admin/advertisement/${row.id}`}>
          {cols[idx].fieldName === 'title' && (
            <span style={{ color: theme.secondary, fontWeight: '600' }}>
              {row[cols[idx].fieldName]}
            </span>
          )}

          {cols[idx].fieldName !== 'title' &&
            cols[idx].fieldName !== 'status' &&
            row[cols[idx].fieldName]}
        </StyledLink>
      )}
    </Td>
  ));

  return (
    <RowStyled selected={selectedRows?.includes(row.id)}>
      {cells}
      <Td>
        <MenuCell
          menuItems={menuItems.filter(el => {
            const isVisible = el.notVisible?.includes(row.status);
            return !isVisible;
          })}
          setMenuFunctions={setMenuFunctions}
          row={row}
        />
      </Td>
    </RowStyled>
  );
};

export default AdvertisementsTableRow;
