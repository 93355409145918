import { api } from 'API';
import { TOAST_POSITION } from 'constans/constantsToast';
import { toast } from 'react-toastify';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

const setTableResultsParameters = item => ({
  type: SET_SEARCH_PARAMETERS,
  item,
});

const fetchTableResults = async params => {
  try {
    const { data } = await api.getTableResults(params);
    return data;
  } catch (e) {
    toast.error(
      'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
      TOAST_POSITION
    );
  }
};

const getTableResultsAction = (
  name,
  newValue,
  category = '',
  suffix = '',
  reduxNameHelper = ''
) => async (dispatch, getState) => {
  const { tableResults } = getState();
  const parametersName = tableResults[`${reduxNameHelper}${name}Parameters`]
    ? `${reduxNameHelper}${name}Parameters`
    : tableResults[`${reduxNameHelper}${name}ParametersDefault`]
    ? `${reduxNameHelper}${name}ParametersDefault`
    : 'defaultParameters';

  const prevParams = tableResults[parametersName];
  const tableDefaultParameters =
    tableResults[`${reduxNameHelper}${name}ParametersDefault`];

  const params =
    newValue === 'clear' || newValue === 'clearAll'
      ? {
          reduxNameHelper: `${reduxNameHelper}`,
          name: `${name}`,
          suffix: `${suffix}`,
          category,
          page: 1,
          size: 10,
          ...tableDefaultParameters,
        }
      : {
          reduxNameHelper: `${reduxNameHelper}`,
          ...prevParams,
          ...newValue,

          //path before params
          category,
          name: `${name}`,
          suffix: `${suffix}`,
        };

  try {
    const { content, data } = await fetchTableResults(params);
    dispatch(
      setTableResultsParameters({
        ...params,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
      })
    );

    if (newValue === 'clearAll') return;
    return { content };
  } catch (e) {
    toast.error(
      'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z dostawcą oprogramowania',
      TOAST_POSITION
    );
  }
};

export default { getTableResultsAction };
