import React, { useState } from 'react';

import { useDrop } from 'react-dnd-latest';

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import SingleSubSection from './SingleSubSection';

import * as S from '../CreateAdvertisement.css';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorAlert } from 'm-web-components';
import { Controller } from 'react-hook-form';
import { Draggable } from 'react-beautiful-dnd';
import { getItemStyle } from '../dndFunctions';

const SingleSection = ({
  accept,
  onDrop,
  selectedDictionaries,
  errors,
  defaultValues,
  control,
  index: sectionIndex,
  deleteSection,
  title,
  id,
  deleteSubSection,
  advId,
}) => {
  const [isEditableTitle, setIsEditableTitle] = useState(false);
  const [isSubsectionsVisible, setSubsectionsVisible] = useState(true);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  //to style (background container)
  const isActive = isOver && canDrop;
  let backgroundColor = '#fff';
  if (isActive) {
    backgroundColor = '#b9e0ff';
  } else if (canDrop) {
    backgroundColor = '#EFF5FA';
  }

  const subSectionsRender = selectedDictionaries.map((item, index) => {
    if (!item?.elements[0]?.content?.canDuplicate) {
      item.elements[0].content.canDuplicate = false;
    }

    return (
      <Draggable
        key={`${sectionIndex}-${index}`}
        draggableId={`${sectionIndex}-${index}`}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <SingleSubSection
              id={`${sectionIndex}-${index}`}
              index={index}
              sectionIndex={sectionIndex}
              dictionaryId={item.dictionaryId}
              formProps={{
                errors,
                defaultValues: defaultValues?.dictionaries?.[index],
                control,
              }}
              deleteSubSection={deleteSubSection}
              advId={advId}
              item={item}
              canDuplicate={item.elements[0].content.canDuplicate}
            />
          </div>
        )}
      </Draggable>
    );
  });

  return (
    <S.WrapperSingleSection
      ref={drop}
      role='Dustbin'
      style={{ backgroundColor }}
    >
      <S.TitleSection>
        <S.InputWrapper
          isedit={isEditableTitle}
          issubionsvisible={isSubsectionsVisible}
        >
          <button type='button'>
            <ArrowDropDownOutlinedIcon
              onClick={() => setSubsectionsVisible(!isSubsectionsVisible)}
            />
          </button>
          <div>
            <Controller
              errors={errors}
              defaultValue={
                defaultValues?.title || `Sekcja ${sectionIndex + 1}`
              }
              control={control}
              rules={{ required: 'To pole jest wymagane' }}
              name={`form[${sectionIndex}].title`}
              render={({ onChange, value }) => (
                <>
                  <S.Input
                    onChange={onChange}
                    value={value}
                    placeholder='Wpisz tytuł'
                    disabled={!isEditableTitle}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name={`form[${sectionIndex}].title`}
              render={({ message }) => (
                <ErrorAlert error={message} maxWidth='200px' type='simple' />
              )}
            />
          </div>
        </S.InputWrapper>
        {isEditableTitle && (
          <button type='button' onClick={() => setIsEditableTitle(false)}>
            <CheckCircleOutlineOutlinedIcon
              fontSize='large'
              style={{ color: '#3B9833', opacity: 0.7 }}
            />
          </button>
        )}
        {!isEditableTitle && (
          <S.ButtonsTitleSection>
            <button type='button' onClick={() => setIsEditableTitle(true)}>
              <EditIcon style={{ color: '#1690F2', cursor: 'pointer' }} />
            </button>
            {sectionIndex !== 0 && sectionIndex !== 1 && sectionIndex !== 2 && (
              <button type='button' onClick={() => deleteSection(id)}>
                <DeleteIcon style={{ color: '#D5001F', cursor: 'pointer' }} />
              </button>
            )}
          </S.ButtonsTitleSection>
        )}
      </S.TitleSection>
      <S.SectionDetails issectionvisible={isSubsectionsVisible}>
        {subSectionsRender}
      </S.SectionDetails>
    </S.WrapperSingleSection>
  );
};

export default SingleSection;
