import React, { useEffect, useState } from 'react';
import { Button } from 'm-web-components';
import Switch from '@material-ui/core/Switch';

import DataPicker from 'components/atoms/DataPicker';
import { formatDate } from 'helpers/newDateFormat';

import * as S from './Reports.css';
import { api } from 'API';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { AccessibilityLabel } from '../../tables/Table.css';
import { TOAST_POSITION } from 'constans/constantsToast';

const ReportGenerator = () => {
  const history = useHistory();
  const [positionsList, setPositionsList] = useState([]);
  const [title, setTitle] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [ids, setIds] = useState([]);
  const [isDetailed, setIsDetailed] = useState(false);

  const getAllPositions = async () => {
    const { data } = await api.getAllPositions();
    setPositionsList(data);
  };

  useEffect(() => {
    getAllPositions();
  }, []);

  const onSubmit = async e => {
    e.preventDefault();

    const body = {
      name: title,
      startDate: startDate ? formatDate(startDate) : '',
      endDate: endDate ? formatDate(endDate) : '',
      positionIds: ids,
      isDetailed,
    };

    try {
      const { data } = await api.genereteRaport(body);
      history.push(`/admin/report-print/${data}`);
    } catch (e) {
      toast.error(
        <div>
          <p>Wystąpił błąd podczas tworzenia raportu.</p>
          {e.response?.data?.errors?.map(err => (
            <p>{err.defaultMessage}</p>
          ))}
        </div>,
        TOAST_POSITION
      );
    }
  };

  const toggleId = (value, id) => {
    let newIds = ids;

    if (!value) newIds = newIds.filter(el => el !== id);
    if (value) newIds = [...newIds, id];

    setIds(newIds);
  };

  const positionsCheckboxes = positionsList.map(el => (
    <S.CheckboxWrapper key={el.id}>
      <label htmlFor={`checkbox-${el.id}`}>
        <input
          type='checkbox'
          id={`checkbox-${el.id}`}
          checked={ids.includes(el.id)}
          onChange={e => toggleId(e.target.checked, el.id)}
        />
        {el.name}
      </label>
    </S.CheckboxWrapper>
  ));

  return (
    <S.Wrapper>
      <form onSubmit={onSubmit}>
        <div>
          <S.Label htmlFor='report-title'>Nazwa raportu</S.Label>
          <S.Input
            id='report-title'
            value={title}
            onChange={e => setTitle(e.target.value)}
            type='text'
            name='title'
            placeholder='Wpisz nazwę raportu...'
            required
          />
        </div>
        <div>
          <S.Label htmlFor='datePicker'>Zakres dat</S.Label>
          <DataPicker
            id='datePicker'
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <S.Label>Raport szczegółowy</S.Label>
          <AccessibilityLabel htmlFor='isDetailed'>
            <p>Zaznacz wszystkie</p>
            <Switch
              id='isDetailed'
              checked={isDetailed}
              onChange={e => setIsDetailed(e.target.checked)}
              color='primary'
              name='isDetailed'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </AccessibilityLabel>
        </div>

        <div>
          <S.Label>Stanowiska</S.Label>
          <S.WrapperPositions>{positionsCheckboxes}</S.WrapperPositions>
        </div>
        <Button colorType='primary' border='none'>
          Generuj raport
        </Button>
      </form>
    </S.Wrapper>
  );
};

export default ReportGenerator;
